import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Card, Avatar } from 'components'
import style from './UserListItem.module.css'

type UserListItemPropType = {
    classes?: string
    name: string
    surname: string
    online: boolean
    title?: string
    photo?: string
    url?: string
}

const UserListItem: React.FC<UserListItemPropType> = ({
    children,
    classes,
    name = '',
    surname = '',
    online,
    title,
    photo,
    url,
}) => {
    const { t } = useTranslation()

    return (
        <Card.Item classes={cn(style.item, classes)} url={url}>
            <Card.Body classes={style.body}>
                <div className={style.avatar}>
                    {online && (
                        <span className={style.online} title={t('user_profile_online')} />
                    )}

                    <Avatar
                        src={photo}
                        name={name}
                        surname={surname}
                        width={60}
                        height={60}
                    />
                </div>

                <div className={style.content}>
                    <div className={style.name}>
                        {title || `${name} ${surname}`}
                    </div>

                    {children}
                </div>
            </Card.Body>
        </Card.Item>
    )
}

export default UserListItem
