import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { IStoreProfile } from 'interfaces'
import {
    TAddGoodsProps,
    TUpdateGoodsProps,
    TAddGoodsResponse,
    TUpdateGoodsResponse,
} from 'services/MarketService'
import { TAddGoodsMutationFnError, TUpdateGoodsMutationFnError } from 'containers/Market/hooks/useFetchGoods'
import { FieldName, formDataInitial, FormDataType } from 'forms/ShopGoodsForm/ShopGoodsForm'
import { useFetchGoodsTypes, useFetchGoodsAvailableStatus, useMutationGoods } from 'containers/Market/hooks'
import { useFetchStoreCatalogTree } from 'containers/Store/hooks'
import { ShopGoodsForm } from 'forms'

export type TCommonShopGoods = Pick<TAddGoodsProps, 'store'> | TUpdateGoodsProps

type ShopGoodsActionPropType = {
    classes?: string
    shopGoodsProps: TCommonShopGoods
    shopProfile?: IStoreProfile
    onPreview?: () => void
    onChange?: (data: FormDataType) => void
    onSuccess?: (data: TAddGoodsResponse | TUpdateGoodsResponse) => void
    onError?: (err: TAddGoodsMutationFnError | TUpdateGoodsMutationFnError) => void
}

const REGEXP_PRICE_NUMBER = /^[1-9]\d{0,11}(\.\d+)?$/

const ShopGoodsAction: React.FC<ShopGoodsActionPropType> = ({
    classes,
    shopGoodsProps,
    shopProfile,
    onPreview,
    onChange,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation()

    const [initialValues, setInitialValues] = useState<FormDataType>({
        ...formDataInitial,
        ...shopGoodsProps,
    })

    // FIXME useFetch on ready api request
    const [dataBrands] = useState([])

    const {
        data: dataStoreCatalogTree,
    } = useFetchStoreCatalogTree({
        storeId: shopGoodsProps.store,
        tree_only: true,
    })

    const { data: dataGoodsTypes } = useFetchGoodsTypes()

    const { data: dataGoodsAvailableStatus } = useFetchGoodsAvailableStatus()

    const { add: addGoods, update: updateGoods } = useMutationGoods()

    const handlerSubmit = ({ id, images, ...params }: FormDataType) => {
        if (id) {
            return updateGoodsAction({
                id,
                images: images?.filter((item) => !item.id || (item.id && item.delete)), // only new or deleted images
                ...params,
            })
                .then(onSuccess)
                .catch(onError)
        }

        return addGoodsAction({ images, ...params })
            .then(onSuccess)
            .catch(onError)
    }

    function addGoodsAction(params: TAddGoodsProps) {
        return addGoods.mutateAsync(params)
    }

    function updateGoodsAction(params: TUpdateGoodsProps) {
        return updateGoods.mutateAsync(params)
    }

    useEffect(() => {
        if (shopGoodsProps) {
            setInitialValues((prevState) => ({ ...prevState, ...shopGoodsProps }))
        }
    }, [shopGoodsProps])

    useEffect(() => {
        if (dataGoodsTypes?.length) {
            /** Set default product type */
            setInitialValues((prevState) => {
                if (!prevState[FieldName.productType]) {
                    return {
                        ...prevState,
                        [FieldName.productType]: dataGoodsTypes[0].id,
                    }
                }

                return prevState
            })
        }
    }, [dataGoodsTypes])

    useEffect(() => {
        if (dataStoreCatalogTree?.length) {
            /** Set default category */
            setInitialValues((prevState) => {
                if (!prevState[FieldName.storeCatalog].length || prevState[FieldName.storeCatalog][0].id === 0) {
                    return {
                        ...prevState,
                        [FieldName.storeCatalog]: [{
                            id: dataStoreCatalogTree[0].id,
                            name: dataStoreCatalogTree[0].name,
                            sort: dataStoreCatalogTree[0].sort + 100,
                        }],
                    }
                }

                return prevState
            })
        }
    }, [dataStoreCatalogTree])

    useEffect(() => {
        if (dataGoodsAvailableStatus?.length) {
            /** Set default goods available status */
            setInitialValues((prevState) => {
                if (!prevState[FieldName.availableStatus]) {
                    return {
                        ...prevState,
                        [FieldName.availableStatus]: dataGoodsAvailableStatus[0].id,
                    }
                }

                return prevState
            })
        }
    }, [dataGoodsAvailableStatus])

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.productType]) {
                    errors[FieldName.productType] = t('error_field_is_empty')
                }
                if (!values[FieldName.name]) {
                    errors[FieldName.name] = t('error_field_is_empty')
                }
                if (!values[FieldName.vendorCode]) {
                    errors[FieldName.vendorCode] = t('error_field_is_empty')
                }
                if (values[FieldName.price] && !REGEXP_PRICE_NUMBER.test(String(values[FieldName.price]))) {
                    errors[FieldName.price] = true
                }
                if (!values[FieldName.price]) {
                    errors[FieldName.price] = t('error_field_is_empty')
                }
                if (values[FieldName.retailPrice] && !REGEXP_PRICE_NUMBER.test(String(values[FieldName.retailPrice]))) {
                    errors[FieldName.retailPrice] = true
                }
                if (!values[FieldName.retailPrice]) {
                    errors[FieldName.retailPrice] = t('error_field_is_empty')
                }
                if (!values[FieldName.weight] && values[FieldName.productType] === 1) {
                    errors[FieldName.weight] = t('error_field_is_empty')
                }
                if (!values[FieldName.storeCatalog].length || values[FieldName.storeCatalog][0].id === 0) {
                    errors[FieldName.storeCatalog] = t('error_field_is_empty')
                }
                if (values[FieldName.availabilityDateFrom] && values[FieldName.availabilityDateTo]) {
                    const dateFrom = values[FieldName.availabilityDateFrom]!
                    const dateTo = values[FieldName.availabilityDateTo]!

                    /** error: end date before the start date */
                    if (new Date(dateFrom) > new Date(dateTo)) {
                        errors[FieldName.availabilityDateTo] = t('end_sale_date_earlier_than_start_error_text')
                    }
                }

                return errors
            }}
            render={({ handleSubmit, submitting }) => (
                <ShopGoodsForm
                    classes={classes}
                    isSubmitting={submitting}
                    productType={dataGoodsTypes}
                    brands={dataBrands}
                    catalog={dataStoreCatalogTree}
                    shopProfile={shopProfile}
                    availableStatus={dataGoodsAvailableStatus}
                    onPreview={onPreview}
                    onChange={onChange}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ShopGoodsAction
