import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IOrderDelivery,
    IOrderDeliveryList,
    IOrderDeliveryListItem,
    // IOrderDeliveryStatus,
} from 'interfaces'
import { CustomSelect, Tag } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './OrderDeliveryTypeForm.module.css'

export enum FieldName {
    deliveryMethod = 'delivery_method',
    deliveryStatus = 'deliveryStatus',
}

export type FormDataType = {
    [FieldName.deliveryMethod]?: number
    [FieldName.deliveryStatus]?: number
}

export type OrderDeliveryStatusFormPropType = {
    isSubmitting: boolean
    deliveryMethod?: IOrderDelivery
    deliveryList?: IOrderDeliveryList
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const OrderDeliveryTypeForm: React.FC<OrderDeliveryStatusFormPropType> = ({
    isSubmitting,
    deliveryMethod,
    deliveryList,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const deliveryLastItem = useMemo(() => {
        return deliveryList
            && deliveryList.delivery_list.reduce((acc, item, index) => {
                return index === 0 ? item : acc
            }, {} as IOrderDeliveryListItem)
    }, [deliveryList])

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                    {t('order_delivery_type')}
                </div>
                <Field
                    <FormDataType[FieldName.deliveryMethod]>
                    name={FieldName.deliveryMethod}
                    render={({ input, meta }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field disabled classes={style.fieldSelect}>
                                {input.value && deliveryMethod?.name}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {/* */}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.fieldText}>
                    {t('order_delivery_status')}
                </div>
                <div className={style.fieldValue}>
                    {!deliveryLastItem?.status && '-'}
                    {deliveryLastItem?.status && (
                        <>
                            <Tag classes={style.tag} classesContent={style.tagContent}>
                                {deliveryLastItem.status.name}
                            </Tag>
                            {deliveryList!.delivery_list.length > 1 && (
                                <span className={style.fieldValueMore}>
                                    {`+ ${deliveryList!.delivery_list.length - 1}`}
                                </span>
                            )}
                        </>
                    )}
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.fieldText}>
                    {t('order_delivery_track')}
                </div>
                <div className={style.fieldValue}>
                    {!deliveryLastItem?.track_info?.track_number && '-'}
                    {deliveryLastItem?.track_info?.track_number && (
                        deliveryLastItem.track_info.track_number
                    )}
                </div>
            </div>
        </form>
    )
}

export default OrderDeliveryTypeForm
