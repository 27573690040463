import React from 'react'
import { Form } from 'react-final-form'

import { IPersonalStore, IGoods } from 'interfaces'
import { FormDataType, FieldName, formDataInitial } from 'forms/WarehouseLeftoversFilterForm/WarehouseLeftoversFilterForm'
import { WarehouseLeftoversFilterForm } from 'forms'

type WarehouseLeftoversFilterActionPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    leftoversProps?: any // TODO
    stores?: IPersonalStore[]
    goods?: IGoods[]
    onChange?: (value: FormDataType) => void
    onSuccess: (value: FormDataType) => void
}

const WarehouseLeftoversFilterAction: React.FC<WarehouseLeftoversFilterActionPropType> = ({
    classes,
    classesBody,
    classesFooter,
    leftoversProps,
    stores,
    goods,
    onChange,
    onSuccess,
}) => {
    const handlerSubmit = (params: FormDataType) => {
        onSuccess(params)
    }

    const handlerChange = (params: FormDataType) => {
        if (onChange) {
            onChange(params)
        }
    }

    return (
        <Form
            initialValues={formDataInitial}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.store].length) {
                    errors[FieldName.store] = true
                }
                // if (!values[FieldName.goods].length) {
                //     errors[FieldName.goods] = true
                // }

                return errors
            }}
            render={({ submitting, handleSubmit }) => (
                <WarehouseLeftoversFilterForm
                    classes={classes}
                    classesBody={classesBody}
                    classesFooter={classesFooter}
                    stores={stores}
                    goods={goods}
                    isSubmitting={submitting}
                    onChange={handlerChange}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default WarehouseLeftoversFilterAction
