import React from 'react'
import cn from 'classnames'

import { IOrder } from 'interfaces'
import { OrderPaymentStatusAction } from 'form-actions'
import style from './OrdersOrderInfo.module.css'

type OrdersOrderInfoPropType = {
    classes?: string
    data: IOrder
    onClickOrder: () => void
}

const OrdersOrderInfo: React.FC<OrdersOrderInfoPropType> = ({ classes, data, onClickOrder }) => {
    return (
        <div className={cn(style.wrap, classes)}>
            <OrderPaymentStatusAction
                orderProps={{
                    storeId: data.store.id,
                    orderId: data.id,
                    payment_status: data.payment_status,
                }}
                paymentSum={data.order_sum}
                paymentCurrency={data.store.currency}
                paymentDate={data.payment_date}
            />

            {/* Not shown in the StartUp version */}
            {/* <div className={style.actions}>
                <Button
                    classes={style.action}
                    styleType="text"
                    text={t('order_details')}
                    onClick={onClickOrder}
                />
            </div> */}
        </div>
    )
}

export default OrdersOrderInfo
