import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    SetDataOptions,
    useQueryClient,
    useQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IPersonalStore } from 'interfaces'
import {
    TMarketServiceError,
    TPersonalStoresResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnError = AxiosError<TMarketServiceError>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string]
export type TQueryFnOpts = UseQueryOptions<TPersonalStoresResponse, TError, TPersonalStoresResponse, TQueryKey>

const key = QueryCacheKeys.personalStores

/**
 * Хук API получить магазины компании
 */
export default function useFetchPersonalStores(opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`], () => {
        return MarketService.fetchPersonalStores()
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useInvalidatePersonalStores() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = ({ exact = true, ...filters }: InvalidateQueryFilters = {}, options: InvalidateOptions = {}) => {
        return queryClient.invalidateQueries([`${key}-${user.id}`], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}

/**
 * Operations with query cached data personal stores
 */
export function useQueryDataPersonalStores(options?: SetDataOptions) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    /**
     * Add to cache new item
     */
    const add = (data: IPersonalStore) => {
        return queryClient.setQueryData<TPersonalStoresResponse>([`${key}-${user.id}`], (queryData) => {
            return queryData ? [...queryData, data] : [data]
        }, options)
    }

    /**
     * Update item in cache
     */
    const update = (data: IPersonalStore) => {
        return queryClient.setQueryData<TPersonalStoresResponse>([`${key}-${user.id}`], (queryData) => {
            if (queryData) {
                return queryData.map((item) => {
                    return item.id === data.id ? { ...item, ...data } : item
                })
            }

            return queryData
        }, options)
    }

    return {
        add,
        update,
    }
}
