import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { PageTitle, Button, Chevron } from 'components'
import style from './PageHeader.module.css'

type PageHeaderPropType = {
    classes?: string
    classesTitle?: string
    classesContent?: string
    title?: string
    titlePos?: 'none' | 'left' | 'center' | 'right'
    contentPos?: 'left' | 'center' | 'right'
    onClickBack?: () => void
}

const PageHeader: React.FC<PageHeaderPropType> = ({
    children,
    classes,
    classesTitle,
    classesContent,
    title,
    titlePos = 'left',
    contentPos = 'right',
    onClickBack,
}) => {
    const { t } = useTranslation()

    return (
        <div className={cn(style.header, classes)}>
            {onClickBack && (
                <div className={style.action}>
                    <Button
                        classes={style.buttonBack}
                        styleType="transparent"
                        title={t('back')}
                        onClick={onClickBack}
                    >
                        <Chevron size={15} type="left" />
                    </Button>
                </div>
            )}
            {title && (
                <PageTitle
                    classes={cn(style.title, style[`title_${titlePos}`], classesTitle)}
                    title={title}
                />
            )}
            {children && (
                <div className={cn(style.content, classesContent)}>
                    {children}
                </div>
            )}
        </div>
    )
}

export default PageHeader
