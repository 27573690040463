import React from 'react'
import { Form } from 'react-final-form'

import { IOrderDelivery } from 'interfaces'
import { TCompanyOrderUpdateProps } from 'services/MarketService'
import { FormDataType } from 'forms/OrderDeliveryTypeForm/OrderDeliveryTypeForm'
import { useFetchOrderDeliveryList } from 'containers/Orders/hooks'
import { OrderDeliveryTypeForm } from 'forms'

type OrderDeliveryTypeActionPropType = {
    orderProps?: TCompanyOrderUpdateProps
    deliveryMethod?: IOrderDelivery
}

const OrderDeliveryTypeAction: React.FC<OrderDeliveryTypeActionPropType> = ({ orderProps, deliveryMethod }) => {
    const {
        data: dataOrderDeliveryList,
    } = useFetchOrderDeliveryList({
        orderId: orderProps?.orderId ?? 0,
    }, {
        enabled: !!orderProps,
    })

    const handlerSubmit = (params: FormDataType) => {
        //
    }

    return (
        <Form
            initialValues={{
                delivery_method: orderProps?.delivery_method,
            }}
            onSubmit={handlerSubmit}
            render={({ handleSubmit, submitting }) => (
                <OrderDeliveryTypeForm
                    isSubmitting={submitting}
                    deliveryMethod={deliveryMethod}
                    deliveryList={dataOrderDeliveryList}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default OrderDeliveryTypeAction
