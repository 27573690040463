import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IPost } from 'interfaces'
import { TDeletePostProps } from 'services/CommunityService'
import { TBlockUserProps, TUnblockUserProps } from 'services/NetworkService'
import { CardSize } from 'enums'
import { BREAKPOINTS } from 'config/app'
import { CardContainer } from 'layout'
import { Context, ContextType } from 'containers/Community/Context'
import {
    useFetchCommunity,
    useMutationCommunity,
    useFetchPostLikes,
    useMutationSubscribe,
    useMutationUnsubscribeCompany,
    useMutationUnsubscribeStore,
    useMutationBlocks,
} from 'containers/Community/hooks'
import { useInvalidateProfile } from 'containers/User/hooks'
import { PostLikeUserCard } from 'containers/Community/components'
import { Modal, List, Loader } from 'components'
import { formDataInitial } from 'forms/PostForm/PostForm'
import { PostAction } from 'form-actions'
import { useWindowResize, useAlertDialog } from 'hooks'
import { PostDto } from 'dto'
import styleOverlay from 'styles/modules/overlay.module.css'
import { useMutationPost } from './hooks'

type CommunityPropType = {
    /** on need invalidate post */
    onUpdatePost: () => void
    /** on need update query post data */
    onEditPost: (data: IPost) => void
    onSuccess: (value: string) => void
    onError?: (value: string) => void
}

const Community: React.FC<CommunityPropType> = ({
    children,
    onUpdatePost,
    onEditPost,
    onSuccess,
    onError = () => {},
}) => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()
    const { showAlertDialog } = useAlertDialog()

    const [postProps, setPostProps] = useState(formDataInitial)
    const [postData, setPostData] = useState<IPost>()

    const [isOpenModalPostLikes, setIsOpenModalPostLikes] = useState(false)
    const [isOpenModalPostForm, setIsOpenModalPostForm] = useState(false)

    const [context, setContext] = useState<ContextType>({
        isLoading: false,
        onLikePost: handlerLikePost,
        onLikesPost: handlerLikesPost,
        onEditPost: handlerEditPost,
        onDeletePost: handlerDeletePost,
        onUnsubscribeUser: handlerUnsubscribePostUser,
        onUnsubscribeUserInStore: handlerUnsubscribeUserInStore,
        onUnsubscribeStore: handlerUnsubscribePostStore,
        onUnsubscribeCommunity: handlerUnsubscribePostCommunity,
        onUnsubscribeCompany: handlerUnsubscribePostCompany,
        onBlockUser: handlerBlockPostUser,
        onUnblockUser: handlerUnblockPostUser,
    })

    const { data: dataCommunity } = useFetchCommunity({
        can_publish: true,
    }, {
        enabled: isOpenModalPostForm,
    })

    const {
        isInitialLoading: isLoadingPostLikes,
        data: dataPostLikes,
    } = useFetchPostLikes({
        id: postData?.id ?? 0,
    }, {
        enabled: isOpenModalPostLikes && !!postData,
    })

    const { unsubscribe: unsubscribeCommunity } = useMutationCommunity()

    const { deletePost, likePost } = useMutationPost()

    const { blockUser, unblockUser } = useMutationBlocks()

    const { unsubscribeUser } = useMutationSubscribe()

    const { unsubscribeCompany } = useMutationUnsubscribeCompany()

    const { unsubscribeStore, unsubscribeStoreUser } = useMutationUnsubscribeStore()

    const { invalidate: invalidateProfile } = useInvalidateProfile()

    const itemPostLikeSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerCloseModalPostLikes = () => {
        setIsOpenModalPostLikes(false)
    }

    const handlerEditCompletePost = (post: IPost) => {
        setIsOpenModalPostForm(false)
        onEditPost(post)
    }

    function handlerLikePost(post: IPost) {
        likePostAction(post)
    }

    function handlerLikesPost(post: IPost) {
        setPostData(post)
        setIsOpenModalPostLikes(true)
    }

    function handlerEditPost(post: IPost) {
        setPostData(post)
        setPostProps({ ...formDataInitial, ...PostDto.getPostProps(post) })
        setIsOpenModalPostForm(true)
    }

    function handlerDeletePost(post: IPost, message: string = '') {
        showAlertDialog({
            message,
            payload: { post },
            onConfirm: removePost,
        })
    }

    function handlerUnsubscribePostUser(userId: number, message: string = '') {
        showAlertDialog({
            message,
            payload: { userId },
            onConfirm: unsubscribePostUser,
        })
    }

    function handlerUnsubscribeUserInStore(storeId: number, userId: number, message: string = '') {
        showAlertDialog({
            message,
            payload: { storeId, userId },
            onConfirm: unsubscribeUserInStore,
        })
    }

    function handlerUnsubscribePostStore(storeId: number, message: string = '') {
        showAlertDialog({
            message,
            payload: { storeId },
            onConfirm: unsubscribePostStore,
        })
    }

    function handlerUnsubscribePostCommunity(communityId: number, message: string = '') {
        showAlertDialog({
            message,
            payload: { communityId },
            onConfirm: unsubscribePostCommunity,
        })
    }

    function handlerUnsubscribePostCompany(companyId: number, message: string = '') {
        showAlertDialog({
            message,
            payload: { companyId },
            onConfirm: unsubscribePostCompany,
        })
    }

    function handlerBlockPostUser(userId: number, message: string) {
        showAlertDialog({
            message,
            payload: { userId },
            onConfirm: blockPostUser,
        })
    }

    function handlerUnblockPostUser(userId: number) {
        unblockUserAction({ userId })
    }

    function removePost({ post }: { post: IPost }) {
        const { id, ...props } = PostDto.getPostProps(post)

        if (id) {
            deletePostAction({ id, ...props })
        }
    }

    function unsubscribePostUser({ userId }: { userId: number }) {
        unsubscribeUserAction(userId)
    }

    function unsubscribeUserInStore({ storeId, userId }: { storeId: number, userId: number }) {
        unsubscribeStoreUserAction(storeId, userId)
    }

    function unsubscribePostStore({ storeId }: { storeId: number }) {
        unsubscribePostStoreAction(storeId)
    }

    function unsubscribePostCommunity({ communityId }: { communityId: number }) {
        unsubscribeCommunityAction(communityId)
    }

    function unsubscribePostCompany({ companyId }: { companyId: number }) {
        unsubscribePostCompanyAction(companyId)
    }

    function blockPostUser({ userId }: { userId: number }) {
        blockPostUserAction({ userId })
    }

    function likePostAction(post: IPost) {
        setContext((prevState) => ({ ...prevState, isLoading: true }))
        likePost.mutate({ id: post.id }, {
            onSuccess: (res) => {
                if (res) {
                    // TODO invalidate post in cache
                    // onLikeCompletePost({ ...post, ...res })
                    onEditPost({ ...post, ...res })
                }
            },
            onSettled: () => {
                setContext((prevState) => ({ ...prevState, isLoading: false }))
            },
        })
    }

    function deletePostAction(params: TDeletePostProps) {
        deletePost.mutate({ ...params, isPublished: false }, {
            onSuccess: (res) => {
                if (res) {
                    onUpdatePost()
                }
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function unsubscribeUserAction(userId: number) {
        unsubscribeUser.mutate({ userId }, {
            onSuccess: (res) => {
                invalidateProfile({ userId })
                onUpdatePost()
                onSuccess(res)
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function unsubscribeStoreUserAction(storeId: number, userId: number) {
        unsubscribeStoreUser.mutate({ storeId, userId }, {
            onSuccess: (res) => {
                onUpdatePost()
                onSuccess(res)
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function unsubscribePostStoreAction(storeId: number) {
        unsubscribeStore.mutate({ storeId }, {
            onSuccess: (res) => {
                onUpdatePost()
                onSuccess(res)
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function unsubscribeCommunityAction(communityId: number) {
        unsubscribeCommunity.mutate({ communityId }, {
            onSuccess: () => {
                onUpdatePost()
                onSuccess(t('dialog_success_title'))
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function unsubscribePostCompanyAction(companyId: number) {
        unsubscribeCompany.mutate({ companyId }, {
            onSuccess: (res) => {
                onUpdatePost()
                onSuccess(res)
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function blockPostUserAction(params: TBlockUserProps) {
        blockUser.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile(params)
                onSuccess(res)
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    function unblockUserAction(params: TUnblockUserProps) {
        unblockUser.mutate(params, {
            onSuccess: (res) => {
                invalidateProfile(params)
                onSuccess(res)
            },
            onError: ([errorText]) => {
                onError(errorText)
            },
        })
    }

    return (
        <>
            <Context.Provider value={context}>
                {children}
            </Context.Provider>

            <Modal
                isOpen={isOpenModalPostForm}
                size="medium"
                classesOverlay={styleOverlay.overlay_light}
                onClose={() => setIsOpenModalPostForm(false)}
            >
                <Modal.Header
                    titlePos="left"
                    title={t('ui_posts_edit_post_title')}
                    isCloseButton
                />
                <PostAction
                    isActiveCommunity={false}
                    postProps={postProps}
                    communities={dataCommunity}
                    onComplete={handlerEditCompletePost}
                />
            </Modal>

            <Modal
                isOpen={isOpenModalPostLikes}
                size="medium"
                onClose={handlerCloseModalPostLikes}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={`${t('likes')} ${dataPostLikes?.length || ''}`}
                />
                <Modal.Body background>
                    {isLoadingPostLikes && (
                        <Loader />
                    )}
                    {!isLoadingPostLikes && dataPostLikes && (
                        <CardContainer>
                            <List limit={12}>
                                {dataPostLikes.map((item) => (
                                    <PostLikeUserCard
                                        data={item}
                                        size={itemPostLikeSize}
                                        key={item.id}
                                    />
                                ))}
                            </List>
                        </CardContainer>
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Community
