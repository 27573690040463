import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    InvalidateOptions,
    InvalidateQueryFilters,
    InfiniteData,
    SetDataOptions,
    Updater,
    useQueryClient,
    useQuery,
    useInfiniteQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TCommunityServiceError,
    TPostsCommunityProps,
    TPostsResponse,
} from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TPostsCommunityProps
export type TFetchFnResponse = TPostsResponse
export type TFetchFnError = AxiosError<TCommunityServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TFetchFnResponse,
    TError,
    TFetchFnResponse,
    TFetchFnResponse,
    TQueryKey
>

const key = QueryCacheKeys.postsCommunity

/**
 * Хук API получение списка постов сообщества
 */
export default function useFetchPostsUser(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchPostsCommunity(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, opts)
}

export function useFetchInfinitePostsCommunity(initParams: TFetchFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return CommunityService.fetchPostsCommunity(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, offset: pages.length * lastPage.length }
        },
        ...opts,
    })
}

export function useInvalidatePostsCommunity() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = (
        params: TFetchFnParams, {
            exact = true,
            ...filters
        }: InvalidateQueryFilters = {},
        options: InvalidateOptions = {},
    ) => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}

export function useSetInfiniteQueryDataPostsCommunity() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const setQueryData = (
        params: TFetchFnParams,
        updater: Updater<InfiniteData<TFetchFnResponse> | undefined, InfiniteData<TFetchFnResponse> | undefined>,
        options?: SetDataOptions,
    ) => {
        queryClient.setQueryData([`${key}-${user.id}`, params], updater, options)
    }

    return {
        setQueryData,
    }
}
