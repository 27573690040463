import React, { useMemo } from 'react'
import { match, NavLink, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMenuNavItemData } from 'interfaces'
import { AppMode } from 'enums'
import { APP_URL } from 'config/app'
import { Badge, SvgResource } from 'components'
import style from './MenuNavLink.module.css'

type MenuNavLinkPropType = {
    data: IMenuNavItemData
    mode: AppMode
    isDisabled?: boolean
}

const ICON_SIZE = {
    [AppMode.user]: 30,
    [AppMode.business]: 32,
}

const MenuNavLink: React.FC<MenuNavLinkPropType> = ({ data, mode, isDisabled }) => {
    const {
        url,
        icon,
        iconClassName,
        translateKey,
        countValue,
        countEvent,
    } = data

    const { t } = useTranslation()
    const { pathname } = useLocation()

    const isMarketplace = useMemo(() => {
        return pathname.includes('market') || pathname.includes('store') || pathname.includes('goods')
    }, [pathname])

    const isShop = useMemo(() => {
        return pathname.includes('shop')
    }, [pathname])

    return (
        <NavLink
            className={cn(style.link, style[`link_${mode}`], { [style.link_disabled]: isDisabled })}
            activeClassName={cn(style.active, style[`active_${mode}`])}
            to={{ pathname: url }}
            isActive={(m: match | null): boolean => {
                return (isMarketplace && url === APP_URL.market)
                    || (isShop && url === APP_URL.stores)
                    || !!m
            }}
        >
            <span className={cn(style.icon, style[`icon_${mode}`])}>
                {icon && (
                    <SvgResource
                        isImgTag={false}
                        classes={iconClassName}
                        resourceKey={icon}
                        width={ICON_SIZE[mode]}
                        height={ICON_SIZE[mode]}
                    />
                )}
            </span>
            {translateKey && (
                <span className={cn(style.text, style[`text_${mode}`])}>
                    {t(translateKey)}
                </span>
            )}
            {!!countValue?.value && (
                <span className={style.count}>
                    {countValue.value}
                </span>
            )}
            {!!countEvent?.value && (
                <Badge classes={style.badge}>
                    {countEvent.value}
                </Badge>
            )}
        </NavLink>
    )
}

export default MenuNavLink
