import React from 'react'
import { Form } from 'react-final-form'

import { TCompanyOrderUpdateProps } from 'services/MarketService'
import { FormDataType } from 'forms/OrderPaymentStatusForm/OrderPaymentStatusForm'
import { useFetchPaymentStatuses } from 'containers/App/hooks'
import { OrderPaymentStatusForm } from 'forms'

type OrderPaymentStatusActionPropType = {
    orderProps?: TCompanyOrderUpdateProps
    paymentDate?: string
    paymentSum: number
    paymentCurrency: string
}

const OrderPaymentStatusAction: React.FC<OrderPaymentStatusActionPropType> = ({
    orderProps,
    paymentDate,
    paymentSum,
    paymentCurrency,
}) => {
    const { data: dataPaymentStatuses } = useFetchPaymentStatuses()

    const handlerSubmit = (params: FormDataType) => {
        //
    }

    return (
        <Form
            initialValues={{
                payment_status: orderProps?.payment_status,
            }}
            onSubmit={handlerSubmit}
            render={({ handleSubmit, submitting }) => (
                <OrderPaymentStatusForm
                    isSubmitting={submitting}
                    paymentStatuses={dataPaymentStatuses}
                    paymentDate={paymentDate}
                    paymentSum={paymentSum}
                    paymentCurrency={paymentCurrency}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default OrderPaymentStatusAction
