import React, { useState } from 'react'
import { Form } from 'react-final-form'

import { FormDataType, FieldName, formDataInitial } from 'forms/ReportsProductForm/ReportsProductForm'
import { useFetchStores } from 'containers/Store/hooks'
import { ReportsProductForm } from 'forms'

type ReportsProductActionPropType = {
    classes?: string
    onChange?: (data: FormDataType) => void
    onSuccess?: (data:FormDataType) => void
}

const ReportsProductAction: React.FC<ReportsProductActionPropType> = ({
    classes,
    onChange,
    onSuccess,
}) => {
    const [initialValues] = useState<FormDataType>(formDataInitial)

    // FIXME useFetch on ready api request
    const [dataDivideByStores] = useState([
        { id: 0, name: 'Выключена' },
        { id: 1, name: 'Включена' },
    ])

    const { data: dataStores } = useFetchStores()

    const handlerSubmit = (params: FormDataType) => {
        if (onSuccess) {
            onSuccess(params)
        }

        return Promise.resolve()
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.store]) {
                    errors[FieldName.store] = true
                }
                if (values[FieldName.dateFrom] && values[FieldName.dateTo]) {
                    const dateFrom = values[FieldName.dateFrom]!
                    const dateTo = values[FieldName.dateTo]!

                    /** error: end date before the start date */
                    if (new Date(dateFrom) > new Date(dateTo)) {
                        errors[FieldName.dateTo] = true
                    }
                }

                return errors
            }}
            render={({ handleSubmit, submitting }) => (
                <ReportsProductForm
                    classes={classes}
                    isSubmitting={submitting}
                    stores={dataStores}
                    divideByStores={dataDivideByStores}
                    onChange={onChange}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ReportsProductAction
