import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardSize } from 'enums'
import { CardV2 } from 'components'
import style from './StoreItemAdd.module.css'

type StoreItemAddPropType = {
    itemSize: keyof typeof CardSize
    url: string
}

const StoreItemAdd: React.FC<StoreItemAddPropType> = ({ itemSize, url }) => {
    const { t } = useTranslation()

    return (
        <CardV2
            classes={style.item}
            size={itemSize}
            url={url}
        >
            <CardV2.Image
                classes={style.image}
                isPlaceholder={false}
            >
                <div className={style.add}>
                    <div className={style.iconAdd} />
                </div>
            </CardV2.Image>
            <CardV2.Body
                classes={style.body}
                classesTitle={style.title}
                title={t('add_store')}
            />
        </CardV2>
    )
}

export default StoreItemAdd
