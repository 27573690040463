import React from 'react'

type FileDropPropType = {
    classes?: string
    onDragEnter?: (e: React.DragEvent<HTMLDivElement>) => void
    onDragLeave?: (e: React.DragEvent<HTMLDivElement>) => void
    onDragOver?: (e: React.DragEvent<HTMLDivElement>) => void
    onDrop?: (files: FileList, e: React.DragEvent<HTMLDivElement>) => void
    onClick?: () => void
}

const FileDrop: React.FC<FileDropPropType> = ({
    children,
    classes,
    onDragEnter,
    onDragLeave,
    onDragOver,
    onDrop,
    onClick,
}) => {
    const handlerDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()

        if (onDragOver) {
            onDragOver(e)
        }
    }

    const handlerDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()

        if (onDrop) {
            onDrop(e.dataTransfer.files, e)
        }
    }

    return (
        // eslint-disable-next-line
        <div
            className={classes}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={handlerDragOver}
            onDrop={handlerDrop}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default FileDrop
