import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { CustomSelect } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './OrderDetailsForm.module.css'

export enum FieldName {
    payment = 'payment',
    cashbox = 'cashbox',
    store = 'store',
}

export type FormDataType = {
    [FieldName.payment]: number
    [FieldName.cashbox]: number
    [FieldName.store]?: number
}

export type OrderDetailsFormPropType = {
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

// FIXME on ready back
const PAYMENTS = [
    { id: 1, name: 'Безналичная форма оплаты' },
    { id: 2, name: 'Оплата в офисе за наличный расчет' },
    { id: 3, name: 'Оплата через терминал' },
]

// FIXME on ready back
const CASHBOX = [
    { id: 1, name: 'Нет кассы' },
]

const OrderDetailsForm: React.FC<OrderDetailsFormPropType> = ({
    isSubmitting,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const paymentsData = useMemo(() => {
        return PAYMENTS.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    }, [])

    const cashboxData = useMemo(() => {
        return CASHBOX.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    }, [])

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                    {/* TODO */}
                    Система оплаты заказа
                </div>
                <Field
                    <FormDataType[FieldName.payment]>
                    name={FieldName.payment}
                    render={({ input, meta }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field classes={style.fieldSelect}>
                                {!!input.value && paymentsData[input.value]}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {PAYMENTS.map((item) => (
                                    <CustomSelect.Option
                                        id={item.id}
                                        key={item.id}
                                        onClick={() => input.onChange(item.id)}
                                    >
                                        {item.name}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                    {/* TODO */}
                    Касса
                </div>
                <Field
                    <FormDataType[FieldName.cashbox]>
                    name={FieldName.cashbox}
                    render={({ input, meta }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field classes={style.fieldSelect}>
                                {!!input.value && cashboxData[input.value]}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {CASHBOX.map((item) => (
                                    <CustomSelect.Option
                                        id={item.id}
                                        key={item.id}
                                        onClick={() => input.onChange(item.id)}
                                    >
                                        {item.name}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.link}>
                    {/* TODO */}
                    Показать чеки
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.group, styleForm.group_alignBottom)}>
                    <div className={cn(styleForm.groupItem, style.fieldText)}>
                        {t('order_sum')}
                    </div>
                    <div className={cn(styleForm.groupItem, style.fieldValue)}>
                        22 000 €
                    </div>
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(style.link, style.link_disabled)}>
                    {/* TODO */}
                    Ссылка на оплату
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(style.link, style.link_disabled)}>
                    {/* TODO */}
                    Транзакции в банковской системе
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                    {/* TODO */}
                    Распечатать счет
                </div>
                <Field
                    <FormDataType[FieldName.store]>
                    name={FieldName.store}
                    render={({ input, meta }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field classes={style.fieldSelect}>
                                {input.value}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {/* */}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.link}>
                    {/* TODO */}
                    Печать
                </div>
            </div>
        </form>
    )
}

export default OrderDetailsForm
