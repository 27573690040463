import React, { useMemo } from 'react'
import cn from 'classnames'

import { IMessengerChannel, IUser } from 'interfaces'
import { APP_URL } from 'config/app'
import { useMessenger, useFetchChannelMembers } from 'containers/Messenger/hooks'
import { MessengerChannelAvatar, MessengerItemInner } from 'containers/Messenger/components'
import { Link } from 'components'
import style from './MessengerItemChannel.module.css'

type MessengerItemChannelPropType = {
    classes?: string
    isActive?: boolean
    data: IMessengerChannel
    user: IUser
}

const MessengerItemChannel: React.FC<MessengerItemChannelPropType> = ({
    classes,
    isActive,
    data,
    user,
}) => {
    const { isReceivedLastMessage, isReadLastMessage } = useMessenger()

    const { data: dataChannelMembers } = useFetchChannelMembers({
        channelId: data.channelId,
        withUsersInfo: true,
    })

    const isReceived = useMemo(() => {
        return isReceivedLastMessage(user.id, data.lastMessage)
    }, [data])

    const isRead = useMemo(() => {
        return isReadLastMessage(user.id, data.lastMessage)
    }, [data])

    const senderName = useMemo(() => {
        return data.lastMessage && data.lastMessage.senderUserId !== user.id
            ? `${data.lastMessage?.senderUser.name ?? ''} ${data.lastMessage?.senderUser.surname ?? ''}`
            : undefined
    }, [data])

    const urlChannel = useMemo(() => {
        return !isActive ? APP_URL.messengerChannel.replace(':id', data.channelId) : undefined
    }, [data, isActive])

    const avatarTpl = (
        <Link className={style.channel} url={urlChannel}>
            <MessengerChannelAvatar
                userId={user.id}
                title={data.channel.title}
                image={data.channel.image}
                members={dataChannelMembers}
            />
        </Link>
    )

    return (
        <MessengerItemInner
            classes={cn(style.item, classes)}
            title={data.channel.title}
            avatar={avatarTpl}
            message={data.lastMessage?.text || data.lastMessage?.forwardedMessage || ''}
            date={data.lastMessage?.createdAt || data.createdAt}
            senderName={senderName}
            url={urlChannel}
            isSimpleMessage
            isHover
            isActive={isActive}
            isReceived={isReceived}
            isRead={isRead}
            isError={false}
        />
    )
}

export default MessengerItemChannel
