import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import {
    IStoreCatalog,
    IStoreHeader,
    IStoreFolder,
    IStoreGoods,
    IGoodsData,
    IStoreCurrency,
    IBasket,
} from 'interfaces'
import { CardSize } from 'enums'
import { CardContainer } from 'layout'
import { CatalogFolder, CatalogGoods } from '../index'
import style from './CatalogItems.module.css'

type CatalogItemsPropType = {
    isSetItemLink: boolean
    isShowBuyButton?: boolean
    storeId: number
    catalog: IStoreCatalog
    itemsSize: keyof typeof CardSize
    basket?: IBasket
    storeCurrency?: IStoreCurrency
    payoutCurrency?: string
    onClickFolder?: (data: IStoreFolder) => void
    onClickGoods?: (data: IGoodsData) => void
    onAddGoodsToBasket?: (data: IGoodsData, count: number) => void
    onAddGoodsToBasketCV?: (data: IGoodsData, count: number) => void
}

type catalogHeaderType = {
    header: IStoreHeader
    items: (IStoreFolder | IStoreGoods)[]
}

/**
 * FIXME replace for CatalogShowcase
 * @deprecated
 */
const CatalogItems: React.FC<CatalogItemsPropType> = ({
    isSetItemLink,
    isShowBuyButton,
    storeId,
    catalog,
    basket,
    itemsSize,
    storeCurrency,
    payoutCurrency,
    onClickFolder,
    onClickGoods,
    onAddGoodsToBasket,
    onAddGoodsToBasketCV,
}) => {
    const [headers, setHeaders] = useState<catalogHeaderType[]>([])
    const [folders, setFolders] = useState<IStoreFolder[]>([])
    const [goods, setGoods] = useState<IStoreGoods[]>([])

    useEffect(() => {
        const catalogHeaders: catalogHeaderType[] = []
        const catalogFolders: IStoreFolder[] = []
        const catalogGoods: IStoreGoods[] = []

        if (catalog?.headers?.length) {
            const headerIds: number[] = []

            catalog.headers.forEach((header) => {
                headerIds.push(header.id)
                catalogHeaders.push({
                    header,
                    items: [...catalog.folders, ...catalog.goods].filter((item) => {
                        if (item.type === 'folder') {
                            return item.parent === header.id
                        }
                        if (item.type === 'goods') {
                            return item.container?.store_catalog?.[0]?.id === header.id
                        }

                        return false
                    }),
                })
            })

            if (catalog?.folders) {
                catalog.folders.forEach((item) => {
                    if (!headerIds.includes(item.parent)) {
                        catalogFolders.push(item)
                    }
                })
            }
            if (catalog?.goods) {
                catalog.goods.forEach((item) => {
                    const parentId = item.container?.store_catalog?.[0]?.id
                    if (parentId && !headerIds.includes(parentId)) {
                        catalogGoods.push(item)
                    }
                })
            }
        } else {
            if (catalog?.folders) {
                catalogFolders.push(...catalog.folders)
            }
            if (catalog?.goods) {
                catalogGoods.push(...catalog.goods)
            }
        }

        setHeaders(catalogHeaders)
        setFolders(catalogFolders)
        setGoods(catalogGoods)
    }, [catalog])

    return (
        <>
            {headers.map(({ header, items }) => (
                <React.Fragment key={header.id}>
                    <h2 className={style.title}>
                        {header.name}
                    </h2>

                    <CardContainer>
                        {items.map((item) => (
                            <React.Fragment key={item.type === 'folder' ? item?.id : item.container.id}>
                                {item.type === 'folder' && (
                                    <CatalogFolder
                                        classes={cn(style.item, style.header)}
                                        isSetLink={isSetItemLink}
                                        storeId={storeId}
                                        item={item}
                                        itemSize={itemsSize}
                                        key={item.id}
                                        onClick={onClickFolder}
                                    />
                                )}

                                {item.type === 'goods' && (
                                    <CatalogGoods
                                        classes={style.item}
                                        isSetLink={isSetItemLink}
                                        isShowBuyButton={isShowBuyButton}
                                        data={item.container}
                                        basket={basket}
                                        itemSize={itemsSize}
                                        storeCurrency={storeCurrency}
                                        payoutCurrency={payoutCurrency}
                                        key={item.container.id}
                                        onClick={onClickGoods}
                                        onAddToBasket={onAddGoodsToBasket}
                                        onAddToBasketCV={onAddGoodsToBasketCV}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </CardContainer>
                </React.Fragment>
            ))}

            {(!!folders?.length) && (
                <CardContainer>
                    {folders.map((item) => (
                        <CatalogFolder
                            classes={cn(style.item, style.header)}
                            isSetLink={isSetItemLink}
                            storeId={storeId}
                            item={item}
                            itemSize={itemsSize}
                            key={item.id}
                            onClick={onClickFolder}
                        />
                    ))}
                </CardContainer>
            )}

            {!!goods?.length && (
                <CardContainer>
                    {goods.map((item) => (
                        <CatalogGoods
                            classes={style.item}
                            isSetLink={isSetItemLink}
                            isShowBuyButton={isShowBuyButton}
                            data={item.container}
                            basket={basket}
                            itemSize={itemsSize}
                            storeCurrency={storeCurrency}
                            payoutCurrency={payoutCurrency}
                            key={item.container?.id}
                            onClick={onClickGoods}
                            onAddToBasket={onAddGoodsToBasket}
                            onAddToBasketCV={onAddGoodsToBasketCV}
                        />
                    ))}
                </CardContainer>
            )}
        </>
    )
}

export default CatalogItems
