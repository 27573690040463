import React, { useEffect, useMemo, useState } from 'react'
import {
    useParams,
    useHistory,
    useLocation,
    generatePath,
} from 'react-router-dom'

import { IReport } from 'interfaces'
import {
    APP_URL,
    REPORT_PV_MATERIALS_ID,
    REPORT_CC_MATERIALS_ID,
} from 'config/app'
import { ContentContainer } from 'layout'
import { ReportList } from 'containers'
import { PageHeader } from 'components'
import { scrollTop } from 'utils/helpers'
import style from './Report.module.css'

const Report: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const { pathname } = useLocation()

    const [pageTitle, setPageTitle] = useState('')
    const [breadcrumbsReportIds, setBreadcrumbsReportIds] = useState<string[]>([])
    const [prevPage, setPrevPage] = useState('')

    const isMaterialsPath = useMemo(() => {
        return pathname.includes(REPORT_PV_MATERIALS_ID) || pathname.includes(REPORT_CC_MATERIALS_ID)
    }, [pathname])

    const isSetBack = useMemo(() => !isMaterialsPath, [isMaterialsPath])

    const handlerLoadReport = (report: IReport) => {
        const { id: reportId, name } = report

        setPageTitle(name)
        setBreadcrumbs(reportId)
    }

    const handlerClickBack = () => {
        if (breadcrumbsReportIds.length) {
            setBreadcrumbsReportIds((prevState) => prevState.slice(0, -1))
        }

        history.push(prevPage)
    }

    function setBreadcrumbs(reportId: string) {
        setBreadcrumbsReportIds((prevState) => {
            return !prevState.includes(reportId) ? [...prevState, reportId] : prevState
        })
    }

    function getPrevPage() {
        const breadcrumbsLen = breadcrumbsReportIds.length
        const parentId = breadcrumbsLen >= 2 ? breadcrumbsReportIds[breadcrumbsLen - 2] : null

        if (parentId) {
            return generatePath(APP_URL.report, { id: parentId })
        }

        return APP_URL.reportsIndicator
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        setPrevPage(getPrevPage)
    }, [breadcrumbsReportIds])

    return (
        <ContentContainer size="half">
            <PageHeader
                classes={style.header}
                title={pageTitle}
                onClickBack={isSetBack ? handlerClickBack : undefined}
            />

            <ReportList
                id={id}
                onLoad={handlerLoadReport}
            />
        </ContentContainer>
    )
}

export default Report
