import React, { TextareaHTMLAttributes, useState } from 'react'
import cn from 'classnames'

import { getId } from 'utils/helpers'
import style from './Textarea.module.css'

type TextareaPropType = {
    classes?: string
    isAutoHeight?: boolean
    styleType?: 'default' | 'defaultBusiness' | 'dynamic' | 'clear' | 'transparent' | 'gray'
    dynamicPlaceholder?: string
    limit?: number
    onChange?: (e: React.SyntheticEvent<HTMLTextAreaElement>) => void
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
} & Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'className'>

const Textarea: React.FC<TextareaPropType> = ({
    classes,
    isAutoHeight = false,
    styleType = 'default',
    placeholder,
    dynamicPlaceholder,
    limit = 1000,
    rows,
    onChange = () => {},
    onBlur = () => {},
    ...props
}) => {
    const [id] = useState(getId(false))
    const [rowsCount, setRowsCount] = useState(rows)

    const handlerChange = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
        if (isAutoHeight) {
            const lines = e.currentTarget.value.split('\n').length
            setRowsCount(() => {
                if (rows) {
                    return lines > rows ? lines : rows
                }
                return lines
            })
        }
        onChange(e)
    }

    const handlerBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
        onBlur(e)
    }

    return (
        <>
            <textarea
                {...props}
                id={id}
                className={cn(
                    style.textarea,
                    {
                        [style.textarea_autoheight]: !!rows,
                        [style.textarea_noresize]: isAutoHeight,
                    },
                    style[styleType],
                    classes,
                )}
                placeholder={dynamicPlaceholder ? ' ' : placeholder}
                maxLength={limit}
                rows={rowsCount}
                onChange={handlerChange}
                onBlur={handlerBlur}
            />

            {styleType === 'dynamic' && dynamicPlaceholder && (
                <label className={style.placeholder} htmlFor={id}>
                    {dynamicPlaceholder}
                </label>
            )}
        </>
    )
}

export default Textarea
