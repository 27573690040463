import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, Image } from 'components'
import style from './ImagePreview.module.css'

type ImagePreviewPropType = {
    isDraggable?: boolean
    classes?: string
    src: string
    onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
    onDragEnter?: (e: React.DragEvent<HTMLDivElement>) => void
    onDragEnd?: (e: React.DragEvent<HTMLDivElement>) => void
    onDelete: () => void
}

const ImagePreview: React.FC<ImagePreviewPropType> = ({
    classes,
    isDraggable,
    src,
    onDragStart,
    onDragEnter,
    onDragEnd,
    onDelete,
}) => {
    const { t } = useTranslation()

    return (
        <div
            className={cn(style.preview, { [style.preview_draggable]: isDraggable }, classes)}
            draggable={isDraggable}
            onDragStart={onDragStart}
            onDragEnter={onDragEnter}
            onDragEnd={onDragEnd}
        >
            {isDraggable && (
                <div className={style.drag}>
                    <div className={style.dragRow}>
                        <div className={style.dragDot} />
                        <div className={style.dragDot} />
                        <div className={style.dragDot} />
                    </div>
                    <div className={style.dragRow}>
                        <div className={style.dragDot} />
                        <div className={style.dragDot} />
                        <div className={style.dragDot} />
                    </div>
                </div>
            )}
            <Image
                classesWrap={style.imageWrap}
                classesImage={style.image}
                src={src}
                width={50}
                height={50}
            />
            <Button
                classes={style.action}
                styleType="transparent"
                title={t('delete')}
                onClick={onDelete}
            >
                <span className={style.delete} />
            </Button>
        </div>
    )
}

export default ImagePreview
