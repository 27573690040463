import { ICompany, ICompanyUser, ICompanyRole } from 'interfaces'
import {
    FETCH_COMPANY_USER,
    // ADD_COMPANY,
    UPDATE_COMPANY,
    FETCH_COMPANY_BUSINESS_ROLES,
} from 'reducers/types'
import {
    fetchCompanyUserActionType,
    addCompanyUserActionType,
    updatedCompanyActionType,
    fetchCompanyBusinessRolesActionType,
} from './company-actions-type'

type companyActionType = fetchCompanyUserActionType | addCompanyUserActionType | updatedCompanyActionType

export const companyUserReducer = (state: null | (ICompany | ICompanyUser) = null, action: companyActionType)
    : null | (ICompany | ICompanyUser) => {
    switch (action.type) {
        case FETCH_COMPANY_USER: {
            const { payload } = action as fetchCompanyUserActionType
            return payload
        }

        // case ADD_COMPANY: {
        //     const { payload } = action as addCompanyUserActionType
        //     return state ? { ...state, ...payload } : payload
        // }

        case UPDATE_COMPANY: {
            const { payload } = action as updatedCompanyActionType
            return state ? { ...state, ...payload } : payload
        }

        default:
            return state
    }
}

export const companyRolesReducer = (state: ICompanyRole[] = [], action: fetchCompanyBusinessRolesActionType)
    : ICompanyRole[] => {
    switch (action.type) {
        case FETCH_COMPANY_BUSINESS_ROLES:
            return action.payload

        default:
            return state
    }
}
