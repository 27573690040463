import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Container } from 'layout'
import { MenuStatic } from 'components'
import { parseTpl } from 'utils/helpers'
import style from './Footer.module.css'

export const Footer: React.FC = () => {
    const { t, i18n } = useTranslation()

    const copyright = useMemo(() => {
        return parseTpl(t('copyright_text'), {
            '%s': new Date().getFullYear(),
        }, {
            prefix: '',
            suffix: '',
        })
    }, [i18n.language])

    return (
        <div className={style.footer}>
            <Container>
                <nav>
                    <MenuStatic
                        isBusiness={false}
                        classesNav={style.nav}
                        classesItem={style.navItem}
                        classesLink={style.navLink}
                    />
                </nav>

                <div className={style.copyright}>
                    {copyright}
                </div>
            </Container>
        </div>
    )
}

export default Footer
