import React, { useMemo, useState } from 'react'
import {
    useParams,
    useHistory,
    useLocation,
    matchPath,
    generatePath,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    IBreadcrumb,
    IStoreHeader,
    IStoreFolder,
    IStoreGoods,
} from 'interfaces'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer } from 'layout'
import {
    PageHeader,
    Breadcrumbs,
    Button,
    CatalogShowcase,
    Loader,
    MenuList,
    Modal,
    SvgResource,
    ErrorMsg,
} from 'components'
import * as appSelectors from 'containers/App/app-selectors'
import { useAppSettings } from 'containers/App/hooks'
import { useFetchStoreProfile, useFetchStoreCatalog } from 'containers/Store/hooks'
import { ShopNav } from 'containers/Shop/components'
import { copyTextClipboard, showAlertNotify } from 'utils/helpers'
import { useWindowResize } from 'hooks'
import style from './ShopCatalog.module.css'

/**
 * ShopCatalog - business store catalog
 */
const ShopCatalog: React.FC = () => {
    const { id, catalogId } = useParams<{ id: string, catalogId?: string}>()
    const { t } = useTranslation()
    const history = useHistory()
    const { pathname } = useLocation()
    const [windowWidth] = useWindowResize()

    const { shopCatalogUserView } = useSelector(appSelectors.settings)

    const [shopId] = useState(Number(id))
    const [isShowModalAdd, setIsShowModalAdd] = useState(false)

    const { update: updateAppSettings } = useAppSettings()

    const {
        data: dataStoreProfile,
        error: errorStoreProfile,
    } = useFetchStoreProfile({
        id: shopId,
    }, {
        enabled: !Number.isNaN(shopId),
    })

    const {
        isInitialLoading: isLoadingStoreCatalog,
        data: dataStoreCatalog,
    } = useFetchStoreCatalog({
        storeId: shopId,
        node: catalogId ? Number(catalogId) : undefined,
        view: 'extra-plain',
    }, {
        enabled: !!dataStoreProfile,
    })

    const isMobile = useMemo(() => {
        return windowWidth < BREAKPOINTS.tabletLandscape
    }, [windowWidth])

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        const parent = { id: 1, name: t('stores'), url: APP_URL.stores }
        const shop = dataStoreProfile
            ? { id: 2, name: dataStoreProfile.name, url: generatePath(APP_URL.shop, { id: dataStoreProfile.id }) }
            : undefined

        if (isMobile) {
            if (matchPath(pathname, { path: APP_URL.shop, exact: true })) {
                return [parent]
            }
            if (shop && matchPath(pathname, APP_URL.shopCatalog)) {
                return [{ id: 3, name: t('catalog'), url: shop.url }]
            }

            return []
        }

        if (shop && matchPath(pathname, APP_URL.shopCatalog)) {
            return [parent, { id: 3, name: t('catalog'), url: shop.url }]
        }

        return [parent]
    }, [pathname, isMobile, dataStoreProfile])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const isShowNav = useMemo(() => {
        return !!matchPath(pathname, { path: APP_URL.shop, exact: true })
    }, [pathname])

    const handlerClickBack = () => {
        history.push(generatePath(APP_URL.shop, { id }))
    }

    const handlerChangeView = () => {
        updateAppSettings('shopCatalogUserView', !shopCatalogUserView)
    }

    const handlerClickEdit = () => {
        history.push(generatePath(APP_URL.storeEdit, { id }))
    }

    const handlerClickShare = () => {
        const { sharing_link: link, sharing_text: text } = dataStoreProfile || {}

        copyTextClipboard(`${link}\n${text}`)
            .then(() => {
                showAlertNotify({ type: 'success', message: t('Copied to clipboard') })
            })
            .catch(() => {
                showAlertNotify({ type: 'error', message: t('update_error') })
            })
    }

    const handlerClickAddFolder = () => {
        history.push(generatePath(APP_URL.shopFolder, { id }))
    }

    const handlerClickAddCategory = () => {
        history.push(generatePath(APP_URL.shopHeader, { id }))
    }

    const handlerClickAddGoods = () => {
        history.push(generatePath(APP_URL.shopGoods, { id }))
    }

    const handlerClickAdd = () => {
        setIsShowModalAdd(true)
    }

    const handlerClickEditHeader = (value: IStoreHeader) => {
        history.push(generatePath(APP_URL.shopHeaderEdit, { id, headerId: value.id }))
    }

    const handlerClickEditFolder = (value: IStoreFolder) => {
        history.push(generatePath(APP_URL.shopFolderEdit, { id, folderId: value.id }))
    }

    const handlerClickEditGoods = (value: IStoreGoods) => {
        history.push(generatePath(APP_URL.shopGoodsEdit, { id, goodsId: value.container.id }))
    }

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                <PageHeader onClickBack={matchPath(pathname, APP_URL.shopCatalog) ? handlerClickBack : undefined}>
                    <Breadcrumbs items={breadcrumbs} />
                </PageHeader>

                {isShowNav && (
                    <ShopNav
                        data={dataStoreProfile}
                        onClickShow={handlerChangeView}
                        onClickEdit={handlerClickEdit}
                        onClickShare={dataStoreProfile && handlerClickShare}
                    />
                )}

                {isLoadingStoreCatalog && (
                    <Loader />
                )}

                {errorStoreProfile && (
                    <ErrorMsg error={errorStoreProfile[0]} />
                )}

                {dataStoreCatalog && (
                    <CatalogShowcase classes={style.catalog}>
                        {dataStoreCatalog.map((item) => (
                            <React.Fragment key={item.type === 'goods' ? item.container.id : item.id}>
                                {item.type === 'header' && (
                                    <CatalogShowcase.Header
                                        data={item}
                                        url={generatePath(APP_URL.shopCatalog, { id: shopId, catalogId: item.id })}
                                    >
                                        {!shopCatalogUserView && (
                                            <Button
                                                styleType="transparent"
                                                title={t('edit')}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handlerClickEditHeader(item)
                                                }}
                                            >
                                                <SvgResource
                                                    resourceKey="ic_edit2_svg"
                                                    width={32}
                                                    height={32}
                                                />
                                            </Button>
                                        )}
                                    </CatalogShowcase.Header>
                                )}
                                {item.type === 'folder' && (
                                    <CatalogShowcase.Folder
                                        data={item}
                                        size={itemSize}
                                        url={generatePath(APP_URL.shopCatalog, { id: shopId, catalogId: item.id })}
                                    >
                                        {!shopCatalogUserView && (
                                            <Button
                                                classes={style.catalogEditAction}
                                                styleType="transparent"
                                                title={t('edit')}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handlerClickEditFolder(item)
                                                }}
                                            >
                                                <SvgResource
                                                    resourceKey="ic_edit2_svg"
                                                    width={32}
                                                    height={32}
                                                />
                                            </Button>
                                        )}
                                    </CatalogShowcase.Folder>
                                )}
                                {item.type === 'goods' && (
                                    <CatalogShowcase.Goods
                                        data={item}
                                        size={itemSize}
                                    >
                                        {!shopCatalogUserView && (
                                            <Button
                                                classes={style.catalogEditAction}
                                                styleType="transparent"
                                                title={t('edit')}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    handlerClickEditGoods(item)
                                                }}
                                            >
                                                <SvgResource
                                                    resourceKey="ic_edit2_svg"
                                                    width={32}
                                                    height={32}
                                                />
                                            </Button>
                                        )}
                                    </CatalogShowcase.Goods>
                                )}
                            </React.Fragment>
                        ))}
                        {!isLoadingStoreCatalog && !shopCatalogUserView && (
                            <CatalogShowcase.Add
                                size={itemSize}
                                onClick={handlerClickAdd}
                            />
                        )}
                    </CatalogShowcase>
                )}
            </ContentContainer>

            <Modal
                isOpen={isShowModalAdd}
                size="smallCenter"
                onClose={() => setIsShowModalAdd(false)}
            >
                <Modal.Header
                    isCloseButton
                    titlePos="left"
                    title={t('add')}
                />
                <Modal.Body>
                    <MenuList type="large">
                        <MenuList.Item onClick={handlerClickAddFolder}>
                            {t('dialog_pm_add_folder')}
                        </MenuList.Item>
                        <MenuList.Item onClick={handlerClickAddCategory}>
                            {t('dialog_pm_add_category')}
                        </MenuList.Item>
                        <MenuList.Item onClick={handlerClickAddGoods}>
                            {t('dialog_pm_add_goods')}
                        </MenuList.Item>
                    </MenuList>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShopCatalog
