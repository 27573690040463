import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { SvgResource } from 'components'
import { FieldFile, FieldStickers, FieldTextContentEditable } from 'forms/fields'
import styleInput from 'components/Input/Input.module.css'
import styleForm from 'styles/modules/form.module.css'
import style from './CommentForm.module.css'

export enum FieldName {
    comment = 'comment',
    sticker = 'sticker',
    file = 'file',
}

export type FormDataType = {
    [FieldName.comment]: string
    [FieldName.sticker]?: string
    [FieldName.file]?: string
}

export const formDataInitial: FormDataType = {
    [FieldName.comment]: '',
    [FieldName.sticker]: '',
    [FieldName.file]: '',
}

export type CommentFormPropType = {
    classes?: string
    isFocus?: boolean
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'

const CommentForm: React.FC<CommentFormPropType> = ({
    classes,
    isFocus,
    isSubmitting,
    onSubmit,
}) => {
    const { t } = useTranslation()

    return (
        <form
            className={cn(style.form, { [styleForm.submitting]: isSubmitting }, classes)}
            onSubmit={onSubmit}
        >
            <FieldTextContentEditable
                classes={cn(style.input, styleInput.gray)}
                placeholder={t('Add comment')}
                name={FieldName.comment}
                isFocus={isFocus}
            />
            <div className={style.actions}>
                <FieldStickers
                    classes={style.action}
                    name={FieldName.sticker}
                />
                <FieldFile
                    classes={style.action}
                    accept={ACCEPT_IMAGE}
                    name={FieldName.file}
                >
                    <SvgResource
                        isImgTag={false}
                        classes={style.iconAttach}
                        resourceKey="ic_attach_svg"
                        width={26}
                        height={24}
                    />
                </FieldFile>
            </div>
        </form>
    )
}

export default CommentForm
