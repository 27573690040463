import { AxiosError } from 'axios'

import {
    IPostContent,
    IPostFile,
    IPostFileCommon,
    IStoreCurrency,
    IDateValues,
} from 'interfaces'
import { EventNotifyPropType } from 'components/AlertNotify/AlertNotify'
import { EventDialogPropType } from 'components/AlertDialog/AlertDialog'
import {
    EVENT_TYPE_ALERT_NOTIFY,
    EVENT_TYPE_ALERT_DIALOG,
    LINE_BREAK_CHAR,
    LINE_BREAK_CHAR1,
    LINE_BREAK_CHAR2,
    MASK_PLACEHOLDER_CHAR,
    STORE_FRIENDS_SHOW_COUNT,
    REGEXP_TAGS,
    REGEXP_STICKER,
    REGEXP_STICKER_CODE,
    REGEXP_CONTENT_TAGS,
    REGEXP_TAG_BR,
} from 'config/app'
import { API_HOST, CDN_HOST_DEFAULT } from 'config/api'
import eventBus from 'utils/EventBus'
import errorLog from 'utils/errorLog'
import i18n from 'i18n'

const LOCALE = window.navigator?.language

export type TStoreCurrencyProps = {
    currency: string
    currency_iso_code: string
    left_currency_symbol_placement: boolean
}

export type TImageResizeProps = {
    src: string | undefined
    width?: string | number
    height?: string | number
    quality?: string | number
    format?: 'auto' | 'webp' | 'jpeg'
    fit?: 'scale-down' | 'contain' | 'cover' | 'pad'
    onerror?: string
}

export type TPartsHighlightResponse = {
    id: string
    highlight: boolean
    value: string
}

export type TParseTplParams = {
    prefix?: string
    suffix?: string
}

export type TFilterProperties<T> = {
    [K in keyof T]: T[K] extends string|number|boolean ? T[K] : never
}

/* FIXME useAlertNotify */
export function showAlertNotify(params: EventNotifyPropType) {
    eventBus.emit(EVENT_TYPE_ALERT_NOTIFY, params)
}

/**
 * @deprecated
 * use useTimeAgo
 */
export function showAlertDialog(params: EventDialogPropType) {
    eventBus.emit(EVENT_TYPE_ALERT_DIALOG, params)
}

export function isObject(value: unknown) {
    return value !== undefined && value !== null && Object.getPrototypeOf(value) === Object.prototype
}

export function isDateInThisWeek(date: Date) {
    const WEEK_DAY_START = 1 // Monday
    const today = new Date()
    const { year, month, day } = getDateValues(today)
    const weekDay = today.getDay()
    // Get the first day of the current week (Monday)
    const firstDayOfWeek = weekDay > 0 ? weekDay - WEEK_DAY_START : 6
    // Get the last day of the current week (Sunday)
    const lastDayOfWeek = weekDay > 0 ? 6 - weekDay - WEEK_DAY_START : 0
    const firstDateWeek = new Date(year, month, day - firstDayOfWeek)
    const lastDateWeek = new Date(year, month, day + lastDayOfWeek, 23, 59, 59)

    return date >= firstDateWeek && date <= lastDateWeek
}

/**
 * Получить экземпляр объекта Date
 * @return по умолчанию возвращается текущая дата
 */
export function getDateInstance(date?: string | number | Date): Date {
    switch (typeof date) {
        case 'string':
            return new Date(date)
        case 'number':
            return new Date(String(date).length === 10 ? date * 1000 : date)
        case 'object':
            return date
        default:
            return new Date()
    }
}

/**
 * Получить числовые значения даты
 */
export function getDateValues(date: Date): IDateValues {
    return {
        year: date.getFullYear(),
        month: date.getMonth(),
        day: date.getDate(),
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
    }
}

export function getNormalizeDateValues(date?: string | number | Date) {
    const d = getDateInstance(date)

    function setZeroPrefix(value: number): string {
        return value < 10 ? `0${value}` : String(value)
    }

    function getTimeZone(value: number) {
        const offset = Math.abs(value)
        const operator = value < 0 ? '+' : '-'
        const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0')
        const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0')

        return `${operator}${offsetHours}:${offsetMinutes}`
    }

    return {
        year: String(d.getFullYear()),
        month: setZeroPrefix(d.getMonth() + 1),
        day: setZeroPrefix(d.getDate()),
        hour: setZeroPrefix(d.getHours()),
        minute: setZeroPrefix(d.getMinutes()),
        second: setZeroPrefix(d.getSeconds()),
        timeZone: getTimeZone(d.getTimezoneOffset()),
    }
}

/**
 * Стандартное форматирование даты: 2000-10-01 | 2000-10-01T19:00:00 | 2000-10-01T19:00:00+03:00
 */
export function defaultDateFormat(date?: string|number|Date, isSetTime: boolean = false, isSetZone: boolean = false) {
    const {
        year,
        month,
        day,
        hour,
        minute,
        second,
        timeZone,
    } = getNormalizeDateValues(date)

    let result = `${year}-${month}-${day}`

    if (isSetTime) {
        result += `T${hour}:${minute}:${second}`
    }
    if (isSetZone) {
        result += timeZone
    }

    return result
}

/**
 * Языко-зависимое форматирование даты и времени
 * @see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 */
export function dateTimeFormat(
    date?: string | number | Date,
    local?: string | [],
    options?: Intl.DateTimeFormatOptions,
    ext?: { sliceRusYearStr?: boolean },
): string {
    const locales = local || LOCALE

    if (locales) {
        const intlDate = new Intl.DateTimeFormat(locales, options)
        let d: Date | number | undefined

        if (date instanceof Date) {
            d = date
        } else if (typeof date === 'string') {
            d = new Date(date)
        } else if (typeof date === 'number') {
            d = date.toString().length === 13 ? date : date * 1000
        }

        return ext?.sliceRusYearStr ? intlDate.format(d).replace(/\s*г\./, '') : intlDate.format(d)
    }

    return defaultDateFormat(date)
}

/**
 * Языко-зависимое форматирование чисел
 */
export function numberFormat(number: number, options?: Intl.NumberFormatOptions, local?: string | string[]): string {
    const locales = local || LOCALE || undefined
    const intlNumber = new Intl.NumberFormat(locales, options)

    return intlNumber.format(number)
}

/**
 * Форматирование суммы с символом валюты
 */
export function priceFormat(price: number, currency?: IStoreCurrency, options?: Intl.NumberFormatOptions): string {
    if (price || price === 0) {
        if (!currency?.symbol) {
            return numberFormat(price, options)
        }

        return currency.leftSymbolPlacement
            ? `${currency.symbol} ${numberFormat(price, options)}`
            : `${numberFormat(price, options)} ${currency.symbol}`
    }

    return ''
}

/**
 * Format and round to fixed digits number
 * @example
 * sumFormat(1230) // 1.2K
 */
export function sumFormat(number: number, digits: number = 1) {
    if (Number.isNaN(number)) {
        return String(number)
    }
    if (Math.abs(Number(number)) >= 1.0e+9) {
        return `${(Math.abs(Number(number)) / 1.0e+9).toFixed(digits)}B`
    }
    if (Math.abs(Number(number)) >= 1.0e+6) {
        return `${(Math.abs(Number(number)) / 1.0e+6).toFixed(digits)}M`
    }
    if (Math.abs(Number(number)) >= 1.0e+3) {
        return `${(Math.abs(Number(number)) / 1.0e+3).toFixed(digits)}K`
    }

    return String(Math.abs(Number(number)))
}

/**
 * Округление числа до кратного
 * @example
 * roundUpNumber(121, 10) // 130
 */
export function roundUpNumber(number: number, multiple: number) {
    return number % multiple ? number + multiple - (number % multiple) : number
}

/**
 * Получить количество цифр дробной части числа
 * @param roundScale
 * @param defaultCount
 */
export function getCountFractionDigits(roundScale?: number, defaultCount: number = 2) {
    // TODO "-" round
    if (typeof roundScale === 'number') {
        const sign = Math.sign(roundScale)
        return sign === 1 || sign === 0 ? roundScale : defaultCount
    }

    return defaultCount
}

/**
 * Привести формат маски телефона к нужному виду
 * @param mask
 */
export function maskNormalizer(mask: string = ''): string {
    return mask.split('').map((char) => (char === MASK_PLACEHOLDER_CHAR ? 0 : char)).join('')
}

export function getDigitCountFromMask(mask: string = ''): number {
    return mask.split('').filter((char) => char === MASK_PLACEHOLDER_CHAR).length
}

export function getDigitCountFromString(str: string = ''): number {
    return str.split('').filter((char) => /\d/.test(char)).length
}

export function getDigitsFromString(str: string = '') {
    const digits = str.match(/\d/g)
    return digits ? digits.join('') : ''
}

/**
 * Unique ID
 * @param {boolean} [isNumber]
 * @return string|number zikme952r|381933
 */
export function getId<T extends boolean>(isNumber?: T): T extends true ? number : string {
    return (isNumber
        ? Math.floor(Math.random() * 1000000)
        : Math.random().toString(36).substr(2, 9)
    ) as T extends true ? number : string
}

/**
 * Universally unique identifier v4
 * @return string
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : ((r & 0x3) | 0x8)

        return v.toString(16)
    })
    // return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, (c: number) => (
    //     (c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & (15 >> c / 4)
    // ).toString(16))
}

/**
 * Проверка строки на наличие data:URL данных
 * @param {string} str
 * @param {string} [pattern='data:image'] - data:[MIME-type]
 * @return boolean
 */

export function isDataUrl(str: string, pattern: string = 'data:image') {
    return String(str).indexOf(pattern) !== -1
}

/**
 * Строковый шаблонизатор
 * @param {string} template
 * @param {object} data
 * @param {object} [params]
 * @param {string} params.prefix - custom tags prefix
 * @param {string} params.suffix - custom tags suffix
 * @return string
 */
export function parseTpl(template: string, data: object, params: TParseTplParams = {}) {
    const defaults: TParseTplParams = {
        prefix: '{',
        suffix: '}',
    }
    const options = { ...defaults, ...params }
    const { prefix, suffix } = options

    return Object.keys(data).reduce((acc, key) => {
        const pattern = `${prefix}${key}${suffix}`
        return acc.replace(new RegExp(pattern, 'g'), data[key])
    }, template)
}

export function getFileImageElement(file: File): Promise<HTMLImageElement> {
    const imgEl = new Image()
    return new Promise((resolve, reject) => {
        imgEl.src = window.URL.createObjectURL(file)
        imgEl.onload = () => {
            window.URL.revokeObjectURL(imgEl.src)
            return resolve(imgEl)
        }
        imgEl.onerror = reject
    })
}

export function setTextWrap(text: string = '', pattern: RegExp = REGEXP_TAG_BR) {
    return text.replaceAll(RegExp(pattern, 'g'), LINE_BREAK_CHAR1)
}

export function truncateText(text = '', maxLength: number, ending = '...') {
    if (text.length > maxLength) {
        const str = text.slice(0, maxLength)
        const indexOfWordEnd = str.lastIndexOf(' ')
        const indexOfSentenceEnd = str.lastIndexOf('.')

        if (indexOfWordEnd > indexOfSentenceEnd) {
            const sliceStr = str.slice(0, indexOfWordEnd)
            const sliceStrLastChar = sliceStr.slice(-1)

            if (sliceStrLastChar === '.') {
                return sliceStr
            }
            if (/\W/.test(sliceStrLastChar)) {
                return sliceStr.slice(0, -1) + ending
            }

            return sliceStr + ending
        }

        return str.slice(0, indexOfSentenceEnd)
    }

    return text
}

export function getAverageRGB(src: string) {
    const blockSize = 5 // only visit every 5 pixels
    const canvas = document.createElement('canvas')
    const context = canvas.getContext && canvas.getContext('2d')
    const defaultRGB = { r: 0, g: 0, b: 0 }
    const rgb = { r: 0, g: 0, b: 0 }

    let data
    let width
    let height
    let i = -4
    let length = 0
    let count = 0

    return new Promise((resolve) => {
        const imgEl = new Image()

        imgEl.crossOrigin = 'Anonymous'
        imgEl.src = src
        imgEl.onload = () => {
            if (!context) {
                return defaultRGB
            }

            height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height
            width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width

            canvas.height = height
            canvas.width = width

            context.drawImage(imgEl, 0, 0)

            try {
                data = context.getImageData(0, 0, width, height)
            } catch (e) {
                errorLog('security error, img on diff domain', e)
                return defaultRGB
            }

            length = data.data.length
            i += blockSize * 4

            while (i < length) {
                count += 1
                i += blockSize * 4
                rgb.r += data.data[i]
                rgb.g += data.data[i + 1]
                rgb.b += data.data[i + 2]
            }

            // ~~ used to floor values
            // eslint-disable-next-line
            rgb.r = ~~(rgb.r / count)
            // eslint-disable-next-line
            rgb.g = ~~(rgb.g / count)
            // eslint-disable-next-line
            rgb.b = ~~(rgb.b / count)

            return resolve(rgb)
        }
    })
}

/**
 * Compute hex color code for an arbitrary string
 * @param str
 * @param saturation
 * @param lightness
 * @return string
 */
export function stringToHslColor(str: string, saturation: number = 50, lightness: number = 50) {
    const hash: number = str.split('').reduce((acc, value) => {
        return value.charCodeAt(0) + ((acc * (2 ** 5)) - acc) // acc * (2 ** 5) === acc << 5
    }, 0)

    return `hsl(${hash % 360}, ${saturation}%, ${lightness}%)`
}

/**
 * Получить первые символы переданных строк
 * @param args
 * @return string
 */
export function getFirstChars(...args: string[]): string {
    return args.reduce((acc: string, value: string) => {
        return value ? acc + value.charAt(0).toUpperCase() : acc
    }, '')
}

/**
 * Изменить первую букву строки на загланую
 * @param {string} str
 * @return string
 */
export function setFirstCharUpper(str: string = ''): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Множественное число существительных
 * @param {number} n
 * @param {array} forms - ['one', 'few', 'many']
 * @return string
 */
export function plural(n: number, forms: string[]): string {
    if (n % 10 === 1 && n % 100 !== 11) { // one
        return forms[0]
    }
    if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) { // few
        return forms[1]
    }
    // many
    return forms[2]
}

/**
 * Получить вложенное свойство объекта
 * @example
 * getObjPropertyByPath({ a: { b: { c: 'val' } } }, 'a.b.c') // val
 */
export function getObjPropertyByPath(obj: object, path: string) {
    let current = { ...obj }
    const parts = path.split('.')

    for (let i = 0, len = parts.length; i < len; i += 1) {
        if (!Object.prototype.hasOwnProperty.call(current, parts[i])) {
            return undefined
        }
        current = current[parts[i]]
    }
    return current
}

/**
 * Развернуть вложенные свойства объекта
 * @example
 * getObjFlatValues({ a: 1, b: { c: 2, d: 3 } }) // { a: 1, c: 2, d: 3 }
 */
export function getObjFlatValues<T extends object>(obj: object): TFilterProperties<T> {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (isObject(value)) {
            return { ...acc, ...getObjFlatValues(value) }
        }

        return { ...acc, [key]: value }
    }, {} as TFilterProperties<T>)
}

/**
 * Получить объект get-параметров url
 */
export function getURLSearchParams<T = Record<string, string>>(search: string): T {
    const params = new URLSearchParams(search)
    // return Object.fromEntries(params.entries()) // node v14+
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
        return { ...acc, [key]: value }
    }, {} as T)
}

/**
 * Создать объект get-параметров url
 * @param {object} params
 * @param {boolean} toString
 * @return string
 */
export function setUrlSearchParams<U extends Record<string, string>, T extends boolean>(params: U, toString: T)
    : T extends true ? string : URLSearchParams {
    return (toString
        ? new URLSearchParams(params).toString()
        : new URLSearchParams(params)) as T extends true ? string : URLSearchParams
}

/**
 * Вырезать в строке все пробелы
 * @param {string} str
 * @return string
 */
export function clearWhiteSpace(str: string = ''): string {
    return str.replaceAll(/\s+/g, '')
}

/**
 * Прокрутка страницы вверх
 */
export function scrollTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    })
}

/**
 * Прокрутка страницы до элемента/низа страницы
 */
export function scrollTo(offset?: number) {
    window.scrollTo({
        top: offset || document.body.scrollHeight,
        behavior: 'smooth',
    })
}

/**
 * Фильтр массива объектов по указанному полю в объекте
 * @param {array} arr
 * @param {string} field
 * @param {string} value
 * @return array
 */
export function filterArrayObjByField(arr: any[] = [], field: string | string[], value: string) {
    const fieldIsArray = Array.isArray(field)

    if (!value) {
        return arr
    }

    function filter(fieldName: string, obj: any): boolean {
        return fieldName in obj ? compare(obj[fieldName], value) : false
    }

    function compare(str: string = '', entry: string): boolean {
        return String(str).toLowerCase().includes(entry.toLowerCase())
    }

    return arr.filter((item) => {
        return fieldIsArray
            ? (field as string[]).some((fieldName) => filter(fieldName, item))
            : filter(field as string, item)
    })
}

/**
 * Разбить строку по символу переноса строки
 */
export function splitStr(str: string = '', linebreak?: string): {id: string, text: string}[] {
    let separator

    if (typeof str !== 'string') {
        return []
    }

    if (linebreak) {
        separator = linebreak
    } else if (str.includes(LINE_BREAK_CHAR2)) {
        separator = LINE_BREAK_CHAR2
    } else if (str.includes(LINE_BREAK_CHAR1)) {
        separator = LINE_BREAK_CHAR1
    } else {
        separator = LINE_BREAK_CHAR
    }

    return str.split(separator).map((item) => ({ id: getId(false), text: item }))
}

/**
 * Получить объект с валютой магазина
 */
export function getStoreCurrency(data: TStoreCurrencyProps): IStoreCurrency {
    const {
        currency: symbol,
        currency_iso_code: isoCode,
        left_currency_symbol_placement: leftSymbolPlacement,
    } = data || {}

    return { symbol, isoCode, leftSymbolPlacement }
}

/**
 * Получить src изображения с параметрами для ресайза
 * @see https://developers.cloudflare.com/images/url-format
 */
export function getImageResizeSrc({
    src,
    width,
    height,
    quality = 95,
    format = 'webp',
    fit,
    onerror = 'redirect',
}: TImageResizeProps): string {
    const URL_DEFAULT = CDN_HOST_DEFAULT
    const URL_PRIMARY = i18n.t('image_resize_url')

    const urlParams: Partial<TImageResizeProps> = {
        quality: String(quality),
        format,
        onerror,
    }

    if (!src) {
        return ''
    }
    if (!width && !height) {
        return src
    }
    if (width) {
        urlParams.width = width
    }
    if (height) {
        urlParams.height = height
    }
    if (fit) {
        urlParams.fit = fit
    }

    const options = Object.entries(urlParams)
        .map(([key, value]) => `${key}=${value}`)
        .join(',')

    return URL_PRIMARY.includes('https') ? `${URL_PRIMARY}${options}/${src}` : `${URL_DEFAULT}${options}/${src}`
}

/**
 * Creates a base-64 encoded ASCII string from a "string" of binary data
 * @param {string} str
 * @return string
 */
export function base64Encode(str = '') {
    return window.btoa(str)
}

/**
 * Decodes a string of data which has been encoded using base-64 encoding
 * @param {string} str
 * @return string
 */
export function base64Decode(str = '') {
    return window.atob(str)
}

/**
 * Breaking a string into parts by pattern with highlight param
 * @param {string} str
 * @param {string} pattern
 * @return array
 */
export function strPartsHighlight(str: string = '', pattern: string = ''): TPartsHighlightResponse[] {
    const dataString = String(str)
    const patternString = Array.isArray(pattern) ? String(pattern[0]) : String(pattern)
    const patternLen = patternString.length

    const strIndex = dataString.toLowerCase().indexOf(patternString.toLowerCase())
    const parts = []

    if (patternLen > 0 && strIndex >= 0) {
        parts.push({
            id: String(getId()),
            highlight: false,
            value: dataString.substring(0, strIndex),
        })
        parts.push({
            id: String(getId()),
            highlight: true,
            value: dataString.substring(strIndex, strIndex + patternLen),
        })
        parts.push({
            id: String(getId()),
            highlight: false,
            value: dataString.substring(strIndex + patternLen, dataString.length),
        })
    } else {
        parts.push({ id: String(getId()), highlight: false, value: dataString })
    }

    return parts
}

/**
 * @param {number} count
 * @param {array<string>} pluralText
 * @return string
 */
export function getStoreFriendsCountText(count: number, pluralText: string[]) {
    const showCount = count && count > STORE_FRIENDS_SHOW_COUNT ? count - STORE_FRIENDS_SHOW_COUNT : 0

    if (showCount) {
        return `+${showCount} ${plural(showCount, pluralText)}`
    }

    return ''
}

/**
 * Копирование текста в буфер обмена
 */
export function copyTextClipboard(text: string = ''): Promise<void> {
    if (window?.navigator?.clipboard) {
        return window.navigator.clipboard.writeText(text)
    }

    return Promise.reject()
}

export function getContentTags(content: string = ''): string[] {
    const pattern = /(<image id=".*?">.*?<\/image>)|(<gallery id=".*?">.*?<\/gallery>)|(<file id=".*?">.*?<\/file>)/g
    return content.match(pattern) || []
}

export function getContentTagById(content: string = '', id: number, tag: string = 'image'): string {
    const pattern = new RegExp(`<${tag} id=\\\\?"${id}\\\\?">.*?</${tag}>`) // TODO replace getTagRegexp
    const [match = ''] = content.match(pattern) || []

    return match
}

/**
 * Get content tag name
 * @example
 * <image id="123456"></image>
 */
export function getTagType(tag: string): 'image' | 'gallery' | 'file' | '' {
    if (tag.indexOf('image') >= 0) {
        return 'image'
    }
    if (tag.indexOf('gallery') >= 0) {
        return 'gallery'
    }
    if (tag.indexOf('file') >= 0) {
        return 'file'
    }

    return ''
}

/**
 * Get tag body text
 * @example
 * image id="1136122">P - СПОКОЙСТВИЕ И РАССЛАБЛЕНИЕ</image>
 */
export function getTagText(tag: string): string {
    const [, text = ''] = tag.match(/>(.*?)</) || []
    return text
}

/**
 * Get RegExp pattern for tag
 */
export function getTagRegexp(tag: string, id: number) {
    return new RegExp(`<${tag} id="${id}">.*?</${tag}>`)
}

/**
 * Set text to tag body
 * @param {string} tag - <image id="123"></image>
 * @param {string} text - some text
 * @return {string} - <image id="123">some text</image>
 */
export function setTagText(tag: string, text: string): string {
    return tag.replace(/>(.*?)</, `>${text}<`)
}

/**
 * Get files for tag
 */
export function getTagFiles(type: string, id: number, files: (IPostFile | IPostFileCommon)[] = [])
    : (IPostFile | IPostFileCommon)[] {
    // single file image|video
    if (type === 'image') {
        return files.filter((file) => {
            return ('goods' in file && file.goods === id)
                || ('goods_id' in file && file.goods_id === id)
                || file.id === id
        })
    }
    // gallery image|video
    if (type === 'gallery') {
        return files.filter((file) => {
            if ('fileGroup' in file) {
                return file.fileGroup === id
            }
            if ('group' in file) {
                return file.group === id
            }

            return false
        })
    }
    // content file image|video
    if (type === 'file') {
        return files.filter((file) => file.id === id)
    }

    return []
}

/**
 * Parse content string by groups
 */
export function parseContentTags(text: string = '', files: IPostContent['files'] = []): IPostContent[] {
    const tags = text.match(new RegExp(REGEXP_CONTENT_TAGS, 'g')) || []
    const data: IPostContent[] = tags.length ? [] : [{
        id: getId(true),
        type: '',
        html: text,
        files: [],
    }]
    let content = text

    tags.forEach((tag, index) => {
        const [idAttr] = tag.match(/id=".*?"/) || [] // id="123456"
        const tagId = idAttr ? idAttr.replace(/[^\d]/g, '') : null

        if (tagId) {
            const tagIdNum = Number(tagId)
            const tagType = getTagType(tag)
            const tagText = getTagText(tag)
            const tagRegexp = getTagRegexp(tagType, tagIdNum)
            const tagFiles = getTagFiles(tagType, tagIdNum, files)

            if (content && tagRegexp) {
                const [partBefore, partAfter] = content.split(tagRegexp)

                content = partAfter

                // post main text
                if (partBefore && data.length === 0) {
                    data.push({
                        id: getId(true),
                        type: '',
                        html: partBefore,
                        files: [],
                    })
                }
                // prev tag text
                if (partBefore && data.length > 0) {
                    data[data.length - 1].html = setTextWrap(partBefore)
                }

                // current tag data
                data.push({
                    id: tagIdNum,
                    type: tagType,
                    html: '',
                    text: tagText,
                    files: tagFiles,
                })
            }
        }
    })

    if (content && data.length > 1) {
        data[data.length - 1].html = setTextWrap(content)
    }

    return data
}

/**
 * Replace stickers code for img tags - {[smile,32]}
 */
export function parseContentStickers(
    content: string = '',
    stickerImgPath: string = '',
    stickerClass?: string,
    width?: number,
    height?: number,
): string {
    return content.replaceAll(new RegExp(REGEXP_STICKER, 'g'), (substr) => {
        const [sticker] = substr.match(REGEXP_STICKER_CODE) || []
        const [type, id] = sticker ? sticker.split(',') : []

        if (type && id) {
            const path = parseTpl(stickerImgPath, { id, type })
            return `<img
                class="${stickerClass}"
                src="${API_HOST}${path}"
                data-sticker="{[${type},${id}]}"
                width="${width ?? ''}"
                height="${height ?? ''}"
                alt=""
            >`
        }

        return ''
    })
}

/**
 * Replace stickers img tags for stickers code
 */
export function stringifyContentStickers(content: string = '') {
    const imgStickerPattern = `<img.*?data-sticker="${REGEXP_STICKER.source}".*?>`

    return content.replaceAll(new RegExp(imgStickerPattern, 'g'), (substr) => {
        const [sticker] = substr.match(REGEXP_STICKER_CODE) || []
        const [type, id] = sticker ? sticker.split(',') : []

        return type && id ? `{[${type},${id}]}` : ''
    })
}

/**
 * Sanitize text content
 */
export function sanitizeContent(content: string = '') {
    return content
        .replaceAll(new RegExp(/<style.*?>.*?<\/style>/, 'g'), '')
        .replaceAll(new RegExp(/<style.*?>[\S\s]*\t<\/style>/, 'g'), '')
        .replaceAll(new RegExp(REGEXP_TAGS, 'g'), '')
        .replaceAll(new RegExp(/&nbsp;/, 'g'), '')
        .replaceAll(new RegExp(LINE_BREAK_CHAR, 'g'), '')
        .trim()
}

/**
 * Convert content groups to string
 */
export function convertContentTags(postContent: IPostContent[]): string {
    return postContent.reduce((acc, item) => {
        const {
            type,
            html,
            text = '',
            files,
        } = item

        // TODO
        const startLineBreak = '' // acc && acc.slice(-1) !== LINE_BREAK_CHAR1 ? LINE_BREAK_CHAR1 : ''
        const endLineBreak = '' // text ? LINE_BREAK_CHAR1 : ''
        // const endLineBreak = text && text.slice(0) !== LINE_BREAK_CHAR1 ? LINE_BREAK_CHAR1 : ''

        // post main text
        if (!type && html) {
            return acc + html
        }
        if ((type === 'image' || type === 'video' || type === 'file') && files.length) {
            const [file] = files
            let imageId = file.id

            if ('goods' in file && file.goods) {
                imageId = file.goods
            } else if ('goods_id' in file && file.goods_id) {
                imageId = file.goods_id
            }

            return `${acc}${startLineBreak}<image id="${imageId}">${text}</image>${endLineBreak}${html}`
        }
        if (type === 'gallery' && files.length) {
            const [file] = files
            let galleryId = 0

            if ('fileGroup' in file && file.fileGroup) {
                galleryId = file.fileGroup
            } else if ('group' in file && file.group) {
                galleryId = file.group
            }

            return `${acc}${startLineBreak}<gallery id="${galleryId}"></gallery>${endLineBreak}${html}`
        }

        return acc
    }, '')
}

/**
 * Get file type
 * @example
 * getFileTypeByMimeType('video/mp4') // { type: video, ext: mp4 }
 */
export function getFileTypeByMimeType(mimeType: string = ''): { type: string, ext: string } {
    const [type = '', ext = ''] = String(mimeType).indexOf('/') > 0 ? String(mimeType).split('/') : []
    return { type, ext }
}

/**
 * Get error message from error response
 */
export function getRequestError<
    T extends string[] | {} | { error?: any, errorMsg?: any } = any,
    D = any
>(err: AxiosError<T, D>): string {
    const { response } = err || {}
    const { data } = response || {}

    if (response?.status === 404) {
        if (Array.isArray(response.data) && typeof response.data[0] === 'string') {
            return response.data[0]
        }
        if (isObject(response.data) && ('error' in response.data || 'errorMsg' in response.data)) {
            if ('error' in response.data && typeof response.data.error === 'string') {
                return response.data.error
            }
            if ('errorMsg' in response.data && typeof response.data.errorMsg === 'string') {
                return response.data.errorMsg
            }
        }
        return i18n.t('nothing_found')
    }
    if (data) {
        if (typeof data === 'string') {
            return data
        }
        if (Array.isArray(data) && typeof data[0] === 'string') {
            return data[0]
        }
        if (isObject(data) && ('error' in data || 'errorMsg' in data)) {
            if ('error' in data && typeof data.error === 'string') {
                return data.error
            }
            if ('errorMsg' in data && typeof data.errorMsg === 'string') {
                return data.errorMsg
            }
        }
    }

    return ''
}

export function convertHexToRgb(color: string): number[] {
    // const RGB_REG_EXP = /rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/
    const HEX_REG_EXP = /^#?(([\da-f]){3}|([\da-f]){6})$/i

    const errMessage = `
    Something went wrong while working with colors...
    
    Make sure the colors provided to the "PieDonutChart" meet the following requirements:
    
    Color must be only HEX string and must be 
    7-characters starts with "#" symbol ('#ffffff')
    or 6-characters without "#" symbol ('ffffff')
    or 4-characters starts with "#" symbol ('#fff')
    or 3-characters without "#" symbol ('fff')
    
    - - - - - - - - -
    
    Error in: "convertHexToRgb" function
    Received value: ${color}
  `

    if (
        !color
        || typeof color !== 'string'
        || color.length < 3
        || color.length > 7
    ) {
        errorLog(errMessage)
        return []
    }

    const replacer = (...args: string[]) => {
        const [
            ,
            r,
            g,
            b,
        ] = args
        // eslint-disable-next-line
        return '' + r + r + g + g + b + b
    }

    const rgbHexArr = color
        ?.replace(HEX_REG_EXP, replacer).match(/.{2}/g)
        ?.map((x) => parseInt(x, 16))

    if (rgbHexArr && Array.isArray(rgbHexArr) && HEX_REG_EXP.test(color)) {
        // return `rgb(${rgbHexArr[0]}, ${rgbHexArr[1]}, ${rgbHexArr[2]})`
        return [rgbHexArr[0], rgbHexArr[1], rgbHexArr[2]]
    }

    return []
}

/**
 * Получить интервал дат начала и конца недели
 */
export function getWeekDateRange(date?: Date) {
    const DAYS_IN_WEEK = 7
    const d = date || new Date()
    // day number for weeks start on monday
    const dateDayNumber = (d.getDay() + 6) % DAYS_IN_WEEK
    const dateYear = d.getFullYear()
    const dateMonth = d.getMonth()
    const dateDay = d.getDate()
    const dateFirstDay = new Date(dateYear, dateMonth, dateDay - dateDayNumber)
    const dateLastDay = new Date(dateYear, dateMonth, dateDay + (DAYS_IN_WEEK - dateDayNumber - 1))

    return {
        dateFrom: defaultDateFormat(dateFirstDay),
        dateTo: defaultDateFormat(dateLastDay),
    }
}

/**
 * Получить интервал дат начала и конца месяца
 */
export function getMonthDateRange(date?: Date) {
    const d = date || new Date()
    const year = d.getFullYear()
    const month = d.getMonth()
    const dateFirstDay = new Date(year, month)
    const dateLastDay = new Date(year, month + 1, 0)

    return {
        dateFrom: defaultDateFormat(dateFirstDay),
        dateTo: defaultDateFormat(dateLastDay),
    }
}

/**
 * Получить интервал дат начала и конца кода
 */
export function getYearDateRange(date?: Date) {
    const d = date || new Date()
    const dateYear = d.getFullYear()
    const dateFirstDay = new Date(dateYear, 0)
    const dateLastDay = new Date(dateYear, 12, 0)

    return {
        dateFrom: defaultDateFormat(dateFirstDay),
        dateTo: defaultDateFormat(dateLastDay),
    }
}

/**
 * Группировать массив объектов по ключу
 */
export function groupBy<T extends object, K extends keyof T>(
    items: ReadonlyArray<T>,
    key: K,
    keyCallback?: (keyValue: T[K]) => string,
): { [k: string]: T[] } {
    return items.reduce((acc, item) => {
        const itemKey = keyCallback ? keyCallback(item[key]) : String(item[key])
        return { ...acc, [itemKey]: [...(acc[itemKey] || []), item] }
    }, {})
}

/**
 * Конвертировать массив объектов в объект по ключу
 */
export function arrayToObjectByKey<T extends object, K extends keyof T>(
    arr: ReadonlyArray<T>,
    key: K,
): { [A in K]: T } {
    return arr.reduce((acc, item, index) => ({ ...acc, [String(item[key])]: item }), {} as { [A in K]: T })
}

/**
 * Получить элемент массива по вычисленному ключу
 */
export function getItemFromArray<T = any>(array: T[], criteria: number): T | undefined {
    return Array.isArray(array) ? array[criteria % array.length] : undefined
}
