import React from 'react'
import cn from 'classnames'

import { BlockHeaderPropType } from './components/BlockHeader/BlockHeader'
import { BlockHeaderInnerPropType } from './components/BlockHeaderInner/BlockHeaderInner'
import { BlockBodyCollapsePropType } from './components/BlockBodyCollapse/BlockBodyCollapse'
import { BlockHeader, BlockHeaderInner, BlockBodyCollapse } from './components'
import style from './Block.module.css'

type BlockPropType = {
    classes?: string
    styles?: React.CSSProperties
}

type BlockComposition = {
    Header: React.FC<BlockHeaderPropType>
    HeaderInner: React.FC<BlockHeaderInnerPropType>
    BodyCollapse: React.FC<BlockBodyCollapsePropType>
}

const Block: React.FC<BlockPropType> & BlockComposition = ({
    children,
    classes,
    styles,
}) => {
    return (
        <div className={cn(style.block, classes)} style={styles}>
            {children}
        </div>
    )
}

Block.Header = BlockHeader
Block.HeaderInner = BlockHeaderInner
Block.BodyCollapse = BlockBodyCollapse
export default Block
