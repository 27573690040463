import { useState, useEffect, useRef } from 'react'

type ImageSource = HTMLImageElement | null

/**
 * Hook lazy load image
 * @param {string} src
 * @return [object|null, boolean]
 */
export default function useOnLoadImage(src?: string): [ImageSource, boolean] {
    const isMounted = useRef(false)

    const [source, setSource] = useState<ImageSource>(null)
    const [error, setError] = useState(false)

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        if (!src && setSource) {
            setSource(null)
        }
        if (src) {
            const img = new Image()

            img.src = src
            img.onload = (e) => {
                if (isMounted.current && e.target) {
                    setSource(e.target as HTMLImageElement)
                }
            }
            img.onerror = () => {
                if (isMounted.current) {
                    setError(true)
                }
            }
        }
    }, [src])

    return [source, error]
}
