import { AxiosError } from 'axios'
import {
    UseInfiniteQueryOptions,
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TNetworkServiceError,
    TUnsubscribeStoreProps,
    TUnsubscribeStoreUserProps,
    TUnsubscribeStoreResponse,
    TUnsubscribeStoreUserResponse,
} from 'services/NetworkService'
import { QueryCacheKeys } from 'enums'
import { NetworkService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TUnsubscribeStoreProps
export type TFetchFnResponse = any
export type TFetchFnError = AxiosError<TNetworkServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TFetchFnResponse,
    TError,
    TFetchFnResponse,
    TFetchFnResponse,
    TQueryKey
>

export type TUnsubscribeStoreMutationFnError = [string, AxiosError<TNetworkServiceError, TUnsubscribeStoreProps>]
export type TUnsubscribeStoreUserMutationFnError = [
    string,
    AxiosError<TNetworkServiceError, TUnsubscribeStoreUserProps>
]

const key = QueryCacheKeys.unsubscribeStore

/**
 * Хук API получить магазин от которого отписан юзер
 */
export default function useFetchUnsubscribeStore(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return NetworkService.fetchUnsubscribeStore(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationUnsubscribeStore() {
    const { t } = useTranslation()

    /**
     * Отписаться от магазина
     */
    const unsubscribeStore = useMutation<
        TUnsubscribeStoreResponse,
        TUnsubscribeStoreMutationFnError,
        TUnsubscribeStoreProps,
        unknown
    >((props) => {
        return NetworkService.unsubscribeStore(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    /**
     * отписаться от юзера в магазине
     */
    const unsubscribeStoreUser = useMutation<
        TUnsubscribeStoreUserResponse,
        TUnsubscribeStoreUserMutationFnError,
        TUnsubscribeStoreUserProps,
        unknown
    >((props) => {
        return NetworkService.unsubscribeStoreUser(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        unsubscribeStore,
        unsubscribeStoreUser,
    }
}
