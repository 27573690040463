import {
    IGoods,
} from 'interfaces'
import { TUpdateGoodsProps } from 'services/MarketService'

export default class GoodsDto {
    static getGoodsProps(data: IGoods): TUpdateGoodsProps {
        const {
            id,
            name,
            store,
            price,
            retail_price: retailPrice,
            available_status: availableStatus,
            store_catalog: storeCatalog,
            product_type,
            vendor_code: vendorCode,
            short_name: shortName,
            description,
            quantity_limit_for_sale: quantityLimitForSale,
            quantity_limit_for_sale_global: quantityLimitForSaleGlobal,
            availability_date_from: availabilityDateFrom,
            availability_date_to: availabilityDateTo,
            // isHideInPopular FIXME
            is_recommended: isRecommended,
            weight,
            images,
        } = data || {}

        return {
            id,
            name,
            store: store.id,
            price,
            retailPrice,
            availableStatus,
            storeCatalog,
            productType: product_type.id,
            vendorCode,
            shortName,
            description,
            quantityLimitForSale,
            quantityLimitForSaleGlobal,
            availabilityDateFrom,
            availabilityDateTo,
            isRecommended: isRecommended ? 1 : 0,
            weight,
            images: images.map((item) => ({
                id: item.id,
                src: item.path,
                format: item.format,
                sort: item.sort,
            })),
        }
    }
}
