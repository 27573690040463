import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import style from './PostFormFieldCommunity.module.css'

type FormFieldSelectCommunityPropType = {
    isActive: boolean
    classes?: string
    community?: string | null // title | error
    onClick: () => void
}

const PostFormFieldCommunity: React.FC<FormFieldSelectCommunityPropType> = ({
    isActive,
    classes,
    community,
    onClick,
}) => {
    const { t } = useTranslation()

    const communityError = useMemo(() => community === null, [community])

    const handlerClick = () => {
        if (isActive) {
            onClick()
        }
    }

    return (
        <div className={cn(style.selectCommunity, classes)}>
            <div className={style.desc}>
                {t('ui_posts_community_hint')}
            </div>

            {communityError && (
                <div className={style.error}>
                    {t('community_not_found')}
                </div>
            )}

            {!communityError && community && (
                // eslint-disable-next-line
                <div
                    className={cn(style.select, { [style.select_active]: isActive })}
                    title={t('Choose the group in which you would like to publish the post.')}
                    onClick={handlerClick}
                >
                    <div className={cn(style.selectText, { [style.selectText_active]: isActive })}>
                        {community}
                    </div>
                    <div className={style.dropDown}>
                        <div className={cn(style.iconDropDown, { [style.iconDropDown_active]: isActive })} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default PostFormFieldCommunity
