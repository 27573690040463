import React, { useMemo } from 'react'

import { IPostFile, IPostFileCommon } from 'interfaces'
import { getFileTypeByMimeType } from 'utils/helpers'
import { PostMainImageImg, PostMainImageVideo, PostMainImageDocument } from '../index'
import { PostMainImageImgPropType } from '../PostMainImageImg/PostMainImageImg'

type PostMainImageItemPropType = {
    classes?: string
    file: IPostFile | IPostFileCommon
    preview?: string
    url?: string
    resize?: PostMainImageImgPropType['resize']
    isCanPlayVideo?: boolean
}

const PostMainImageFile: React.FC<PostMainImageItemPropType> = ({
    children,
    classes,
    file,
    preview,
    url,
    resize,
    isCanPlayVideo,
}) => {
    const fileOptions = useMemo(() => ({
        width: file.width,
        height: file.height,
        placeholderColor: 'placeholder_color' in file ? file.placeholder_color : undefined,
    }), [file])

    const fileType = useMemo(() => {
        const { type } = getFileTypeByMimeType(file.type)
        return type
    }, [file])

    if (fileType === 'video') {
        return (
            <PostMainImageVideo
                classes={classes}
                type={file.type}
                background={fileOptions.placeholderColor}
                poster={'preview_src' in file ? file.preview_src : undefined}
                src={file.src}
                url={url}
                resize={resize}
                isCanPlay={isCanPlayVideo}
            >
                {children}
            </PostMainImageVideo>
        )
    }
    if (fileType === 'application') {
        return (
            <PostMainImageDocument
                classes={classes}
                src={file.src}
                title={file.title}
            >
                {children}
            </PostMainImageDocument>
        )
    }

    return (
        <PostMainImageImg
            classes={classes}
            preview={preview}
            src={'preview_src' in file && file.preview_src ? file.preview_src : file.src}
            background={fileOptions.placeholderColor}
            width={fileOptions.width}
            height={fileOptions.height}
            url={url}
            resize={resize}
        >
            {children}
        </PostMainImageImg>
    )
}

export default PostMainImageFile
