import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ITransaction } from 'interfaces'
import { REGEXP_RU_YEAR_SYMBOL } from 'config/app'
import {
    Loader,
    InfoBlock,
    InfiniteScroll,
    Button,
    SvgResource,
    NoDataInfo,
    ErrorMsg,
} from 'components'
import { defaultDateFormat, dateTimeFormat } from 'utils/helpers'
import { WalletFilterDateType } from '../WalletFilter/WalletFilter'
import { WalletFilter, WalletTransaction } from '../index'
import { useFetchPayoutHistory } from '../../hooks'
import style from './WalletTransactions.module.css'

type WalletTransactionsPropType = {
    classes?: string
    currencyId?: number
}

type TransactionsGroups = {
    date: string
    dateFormatted: string
    items: ITransaction[]
}

type FilterDateType = Partial<WalletFilterDateType>

const CURRENT_YEAR = new Date().getFullYear()
const DATE_OPTIONS_CURRENT_YEAR: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric' }
const DATE_OPTIONS_PREV_YEAR: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' }
const TRANSACTIONS_LIMIT = 10
const TRANSACTIONS_OFFSET = 0

const initialFilterState: FilterDateType = { from: undefined, to: undefined }

/** Получить форматированную дату без указания года для текщего года */
const getGroupFormattedDate = (date: string): string => {
    const options = date.includes(String(CURRENT_YEAR)) ? DATE_OPTIONS_CURRENT_YEAR : DATE_OPTIONS_PREV_YEAR
    const dateFormatted = dateTimeFormat(date, '', options)

    return dateFormatted.replace(REGEXP_RU_YEAR_SYMBOL, '')
}

/** Получить сгруппированные транзакции по дате */
const getTransactionsGroups = (transactions: ITransaction[] = []) => {
    return transactions.reduce((acc: TransactionsGroups[], item: ITransaction) => {
        const dateKey = defaultDateFormat(item.date)
        const index = acc.findIndex((group) => group.date === dateKey)

        if (index >= 0) {
            acc[index].items.push(item)
            return acc
        }

        return [...acc, { date: dateKey, dateFormatted: getGroupFormattedDate(item.date), items: [item] }]
    }, [])
}

const WalletTransactions: React.FC<WalletTransactionsPropType> = ({ classes, currencyId }) => {
    const { t } = useTranslation()

    const [transactions, setTransactions] = useState<ITransaction[] | undefined>()
    const [transactionsOffset, setTransactionsOffset] = useState(TRANSACTIONS_OFFSET)
    const [transactionsFilterDate, setTransactionsFilterDate] = useState<FilterDateType>(initialFilterState)
    const [transactionsCurrency, setTransactionsCurrency] = useState(currencyId)

    const [isShowFilter, setIsShowFilter] = useState(false)
    const [isLoadingFilter, setIsLoadingFilter] = useState(false)
    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isLoading: isLoadingOuter,
        isFetching: isLoadingInner,
        data: transactionsData,
        error: transactionsError,
    } = useFetchPayoutHistory({
        limit: TRANSACTIONS_LIMIT,
        offset: transactionsOffset,
        dateFrom: transactionsFilterDate.from,
        dateTo: transactionsFilterDate.to,
        rewardCurrencyId: transactionsCurrency,
    }, {
        keepPreviousData: true,
    })

    const transactionGroups: TransactionsGroups[] = useMemo(() => getTransactionsGroups(transactions), [transactions])

    const handlerOnLoad = () => {
        setTransactionsOffset((prevState) => prevState + TRANSACTIONS_LIMIT)
    }

    const handlerClickFilter = () => {
        if (isShowFilter) {
            resetFilter()
        }

        setIsShowFilter((prevState) => !prevState)
    }

    const handlerChangeFilter = ({ from, to }: WalletFilterDateType) => {
        setIsLoadingFilter(true)
        resetTransactions()

        if (from || to) {
            setTransactionsFilterDate({ from, to })
        } else {
            resetFilter()
        }
    }

    function resetTransactions() {
        setTransactions([])
        setTransactionsOffset(0)
        setIsHideLoader(false)
    }

    function resetFilter() {
        setTransactionsFilterDate(initialFilterState)
        setIsHideLoader(false)
    }

    useEffect(() => {
        if (transactionsData) {
            if (transactionsData.length < TRANSACTIONS_LIMIT) {
                setIsHideLoader(true)
            }

            setTransactions((prevState) => {
                return prevState ? [...prevState, ...transactionsData] : transactionsData
            })
        }
        if (isLoadingFilter) {
            setIsLoadingFilter(false)
        }
    }, [transactionsData])

    useEffect(() => {
        if (currencyId !== transactionsCurrency) {
            setTransactionsCurrency(currencyId)
            resetTransactions()
        }
    }, [currencyId])

    return (
        <>
            {isLoadingOuter && !transactionsData && (
                <Loader />
            )}

            {!isLoadingOuter && (!!transactionGroups.length || isShowFilter || !!transactionsCurrency) && (
                <InfoBlock classes={cn(style.block, classes)}>
                    <InfoBlock.Body classes={style.blockBody}>
                        <div className={style.blockTitle}>
                            <div className={style.title}>
                                {t('business_kicks_my_operations')}
                            </div>

                            <Button
                                classes={style.filterAction}
                                styleType="text"
                                onClick={handlerClickFilter}
                            >
                                <SvgResource
                                    resourceKey="wallet_icon_calendar_svg"
                                    width={30}
                                    height={30}
                                />
                            </Button>
                        </div>

                        {isShowFilter && (
                            <WalletFilter
                                classes={style.filter}
                                disabled={isLoadingFilter}
                                onChange={handlerChangeFilter}
                            />
                        )}
                    </InfoBlock.Body>
                    <InfoBlock.Footer classes={style.blockFooter}>
                        {isLoadingInner && !transactionGroups.length && (
                            <Loader />
                        )}

                        {!!transactionGroups.length && (
                            <InfiniteScroll
                                isHideLoader={isHideLoader}
                                onLoad={handlerOnLoad}
                            >
                                {transactionGroups.map((group) => (
                                    <div className={style.group} key={group.date}>
                                        <div className={style.date}>
                                            {group.dateFormatted}
                                        </div>

                                        {group.items.map((item) => (
                                            <WalletTransaction
                                                classes={style.transaction}
                                                data={item}
                                                key={item.id}
                                            />
                                        ))}
                                    </div>
                                ))}
                            </InfiniteScroll>
                        )}

                        {!isLoadingInner && !transactionsData?.length && (
                            <ErrorMsg
                                classes={style.blockMessage}
                                error={t('nothing_found')}
                            />
                        )}
                    </InfoBlock.Footer>
                </InfoBlock>
            )}

            {!isLoadingOuter && transactionsData && !transactionsData.length && !isShowFilter && (
                <NoDataInfo
                    title={`${t('Welcome')}!`}
                    text={t('empty_wallet_text')}
                />
            )}

            {!isLoadingOuter && transactionsError && (
                <ErrorMsg
                    classes={style.errorMessage}
                    error={transactionsError}
                />
            )}
        </>
    )
}

export default WalletTransactions
