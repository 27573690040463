import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Tag } from 'components'
import style from './CompanyOrdersPresets.module.css'

type CompanyOrdersPresetsPropType = {
    classes?: string
    data?: { id: number, name: string }[] // FIXME
    onDelete: (id: number) => void
    onSelect: (id: number) => void
}

const MAX_ITEMS_SHOW = 3
const MORE_ITEMS_SHOW = 10

const CompanyOrdersPresets: React.FC<CompanyOrdersPresetsPropType> = ({
    classes,
    data,
    onDelete,
    onSelect,
}) => {
    const { t } = useTranslation()

    const [itemsShow, setItemsShow] = useState(MAX_ITEMS_SHOW)

    const moreText = useMemo(() => {
        return data && data.length > itemsShow ? `+${data.length - itemsShow}` : ''
    }, [data, itemsShow])

    const handlerClickMore = () => {
        setItemsShow((prevState) => prevState + MORE_ITEMS_SHOW)
    }

    return (
        <div className={classes}>
            {data && !data.length && (
                <div className={style.empty}>
                    {t('presets_empty')}
                </div>
            )}
            {data && !!data.length && (
                <>
                    <div className={style.title}>
                        {t('presets_available')}
                    </div>
                    {data.slice(0, itemsShow).map((item) => (
                        <Tag
                            classes={style.tag}
                            classesContent={style.tagContent}
                            classesCross={style.tagCross}
                            key={item.id}
                            onDelete={() => onDelete(item.id)}
                            onClick={() => onSelect(item.id)}
                        >
                            {item.name}
                        </Tag>
                    ))}
                </>
            )}
            {moreText && (
                <Button
                    classes={style.actionMore}
                    styleType="text"
                    title={t('show_more')}
                    onClick={handlerClickMore}
                >
                    {moreText}
                </Button>
            )}
        </div>
    )
}

export default CompanyOrdersPresets
