import {
    ICatalogEntity,
    ICatalogItem,
    IStoreProfile,
    IStoreHeader,
    IStoreFolder,
    IStoreGoods,
    IStoreRank,
    IStoreSettings,
    IGoods,
} from 'interfaces'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'
import { parseTpl } from 'utils/helpers'

export type TStoreServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TStoresProps = {
    offset?: number
    limit?: number
}

export type TStoreProfileProps = {
    id: number
}

export type TStoreCategoriesProps = {
    storeId: number
}

export type TStoreRootCatalogProps = {
    storeId: number
}

export type TStoreCatalogProps = {
    storeId: number
    view?: 'plain' | 'extra-plain'
    node?: number // parent id
    depth?: number
    lang_id?: number
}

export type TStoreCatalogTreeProps = TStoreCatalogProps & {
    tree_only: boolean
}

export type TStoreShowcaseProps = {
    storeId: number
    recommended?: boolean
    lang_id?: number
    q?: string
    offset?: number
    limit?: number
}

export type TAddStoreCatalogProps = {
    storeId: number
    name: string
    type: 'folder' | 'header'
    parent: number
    code?: string
    color?: string
    order?: number
    photo?: string // base64
    remove_photo?: boolean
}

export type TUpdateStoreCatalogProps = TAddStoreCatalogProps & { id: number }

export type TRemoveStoreCatalogProps = { id: number }

export type TStorePopularGoodsProps = {
    storeId: number
}

export type TStoreCpaLinkProps = {
    storeId: number
}

export type TStoresResponse = IStoreProfile[]

export type TStoreCatalogResponse = (IStoreHeader | IStoreFolder | IStoreGoods)[]

export type TStoreCatalogTreeResponse = ICatalogItem[]

export type TStoreShowcaseResponse = IGoods[]

export type TStoreProfileResponse = IStoreProfile

export type TStoreCategoriesResponse = ICatalogItem[]

export type TAddStoreCatalogResponse = ICatalogEntity

export type TUpdateStoreCatalogResponse = ICatalogEntity

export type TRemoveStoreCatalogResponse = string // OK

export type TStoreCpaLinkResponse = {
    kick_loyalty_settings: string
    kick_loyalty_settings_user: string
    kick_max_loyalty_settings: string
    kick_max_loyalty_settings_user: string
    rank: IStoreRank
    link: string
}

export type TStoreSettingsResponse = IStoreSettings[]

export type TStoreSettingsUpdateResponse = IStoreSettings[]

export type TStoreSettingsProps = {
    limit?: number
    offset?: number
    q?: string
    store_ids?: number[]
}

export type TStoreSettingsUpdateProps = {
    limit?: number
    offset?: number
    subscribe_list?: {
        id: number
        is_subscribe: boolean
    }[]
}

// TODO to MarketService
class StoreService {
    static fetchStores(params: TStoresProps = {}) {
        return requestClient<TStoresResponse>(API_URL.store, { params })
    }

    static fetchStoreProfile({ id }: TStoreProfileProps) {
        const url = parseTpl(API_URL.storeProfile, { store_id: id })
        return requestClient<TStoreProfileResponse>(url)
    }

    static fetchStoreCategories({ storeId }: TStoreCategoriesProps) {
        const url = parseTpl(API_URL.catalog, { store_id: storeId })
        return requestClient<TStoreCategoriesResponse>(url)
    }

    static fetchStoreCatalog({ storeId, ...params }: TStoreCatalogProps) {
        const url = parseTpl(API_URL.catalog, { store_id: storeId })
        return requestClient<TStoreCatalogResponse>(url, { params })
    }

    static fetchStoreCatalogTree({ storeId, ...params }: TStoreCatalogTreeProps) {
        const url = parseTpl(API_URL.catalog, { store_id: storeId })
        return requestClient<TStoreCatalogTreeResponse>(url, { params })
    }

    static fetchStoreShowcase({ storeId, ...params }: TStoreShowcaseProps) {
        const url = parseTpl(API_URL.showcase, { store_id: storeId })
        return requestClient<TStoreShowcaseResponse>(url, { params })
    }

    static addStoreCatalog({ storeId, ...params }: TAddStoreCatalogProps) {
        const url = parseTpl(API_URL.catalog, { store_id: storeId })
        return requestClient<TAddStoreCatalogResponse>(url, { method: 'post', data: params })
    }

    static updateStoreCatalog({ storeId, id, ...params }: TUpdateStoreCatalogProps) {
        const url = parseTpl(API_URL.catalog, { store_id: storeId })
        return requestClient<TUpdateStoreCatalogResponse>(`${url}/${id}`, { method: 'post', data: params })
    }

    static removeStoreCatalog({ id }: TRemoveStoreCatalogProps) {
        return requestClient<TRemoveStoreCatalogResponse>(`${API_URL.catalogCategory}/${id}`, { method: 'delete' })
    }

    static fetchStorePopularGoods({ storeId }: TStorePopularGoodsProps) {
        const url = parseTpl(API_URL.popularGoods, { store_id: storeId })
        return requestClient<IGoods[]>(url)
    }

    static fetchCpaStoreLink({ storeId }: TStoreCpaLinkProps) {
        const url = parseTpl(API_URL.cpaLink, { store_id: storeId })
        return requestClient<TStoreCpaLinkResponse>(url)
    }

    static fetchStoreSettings(params: TStoreSettingsProps) {
        return requestClient<TStoreSettingsResponse>(API_URL.storeSettings, { params })
    }

    static updateStoreSettings(params: TStoreSettingsUpdateProps) {
        return requestClient<TStoreSettingsUpdateResponse>(API_URL.storeSettings, { method: 'post', data: params })
    }
}

export default StoreService
