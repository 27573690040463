import React, { useRef, useState } from 'react'
import cn from 'classnames'

import { useOutsideClick } from 'hooks'
import { Context } from '../Context'
import style from './CustomSelect.module.css'

export type CustomSelectSelectPropType = {
    classes?: string
    isOpen?: boolean
}

const CustomSelectSelect: React.FC<CustomSelectSelectPropType> = ({
    children,
    classes,
    isOpen = false,
}) => {
    const ref = useRef(null)
    const [context, setContext] = useState({ isOpen, setOpen })

    function setOpen(open: boolean) {
        setContext((prevState) => ({ ...prevState, isOpen: open }))
    }

    useOutsideClick(ref, () => {
        setOpen(false)
    })

    return (
        <div className={cn(style.select, classes)} ref={ref}>
            <Context.Provider value={context}>
                {children}
            </Context.Provider>
        </div>
    )
}

export default CustomSelectSelect
