import { AxiosError } from 'axios'
import {
    UseInfiniteQueryOptions,
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TNetworkServiceError,
    TBlocksResponse,
    TBlockUserProps,
    TBlockUserResponse,
    TUnblockUserProps,
    TUnblockUserResponse,
} from 'services/NetworkService'
import { QueryCacheKeys } from 'enums'
import { NetworkService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnResponse = TBlocksResponse
export type TFetchFnError = AxiosError<TNetworkServiceError>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TFetchFnResponse,
    TError,
    TFetchFnResponse,
    TFetchFnResponse,
    TQueryKey
>

export type TBlockUserMutationFnError = [string, AxiosError<TNetworkServiceError, TBlockUserProps>]
export type TUnblockUserMutationFnError = [string, AxiosError<TNetworkServiceError, TUnblockUserProps>]

const key = QueryCacheKeys.blocks

/**
 * Хук API получить список заблокированных пользователей
 */
export default function useFetchBlocks(opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`], () => {
        return NetworkService.fetchBlocks()
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationBlocks() {
    const { t } = useTranslation()

    const blockUser = useMutation<
        TBlockUserResponse,
        TBlockUserMutationFnError,
        TBlockUserProps,
        unknown
    >((props) => {
        return NetworkService.blockUser(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const unblockUser = useMutation<
        TUnblockUserResponse,
        TUnblockUserMutationFnError,
        TUnblockUserProps,
        unknown
    >((props) => {
        return NetworkService.unBlockUser(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        blockUser,
        unblockUser,
    }
}
