import React from 'react'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, Input, Tooltip } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './CompanyOrdersPresetsForm.module.css'

export enum FieldName {
    preset = 'preset',
}

export type FormDataType = {
    [FieldName.preset]: string
}

type CompanyOrdersPresetsFormPropType = {
    classes?: string
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.preset]: '',
}

const CompanyOrdersPresetsForm: React.FC<CompanyOrdersPresetsFormPropType> = ({
    classes,
    isSubmitting,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { hasValidationErrors } = useFormState<FormDataType>()

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                    <div className={styleForm.groupItem}>
                        <div className={styleForm.fieldHead}>
                            {/* TODO */}
                            Создать новый пресет
                        </div>
                    </div>
                    <div className={styleForm.groupItem}>
                        <Tooltip
                            classes={style.tooltip}
                            position="right"
                            tooltip={(
                                <div className={style.tooltipHint}>
                                    {/* TODO */}
                                    Создать новый пресет
                                </div>
                            )}
                        >
                            <Tooltip.TriggerInfo />
                        </Tooltip>
                    </div>
                </div>
                <Field
                    <FormDataType[FieldName.preset]>
                    name={FieldName.preset}
                    render={({ input }) => (
                        <Input
                            {...input}
                            styleType="defaultBusiness"
                        />
                    )}
                />
            </div>
            <div>
                <Button
                    classes={style.control}
                    styleType="bordered"
                    size="size44"
                    type="submit"
                    text={t('save')}
                    disabled={hasValidationErrors}
                />
            </div>
        </form>
    )
}

export default CompanyOrdersPresetsForm
