import React from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import { Avatar, SvgResource } from 'components'
import { useAppMode } from 'containers/App/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import style from './UserInfo.module.css'

const UserInfo: React.FC = () => {
    const { appMode, isBusinessMode } = useAppMode()
    const { t } = useTranslation()

    const user = useSelector(userSelectors.user)
    const profile = useSelector(userSelectors.profile)

    return (
        <>
            {isBusinessMode ? (
                <NavLink
                    className={cn(style.wrap, style[`wrap_${appMode}`])}
                    to={APP_URL.userSettings}
                >
                    <Avatar
                        src={user?.photo}
                        width={48}
                        height={48}
                        name={user?.name}
                        surname={user?.surname}
                    />

                    <div className={cn(style.user, style[`user_${appMode}`])}>
                        <div className={cn(style.userName, style[`userName_${appMode}`])}>
                            {user?.name}
                            {' '}
                            {user?.surname}
                        </div>
                        <div className={style.userEmail}>
                            {user?.email}
                        </div>
                    </div>
                </NavLink>
            ) : (
                <div className={cn(style.wrap, style[`wrap_${appMode}`])}>
                    <NavLink
                        className={style.linkEdit}
                        activeClassName={style.linkEdit_active}
                        title={t('edit')}
                        to={APP_URL.userSettings}
                    >
                        <SvgResource
                            resourceKey="edit_profile_svg"
                            width={30}
                            height={30}
                        />
                    </NavLink>

                    <Avatar
                        src={user?.photo}
                        width={117}
                        height={117}
                        name={user?.name}
                        surname={user?.surname}
                    />

                    <div className={cn(style.user, style[`user_${appMode}`])}>
                        <div className={cn(style.userName, style[`userName_${appMode}`])}>
                            {user?.name}
                            {' '}
                            {user?.surname}
                        </div>
                    </div>
                    <div className={style.data}>
                        {profile?.rating_sessia?.rating >= 0 && (
                            <div className={style.dataItem}>
                                <SvgResource
                                    classes={style.dataIcon}
                                    resourceKey="rating_ic_star_svg"
                                    width={20}
                                    height={20}
                                />
                                <span className={style.dataText}>
                                    {profile.rating_sessia.rating}
                                </span>
                            </div>
                        )}

                        {profile?.country && (
                            <div className={style.dataItem}>
                                {profile.country.icon && (
                                    <span className={style.dataIcon}>
                                        <img className={style.dataIconImg} src={profile.country.icon} alt="" />
                                    </span>
                                )}
                                {(profile.city?.name || profile.country.name) && (
                                    <span className={style.dataText}>
                                        {profile.city?.name || profile.country.name}
                                    </span>
                                )}
                            </div>
                        )}
                    </div>

                    {profile?.profileStatus && (
                        <div className={style.text}>
                            {profile.profileStatus}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default UserInfo
