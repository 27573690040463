import React from 'react'

import { ICatalogItem } from 'interfaces'
import { CatalogTreeItem } from './components'
import style from './CatalogTree.module.css'

type CatalogTreePropType = {
    classes?: string
    data: ICatalogItem[]
    onClick?: (data: ICatalogItem) => void
}

const CatalogTree: React.FC<CatalogTreePropType> = ({ classes, data, onClick }) => {
    return (
        <div className={classes}>
            {data.map((item) => (
                <CatalogTreeItem
                    classes={style.item}
                    data={item}
                    key={item.id}
                    onClick={onClick}
                />
            ))}
        </div>
    )
}

export default CatalogTree
