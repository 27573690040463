import React from 'react'
import cn from 'classnames'

import { IStickerCollection } from '../../../../interfaces'
import { Sticker } from '../../../index'
import style from './StickersMenuItem.module.css'

type StickersMenuItemPropType = {
    data: IStickerCollection
    isActive: boolean
    onClick: (id: number) => void
}

const StickersMenuItem: React.FC<StickersMenuItemPropType> = ({
    data,
    isActive,
    onClick,
}) => {
    const { id, type } = data

    const handlerClick = () => {
        onClick(id)
    }

    return (
        <li
            className={cn(style.item, isActive ? style.item_active : style.item_clickable)}
            role="presentation"
            onKeyUp={handlerClick}
            onClick={handlerClick}
        >
            <Sticker
                isMenuSticker
                classes={style.img}
                data={{ type }}
            />
        </li>
    )
}

export default StickersMenuItem
