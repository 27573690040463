import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    ICatalogItem,
    IGoodsType,
    IGoodsAvailableStatus,
    IStoreProfile,
} from 'interfaces'
import { TFile } from 'hooks/useUploadFiles'
import { BREAKPOINTS } from 'config/app'
import {
    CatalogTree,
    CustomSelect,
    Cropper,
    DatePicker,
    FileDrop,
    Button,
    Input,
    Tag,
    Textarea,
    Tooltip,
    SlideDown,
    Switch,
    SvgResource,
    Modal,
} from 'components'
import { useWindowResize, useUploadFiles } from 'hooks'
import { ImagePreview } from 'forms/StoreForm/components'
import { getDateValues, dateTimeFormat, defaultDateFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './ShopGoodsForm.module.css'

export enum FieldName {
    id = 'id',
    productType = 'productType',
    name = 'name',
    shortName = 'shortName',
    subtitle = 'subtitle',
    store = 'store',
    storeCatalog = 'storeCatalog',
    price = 'price',
    weight = 'weight',
    retailPrice = 'retailPrice',
    availableStatus = 'availableStatus',
    description = 'description',
    vendorCode = 'vendorCode',
    brands = 'brands', // FIXME on ready backend
    customVendorCategoryCode = 'customVendorCategoryCode',
    codePackage = 'codePackage',
    quantityLimitForSale = 'quantityLimitForSale',
    quantityLimitForSaleGlobal = 'quantityLimitForSaleGlobal',
    availabilityDateFrom = 'availabilityDateFrom',
    availabilityDateTo = 'availabilityDateTo',
    isHideInPopular = 'isHideInPopular',
    isRecommended = 'isRecommended',
    images = 'images',
}

export type FormDataType = {
    [FieldName.id]?: number
    [FieldName.productType]: number
    [FieldName.name]: string
    [FieldName.store]: number
    [FieldName.storeCatalog]: { id: number, name: string, sort: number }[]
    [FieldName.price]: number
    [FieldName.retailPrice]: number
    [FieldName.availableStatus]: number
    [FieldName.isHideInPopular]: 0|1
    [FieldName.isRecommended]: 0|1
    [FieldName.shortName]?: string
    [FieldName.subtitle]?: string
    [FieldName.description]?: string
    [FieldName.weight]?: number
    [FieldName.vendorCode]?: string
    [FieldName.brands]?: { id: number, name: string }[]
    [FieldName.quantityLimitForSale]?: number
    [FieldName.quantityLimitForSaleGlobal]?: number
    [FieldName.availabilityDateFrom]?: string
    [FieldName.availabilityDateTo]?: string
    [FieldName.images]?: TFormImage[]
}

type TFormImage = {
    id?: number
    delete?: boolean
    src: string
    format: number
    sort: number
}

type ShopGoodsFormPropType = {
    classes?: string
    isSubmitting: boolean
    productType?: IGoodsType[]
    brands?: { id: number, name: string, code: any } [] // FIXME on ready api request
    availableStatus?: IGoodsAvailableStatus[]
    catalog?: ICatalogItem[]
    shopProfile?: IStoreProfile
    onPreview?: () => void
    onChange?: (data: FormDataType) => void
    onError?: (err: any) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

type TDateField = FieldName.availabilityDateFrom | FieldName.availabilityDateTo

const CATALOG_EMPTY_VALUE = { id: 0, name: '', sort: 0 }
const ACCEPT_IMAGE = 'image/jpeg,image/png'
const IMAGE_ASPECT_RATIO = 1.33
const IMAGE_MAX_COUNT = 32
const IMAGE_FORMAT_PREVIEW = 1
const IMAGE_FORMAT_IMAGE = 2
const IMAGE_SORT_PREVIEW = 0
const IMAGE_SORT_IMAGE = 1
const DESCRIPTION_LENGTH_MAX = 1024
const SUBTITLE_LENGTH_MAX = 128
const PATTERN_PRICE_NUMBER = '^[1-9]\\d{0,11}([.,]\\d*)?$'

export const formDataInitial: FormDataType = {
    [FieldName.productType]: 0,
    [FieldName.name]: '',
    [FieldName.store]: 0,
    [FieldName.storeCatalog]: [CATALOG_EMPTY_VALUE],
    [FieldName.price]: 0,
    [FieldName.retailPrice]: 0,
    [FieldName.availableStatus]: 0,
    [FieldName.isHideInPopular]: 0,
    [FieldName.isRecommended]: 0,
}

const ShopGoodsForm: React.FC<ShopGoodsFormPropType> = ({
    classes,
    isSubmitting,
    productType,
    availableStatus,
    brands,
    catalog,
    shopProfile,
    onPreview,
    onChange,
    onError,
    onSubmit,
}) => {
    const { t, i18n } = useTranslation()
    const [windowWidth] = useWindowResize()

    const inputFileRef = useRef<HTMLInputElement>(null)

    const { change, batch } = useForm<FormDataType>()
    const { values, hasValidationErrors } = useFormState<FormDataType>({
        onChange: (formState) => {
            if (formState.values[FieldName.productType] === 8 && formState.values[FieldName.weight]) {
                batch(() => {
                    change(FieldName.weight, undefined)
                })
            }

            if (onChange) {
                onChange(formState.values)
            }
        },
    })

    const [imageFiles, setImageFiles] = useState<File[]>()
    const [image, setImage] = useState<string>() // base64
    const [imageCrop, setImageCrop] = useState<string>() // base64
    const [currentCatalogItemIndex, setCurrentCatalogItemIndex] = useState(0)
    const [currentDateField, setCurrentDateField] = useState<TDateField>()
    const [dateCalendar, setDateCalendar] = useState(new Date())
    const [imageDragTo, setImageDragTo] = useState<number>()
    const [isWarningAvailabilityDateFrom, setIsWarningAvailabilityDateFrom] = useState(false)
    const [isActiveFileDropzone, setIsActiveFileDropzone] = useState(false)
    const [isOpenModalCatalog, setIsOpenModalCatalog] = useState(false)
    const [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)
    const [isOpenModalCropper, setIsOpenModalCropper] = useState(false)

    const { upload: uploadFiles } = useUploadFiles()

    const productTypeData: Record<number, string> = useMemo(() => {
        return productType
            ? productType.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [productType])

    const availableStatusData: Record<number, IGoodsAvailableStatus> = useMemo(() => {
        return availableStatus
            ? availableStatus.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
            : {}
    }, [availableStatus])

    const shopCurrency = useMemo(() => {
        return shopProfile ? shopProfile.currency : ''
    }, [shopProfile])

    const dateSelected = useMemo(() => {
        return currentDateField && values[currentDateField]
            ? new Date(values[currentDateField] as string)
            : undefined
    }, [values, currentDateField])

    const warningTextAvailabilityDateFrom = useMemo(() => {
        return values[FieldName.availabilityDateFrom]
            ? t('past_date_warning_text')
                .replace('%d%', dateTimeFormat(values[FieldName.availabilityDateFrom]!, i18n.language))
            : ''
    }, [values[FieldName.availabilityDateFrom]])

    const isDisablesInputImages = useMemo(() => {
        return values[FieldName.images] && values[FieldName.images]!.length >= IMAGE_MAX_COUNT
    }, [values[FieldName.images]])

    const isMobile = useMemo(() => {
        return windowWidth < BREAKPOINTS.tabletLandscape
    }, [windowWidth])

    const handlerClickCategory = ({ id, name, sort }: ICatalogItem) => {
        const isSetCurrentCategory = values[FieldName.storeCatalog][currentCatalogItemIndex].id === id
        let value: FormDataType[FieldName.storeCatalog]

        if (isSetCurrentCategory) { /** remove selected category */
            value = values[FieldName.storeCatalog].length > 1
                ? values[FieldName.storeCatalog].filter((item, index) => {
                    return index !== currentCatalogItemIndex
                })
                : values[FieldName.storeCatalog]
        } else { /** add selected category */
            value = !values[FieldName.storeCatalog].find((item) => item.id === id)
                ? values[FieldName.storeCatalog].map((item, index) => {
                    return index === currentCatalogItemIndex ? { id, name, sort: sort + 100 } : item
                })
                : values[FieldName.storeCatalog]
        }

        change(FieldName.storeCatalog, value)
        setIsOpenModalCatalog(false)
    }

    const handlerChangeCategorySort = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const sort = currentTarget.value ? Number.parseInt(currentTarget.value, 10) : 0

        if (!Number.isNaN(sort)) {
            const value = values[FieldName.storeCatalog].map((item, index) => {
                return index === currentCatalogItemIndex ? { ...item, sort } : item
            })

            change(FieldName.storeCatalog, value)
        }
    }

    const handlerChangeAvailabilityDate = (value: Date) => {
        if (currentDateField) {
            const { year, month, day } = getDateValues(new Date())
            const dateCurrent = new Date(year, month, day, 0, 0)
            const dateFromValue = values[FieldName.availabilityDateFrom]
            /** warning: start date in the past */
            const isWarning = currentDateField === FieldName.availabilityDateFrom
                ? value < dateCurrent
                : !!dateFromValue && new Date(dateFromValue) < dateCurrent

            setIsWarningAvailabilityDateFrom(isWarning)
            change(currentDateField, defaultDateFormat(value, true, true))
        }
        setIsOpenModalCalendar(false)
    }

    const handlerAddCategory = () => {
        const isSetEmptyCatalog = values[FieldName.storeCatalog].some((item) => item.id === CATALOG_EMPTY_VALUE.id)

        if (!isSetEmptyCatalog) {
            change(FieldName.storeCatalog, [...values[FieldName.storeCatalog], CATALOG_EMPTY_VALUE])
        }
    }

    const handlerAddFiles = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = currentTarget

        if (files?.length) {
            setImageFiles(Array.from(files).slice(0, IMAGE_MAX_COUNT))
        }
    }

    const handlerDropFiles = (files: FileList) => {
        if (files?.length) {
            setImageFiles(Array.from(files).slice(0, IMAGE_MAX_COUNT))
            setIsActiveFileDropzone(false)
        }
    }

    const handlerDragEnterPhoto = (idx: number) => {
        setImageDragTo(idx)
    }

    const handlerDragEndPhoto = (idx: number) => {
        if (imageDragTo !== undefined && values[FieldName.images]?.length) {
            const value = [...values[FieldName.images]!]
            const item = value.splice(idx, 1)[0]

            value.splice(imageDragTo, 0, item)
            setImageDragTo(undefined)
            change(FieldName.images, value.map((val, index) => ({
                ...val,
                format: index === 0 ? IMAGE_FORMAT_PREVIEW : IMAGE_FORMAT_IMAGE,
                sort: index === 0 ? IMAGE_SORT_PREVIEW : IMAGE_SORT_IMAGE,
            })))
        }
    }

    const handlerRemovePhoto = (idx: number) => {
        const value = (values[FieldName.images] ?? []).map((item, index) => {
            if (index === idx) {
                return { ...item, delete: true }
            }
            return item
        })
        change(FieldName.images, value)
    }

    const handlerDragOverFiles = () => {
        setIsActiveFileDropzone(true)
    }

    const handlerClickDropzone = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }

    const handlerCrop = (data: string, type: string) => {
        setImageCrop(`data:${type};base64,${data}`)
    }

    const handlerSaveImage = () => {
        if (imageCrop) {
            const [data] = imageCrop.split(',')
            const [type] = data.match(/image\/\w+/) || []
            const [, ext] = data.split('/')

            fetch(imageCrop)
                .then((res) => {
                    return res.blob()
                })
                .then((blob) => {
                    const file = new File([blob], `file.${ext}`, { type })

                    /** replace origin file for cropped */
                    setImageFiles((prevState) => {
                        if (prevState) {
                            const items = [...prevState]

                            items.splice(0, 1, file)
                            return items
                        }
                        return undefined
                    })
                    setIsOpenModalCropper(false)
                })
                .catch((err) => {
                    if (onError) {
                        onError(err)
                    }
                })
        }
    }

    function addUploadedFile({ fileData, image: imageEl }: TFile) {
        if (fileData) {
            const images = [...values[FieldName.images] ?? []]
            const value = [
                ...images,
                {
                    src: imageEl ? `${fileData.path}?sswidth=${imageEl.width}&ssheight=${imageEl.height}` : fileData.path,
                    format: images.length ? IMAGE_FORMAT_IMAGE : IMAGE_FORMAT_PREVIEW,
                    sort: images.length ? IMAGE_SORT_IMAGE : IMAGE_SORT_PREVIEW,
                },
            ]

            change(FieldName.images, value)
            setImageFiles((prevState) => {
                return prevState?.length ? prevState.slice(1) : undefined
            })
        }
    }

    function uploadFilesAction(files: FileList, isUpload: boolean) {
        return uploadFiles(files, { isUpload })
    }

    useEffect(() => {
        if (imageFiles?.length) {
            const dt = new DataTransfer()

            dt.items.add(imageFiles[0])
            uploadFilesAction(dt.files, false)
                .then(([data]) => {
                    const { image: imageEl, imageBase64 } = data

                    if (imageEl) {
                        const aspectRatio = Number((imageEl.width / imageEl.height).toFixed(2))

                        if (aspectRatio !== IMAGE_ASPECT_RATIO) {
                            setImage(imageBase64)
                            setIsOpenModalCropper(true)
                        } else {
                            uploadFilesAction(dt.files, true)
                                .then(([uploadedFile]) => {
                                    addUploadedFile(uploadedFile)
                                })
                                .catch((err) => {
                                    if (onError) {
                                        onError(err)
                                    }
                                })
                        }
                    }
                })
                .catch((err) => {
                    if (onError) {
                        onError(err)
                    }
                })
        }
    }, [imageFiles])

    return (
        <>
            <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <SlideDown isOpened classes={style.slideDown}>
                    <SlideDown.Header classes={style.slideDownHeader}>
                        {t('info_section')}
                    </SlideDown.Header>
                    <SlideDown.Body classes={style.slideDownBody}>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('product_type')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position="right"
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('product_type_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                <FormDataType[FieldName.productType]>
                                name={FieldName.productType}
                                render={({ input, meta }) => (
                                    <CustomSelect.Select>
                                        <CustomSelect.Field classes={style.selectField} disabled={!productType}>
                                            {!!input.value && productTypeData[input.value]}
                                        </CustomSelect.Field>
                                        <CustomSelect.Options>
                                            {productType?.map((item) => (
                                                <CustomSelect.Option
                                                    id={item.id}
                                                    isActive={item.id === input.value}
                                                    key={item.id}
                                                    onClick={input.onChange}
                                                >
                                                    {item.name}
                                                </CustomSelect.Option>
                                            ))}
                                        </CustomSelect.Options>
                                    </CustomSelect.Select>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={styleForm.fieldHead}>
                                {`${t('screen_pm_name_title')}*`}
                            </div>
                            <Field
                                name={FieldName.name}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {`${t('screen_pm_vendor_code_title')}*`}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position="right"
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('vendor_code_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                name={FieldName.vendorCode}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_spaceBetween)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('screen_pm_description_title')}
                                    </div>
                                </div>
                                <div className={styleForm.groupItem}>
                                    <div className={cn(style.textCharCount, {
                                        [style.textCharCount_danger]: values[FieldName.description]
                                            && values[FieldName.description]!.length === DESCRIPTION_LENGTH_MAX,
                                    })}
                                    >
                                        {`${values[FieldName.description]?.length ?? 0}/${DESCRIPTION_LENGTH_MAX}`}
                                    </div>
                                </div>
                            </div>
                            <Field
                                <FormDataType[FieldName.description]>
                                name={FieldName.description}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Textarea
                                            {...input}
                                            isAutoHeight
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                            rows={1}
                                            limit={DESCRIPTION_LENGTH_MAX}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('market_sub_title')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position="right"
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('goods_subtitle_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                name={FieldName.subtitle}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                            maxLength={SUBTITLE_LENGTH_MAX}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={styleForm.group}>
                                <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth, style.groupItem)}>
                                    <div className={styleForm.fieldHead}>
                                        {`${t('screen_pm_price_title')}, ${shopCurrency}*`}
                                    </div>
                                    <Field
                                        <FormDataType[FieldName.price]>
                                        name={FieldName.price}
                                        render={({ input, meta }) => (
                                            <div className={styleForm.field}>
                                                <Input
                                                    {...input}
                                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                                    styleType="defaultBusiness"
                                                    pattern={PATTERN_PRICE_NUMBER}
                                                    value={input.value || ''}
                                                    onChange={({ currentTarget }) => {
                                                        if (currentTarget.validity.valid) {
                                                            input.onChange(currentTarget.value.replace(',', '.'))
                                                        }
                                                    }}
                                                />
                                                <div className={styleForm.error}>
                                                    {meta.dirty && meta.error}
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>
                                {values[FieldName.productType] === 1 && (
                                    <div className={cn(styleForm.groupItem, style.groupItem)}>
                                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                            <div className={styleForm.groupItem}>
                                                <div className={cn(
                                                    styleForm.fieldHead,
                                                    styleForm.fieldHead_capitalize,
                                                )}
                                                >
                                                    {`${t('weight')}*`}
                                                </div>
                                            </div>
                                            <div className={cn(
                                                styleForm.groupItem,
                                                styleForm.groupItem_maxWidth,
                                                style.groupItem,
                                                style.groupItem_flex,
                                            )}
                                            >
                                                <Tooltip
                                                    classes={style.tooltip}
                                                    position="right"
                                                    tooltip={(
                                                        <div className={cn(
                                                            style.tooltipHint,
                                                            style.tooltipHint_widthAuto,
                                                        )}
                                                        >
                                                            {t('weight_hint')}
                                                        </div>
                                                    )}
                                                >
                                                    <Tooltip.TriggerInfo />
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <Field
                                            <FormDataType[FieldName.weight]>
                                            name={FieldName.weight}
                                            render={({ input, meta }) => (
                                                <div className={styleForm.field}>
                                                    <Input
                                                        {...input}
                                                        classes={cn({
                                                            [styleForm.invalid]: meta.modified && !!meta.error,
                                                        })}
                                                        styleType="defaultBusiness"
                                                        pattern="[1-9][0-9]*"
                                                        onChange={(e) => {
                                                            if (e.currentTarget.validity.valid) {
                                                                input.onChange(e.currentTarget.value
                                                                    && Number(e.currentTarget.value))
                                                            }
                                                        }}
                                                    />
                                                    <div className={styleForm.error}>
                                                        {meta.modified && meta.error}
                                                    </div>
                                                </div>
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_capitalize)}>
                                        {t('brands')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position="right"
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('goods_brands_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                <FormDataType[FieldName.brands]>
                                name={FieldName.brands}
                                render={({ input }) => (
                                    <CustomSelect.Select>
                                        <CustomSelect.Field
                                            classes={cn(style.selectField, style.selectField_heightAuto)}
                                        >
                                            <div className={style.selectFieldWrapTag}>
                                                {input.value && input.value.map((item, idx, items) => (
                                                    <Tag
                                                        classes={style.tag}
                                                        key={item.id}
                                                        onDelete={() => {
                                                            input.onChange(items.filter((val) => val.id !== item.id))
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Tag>
                                                ))}
                                            </div>
                                        </CustomSelect.Field>
                                        <CustomSelect.Options>
                                            <CustomSelect.Search
                                                styleType="clear"
                                                value=""
                                                onChange={() => {}}
                                            />
                                            {brands?.map((item) => (
                                                <CustomSelect.Option
                                                    id={item.id}
                                                    // isActive={item.id === input.value}
                                                    key={item.id}
                                                    onClick={input.onChange}
                                                >
                                                    {item.name}
                                                </CustomSelect.Option>
                                            ))}
                                        </CustomSelect.Options>
                                    </CustomSelect.Select>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={styleForm.group}>
                                <div className={styleForm.groupItem}>
                                    <Button
                                        classes={styleForm.fieldHead}
                                        styleType="transparent"
                                        disabled={isDisablesInputImages}
                                        onClick={() => inputFileRef.current?.click()}
                                    >
                                        {t('screen_pm_add_photo')}
                                    </Button>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position="right"
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('goods_photo_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                    <div className={style.iconAddPhoto} />
                                </div>
                            </div>
                            <Field
                                <FormDataType[FieldName.images]>
                                name={FieldName.images}
                                render={({ input }) => (
                                    <>
                                        <Input
                                            multiple
                                            classes={styleForm.inputFile}
                                            styleType="clear"
                                            type="file"
                                            accept={ACCEPT_IMAGE}
                                            name={input.name}
                                            ref={inputFileRef}
                                            disabled={isDisablesInputImages}
                                            onChange={handlerAddFiles}
                                        />
                                        <FileDrop
                                            classes={cn(style.fileDropzone, {
                                                [style.fileDropzone_active]: isActiveFileDropzone,
                                                [style.fileDropzone_clickable]: !input.value
                                                    || input.value.every((item) => item.delete),
                                            })}
                                            onDragLeave={() => !isDisablesInputImages && setIsActiveFileDropzone(false)}
                                            onDragOver={() => !isDisablesInputImages && handlerDragOverFiles()}
                                            onDrop={(fileList) => !isDisablesInputImages && handlerDropFiles(fileList)}
                                            onClick={!input.value || input.value.every((item) => item.delete)
                                                ? handlerClickDropzone
                                                : undefined}
                                        >
                                            {input.value && input.value.some((item) => !item.delete) ? (
                                                input.value.map((item, index) => (
                                                    <React.Fragment key={item.src}>
                                                        {(!('delete' in item) || ('delete' in item && !item.delete)) && (
                                                            <ImagePreview
                                                                isDraggable
                                                                src={item.src}
                                                                onDragEnter={() => handlerDragEnterPhoto(index)}
                                                                onDragEnd={() => handlerDragEndPhoto(index)}
                                                                onDelete={() => handlerRemovePhoto(index)}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <div className={cn(style.fileDropzoneText, {
                                                    [style.fileDropzoneText_active]: isActiveFileDropzone,
                                                })}
                                                >
                                                    {t('dropzone_hint')}
                                                </div>
                                            )}
                                        </FileDrop>
                                    </>
                                )}
                            />
                        </div>
                    </SlideDown.Body>
                </SlideDown>
                <SlideDown classes={style.slideDown}>
                    <SlideDown.Header classes={style.slideDownHeader}>
                        {t('visiblity_section')}
                    </SlideDown.Header>
                    <SlideDown.Body classes={style.slideDownBody}>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_capitalize)}>
                                        {t('screen_pm_goods_spinner_description_hint')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    {!!values[FieldName.availableStatus] && (
                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {availableStatusData[values[FieldName.availableStatus]].name}
                                                    {'. '}
                                                    {availableStatusData[values[FieldName.availableStatus]].hint}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            <Field
                                <FormDataType[FieldName.availableStatus]>
                                name={FieldName.availableStatus}
                                render={({ input, meta }) => (
                                    <>
                                        <CustomSelect.Select>
                                            <CustomSelect.Field classes={style.selectField} disabled={!availableStatus}>
                                                {!!input.value && availableStatusData[input.value].name}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options>
                                                {availableStatus?.map((item) => (
                                                    <CustomSelect.Option
                                                        id={item.id}
                                                        isActive={item.id === input.value}
                                                        key={item.id}
                                                        onClick={input.onChange}
                                                    >
                                                        {item.name}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <Field
                                <FormDataType[FieldName.storeCatalog]>
                                name={FieldName.storeCatalog}
                                render={({ input, meta }) => (
                                    input.value.map((item, index) => (
                                        <div
                                            className={cn(
                                                styleForm.row,
                                                styleForm.row_20,
                                                styleForm.group,
                                                styleForm.group_alignCenter,
                                            )}
                                            key={item.id}
                                        >
                                            <div className={cn(
                                                styleForm.groupItem,
                                                styleForm.groupItem_maxWidth,
                                                style.groupItem,
                                            )}
                                            >
                                                {index === 0 && (
                                                    <div className={styleForm.fieldHead}>
                                                        {`${t('dialog_pm_add_category')}*`}
                                                    </div>
                                                )}
                                                <div className={styleForm.field}>
                                                    <Input
                                                        {...input}
                                                        styleType="defaultBusiness"
                                                        value={item.name}
                                                        onFocus={(e) => {
                                                            setCurrentCatalogItemIndex(index)
                                                            setIsOpenModalCatalog(true)
                                                            e.currentTarget.blur()
                                                        }}
                                                        onChange={() => {}}
                                                    />
                                                    <SvgResource
                                                        classes={style.fieldArrow}
                                                        resourceKey="dropdpwn_daw_svg"
                                                        isImgTag={false}
                                                        width={18}
                                                        height={11}
                                                    />
                                                </div>
                                            </div>
                                            <div className={cn(styleForm.groupItem, style.groupItem)}>
                                                {index === 0 && (
                                                    <div className={styleForm.fieldHead}>
                                                        {t('pm_sorting_title')}
                                                    </div>
                                                )}
                                                <div className={styleForm.field}>
                                                    <Input
                                                        styleType="defaultBusiness"
                                                        inputMode="numeric"
                                                        value={item.sort}
                                                        onChange={(e) => {
                                                            setCurrentCatalogItemIndex(index)
                                                            handlerChangeCategorySort(e)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <Button
                                classes={cn(styleForm.group, styleForm.group_alignTop, style.fieldHead_button)}
                                styleType="transparent"
                                onClick={handlerAddCategory}
                            >
                                <div className={styleForm.fieldHead}>
                                    {t('screen_pm_add_category_title')}
                                </div>
                                <div className={style.iconAddPhoto} />
                            </Button>
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('screen_pm_start_sale_date_title')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position={isMobile ? 'left' : 'right'}
                                        tooltip={(
                                            <div className={cn(
                                                style.tooltipHint,
                                                style.tooltipHint_widthAuto,
                                            )}
                                            >
                                                {t('start_sale_date_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                <FormDataType[FieldName.availabilityDateFrom]>
                                name={FieldName.availabilityDateFrom}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            styleType="defaultBusiness"
                                            name={input.name}
                                            value={input.value && dateTimeFormat(input.value, i18n.language)}
                                            onFocus={(e) => {
                                                setCurrentDateField(FieldName.availabilityDateFrom)
                                                setDateCalendar(input.value
                                                    ? new Date(input.value)
                                                    : new Date())
                                                setIsOpenModalCalendar(true)
                                                e.currentTarget.blur()
                                            }}
                                            onChange={() => {}}
                                        />
                                        <SvgResource
                                            isImgTag={false}
                                            classes={cn(
                                                styleForm.fieldIcon,
                                                style.fieldIcon,
                                                style.iconCalendar,
                                            )}
                                            resourceKey="wallet_icon_calendar_svg"
                                            width={24}
                                            height={24}
                                        />
                                        <div className={styleForm.fieldWarning}>
                                            {isWarningAvailabilityDateFrom && warningTextAvailabilityDateFrom}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('screen_pm_end_sale_date_title')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position={isMobile ? 'left' : 'right'}
                                        tooltip={(
                                            <div className={cn(
                                                style.tooltipHint,
                                                style.tooltipHint_widthAuto,
                                            )}
                                            >
                                                {t('end_sale_date_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                <FormDataType[FieldName.availabilityDateTo]>
                                name={FieldName.availabilityDateTo}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                            name={input.name}
                                            value={input.value && dateTimeFormat(input.value, i18n.language)}
                                            onFocus={(e) => {
                                                setCurrentDateField(FieldName.availabilityDateTo)
                                                setDateCalendar(input.value
                                                    ? new Date(input.value)
                                                    : new Date())
                                                setIsOpenModalCalendar(true)
                                                e.currentTarget.blur()
                                            }}
                                            onChange={() => {}}
                                        />
                                        <SvgResource
                                            isImgTag={false}
                                            classes={cn(
                                                styleForm.fieldIcon,
                                                style.fieldIcon,
                                                style.iconCalendar,
                                            )}
                                            resourceKey="wallet_icon_calendar_svg"
                                            width={24}
                                            height={24}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </SlideDown.Body>
                </SlideDown>
                <SlideDown classes={style.slideDown}>
                    <SlideDown.Header classes={style.slideDownHeader}>
                        {t('market_additional_settings')}
                    </SlideDown.Header>
                    <SlideDown.Body classes={style.slideDownBody}>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {`${t('screen_pm_retail_price_title')}*, ${shopCurrency}`}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position="right"
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('retail_price_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                name={FieldName.retailPrice}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                            pattern={PATTERN_PRICE_NUMBER}
                                            value={input.value || ''}
                                            onChange={({ currentTarget }) => {
                                                if (currentTarget.validity.valid) {
                                                    input.onChange(currentTarget.value.replace(',', '.'))
                                                }
                                            }}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={styleForm.fieldHead}>
                                {t('screen_pm_max_num_purchases_title')}
                            </div>
                            <Field
                                name={FieldName.quantityLimitForSale}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                            type="numeric"
                                            pattern="[0-9]*"
                                            onChange={(e) => {
                                                if (e.currentTarget.validity.valid) {
                                                    input.onChange(e.currentTarget.value)
                                                }
                                            }}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('screen_pm_max_num_purchases_global_title')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position={isMobile ? 'left' : 'right'}
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('max_num_purchases_global_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                name={FieldName.quantityLimitForSaleGlobal}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                            pattern="[0-9]*"
                                            onChange={(e) => {
                                                if (e.currentTarget.validity.valid) {
                                                    input.onChange(e.currentTarget.value)
                                                }
                                            }}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('screen_pm_shortname_title')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position={isMobile ? 'left' : 'right'}
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('shortname_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                name={FieldName.shortName}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('custom_tax_category_code')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position={isMobile ? 'left' : 'right'}
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('custom_tax_category_code_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                name={FieldName.customVendorCategoryCode}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('custom_tax_package_code')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position={isMobile ? 'left' : 'right'}
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('custom_tax_package_code_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                            </div>
                            <Field
                                name={FieldName.codePackage}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('dont_show_in_popular')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position={isMobile ? 'left' : 'right'}
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('dont_show_in_popular_hint')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                                <div className={styleForm.groupItem}>
                                    <Field
                                        <FormDataType[FieldName.isHideInPopular]>
                                        name={FieldName.isHideInPopular}
                                        render={({ input, meta }) => (
                                            <div className={cn(
                                                styleForm.group,
                                                styleForm.group_fullWidth,
                                                styleForm.group_alignCenter,
                                            )}
                                            >
                                                <Switch
                                                    classes={style.switch}
                                                    name={input.name}
                                                    checked={Boolean(input.value)}
                                                    onChange={({ target }) => input.onChange(Number(target.checked))}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_20)}>
                            <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                <div className={styleForm.groupItem}>
                                    <div className={styleForm.fieldHead}>
                                        {t('recommended_item_switch_title')}
                                    </div>
                                </div>
                                <div className={cn(
                                    styleForm.groupItem,
                                    styleForm.groupItem_maxWidth,
                                    style.groupItem,
                                    style.groupItem_flex,
                                )}
                                >
                                    <Tooltip
                                        classes={style.tooltip}
                                        position={isMobile ? 'left' : 'right'}
                                        tooltip={(
                                            <div className={style.tooltipHint}>
                                                {t('recommended_item_switch_title')}
                                            </div>
                                        )}
                                    >
                                        <Tooltip.TriggerInfo />
                                    </Tooltip>
                                </div>
                                <div className={styleForm.groupItem}>
                                    <Field
                                        <FormDataType[FieldName.isRecommended]>
                                        name={FieldName.isRecommended}
                                        render={({ input, meta }) => (
                                            <div className={cn(
                                                styleForm.group,
                                                styleForm.group_fullWidth,
                                                styleForm.group_alignCenter,
                                            )}
                                            >
                                                <Switch
                                                    classes={style.switch}
                                                    name={input.name}
                                                    checked={Boolean(input.value)}
                                                    onChange={({ target }) => input.onChange(Number(target.checked))}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                    </SlideDown.Body>
                </SlideDown>
                <div className={cn(styleForm.controls_32, style.actions)}>
                    {onPreview && (
                        <Button
                            classes={style.action}
                            styleType="bordered"
                            size="size46"
                            text={t('preview')}
                            onClick={onPreview}
                        />
                    )}
                    <Button
                        classes={style.action}
                        type="submit"
                        size="size46"
                        text={t('save')}
                        disabled={hasValidationErrors}
                    />
                </div>
            </form>

            <Modal
                isOpen={isOpenModalCatalog}
                size="smallCenter"
                onClose={() => setIsOpenModalCatalog(false)}
            >
                <Modal.Header
                    isCloseButton
                    classes={style.modalCatalogTitle}
                    title={t('screen_pm_select_category')}
                    titlePos="left"
                />
                <Modal.Body classes={style.modalCatalogBody}>
                    {catalog && (
                        <CatalogTree
                            data={catalog}
                            onClick={handlerClickCategory}
                        />
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                isOpen={isOpenModalCalendar}
                size="smallCenter"
                onClose={() => setIsOpenModalCalendar(false)}
            >
                <Modal.Header
                    classes={style.modalCalendarTitle}
                    title={t('select_date')}
                    titlePos="center"
                />
                <Modal.Body classes={style.modalCalendarBody}>
                    <DatePicker
                        date={dateCalendar}
                        dateSelected={dateSelected}
                        onChange={handlerChangeAvailabilityDate}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                classes={style.modalCrop}
                isOpen={isOpenModalCropper}
                size="fullWidth"
                onClose={() => {
                    if (inputFileRef.current) {
                        /** fix chrome: reset input value on close modal */
                        inputFileRef.current.value = ''
                    }
                    setIsOpenModalCropper(false)
                }}
            >
                <Modal.Body classes={style.modalCropBody}>
                    <Cropper
                        className={style.cropper}
                        aspectRatio={IMAGE_ASPECT_RATIO}
                        source={image}
                        guides={false}
                        center={false}
                        onCrop={handlerCrop}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        size="size46"
                        text={t('save')}
                        onClick={handlerSaveImage}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShopGoodsForm
