import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TDirectoryServiceError,
    TPaymentMethodsResponse,
} from 'services/DirectoryService'
import { QueryCacheKeys } from 'enums'
import { DirectoryService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TQueryFnOpts = UseQueryOptions<
    TPaymentMethodsResponse,
    [string, AxiosError<TDirectoryServiceError, never>],
    TPaymentMethodsResponse,
    [string]
>

const key = QueryCacheKeys.paymentMethods

/**
 * Hook API payment methods
 */
export default function useFetchPaymentMethods(opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key], () => {
        return DirectoryService.fetchPaymentMethods()
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
