import React from 'react'
import { Form } from 'react-final-form'

import { IOrder } from 'interfaces'
import { FormDataType, formDataInitial } from 'forms/OrderCommentForm/OrderCommentForm'
import { TCompanyOrderUpdateProps } from 'services/MarketService'
import { useMutationCompanyOrder } from 'containers/Orders/hooks'
import { OrderCommentForm } from 'forms'

type OrderCommentActionPropType = {
    orderProps: TCompanyOrderUpdateProps
    onError?: () => void
    onSuccess?: (value: IOrder) => void
}

const OrderCommentAction: React.FC<OrderCommentActionPropType> = ({
    orderProps,
    onError = () => {},
    onSuccess = () => {},
}) => {
    const { update: updateCompanyOrder } = useMutationCompanyOrder()

    const handlerSubmit = (params: FormDataType) => {
        return updateCompanyOrderAction({ ...orderProps, ...params })
    }

    function updateCompanyOrderAction(params: TCompanyOrderUpdateProps) {
        return updateCompanyOrder.mutateAsync(params, {
            onError,
            onSuccess,
        })
    }

    return (
        <Form
            initialValues={{
                ...formDataInitial,
                comment: orderProps?.comment,
            }}
            onSubmit={handlerSubmit}
            render={({ handleSubmit, submitting }) => (
                <OrderCommentForm
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default OrderCommentAction
