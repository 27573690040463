import React, { useEffect } from 'react'
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom'

import { APP_URL } from 'config/app'
import { scrollTop } from 'utils/helpers'
import DeliveryServices from './DeliveryServices/DeliveryServices'
import DeliveryTypeAdd from './DeliveryTypeAdd/DeliveryTypeAdd'
import DeliveryTypeEdit from './DeliveryTypeEdit/DeliveryTypeEdit'

const Delivery: React.FC = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [pathname])

    return (
        <Switch>
            <Route exact path={APP_URL.deliveryServices} component={DeliveryServices} />
            <Route path={APP_URL.deliveryTypeAdd} component={DeliveryTypeAdd} />
            <Route path={APP_URL.deliveryTypeEdit} component={DeliveryTypeEdit} />
            <Redirect from="*" to={APP_URL.deliveryServices} />
        </Switch>
    )
}

export default Delivery
