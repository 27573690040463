import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICalendarDay, ICalendarHour, IDateValues } from 'interfaces'
import { DatePeriod } from 'enums'
import {
    CalendarGridMonth,
    CustomSelect,
    DateChanger,
} from 'components'
import { useCalendar } from 'hooks'
import { dateTimeFormat, defaultDateFormat, getDateValues } from 'utils/helpers'
import style from './DatePicker.module.css'

type DatePickerPropType = {
    classesFieldTime?: string
    date: Date
    dateSelected?: Date
    isSetTime?: boolean
    onChange: (value: Date) => void
}

const GRID_ITEMS_LENGTH = 24
const TIME_START_HOUR = 0

const getHours = ({ year, month, day }: Pick<IDateValues, 'year' | 'month' | 'day'>): ICalendarHour[] => {
    return Array(GRID_ITEMS_LENGTH)
        .fill('')
        .map((item, index) => {
            const dateDate = new Date(year, month, day, index + TIME_START_HOUR, 0, 0)
            return {
                id: defaultDateFormat(dateDate, true),
                date: dateDate,
            }
        })
}

const DatePicker: React.FC<DatePickerPropType> = ({
    classesFieldTime,
    date,
    dateSelected,
    isSetTime,
    onChange,
}) => {
    const { t, i18n } = useTranslation()
    const [dateCalendar, setDateCalendar] = useState(date)
    const [daysMonth, setDaysMonth] = useState<ICalendarDay[]>([])
    const [times] = useState(getHours(getDateValues(date)))

    const { getMonthDays } = useCalendar()

    const handlerChangeDay = (value: Date) => {
        if (isSetTime) {
            const today = new Date()
            const dateTime = new Date(
                value.getFullYear(),
                value.getMonth(),
                value.getDate(),
                today.getHours(),
                today.getMinutes(),
                today.getSeconds(),
            )

            onChange(dateTime)
        } else {
            onChange(value)
        }
    }

    useEffect(() => {
        setDaysMonth(getMonthDays(dateCalendar))
    }, [dateCalendar])

    return (
        <div className={style.datepicker}>
            <DateChanger
                classes={style.changer}
                date={dateCalendar}
                period={DatePeriod.month}
                onChange={(value) => setDateCalendar(value)}
            />
            <CalendarGridMonth
                days={daysMonth}
                daysPosts={{}}
                dateSelected={dateSelected}
                onClickDay={handlerChangeDay}
            />
            {isSetTime && (
                <>
                    <div className={style.titleTime}>
                        {t('select_time')}
                    </div>
                    <CustomSelect.Select isOpen>
                        <CustomSelect.Field classes={cn(style.fieldTime, classesFieldTime)}>
                            {dateSelected && dateTimeFormat(dateSelected, i18n.language, { timeStyle: 'medium' })}
                        </CustomSelect.Field>
                        <CustomSelect.Options classesList={style.optionsTime}>
                            {times.map((item, index) => (
                                <CustomSelect.Option
                                    id={index}
                                    classes={style.optionTime}
                                    key={item.id}
                                    onClick={() => onChange(item.date)}
                                >
                                    {dateTimeFormat(item.date, i18n.language, { timeStyle: 'medium' })}
                                </CustomSelect.Option>
                            ))}
                        </CustomSelect.Options>
                    </CustomSelect.Select>
                </>
            )}
        </div>
    )
}

export default DatePicker
