import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { appLogoutUser, authorizeUser } from 'containers/User/user-actions'
import { useThunkDispatch } from 'hooks'

const Logout: React.FC = () => {
    const dispatch = useDispatch()
    const thunkDispatch = useThunkDispatch()

    useEffect(() => {
        thunkDispatch(appLogoutUser())
            .then(() => {
                dispatch(authorizeUser(false))
            })
            .catch(() => {
                //
            })
    }, [])

    return (
        <div />
    )
}

export default Logout
