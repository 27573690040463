import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import { IStoreFolder } from 'interfaces'
import { CardSize } from 'enums'
import { APP_URL } from 'config/app'
import { CardV2 } from 'components'
import style from './CatalogFolder.module.css'

type CatalogFolderPropType = {
    isSetLink: boolean
    storeId: number
    item: IStoreFolder
    itemSize: keyof typeof CardSize
    classes?: string
    onClick?: (data: IStoreFolder) => void
}

const CatalogFolder: React.FC<CatalogFolderPropType> = ({
    isSetLink,
    storeId,
    item,
    itemSize,
    classes,
    onClick,
}) => {
    const url = useMemo(() => {
        if (item && isSetLink) {
            return generatePath(APP_URL.catalog, { id: storeId, catalogId: item.id })
        }

        return ''
    }, [item])

    const handlerClick = () => {
        if (onClick) {
            onClick(item)
        }
    }

    return (
        <CardV2
            classes={classes}
            size={itemSize}
            url={url}
            onClick={handlerClick}
        >
            <CardV2.Image src={item?.image} width={400} />
            <CardV2.Body classes={style.body} classesTitle={style.title} title={item.name} />
        </CardV2>
    )
}

export default CatalogFolder
