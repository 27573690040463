import React, { useMemo } from 'react'
import { Form } from 'react-final-form'

import { DeliveryTypeForm } from 'forms'
// eslint-disable-next-line
import { FormDataType, FieldName, formDataInitial } from 'forms/DeliveryTypeForm/DeliveryTypeForm'
import { useAppSelector } from 'store'

type DeliveryTypeActionPropType = {
    classesBody?: string
    classesFooter?: string
    deliveryTypeProps?: any // TODO
}

const DeliveryTypeAction: React.FC<DeliveryTypeActionPropType> = ({
    classesBody,
    classesFooter,
    deliveryTypeProps,
}) => {
    const user = useAppSelector((state) => state.user)
    const languages = useAppSelector((state) => state.app.languages)

    const langDefault = useMemo(() => {
        return user ? user.language.id : undefined
    }, [user])

    const handlerSubmit = (params: FormDataType) => {
        //
    }

    return (
        <Form
            initialValues={{
                ...formDataInitial,
                lang: langDefault,
                deliveryTypeSubtitleLang: langDefault,
                deliveryTypeTextLang: langDefault,
                deliveryTrackSubtitleLang: langDefault,
                deliveryTrackTextLang: langDefault,
                ...deliveryTypeProps,
            }}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                // TODO

                return errors
            }}
            render={({ submitting, handleSubmit }) => (
                <DeliveryTypeForm
                    classesBody={classesBody}
                    classesFooter={classesFooter}
                    languages={languages}
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default DeliveryTypeAction
