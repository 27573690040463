import { ITransaction, IPayout } from 'interfaces'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

export type TPayoutMarketAvailableSumProps = {
    store_id: number | string
}

export type TPayoutHistoryProps = {
    q?: string
    limit?: number
    offset?: number
    dateFrom?: string
    dateTo?: string
    rewardCurrencyId?: number
}

export type TPayoutHistoryByStoresProps = {
    limit?: number
    offset?: number
    status_group?: 1 | 2
    sort?: 1 | 2 | 3
    converted_balance_currency_id?: number
}

export type TPayoutOptionsProps = {
    converted_balance_currency_id?: number
}

export type TMarketAvailableSumResponse = {
    currency: string
    canPayout: number
}

/**
 * API payout
 */
class PayoutService {
    static fetchPayoutMarketAvailableSum(params: TPayoutMarketAvailableSumProps) {
        return requestClient<TMarketAvailableSumResponse>(API_URL.payoutMarketOptions, { params })
    }

    static fetchPayoutHistory(params: TPayoutHistoryProps = {}) {
        return requestClient<ITransaction[]>(API_URL.payoutHistory, { params })
    }

    static fetchPayoutHistoryByStores(params: TPayoutHistoryByStoresProps = {}) {
        return requestClient<ITransaction[]>(API_URL.payoutHistoryByStores, { params })
    }

    static fetchPayoutOptions(params: TPayoutOptionsProps = {}) {
        return requestClient<IPayout[]>(API_URL.payoutOptions, { params })
    }
}

export default PayoutService
