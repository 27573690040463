import React from 'react'
import cn from 'classnames'

import style from './BlockHeaderInner.module.css'

export type BlockHeaderInnerPropType = {
    classes?: string
}

const BlockHeaderInner: React.FC<BlockHeaderInnerPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.headerInner, classes)}>
            {children}
        </div>
    )
}

export default BlockHeaderInner
