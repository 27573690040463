import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import { IPersonalStore } from 'interfaces'
import { CardSize } from 'enums'
import { APP_URL } from 'config/app'
import { CardV2 } from 'components'
import style from './StoreItem.module.css'

type StoreItemPropType = {
    data: IPersonalStore
    itemSize: keyof typeof CardSize
}

const StoreItem: React.FC<StoreItemPropType> = ({ data, itemSize }) => {
    const url = useMemo(() => {
        return generatePath(APP_URL.shop, { id: data.id })
    }, [data])

    return (
        <CardV2
            size={itemSize}
            url={url}
        >
            <CardV2.Image
                src={data.logo_image}
                width={400}
            />
            <CardV2.Body
                classes={style.body}
                title={data.name}
                desc={data.sub_title}
            />
        </CardV2>
    )
}

export default StoreItem
