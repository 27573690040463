import { useMemo } from 'react'

import { APP_MODE } from 'config/api'
import { AppMode } from 'enums'

/**
 * Hook app run mode
 */
export default function useAppMode() {
    return useMemo(() => ({
        appMode: APP_MODE as AppMode,
        isUserMode: APP_MODE === AppMode.user,
        isBusinessMode: APP_MODE === AppMode.business,
    }), [])
}
