import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, Modal } from 'components'
import { useOutsideClick } from 'hooks'
import { PopoverTriggerDotsPropType } from './components/PopoverTriggerDots/PopoverTriggerDots'
import { PopoverTriggerMorePropType } from './components/PopoverTriggerMore/PopoverTriggerMore'
import { PopoverTriggerDots, PopoverTriggerMore } from './components'
import style from './Popover.module.css'

type PopoverPropType = {
    trigger: JSX.Element
    classes?: string
    classesBody?: string
    position?: 'top' | 'middle' | 'bottom'
    side?: 'left' | 'right' | 'center'
    isOpen?: boolean
    isPopup?: boolean
    isAutoClose?: boolean
    isShowArrow?: boolean
    isDisabled?: boolean
    onOpen?: () => void
    onClose?: () => void
}

type PopoverComposition = {
    TriggerDots: React.FC<PopoverTriggerDotsPropType>
    TriggerMore: React.FC<PopoverTriggerMorePropType>
}

/**
 * A Popover can be used to display some content on top of another
 *
 * @param isOpen - open/close state after render
 * @param isPopup - show content in modal
 * @param isAutoClose - auto open/close on click
 * @param isShowArrow - show/hide body arrow
 * @param isDisabled
 * @param onOpen - manual action on click open, isAutoClose=false
 * @param onClose - manual action on click close, isAutoClose=false
 */
const Popover: React.FC<PopoverPropType> & PopoverComposition = ({
    children,
    trigger,
    classes,
    classesBody,
    position = 'top',
    side = 'right',
    isOpen = false,
    isPopup = false,
    isAutoClose = true,
    isShowArrow = true,
    isDisabled = false,
    onOpen = () => {},
    onClose = () => {},
}) => {
    const { t } = useTranslation()
    const wrapperRef = useRef(null)
    const [isShow, setIsShow] = useState(isOpen)

    const handlerClickOpen = () => {
        if (isAutoClose) {
            setIsShow((prevState) => !prevState)
        } else if (isShow) {
            onClose()
        } else {
            onOpen()
        }
    }

    const handlerClickInside = () => {
        if (isAutoClose && !isPopup) {
            setIsShow(false)
        }
    }

    const handlerClickClosePopup = () => {
        onClose()
    }

    useOutsideClick(wrapperRef, () => {
        if (!isPopup) {
            if (isAutoClose) {
                setIsShow(false)
            } else {
                onClose()
            }
        }
    })

    useEffect(() => {
        if (!isAutoClose) {
            setIsShow(isOpen)
        }
    }, [isOpen])

    return (
        <>
            <div
                className={cn(
                    style.popover,
                    { [style.popover_disabled]: isDisabled },
                    classes,
                )}
                ref={wrapperRef}
            >
                <Button
                    classes={style.trigger}
                    styleType="transparent"
                    disabled={isDisabled}
                    onClick={handlerClickOpen}
                >
                    {trigger}
                </Button>

                {isShow && !isPopup && (
                    // eslint-disable-next-line
                    <div
                        className={cn(
                            style.body,
                            style[`body_${side}`],
                            style[`body_${position}`],
                            { [style.body_arrow]: isShowArrow },
                            classesBody,
                        )}
                        onClick={handlerClickInside}
                    >
                        {children}
                    </div>
                )}
            </div>

            <Modal
                isOpen={isShow && isPopup}
                size="smallCenter"
                onClose={handlerClickClosePopup}
            >
                <Modal.Header
                    title={t('actions_title')}
                    titlePos="center"
                />
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </>
    )
}

Popover.TriggerDots = PopoverTriggerDots
Popover.TriggerMore = PopoverTriggerMore
export default Popover
