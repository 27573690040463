import React, { useEffect, useState } from 'react'

import { IPost } from 'interfaces'
import { LoadDataType } from 'components/InfiniteScroll/InfiniteScroll'
import { PostsPropType } from 'components/Posts/Posts'
import { TPostsUserProps } from 'services/CommunityService'
import { Community } from 'containers'
import {
    useFetchInfinitePostsUser,
    useInvalidatePostsUser,
    useSetInfiniteQueryDataPostsUser,
    useSetQueryDataPost,
} from 'containers/Community/hooks'
import { Posts } from 'components'
import { showAlertNotify } from 'utils/helpers'

type FeedPostsCommunityPropType = {
    queryParams: TPostsUserProps
    isPostTile?: boolean
} & Pick<PostsPropType, 'noDataTitleKey' | 'noDataTextKey'>

const FeedPostsUser: React.FC<FeedPostsCommunityPropType> = ({
    queryParams,
    isPostTile,
    ...props
}) => {
    const { limit = 10, offset = 0, ...params } = queryParams

    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isInitialLoading: isLoading,
        isLoadingError,
        data,
        error,
        fetchNextPage,
    } = useFetchInfinitePostsUser({
        limit,
        offset,
        ...params,
    }, {
        staleTime: 60 * 1000, // 1m
        cacheTime: 60 * 1000, // 1m
    })

    const { invalidate: invalidatePostsUser } = useInvalidatePostsUser()

    const { setQueryData: setInfiniteQueryDataPostsUser } = useSetInfiniteQueryDataPostsUser()

    const { setQueryData: setQueryDataPost } = useSetQueryDataPost()

    const handlerOnLoad = ({ page }: LoadDataType) => {
        if (page > 0) {
            fetchNextPage()
        }
    }

    const handlerUpdatePost = () => {
        invalidatePostsUser({ limit, offset, ...params })
    }

    const handlerEditPost = (value: IPost) => {
        /** update post in user feed */
        setInfiniteQueryDataPostsUser({ limit, offset, ...params }, (infiniteData) => {
            if (infiniteData) {
                const pages = infiniteData.pages.map((page) => {
                    return page.map((queryData) => (queryData.id === value.id ? { ...queryData, ...value } : queryData))
                })

                return { ...infiniteData, pages }
            }

            return infiniteData
        })

        /** update post publication */
        setQueryDataPost({ id: value.id }, (queryData) => {
            if (queryData) {
                return { ...queryData, ...value }
            }

            return undefined
        })
    }

    const handlerSuccessPost = (value: string) => {
        showAlertNotify({ type: 'success', message: value })
    }

    const handlerErrorPost = (value: string) => {
        showAlertNotify({ type: 'error', message: value })
    }

    useEffect(() => {
        if (isLoadingError) {
            setIsHideLoader(true)
        }
    }, [isLoadingError])

    useEffect(() => {
        if (data) {
            const { pages } = data
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < limit) {
                setIsHideLoader(true)
            }
        }
    }, [data])

    return (
        <Community
            onUpdatePost={handlerUpdatePost}
            onEditPost={handlerEditPost}
            onSuccess={handlerSuccessPost}
            onError={handlerErrorPost}
        >
            <Posts
                isLoading={isLoading}
                isHideLoader={isHideLoader}
                isPostTile={isPostTile}
                data={data}
                error={error && error[0]}
                onLoad={handlerOnLoad}
                {...props}
            />
        </Community>
    )
}

export default FeedPostsUser
