import { ThunkAction } from 'redux-thunk'

import {
    IRootState,
    ICompany,
    ICompanyUser,
    ICompanyRole,
} from 'interfaces'
import { fetchCompanyProps, addCompanyProps, updatedCompanyProps } from 'services/CompanyService'
import {
    FETCH_COMPANY_USER,
    ADD_COMPANY,
    UPDATE_COMPANY,
    FETCH_COMPANY_BUSINESS_ROLES,
} from 'reducers/types'
import { CompanyService } from 'services'
import {
    fetchCompanyUserActionType,
    addCompanyUserActionType,
    updatedCompanyActionType,
    fetchCompanyBusinessRolesActionType,
} from './company-actions-type'

export function fetchCompanyUser(params?: fetchCompanyProps)
    : ThunkAction<Promise<ICompanyUser>, IRootState, unknown, fetchCompanyUserActionType> {
    return (dispatch) => {
        return CompanyService.fetchCompany(params)
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: FETCH_COMPANY_USER, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export function addCompany(params: addCompanyProps)
    : ThunkAction<Promise<ICompany>, IRootState, unknown, addCompanyUserActionType> {
    return (dispatch) => {
        return CompanyService.addCompany(params)
            .then(({ data }) => {
                if (data) {
                    // TODO ?
                    dispatch({ type: ADD_COMPANY, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export function updateCompany(params: updatedCompanyProps)
    : ThunkAction<Promise<ICompany>, IRootState, unknown, updatedCompanyActionType> {
    return (dispatch) => {
        return CompanyService.updateCompany(params)
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: UPDATE_COMPANY, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}

export function fetchCompanyBusinessRoles()
    : ThunkAction<Promise<ICompanyRole[]>, IRootState, unknown, fetchCompanyBusinessRolesActionType> {
    return (dispatch) => {
        return CompanyService.fetchCompanyBusinessRoles()
            .then(({ data }) => {
                if (data) {
                    dispatch({ type: FETCH_COMPANY_BUSINESS_ROLES, payload: data })
                }

                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }
}
