import { useSelector } from 'react-redux'
import { useMemo } from 'react'

import { APP_URL } from 'config/app'
import { useAppMode } from 'containers/App/hooks'
import * as appSelectors from 'containers/App/app-selectors'

/**
 * Hook app variables
 */
export default function useApp() {
    const { isUserMode, isBusinessMode } = useAppMode()

    const { forbidden: forbiddenConfig } = useSelector(appSelectors.config)

    const mainPrivateRoute = useMemo(() => {
        const { feed } = forbiddenConfig

        if (isUserMode || (isBusinessMode && feed !== false)) {
            return APP_URL.lenta
        }

        return isBusinessMode
            ? APP_URL.stores // business mode default
            : APP_URL.lenta // user mode default
    }, [forbiddenConfig])

    return {
        mainPrivateRoute,
    }
}
