import React, { useEffect, useMemo, useState } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb } from 'interfaces'
import { TStoreSettingsUpdateProps } from 'services/StoreService'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { CardContainer, ContentContainer } from 'layout'
import {
    PageHeader,
    PageTitle,
    Breadcrumbs,
    InfiniteScroll,
    Loader,
    NoDataInfo,
    ErrorMsg,
} from 'components'
import { MarketItem, MarketStoreItem } from 'containers/Market/components'
import { useFetchMarkerCategories, useRemoveQueriesDataShopsStatic } from 'containers/Market/hooks'
import {
    useFetchInfiniteStoreSettings,
    useMutationStoreSettings,
    useSetInfiniteQueryDataStoreSettings,
    useQueriesDataStoreProfile,
} from 'containers/Store/hooks'
import { useWindowResize } from 'hooks'
import { scrollTop } from 'utils/helpers'
import style from './Market.module.css'

const LIMIT = 12
const OFFSET = 0

const Market: React.FC = () => {
    const routeShopsSettings = useRouteMatch({ path: APP_URL.shopsSettings, strict: true })
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        isInitialLoading: isLoadingMarkerCategories,
        data: dataCategories,
        error: errorMarkerCategories,
    } = useFetchMarkerCategories({
        enabled: !routeShopsSettings?.isExact,
    })

    const {
        isInitialLoading: isLoadingStoreSettings,
        data: dataStoreSettings,
        error: errorStoreSettings,
        fetchNextPage: fetchNextPageStoreSettings,
    } = useFetchInfiniteStoreSettings({
        limit: LIMIT,
        offset: OFFSET,
    }, {
        enabled: !!routeShopsSettings?.isExact,
    })

    const { update: updateStoreSettings } = useMutationStoreSettings()

    const { remove: removeQueriesDataShopsStatic } = useRemoveQueriesDataShopsStatic()

    const { remove: removeQueriesDataStoreProfile } = useQueriesDataStoreProfile()

    const { setQueryData: setInfiniteQueryDataStoreSettings } = useSetInfiniteQueryDataStoreSettings({
        limit: LIMIT,
        offset: OFFSET,
    })

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        return [{ id: 1, name: t('marketplace') }]
    }, [])

    const isLoading = useMemo(() => {
        return isLoadingMarkerCategories || isLoadingStoreSettings
    }, [isLoadingMarkerCategories, isLoadingStoreSettings])

    const error = useMemo(() => {
        if (errorMarkerCategories) {
            return errorMarkerCategories[0]
        }
        if (errorStoreSettings) {
            return errorStoreSettings[0]
        }

        return null
    }, [errorMarkerCategories, errorStoreSettings])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerLoad = () => {
        if (!isLoadingStoreSettings) {
            fetchNextPageStoreSettings()
        }
    }

    const handlerClickBack = () => {
        history.push(APP_URL.shopsFavorite)
    }

    const handlerStoreSubscribe = (id: number, isSubscribe: boolean) => {
        updateStoreSettingsAction({
            limit: 0,
            subscribe_list: [{ id, is_subscribe: isSubscribe }],
        })
    }

    function updateStoreSettingsAction({ subscribe_list: subscribeList, ...params }: TStoreSettingsUpdateProps) {
        const [listItem] = subscribeList || []
        const { id } = listItem || {}

        updateStoreSettings.mutate({ subscribe_list: subscribeList, ...params }, {
            onSuccess: (res) => {
                if (res && id) {
                    const currentStoreSettings = res.find((item) => item.id === id)

                    if (currentStoreSettings) {
                        setInfiniteQueryDataStoreSettings(currentStoreSettings)
                        removeQueriesDataShopsStatic({}, { exact: false })
                        removeQueriesDataStoreProfile({ id })
                    }
                }
            },
        })
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataStoreSettings) {
            const { pages } = dataStoreSettings
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoader(true)
            }
        }
    }, [dataStoreSettings])

    return (
        <ContentContainer classes={style.content} size="three-quarters">
            {routeShopsSettings?.isExact ? (
                <PageHeader
                    title={t('stores')}
                    onClickBack={handlerClickBack}
                />
            ) : (
                <PageTitle>
                    <Breadcrumbs items={breadcrumbs} />
                </PageTitle>
            )}

            {isLoading && (
                <Loader />
            )}

            {!isLoading
            && (
                (dataCategories && !dataCategories.length) || (dataStoreSettings && !dataStoreSettings.pages.length)
            ) && (
                <NoDataInfo text={t('nothing_found')} />
            )}

            {!routeShopsSettings?.isExact && dataCategories && (
                <CardContainer>
                    <MarketItem
                        url={APP_URL.shopsFavorite}
                        category={{
                            id: 0,
                            name: t('my_stores'),
                            pluralName: '',
                            nameKey: 'my_stores',
                            image: '/api/directory/app-resource/ic_category_favorites_png',
                            sort: 1,
                        }}
                    />
                    {dataCategories.map((category) => (
                        <MarketItem
                            category={category}
                            key={category.id}
                        />
                    ))}
                </CardContainer>
            )}
            {routeShopsSettings?.isExact && dataStoreSettings && (
                <InfiniteScroll
                    options={{ rootMargin: '0px 0px 25% 0px' }}
                    isHideLoader={isHideLoader}
                    onLoad={handlerLoad}
                >
                    <CardContainer>
                        {dataStoreSettings.pages.map((page, i) => (
                            /* eslint-disable-next-line */
                            <React.Fragment key={i}>
                                {page.map((item) => (
                                    <MarketStoreItem
                                        data={item}
                                        size={itemSize}
                                        key={item.id}
                                        onSubscribe={handlerStoreSubscribe}
                                    />
                                ))}
                            </React.Fragment>
                        ))}
                    </CardContainer>
                </InfiniteScroll>
            )}

            {!isLoading && error && (
                <ErrorMsg error={error} />
            )}
        </ContentContainer>
    )
}

export default Market
