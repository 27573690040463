import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IMenuNavItemData } from 'interfaces'
import { AppMenuKey } from 'enums'
import {
    Popover,
    MenuList,
    Icon,
} from 'components'

import style from './MenuNavDropDown.module.css'

type MenuNavDropDownPropType = {
    items: IMenuNavItemData[]
}

const MenuNavDropDown: React.FC<MenuNavDropDownPropType> = ({ items = [] }) => {
    const { t } = useTranslation()

    const isCommonNotify = useMemo(() => {
        return !!items.find((item) => item.id === AppMenuKey.messenger)?.countEvent?.value
            || !!items.find((item) => item.id === AppMenuKey.support)?.notify?.value
    }, [items])

    return (
        <Popover
            side="left"
            trigger={(
                <Icon
                    isImgTag={false}
                    classes={style.iconDropDown}
                    resourceKey="ic_tab_menu_svg"
                    width={30}
                    height={30}
                    notify={isCommonNotify}
                />
            )}
        >
            <MenuList classes={style.menu}>
                {items.map((item) => (
                    item.translateKey && (
                        <MenuList.Item classes={style.item} key={item.id}>
                            <NavLink
                                className={style.link}
                                activeClassName={style.link_active}
                                to={{ pathname: item.url }}
                            >
                                {item.icon && (
                                    <Icon
                                        isImgTag={false}
                                        classes={item.iconClassName}
                                        resourceKey={item.icon}
                                        width={32}
                                        height={32}
                                        count={item.countEvent?.value ? Number(item.countEvent.value) : undefined}
                                        notify={item.notify?.value}
                                    />
                                )}
                                <div className={style.text}>
                                    {t(item.translateKey)}
                                </div>
                            </NavLink>
                        </MenuList.Item>
                    )
                ))}
            </MenuList>
        </Popover>
    )
}

export default MenuNavDropDown
