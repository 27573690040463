import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, useFormState } from 'react-final-form'
import cn from 'classnames'

import { ILanguage } from 'interfaces'
import { BREAKPOINTS } from 'config/app'
// eslint-disable-next-line
import { CardContainer } from 'layout'
import {
    Button,
    Checkbox,
    CustomSelect,
    Input,
    Tooltip,
} from 'components'
import { useWindowResize } from 'hooks'
import { parseTpl } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './DeliveryTypeForm.module.css'

export enum FieldName {
    lang = 'lang',
    name = 'name',
    pickup = 'pickup',
    hidden = 'hidden',
    calculation = 'calculation',
    freeShippingFromAmount = 'freeShippingFromAmount',
    freeShippingFromCvText = 'freeShippingFromCvText',
    discountFromCv = 'discountFromCv',
    discountFromCvPercent = 'discountFromCvPercent',
    deliveryTypeCode = 'deliveryTypeCode',
    deliveryTypeSubtitle = 'deliveryTypeSubtitle',
    deliveryTypeSubtitleLang = 'deliveryTypeSubtitleLang',
    deliveryTypeText = 'deliveryTypeText',
    deliveryTypeTextLang = 'deliveryTypeTextLang',
    deliveryTrackSubtitle = 'deliveryTrackSubtitle',
    deliveryTrackSubtitleLang = 'deliveryTrackSubtitleLang',
    deliveryTrackText = 'deliveryTrackText',
    deliveryTrackTextLang = 'deliveryTrackTextLang',
}

export type FormDataType = {
    [FieldName.lang]: number
    [FieldName.name]: string
    [FieldName.pickup]: 0|1
    [FieldName.hidden]: 0|1
    [FieldName.calculation]: 0|1
    [FieldName.freeShippingFromAmount]?: number
    [FieldName.freeShippingFromCvText]?: number
    [FieldName.discountFromCv]?: number
    [FieldName.discountFromCvPercent]?: number
    [FieldName.deliveryTypeCode]?: string
    [FieldName.deliveryTypeSubtitle]?: string
    [FieldName.deliveryTypeSubtitleLang]?: number
    [FieldName.deliveryTypeText]?: string
    [FieldName.deliveryTypeTextLang]?: number
    [FieldName.deliveryTrackSubtitle]?: string
    [FieldName.deliveryTrackSubtitleLang]?: number
    [FieldName.deliveryTrackText]?: string
    [FieldName.deliveryTrackTextLang]?: number
}

type DeliveryTypeFormPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    languages?: ILanguage[]
    isSubmitting?: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.lang]: 0,
    [FieldName.name]: '',
    [FieldName.pickup]: 0,
    [FieldName.hidden]: 0,
    [FieldName.calculation]: 0,
}

const DeliveryTypeForm: React.FC<DeliveryTypeFormPropType> = ({
    classes,
    classesBody,
    classesFooter,
    languages,
    isSubmitting,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    // eslint-disable-next-line
    const { values } = useFormState<FormDataType>()

    const languageData = useMemo(() => {
        return languages
            ? languages.reduce((acc, item) => ({ ...acc, [item.id]: item.iso_code }), {})
            : {}
    }, [languages])

    // TODO currency
    // eslint-disable-next-line
    const freeShippingFromCvText = useMemo(() => {
        return parseTpl(t('free_shipping_from_cv'), { '%virtual_currency%': 'CV' }, { prefix: '', suffix: '' })
    }, [])
    // eslint-disable-next-line
    const discountFromCvText = useMemo(() => {
        return parseTpl(t('discount_from_cv'), { '%virtual_currency%': 'CV' }, { prefix: '', suffix: '' })
    }, [])
    // eslint-disable-next-line
    const discountFromCvPercentText = useMemo(() => {
        return parseTpl(t('discount_from_cv_percent'), { '%virtual_currency%': 'CV' }, { prefix: '', suffix: '' })
    }, [])

    const isMobile = useMemo(() => {
        return windowWidth < BREAKPOINTS.tabletLandscape
    }, [windowWidth])

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={classesBody}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(
                        styleForm.group,
                        styleForm.group_alignCenter,
                        styleForm.fieldHead,
                        styleForm.fieldHead_small,
                    )}
                    >
                        <div className={styleForm.groupItem}>
                            {t('name')}
                        </div>
                        <div className={styleForm.groupItem}>
                            <Field
                                <FormDataType[FieldName.lang]>
                                name={FieldName.lang}
                                render={({ input }) => (
                                    <CustomSelect.Select>
                                        <CustomSelect.Field
                                            classes={cn(style.fieldSelect, style.fieldSelect_lang)}
                                            arrowWidth={10}
                                            arrowHeight={8}
                                        >
                                            {!!input.value && languageData[input.value]}
                                        </CustomSelect.Field>
                                        <CustomSelect.Options classes={cn(style.fieldOption, style.fieldOption_lang)}>
                                            {languages?.map((item) => (
                                                <CustomSelect.Option
                                                    isActive={item.id === input.value}
                                                    id={item.id}
                                                    key={item.id}
                                                    onClick={(id) => input.onChange(id)}
                                                >
                                                    {item.iso_code}
                                                </CustomSelect.Option>
                                            ))}
                                        </CustomSelect.Options>
                                    </CustomSelect.Select>
                                )}
                            />
                        </div>
                    </div>
                    <Field
                        <FormDataType[FieldName.name]>
                        name={FieldName.name}
                        render={({ input }) => (
                            <Input
                                {...input}
                                styleType="defaultBusiness"
                            />
                        )}
                    />
                </div>
                {/* Not shown in the StartUp version */}
                {/*
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <CardContainer gap="20px">
                        <div className={(cn(style.fieldItem, style.fieldItem_column2))}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small, style.fieldHead)}>
                                {t('free_shipping_from_amount')}
                            </div>
                            <Field
                                <FormDataType[FieldName.freeShippingFromAmount]>
                                name={FieldName.freeShippingFromAmount}
                                render={({ input }) => (
                                    <Input
                                        {...input}
                                        styleType="defaultBusiness"
                                    />
                                )}
                            />
                        </div>
                        <div className={(cn(style.fieldItem, style.fieldItem_column2))}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small, style.fieldHead)}>
                                {freeShippingFromCvText}
                            </div>
                            <Field
                                <FormDataType[FieldName.freeShippingFromCvText]>
                                name={FieldName.freeShippingFromCvText}
                                render={({ input }) => (
                                    <Input
                                        {...input}
                                        styleType="defaultBusiness"
                                    />
                                )}
                            />
                        </div>
                    </CardContainer>
                </div>
                */}
                {/* Not shown in the StartUp version */}
                {/*
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <CardContainer gap="20px">
                        <div className={(cn(style.fieldItem, style.fieldItem_column3))}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                <div className={styleForm.group}>
                                    <div className={styleForm.groupItem}>
                                        {discountFromCvText}
                                    </div>
                                    <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                        <Tooltip
                                            classes={style.tooltip}
                                            position={isMobile ? 'left' : 'right'}
                                            tooltip={(
                                                <div className={style.tooltipHint}>
                                                    {discountFromCvText}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            <Field
                                <FormDataType[FieldName.discountFromCv]>
                                name={FieldName.discountFromCv}
                                render={({ input }) => (
                                    <Input
                                        {...input}
                                        styleType="defaultBusiness"
                                    />
                                )}
                            />
                        </div>
                        <div className={(cn(style.fieldItem, style.fieldItem_column3))}>
                            <div
                                className={cn(styleForm.fieldHead, styleForm.fieldHead_small, style.fieldHead)}
                                title={discountFromCvPercentText}
                            >
                                {discountFromCvPercentText}
                            </div>
                            <Field
                                <FormDataType[FieldName.discountFromCvPercent]>
                                name={FieldName.discountFromCvPercent}
                                render={({ input }) => (
                                    <Input
                                        {...input}
                                        styleType="defaultBusiness"
                                    />
                                )}
                            />
                        </div>
                        <div className={(cn(style.fieldItem, style.fieldItem_column3))}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                {t('delivery_type_code')}
                            </div>
                            <Field
                                <FormDataType[FieldName.deliveryTypeCode]>
                                name={FieldName.deliveryTypeCode}
                                render={({ input }) => (
                                    <Input
                                        {...input}
                                        styleType="defaultBusiness"
                                    />
                                )}
                            />
                        </div>
                    </CardContainer>
                </div>
                */}
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        <FormDataType[FieldName.pickup]>
                        name={FieldName.pickup}
                        render={({ input }) => (
                            <Checkbox
                                {...input}
                                styleType="small"
                                text={t('selfdelivery')}
                                value={String(input.value)}
                                checked={false}
                                onChange={() => {}}
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        <FormDataType[FieldName.hidden]>
                        name={FieldName.hidden}
                        render={({ input }) => (
                            <Checkbox
                                {...input}
                                styleType="small"
                                text={t('hidden_feminine')}
                                value={String(input.value)}
                                checked={false}
                                onChange={() => {}}
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        <FormDataType[FieldName.calculation]>
                        name={FieldName.calculation}
                        render={({ input }) => (
                            <Checkbox
                                {...input}
                                styleType="small"
                                text={t('by_calculation')}
                                value={String(input.value)}
                                checked={false}
                                onChange={() => {}}
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        {t('delivery_external_service')}
                    </div>
                    <Field
                        <FormDataType[FieldName.pickup]>
                        name={FieldName.pickup}
                        render={({ input }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={cn(style.fieldSelect, style.fieldSelect_field)}>
                                    {!!input.value}
                                    {!input.value && (
                                        <span className={style.fieldSelectPls}>
                                            {t('delivery_select_external_services')}
                                        </span>
                                    )}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {/* */}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        <div className={cn(
                            styleForm.group,
                            styleForm.group_alignTop,
                            styleForm.fieldHead,
                            styleForm.fieldHead_small,
                        )}
                        >
                            <div className={cn(styleForm.groupItem, style.fieldTitle)}>
                                {t('delivery_type_subtitle')}
                            </div>
                            <div className={styleForm.groupItem}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position={isMobile ? 'left' : 'right'}
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {t('delivery_type_subtitle')}
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                            <div className={cn(styleForm.groupItem, style.fieldHeadActions)}>
                                <Field
                                    <FormDataType[FieldName.deliveryTypeSubtitleLang]>
                                    name={FieldName.deliveryTypeSubtitleLang}
                                    render={({ input }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field
                                                classes={cn(style.fieldSelect, style.fieldSelect_lang)}
                                                arrowWidth={10}
                                                arrowHeight={8}
                                            >
                                                {!!input.value && languageData[input.value]}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options classes={cn(
                                                style.fieldOption,
                                                style.fieldOption_lang,
                                            )}
                                            >
                                                {languages?.map((item) => (
                                                    <CustomSelect.Option
                                                        isActive={item.id === input.value}
                                                        id={item.id}
                                                        key={item.id}
                                                        onClick={(id) => input.onChange(id)}
                                                    >
                                                        {item.iso_code}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <Field
                        <FormDataType[FieldName.deliveryTypeSubtitle]>
                        name={FieldName.deliveryTypeSubtitle}
                        render={({ input }) => (
                            <Input
                                {...input}
                                styleType="defaultBusiness"
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        <div className={cn(
                            styleForm.group,
                            styleForm.group_alignTop,
                            styleForm.fieldHead,
                            styleForm.fieldHead_small,
                        )}
                        >
                            <div className={cn(styleForm.groupItem, style.fieldTitle)}>
                                {t('delivery_type_text')}
                            </div>
                            <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position={isMobile ? 'left' : 'right'}
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {t('delivery_type_text')}
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                            <div className={cn(styleForm.groupItem, style.fieldHeadActions)}>
                                <Field
                                    <FormDataType[FieldName.deliveryTypeTextLang]>
                                    name={FieldName.deliveryTypeTextLang}
                                    render={({ input }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field
                                                classes={cn(style.fieldSelect, style.fieldSelect_lang)}
                                                arrowWidth={10}
                                                arrowHeight={8}
                                            >
                                                {!!input.value && languageData[input.value]}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options classes={cn(
                                                style.fieldOption,
                                                style.fieldOption_lang,
                                            )}
                                            >
                                                {languages?.map((item) => (
                                                    <CustomSelect.Option
                                                        isActive={item.id === input.value}
                                                        id={item.id}
                                                        key={item.id}
                                                        onClick={(id) => input.onChange(id)}
                                                    >
                                                        {item.iso_code}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <Field
                        <FormDataType[FieldName.deliveryTypeText]>
                        name={FieldName.deliveryTypeText}
                        render={({ input }) => (
                            <Input
                                {...input}
                                styleType="defaultBusiness"
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        <div className={cn(
                            styleForm.group,
                            styleForm.group_alignTop,
                            styleForm.fieldHead,
                            styleForm.fieldHead_small,
                        )}
                        >
                            <div className={cn(styleForm.groupItem, style.fieldTitle)}>
                                {t('track_id_mail_header')}
                            </div>
                            <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position={isMobile ? 'left' : 'right'}
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {t('track_id_mail_header')}
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                            <div className={cn(styleForm.groupItem, style.fieldHeadActions)}>
                                <Field
                                    <FormDataType[FieldName.deliveryTrackSubtitleLang]>
                                    name={FieldName.deliveryTrackSubtitleLang}
                                    render={({ input }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field
                                                classes={cn(style.fieldSelect, style.fieldSelect_lang)}
                                                arrowWidth={10}
                                                arrowHeight={8}
                                            >
                                                {!!input.value && languageData[input.value]}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options classes={cn(
                                                style.fieldOption,
                                                style.fieldOption_lang,
                                            )}
                                            >
                                                {languages?.map((item) => (
                                                    <CustomSelect.Option
                                                        isActive={item.id === input.value}
                                                        id={item.id}
                                                        key={item.id}
                                                        onClick={(id) => input.onChange(id)}
                                                    >
                                                        {item.iso_code}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <Field
                        <FormDataType[FieldName.deliveryTrackSubtitle]>
                        name={FieldName.deliveryTrackSubtitle}
                        render={({ input }) => (
                            <Input
                                {...input}
                                styleType="defaultBusiness"
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        <div className={cn(
                            styleForm.group,
                            styleForm.group_alignTop,
                            styleForm.fieldHead,
                            styleForm.fieldHead_small,
                        )}
                        >
                            <div className={cn(styleForm.groupItem, style.fieldTitle)}>
                                {t('track_id_mail_text')}
                            </div>
                            <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                <Tooltip
                                    classes={style.tooltip}
                                    position={isMobile ? 'left' : 'right'}
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {t('track_id_mail_text')}
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                            <div className={cn(styleForm.groupItem, style.fieldHeadActions)}>
                                <Field
                                    <FormDataType[FieldName.deliveryTrackTextLang]>
                                    name={FieldName.deliveryTrackTextLang}
                                    render={({ input }) => (
                                        <CustomSelect.Select>
                                            <CustomSelect.Field
                                                classes={cn(style.fieldSelect, style.fieldSelect_lang)}
                                                arrowWidth={10}
                                                arrowHeight={8}
                                            >
                                                {!!input.value && languageData[input.value]}
                                            </CustomSelect.Field>
                                            <CustomSelect.Options classes={cn(
                                                style.fieldOption,
                                                style.fieldOption_lang,
                                            )}
                                            >
                                                {languages?.map((item) => (
                                                    <CustomSelect.Option
                                                        isActive={item.id === input.value}
                                                        id={item.id}
                                                        key={item.id}
                                                        onClick={(id) => input.onChange(id)}
                                                    >
                                                        {item.iso_code}
                                                    </CustomSelect.Option>
                                                ))}
                                            </CustomSelect.Options>
                                        </CustomSelect.Select>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <Field
                        <FormDataType[FieldName.deliveryTrackText]>
                        name={FieldName.deliveryTrackText}
                        render={({ input }) => (
                            <Input
                                {...input}
                                styleType="defaultBusiness"
                            />
                        )}
                    />
                </div>
            </div>
            <div className={classesFooter}>
                <div className={style.controls}>
                    <Button
                        classes={style.control}
                        size="size44"
                        text={t('save')}
                    />
                </div>
            </div>
        </form>
    )
}

export default DeliveryTypeForm
