import React from 'react'
import cn from 'classnames'

import { IOrder } from 'interfaces'
import { OrderDeliveryTypeAction } from 'form-actions'
import style from './OrdersOrderDelivery.module.css'

type OrdersOrderDeliveryPropType = {
    classes?: string
    data: IOrder
    onClickDelivery: () => void
}

const OrdersOrderDelivery: React.FC<OrdersOrderDeliveryPropType> = ({ classes, data, onClickDelivery }) => {
    return (
        <div className={cn(style.wrap, classes)}>
            <OrderDeliveryTypeAction
                orderProps={{
                    storeId: data.store.id,
                    orderId: data.id,
                    delivery_method: data.delivery_method?.id,
                }}
                deliveryMethod={data.delivery_method}
            />

            {/* Not shown in the StartUp version */}
            {/* <div className={style.actions}>
                <Button
                    classes={style.action}
                    styleType="text"
                    text="Детали доставки"
                    onClick={onClickDelivery}
                />
            </div> */}
        </div>
    )
}

export default OrdersOrderDelivery
