import React, { useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    CustomSelect,
    DatePicker,
    Button,
    Input,
    Radio,
    SvgResource,
    Modal,
} from 'components'
import { dateTimeFormat, defaultDateFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './ReportsProductForm.module.css'

// FIXME on ready backend
export enum FieldName {
    id = 'id',
    period = 'period',
    dateFrom = 'dateFrom',
    dateTo = 'dateTo',
    store = 'store',
    currency = 'currency',
    isDivideByStores = 'isDivideByStores',
    isCumulative = 'isCumulative',
}

export type FormDataType = {
    [FieldName.id]?: number
    [FieldName.period]: number
    [FieldName.store]: number
    [FieldName.isDivideByStores]: 0|1
    [FieldName.isCumulative]: 0|1
    [FieldName.dateFrom]: string
    [FieldName.dateTo]: string
    [FieldName.currency]?: number
}

type ShopGoodsFormPropType = {
    classes?: string
    isSubmitting: boolean
    // datePeriod?: { id: number, name: string }[] // FIXME on ready api request
    stores?: { id: number, name: string }[] // FIXME on ready api request
    divideByStores?: { id: number, name: string }[] // FIXME on ready api request
    // currencies?: ICurrency[]
    onChange?: (data: FormDataType) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

type TDateField = FieldName.dateFrom | FieldName.dateTo

export const formDataInitial: FormDataType = {
    [FieldName.period]: 0,
    [FieldName.store]: 0,
    [FieldName.dateFrom]: defaultDateFormat(new Date()),
    [FieldName.dateTo]: defaultDateFormat(new Date()),
    [FieldName.isDivideByStores]: 0,
    [FieldName.isCumulative]: 0,
}

const ReportsProductForm: React.FC<ShopGoodsFormPropType> = ({
    classes,
    isSubmitting,
    // datePeriod,
    stores,
    divideByStores,
    // currencies,
    onChange,
    onSubmit,
}) => {
    const { t, i18n } = useTranslation()

    const { change } = useForm<FormDataType>()
    const { values, hasValidationErrors } = useFormState<FormDataType>({
        onChange: (formState) => {
            if (onChange) {
                onChange(formState.values)
            }
        },
    })

    const [currentDateField, setCurrentDateField] = useState<TDateField>()
    const [dateCalendar, setDateCalendar] = useState(new Date())
    const [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)

    const storesData: Record<number, string> = useMemo(() => {
        return stores
            ? stores.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [stores])

    const divideByStoresData: Record<number, string> = useMemo(() => {
        return divideByStores
            ? divideByStores.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [divideByStores])

    const dateSelected = useMemo(() => {
        return currentDateField && values[currentDateField]
            ? new Date(values[currentDateField] as string)
            : undefined
    }, [values, currentDateField])

    return (
        <>
            <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {t('date')}
                    </div>
                    <div className={styleForm.group}>
                        <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth, style.groupItem)}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                {t('reports_date_start')}
                            </div>
                            <Field
                                <FormDataType[FieldName.dateFrom]>
                                name={FieldName.dateFrom}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            styleType="defaultBusiness"
                                            name={input.name}
                                            value={input.value && dateTimeFormat(input.value, i18n.language)}
                                            onFocus={(e) => {
                                                e.currentTarget.blur()
                                            }}
                                            onBlur={() => {
                                                setCurrentDateField(FieldName.dateFrom)
                                                setDateCalendar(input.value
                                                    ? new Date(input.value)
                                                    : new Date())
                                                setIsOpenModalCalendar(true)
                                            }}
                                            onChange={() => {}}
                                        />
                                        <SvgResource
                                            isImgTag={false}
                                            classes={cn(
                                                styleForm.fieldIcon,
                                                style.fieldIcon,
                                                style.iconCalendar,
                                            )}
                                            resourceKey="ic_kicks_calendar2_svg"
                                            width={24}
                                            height={24}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth, style.groupItem)}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                {t('reports_date_end')}
                            </div>
                            <Field
                                <FormDataType[FieldName.dateTo]>
                                name={FieldName.dateTo}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                            name={input.name}
                                            value={input.value && dateTimeFormat(input.value, i18n.language)}
                                            onFocus={(e) => {
                                                e.currentTarget.blur()
                                            }}
                                            onBlur={() => {
                                                setCurrentDateField(FieldName.dateTo)
                                                setDateCalendar(input.value
                                                    ? new Date(input.value)
                                                    : new Date())
                                                setIsOpenModalCalendar(true)
                                            }}
                                            onChange={() => {}}
                                        />
                                        <SvgResource
                                            isImgTag={false}
                                            classes={cn(
                                                styleForm.fieldIcon,
                                                style.fieldIcon,
                                                style.iconCalendar,
                                            )}
                                            resourceKey="ic_kicks_calendar2_svg"
                                            width={24}
                                            height={24}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {`${t('reports_store')}*`}
                    </div>
                    <Field
                        <FormDataType[FieldName.store]>
                        name={FieldName.store}
                        render={({ input }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.selectField}>
                                    {storesData[input.value]}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {stores?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            key={item.id}
                                            onClick={() => input.onChange(item.id)}
                                        >
                                            <Radio
                                                classesText={style.selectOptionText}
                                                name={input.id}
                                                size="small"
                                                checked={item.id === input.value}
                                                text={item.name}
                                            />
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {t('reports_group_store')}
                    </div>
                    <Field
                        <FormDataType[FieldName.isDivideByStores]>
                        name={FieldName.isDivideByStores}
                        render={({ input, meta }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.selectField} disabled={!divideByStores}>
                                    {divideByStoresData[input.value]}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {divideByStores?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            key={item.id}
                                            onClick={() => input.onChange(item.id)}
                                        >
                                            <Radio
                                                classesText={style.selectOptionText}
                                                name={input.name}
                                                size="small"
                                                checked={item.id === input.value}
                                                text={item.name}
                                            />
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.controls, styleForm.controls_32, style.actions)}>
                    <Button
                        classes={style.action}
                        type="submit"
                        size="size46"
                        text={t('show')}
                        disabled={hasValidationErrors}
                    />
                    <Button
                        disabled
                        classes={style.action}
                        styleType="bordered"
                        size="size46"
                        text={t('download')}
                    />
                </div>
            </form>

            <Modal
                isOpen={isOpenModalCalendar}
                size="smallCenter"
                onClose={() => setIsOpenModalCalendar(false)}
            >
                <Modal.Header
                    classes={style.modalCalendarTitle}
                    title={t('select_date')}
                    titlePos="center"
                />
                <Modal.Body classes={style.modalCalendarBody}>
                    <DatePicker
                        date={dateCalendar}
                        dateSelected={dateSelected}
                        onChange={(value) => {
                            if (currentDateField) {
                                change(currentDateField, defaultDateFormat(value))
                            }
                            setIsOpenModalCalendar(false)
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReportsProductForm
