import React from 'react'
import cn from 'classnames'

import { TableListHeaderPropType } from './components/TableListHeader/TableListHeader'
import { TableListBodyPropType } from './components/TableListBody/TableListBody'
import { TableListRowPropType } from './components/TableListRow/TableListRow'
import { TableListColPropType } from './components/TableListCol/TableListCol'
import { TableListSortPropType } from './components/TableListSort/TableListSort'
import { TableListFooterPropType } from './components/TableListFooter/TableListFooter'
import {
    TableListHeader,
    TableListBody,
    TableListRow,
    TableListCol,
    TableListSort,
    TableListFooter,
} from './components'
import style from './TableList.module.css'

type TableListPropType = {
    classes?: string
}

type TableListComposition = {
    Header: React.FC<TableListHeaderPropType>
    Body: React.FC<TableListBodyPropType>
    Row: React.FC<TableListRowPropType>
    Col: React.FC<TableListColPropType>
    Sort: React.FC<TableListSortPropType>
    Footer: React.FC<TableListFooterPropType>
}

const TableList: React.FC<TableListPropType> & TableListComposition = ({
    children,
    classes,
}) => {
    return (
        <div className={cn(style.table, classes)}>
            {children}
        </div>
    )
}

TableList.Header = TableListHeader
TableList.Body = TableListBody
TableList.Row = TableListRow
TableList.Col = TableListCol
TableList.Sort = TableListSort
TableList.Footer = TableListFooter
export default TableList
