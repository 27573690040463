import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPaymentStatuses } from 'interfaces'
import {
    CustomSelect,
    // DatePicker,
    SvgResource,
    Input,
    // Modal,
} from 'components'
import { defaultDateFormat, dateTimeFormat, numberFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './OrderPaymentStatusForm.module.css'

export enum FieldName {
    paymentStatus = 'payment_status',
}

export type FormDataType = {
    [FieldName.paymentStatus]: number
}

export type OrderStatusFormPropType = {
    isSubmitting: boolean
    paymentStatuses?: IPaymentStatuses
    paymentDate?: string
    paymentSum?: number
    paymentCurrency?: string
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const OrderPaymentStatusForm: React.FC<OrderStatusFormPropType> = ({
    isSubmitting,
    paymentStatuses,
    paymentDate,
    paymentSum,
    paymentCurrency,
    onSubmit,
}) => {
    const { t, i18n } = useTranslation()

    // const { change } = useForm<FormDataType>()
    // const { values } = useFormState<FormDataType>()

    // const [dateCalendar] = useState(new Date())
    // const [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)

    // TODO
    const refundSum = useMemo(() => {
        return '-'
    }, [])

    return (
        <>
            <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        {t('order_date_payment')}
                    </div>
                    <div className={cn(styleForm.group, style.field)}>
                        <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                            <Input
                                disabled
                                styleType="defaultBusiness"
                                value={paymentDate
                                && `${defaultDateFormat(paymentDate)} ${dateTimeFormat(paymentDate, i18n.language, { timeStyle: 'medium' })}`}
                                // onFocus={(e) => e.currentTarget.blur()}
                                // onBlur={() => setIsOpenModalCalendar(true)}
                                // onChange={() => {}}
                            />
                        </div>
                        <div className={cn(styleForm.groupItem, styleForm.groupItem_alignCenter)}>
                            <SvgResource
                                isImgTag={false}
                                classes={cn(style.fieldIcon, style.iconCalendar)}
                                resourceKey="ic_kicks_calendar2_svg"
                                width={20}
                                height={20}
                            />
                        </div>
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.group, styleForm.group_alignCenter, styleForm.group_spaceBetween)}>
                        <div className={cn(styleForm.groupItem, style.fieldText)}>
                            {t('order_payment_sum')}
                        </div>
                        <div className={cn(styleForm.groupItem, style.fieldValue)}>
                            {paymentSum && `${numberFormat(paymentSum)} ${paymentCurrency}`}
                        </div>
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        {t('order_payment_status')}
                    </div>
                    <Field
                        <FormDataType[FieldName.paymentStatus]>
                        name={FieldName.paymentStatus}
                        render={({ input, meta }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field disabled classes={style.fieldSelect}>
                                    {!!input.value && paymentStatuses && paymentStatuses[input.value]}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {paymentStatuses && Object.entries(paymentStatuses).map(([key, name]) => (
                                        <CustomSelect.Option
                                            id={Number(key)}
                                            isActive={Number(key) === input.value}
                                            key={key}
                                            onClick={() => input.onChange(Number(key))}
                                        >
                                            {name}
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.group, styleForm.group_alignCenter, styleForm.group_spaceBetween)}>
                        <div className={cn(styleForm.groupItem, style.fieldText)}>
                            {t('order_refund_amount')}
                        </div>
                        <div className={cn(styleForm.groupItem, style.fieldValue)}>
                            {refundSum}
                        </div>
                    </div>
                </div>
            </form>

            {/* <Modal
                isOpen={isOpenModalCalendar}
                size="extraSmallCenter"
                onClose={() => setIsOpenModalCalendar(false)}
            >
                <Modal.Body classes={style.modalCalendarBody}>
                    <DatePicker
                        isSetTime
                        date={dateCalendar}
                        dateSelected={values[FieldName.paymentDate]
                            ? new Date(values[FieldName.paymentDate])
                            : undefined}
                        onChange={(value) => {
                            change(FieldName.paymentDate, defaultDateFormat(value, true, true))
                            setIsOpenModalCalendar(false)
                        }}
                    />
                </Modal.Body>
            </Modal> */}
        </>
    )
}

export default OrderPaymentStatusForm
