import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICalendarDay, ICalendarPost } from 'interfaces'
import { Button } from 'components'
import { defaultDateFormat } from 'utils/helpers'
import style from './CalendarGridMonth.module.css'

type CalendarGridMonthPropType = {
    classes?: string
    classesHeader?: string
    classesWeekday?: string
    classesBody?: string
    classesDate?: string
    days: ICalendarDay[]
    daysPosts: Record<string, ICalendarPost[]>
    dateSelected?: Date
    isShowPrevMonth?: boolean
    onClickDay: (value: Date) => void
}

const CalendarGridMonth: React.FC<CalendarGridMonthPropType> = ({
    classes,
    classesHeader,
    classesWeekday,
    classesBody,
    classesDate,
    days,
    daysPosts,
    dateSelected,
    isShowPrevMonth = false,
    onClickDay,
}) => {
    const { t } = useTranslation()

    const weekdays: string[] = useMemo(() => {
        return t('mo, tu, we, th, fr, sa, su').split(',')
    }, [])

    const isSelected = (date: Date) => {
        if (dateSelected) {
            return defaultDateFormat(dateSelected) === defaultDateFormat(date)
        }
        return false
    }

    return (
        <div className={classes}>
            <div className={cn(style.weekdays, classesHeader)}>
                {weekdays.map((item) => (
                    <div className={cn(style.item, style.weekday, classesWeekday)} key={item}>
                        {item}
                    </div>
                ))}
            </div>
            <div className={cn(style.days, classesBody)}>
                {days.map((item) => (
                    <div
                        className={cn(style.item, {
                            [style.item_hidden]: !isShowPrevMonth && !item.activeMonth,
                        })}
                        key={item.id}
                    >
                        <Button
                            classes={cn(
                                style.date, {
                                    [style.date_active]: item.currentMonth,
                                    [style.date_current]: item.currentDay,
                                    [style.date_selected]: isSelected(item.date),
                                    [style.date_marked]: !!daysPosts[item.id],
                                },
                                classesDate,
                            )}
                            styleType="transparent"
                            onClick={() => onClickDay(item.date)}
                        >
                            {item.date.getDate()}
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CalendarGridMonth
