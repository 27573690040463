import React, { useMemo, useState } from 'react'
import {
    useParams,
    useHistory,
    useLocation,
    matchPath,
    generatePath,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb } from 'interfaces'
import { TAddGoodsResponse, TUpdateGoodsResponse } from 'services/MarketService'
import { TAddGoodsMutationFnError, TUpdateGoodsMutationFnError } from 'containers/Market/hooks/useFetchGoods'
import { FormDataType } from 'forms/ShopGoodsForm/ShopGoodsForm'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, SideBarContainer, Block } from 'layout'
import { useFetchStoreProfile, useInvalidateStoreCatalog } from 'containers/Store/hooks'
import { useFetchGoods, useQueryDataGoods } from 'containers/Market/hooks'
import { ShopGoodsPreview } from 'containers/Shop/components'
import { PageHeader, Breadcrumbs, Modal } from 'components'
import { ShopGoodsAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import { GoodsDto } from 'dto'
import { showAlertNotify } from 'utils/helpers'
import style from './ShopGoods.module.css'

const ShopGoods: React.FC = () => {
    const { id, goodsId } = useParams<{ id: string, goodsId?: string }>()
    const { t } = useTranslation()
    const history = useHistory()
    const { pathname } = useLocation()
    const [windowWidth] = useWindowResize()

    const [shopId] = useState(Number(id))
    const [dataShopGoods, setDataShopGoods] = useState<FormDataType>()
    const [isOpenModalPreview, setIsOpenModalPreview] = useState(false)

    const {
        data: dataGoods,
    } = useFetchGoods({
        id: goodsId ? Number(goodsId) : 0,
    }, {
        enabled: !!goodsId && !Number.isNaN(Number(goodsId)),
    })

    const { data: dataStoreProfile } = useFetchStoreProfile({
        id: shopId,
    }, {
        enabled: !Number.isNaN(shopId),
    })

    const { invalidate: invalidateStoreCatalog } = useInvalidateStoreCatalog()

    const { setQueryData: setQueryDataGoods } = useQueryDataGoods()

    const isMobile = useMemo(() => {
        return windowWidth < BREAKPOINTS.tabletLandscape
    }, [windowWidth])

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        const parent = { id: 1, name: t('stores'), url: APP_URL.stores }
        const shop = dataStoreProfile
            ? { id: 2, name: dataStoreProfile.name, url: generatePath(APP_URL.shop, { id: dataStoreProfile.id }) }
            : undefined
        const goodsCreate = { id: 3, name: t('screen_pm_goods_create_title') }
        const goodsEdit = { id: 3, name: t('screen_pm_goods_edit_title') }

        if (isMobile) {
            if (matchPath(pathname, { path: APP_URL.shopGoods, exact: true })) {
                return [goodsCreate]
            }
            if (matchPath(pathname, APP_URL.shopGoodsEdit)) {
                return [goodsEdit]
            }

            return []
        }

        if (shop && matchPath(pathname, { path: APP_URL.shopGoods, exact: true })) {
            return [parent, shop, goodsCreate]
        }
        if (shop && matchPath(pathname, APP_URL.shopGoodsEdit)) {
            return [parent, shop, goodsEdit]
        }

        return []
    }, [pathname, isMobile, dataStoreProfile])

    const shopGoodsProps = useMemo(() => {
        return dataGoods ? GoodsDto.getGoodsProps(dataGoods) : { store: shopId }
    }, [dataGoods])

    const handlerClickBack = () => {
        history.push(generatePath(APP_URL.shop, { id }))
    }

    const handlerSuccessShopGoods = (goods: TAddGoodsResponse | TUpdateGoodsResponse) => {
        const { store_catalog: storeCatalog } = goods
        const catalogId = goodsId ? storeCatalog[0].id : undefined

        const url = catalogId
            ? generatePath(APP_URL.shopCatalog, { id: shopId, catalogId })
            : generatePath(APP_URL.shop, { id: shopId })

        setQueryDataGoods({ id: Number(goodsId) }, (queryData) => {
            return queryData ? { ...queryData, ...goods } : queryData
        })

        invalidateStoreCatalog({ storeId: shopId }, { exact: false, type: 'all' })
            .then(() => {
                history.push(url)
            })
    }

    const handlerChangeShopGoods = (data: FormDataType) => {
        setDataShopGoods(data)
    }

    const handlerPreviewShopFolder = () => {
        setIsOpenModalPreview(true)
    }

    const handlerErrorShopGoods = (err: TAddGoodsMutationFnError | TUpdateGoodsMutationFnError) => {
        showAlertNotify({ type: 'error', message: err[0] })
    }

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                <PageHeader onClickBack={handlerClickBack}>
                    <Breadcrumbs items={breadcrumbs} />
                </PageHeader>
                <ShopGoodsAction
                    shopGoodsProps={shopGoodsProps}
                    shopProfile={dataStoreProfile}
                    onPreview={isMobile ? handlerPreviewShopFolder : undefined}
                    onChange={handlerChangeShopGoods}
                    onSuccess={handlerSuccessShopGoods}
                    onError={handlerErrorShopGoods}
                />
            </ContentContainer>
            <SideBarContainer classes={style.sidebar} position="right">
                {!isMobile && (
                    <>
                        <div className={style.sidebarTitle}>
                            {t('preview_button')}
                        </div>
                        <Block classes={cn(style.block, style.block_sidebar)}>
                            <ShopGoodsPreview
                                title={dataShopGoods?.name}
                                subtitle={dataShopGoods?.subtitle}
                                image={dataShopGoods?.images?.find((item) => !item.delete)?.src}
                                price={dataShopGoods?.price}
                                currency={dataStoreProfile?.currency}
                            />
                        </Block>
                    </>
                )}
            </SideBarContainer>

            <Modal
                isOpen={isOpenModalPreview}
                size="smallCenter"
                onClose={() => setIsOpenModalPreview(false)}
            >
                <Modal.Header isCloseButton />
                <Modal.Body>
                    <ShopGoodsPreview
                        title={dataShopGoods?.name}
                        subtitle={dataShopGoods?.subtitle}
                        image={dataShopGoods?.images?.find((item) => !item.delete)?.src}
                        price={dataShopGoods?.price}
                        currency={dataStoreProfile?.currency}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShopGoods
