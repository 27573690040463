import React from 'react'
import cn from 'classnames'

import { IUser } from 'interfaces'
import { Avatar } from 'components'
import style from './UserMessageFormWrap.module.css'

type UserMessageFormWrapPropType = {
    classes?: string
    user: IUser
}

const UserMessageFormWrap: React.FC<UserMessageFormWrapPropType> = ({
    children,
    classes,
    user,
}) => {
    return (
        <div className={cn(style.wrap, classes)}>
            {user && (
                <Avatar
                    classes={style.avatar}
                    src={user.photo}
                    width={54}
                    height={54}
                    name={user.name}
                    surname={user.surname}
                />
            )}

            <div className={style.body}>
                {children}
            </div>
        </div>
    )
}

export default UserMessageFormWrap
