import { useQuery, UseQueryOptions } from '@tanstack/react-query'

import { IPayout } from 'interfaces'
import { TPayoutOptionsProps } from 'services/PayoutService'
import { PayoutService } from 'services'

const key = 'payoutOption'

export type TFetchFnParams = TPayoutOptionsProps
export type TError = string
export type TQueryKey = [typeof key, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IPayout[], TError, IPayout[], TQueryKey>

export default function useFetchPayoutOptions(params: TFetchFnParams, {
    cacheTime = 600 * 1000, // 10m
    staleTime = 60 * 1000, // 1m
    keepPreviousData = true,
    ...opts
}: TQueryFnOpts = {}) {
    return useQuery([key, params], () => {
        return PayoutService.fetchPayoutOptions(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(err)
            })
            .finally(() => {})
    }, {
        cacheTime,
        staleTime,
        keepPreviousData,
        ...opts,
    })
}
