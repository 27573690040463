import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    useQueryClient,
    useQuery,
    QueryFilters,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IUserData } from 'interfaces'
import { TUsersDataProps, TUsersDataResponse } from 'services/UserService'
import { QueryCacheKeys } from 'enums'
import { UserService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TUsersDataProps
export type TFetchFnResponse = TUsersDataResponse
export type TFetchFnError = AxiosError<TFetchFnResponse, TFetchFnParams>
export type TError = [string, TFetchFnError['response']]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

export type TUserDataRecord = Record<number, IUserData>

const key = QueryCacheKeys.usersData

/**
 * Хук API получить данные по юзерам
 */
export default function useFetchUsersData(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return UserService.fetchUsersData(params)
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

/**
 * @deprecated
 * Кеширование данных юзеров
 */
export function useCacheUsersData(cacheKey = 'users-data-records') {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const get = (filters: QueryFilters = {}) => {
        return queryClient.getQueryData<TUserDataRecord>([`${cacheKey}-${user.id}`], filters)
    }

    const set = (data: TUserDataRecord) => {
        return queryClient.setQueryData<TUserDataRecord>([`${cacheKey}-${user.id}`], (cacheData) => {
            if (cacheData) {
                return { ...cacheData, ...data }
            }

            return data
        })
    }

    return {
        get,
        set,
    }
}
