import React from 'react'

import {
    Link,
    Logo,
    MenuNavMain,
} from 'components'
import { useApp } from 'containers/App/hooks'
import style from './NavMenu.module.css'

type NavMenuPropType = {}

const NavMenu: React.FC<NavMenuPropType> = () => {
    const { mainPrivateRoute } = useApp()

    return (
        <>
            <div className={style.colLeft}>
                <Link url={mainPrivateRoute}>
                    <Logo />
                </Link>
            </div>
            <div className={style.colCenter}>
                {/* <div className={style.searchWrap}>
                    <Search
                        disabled
                        classesInput={style.searchInput}
                        styleType="transparent"
                        onSearch={() => {}}
                    />
                    <SvgResource
                        classes={style.iconSearch}
                        resourceKey="ic_search_white_thin_svg"
                        width={30}
                        height={30}
                    />
                </div> */}
            </div>
            <div className={style.colRight}>
                <MenuNavMain />
            </div>
        </>
    )
}

export default NavMenu
