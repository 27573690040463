import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'

import { ICity } from 'interfaces'
import { TClientsSearchProps } from 'services/ClientService'
import { FormDataType, formDataInitial } from 'forms/ClientFilterForm/ClientFilterForm'
import { useCacheCities } from 'containers/App/hooks'
import { ClientFilterForm } from 'forms'

type FriendFilterActionPropType = {
    classes?: string
    filterProps?: TClientsSearchProps
    onReset?: () => void
    onSuccess: (value: FormDataType) => void
}

const FORM_AGE_FROM = 14
const FORM_AGE_TO = 150

const ClientFilterAction: React.FC<FriendFilterActionPropType> = ({
    classes,
    filterProps,
    onReset = () => {},
    onSuccess,
}) => {
    const [initCityData, setInitCityData] = useState<ICity | undefined>()

    const { getAll: getAllCacheCities } = useCacheCities()

    const handlerSubmit = (params: FormDataType) => {
        onSuccess(params)
    }

    useEffect(() => {
        if (filterProps?.city) {
            const cacheCities = getAllCacheCities()

            /**
             * Fix: find city data in cache queries for get city name by id
             */
            cacheCities.find(([queryKey, values]) => {
                if (values) {
                    const city = values.find((item) => item.id === filterProps.city)
                    setInitCityData(city)

                    return city
                }

                return undefined
            })
        }
    }, [])

    return (
        <Form
            initialValues={{
                ...formDataInitial,
                ...filterProps,
            }}
            onSubmit={handlerSubmit}
            render={({ handleSubmit }) => (
                <ClientFilterForm
                    classes={classes}
                    initCityData={initCityData}
                    ageRangeMin={FORM_AGE_FROM}
                    ageRangeMax={FORM_AGE_TO}
                    isSubmitting={false}
                    onReset={onReset}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ClientFilterAction
