import React, {
    useRef,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import cn from 'classnames'

import { IPost } from 'interfaces'
import {
    POST_FILE_GROUP_ID_1,
    POST_FILE_GROUP_ID_3,
    POST_TYPE_EVENT,
} from 'config/app'
import { ContentContainer } from 'layout'
import {
    PageHeader,
    Post,
    Loader,
    ErrorMsg,
} from 'components'
import { Community, Comments, CommentForm } from 'containers'
import { useFetchPost, useSetQueryDataPost } from 'containers/Community/hooks'
import { scrollTo, scrollTop, showAlertNotify } from 'utils/helpers'
import { useIntersectionObserver, usePost } from 'hooks'
import stylePost from 'components/Post/Post.module.css'
import style from './Publication.module.css'

const Publication: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const location = useLocation()

    const commentsRef = useRef<HTMLDivElement>(null)
    const [isIntersectingComments] = useIntersectionObserver(commentsRef, { freezeOnceVisible: true })

    const [isLoadComments, setIsLoadComments] = useState(false)

    const {
        isInitialLoading: isLoadingPost,
        data: dataPost,
        error: errorPost,
        refetch: refetchPost,
    } = useFetchPost({
        id: Number(id),
    })

    const { getMainFiles, getContent } = usePost()

    const { setQueryData: setQueryDataPost } = useSetQueryDataPost()

    const mainFiles = useMemo(() => {
        return dataPost ? getMainFiles(dataPost.files, [POST_FILE_GROUP_ID_1, POST_FILE_GROUP_ID_3]) : []
    }, [dataPost])

    const content = useMemo(() => {
        return dataPost ? getContent(dataPost.text, dataPost.files) : []
    }, [dataPost])

    const handlerClickBack = () => {
        history.goBack()
    }

    const handlerLoadComments = () => {
        checkUrlHash()
    }

    const handlerUpdatePost = () => {
        refetchPost()
    }

    const handlerEditPost = (value: IPost) => {
        setQueryDataPost({ id: Number(id) }, (queryData) => {
            if (queryData) {
                return { ...queryData, ...value }
            }

            return undefined
        })
    }

    const handlerSuccessPost = (value: string) => {
        showAlertNotify({ type: 'success', message: value })
    }

    const handlerErrorPost = (value: string) => {
        showAlertNotify({ type: 'error', message: value })
    }

    function checkUrlHash() {
        if (isSetUrlHashComments()) {
            const commentsOffsetTop = commentsRef.current ? commentsRef.current.offsetTop : undefined
            scrollTo(commentsOffsetTop)
        } else if (isSetUrlHashCommentForm()) {
            scrollTo()
        }
    }

    function isSetUrlHashComments() {
        const { hash } = location
        return /comments/.test(hash)
    }

    function isSetUrlHashCommentForm() {
        const { hash } = location
        return /comment-form/.test(hash)
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataPost && location.hash) {
            setIsLoadComments(true)
        }
    }, [dataPost])

    useEffect(() => {
        if (isIntersectingComments && !isLoadComments) {
            setIsLoadComments(true)
        }
    }, [isIntersectingComments])

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                {(dataPost || errorPost) && (
                    <PageHeader
                        classes={style.header}
                        onClickBack={handlerClickBack}
                    />
                )}

                {isLoadingPost && (
                    <Loader />
                )}

                {errorPost && (
                    <ErrorMsg error={errorPost[0]} />
                )}

                {dataPost && (
                    <>
                        <Community
                            onUpdatePost={handlerUpdatePost}
                            onEditPost={handlerEditPost}
                            onSuccess={handlerSuccessPost}
                            onError={handlerErrorPost}
                        >
                            <Post
                                classes={style.post}
                            >
                                <Post.Header
                                    classes={style.postHeader}
                                    data={dataPost}
                                    isSowMenu={dataPost.type !== POST_TYPE_EVENT}
                                />
                                <Post.MainImage
                                    isCanPlayVideo
                                    preview={dataPost.small_preview}
                                    files={mainFiles}
                                    order={dataPost?.order}
                                />
                                <Post.Attaches
                                    isShowDesc
                                    classes={stylePost.attaches}
                                    files={dataPost.files}
                                />
                                <div className={stylePost.body}>
                                    {dataPost.title && (
                                        <div className={stylePost.title}>
                                            {dataPost.title}
                                        </div>
                                    )}
                                    {dataPost.kickbacks?.status_name && (
                                        <div className={stylePost.status}>
                                            <div className={cn(
                                                stylePost.statusBadge,
                                                stylePost[`checkStatusColor${dataPost.kickbacks.status_id}`],
                                            )}
                                            >
                                                {dataPost.kickbacks.status_name}
                                            </div>
                                        </div>
                                    )}
                                    <div className={stylePost.content}>
                                        {content.map((item, index) => (
                                            <Post.Content
                                                content={item}
                                                key={item.id}
                                                renderContentText={() => (
                                                    <Post.ContentText data={item} />
                                                )}
                                            >
                                                {/* skip post main text item */}
                                                {index > 0 && (
                                                    <>
                                                        <Post.ContentText isTypeText={false} data={item} />
                                                        <Post.ContentText data={item} />
                                                    </>
                                                )}
                                            </Post.Content>
                                        ))}
                                    </div>
                                </div>
                                <Post.Footer
                                    data={dataPost}
                                />
                            </Post>
                        </Community>
                        <div className={style.comments} ref={commentsRef}>
                            <Comments
                                classes={style.commentsItems}
                                isLoad={isLoadComments}
                                postId={dataPost.id}
                                onLoad={handlerLoadComments}
                            />

                            <CommentForm postId={dataPost.id} />
                        </div>
                    </>
                )}
            </ContentContainer>
        </>
    )
}

export default Publication
