import React from 'react'
import { useHistory, generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IOrder } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar, Button } from 'components'
import { useFetchProfile } from 'containers/User/hooks'
import style from './OrdersOrderOwner.module.css'

type OrdersOrderOwnerPropType = {
    classes?: string
    data: IOrder
}

const OrdersOrderOwner: React.FC<OrdersOrderOwnerPropType> = ({ classes, data }) => {
    const { t } = useTranslation()

    const history = useHistory()

    const { data: dataProfile } = useFetchProfile({
        account_id: data.account?.id ?? 0,
        userId: data.account?.user.id ?? 0,
    }, {
        enabled: !!data.account,
    })

    const handlerClickToProfile = () => {
        if (data.account) {
            history.push(generatePath(APP_URL.profile, { id: data.account.user.id }))
        }
    }

    return (
        <div className={cn(style.wrap, classes)}>
            <Avatar
                classes={style.avatar}
                src={dataProfile?.photo}
                width={90}
                height={90}
            />
            <div className={style.user}>
                <div className={style.userName}>
                    {data.account?.user.name ?? ''}
                </div>
                <div className={style.userSurname}>
                    {data.account?.user.surname ?? ''}
                </div>
            </div>
            <div className={style.data}>
                <div className={style.dataAccount}>
                    {`Account ID: ${data.account ? data.account.id : '-'}`}
                </div>
                <div className={style.dataMember}>
                    {`Member ID: ${data.account ? data.account.member_id : '-'}`}
                </div>
            </div>
            <div className={style.actions}>
                <Button
                    classes={style.action}
                    size="size44"
                    text={t('to_profile')}
                    disabled={!data.account}
                    onClick={handlerClickToProfile}
                />
            </div>
        </div>
    )
}

export default OrdersOrderOwner
