import React from 'react'

import { ISticker } from '../../../../interfaces'
import { Sticker } from '../../../index'
import style from './StickersListItem.module.css'

type StickersMenuItemPropType = {
    data: ISticker & { type: string }
    onClick: (id: number) => void
}

const StickersListItem: React.FC<StickersMenuItemPropType> = ({
    data,
    onClick,
}) => {
    const { id } = data

    const handlerClick = () => {
        onClick(id)
    }

    return (
        <li
            className={style.item}
            role="presentation"
            onKeyUp={handlerClick}
            onClick={handlerClick}
        >
            <Sticker
                classes={style.img}
                data={data}
            />
        </li>
    )
}

export default StickersListItem
