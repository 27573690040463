import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ContentContainer, Block } from 'layout'
import { Tabs } from 'components'
import { PostForm } from 'containers'
import { useAppMode } from 'containers/App/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { FeedPostsCommunity, FeedPostsUser } from './components'
import style from './Feed.module.css'

const POSTS_LIMIT = 10
const POSTS_TILE_LIMIT = 18

const Feed: React.FC = () => {
    const { t } = useTranslation()

    const { isUserMode, isBusinessMode } = useAppMode()

    // const { feedPostTile: isPostTile } = useSelector(appSelectors.settings)
    const isPostTile = false
    const user = useSelector(userSelectors.user)

    const [activeTabId, setActiveTabId] = useState<number>(1)

    const tabs = useMemo(() => {
        if (isUserMode) {
            return [
                { id: 1, name: t('feed') },
                { id: 2, name: t('user_profile_posts_and_checks') },
            ]
        }
        if (isBusinessMode) {
            return [
                { id: 1, name: t('checks') },
                { id: 2, name: t('user_profile_posts') },
            ]
        }

        return []
    }, [])

    const handlerChangeTabItem = (id: number) => {
        setActiveTabId(id)
    }

    return (
        <ContentContainer classes={style.content} size="half">
            <PostForm />
            <Block classes={style.nav}>
                <div className={style.tabs}>
                    <Tabs
                        items={tabs}
                        active={activeTabId}
                        classesItem={style.tabsItem}
                        classesActive={style.tabsItemActive}
                        onChange={handlerChangeTabItem}
                    />
                </div>
            </Block>
            <div className={style.feed}>
                {activeTabId === 1 && (
                    <FeedPostsCommunity
                        isPostTile={!!isPostTile}
                        queryParams={{ limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}

                {activeTabId === 2 && (
                    <FeedPostsUser
                        isPostTile={!!isPostTile}
                        queryParams={{ userId: user.id, limit: isPostTile ? POSTS_TILE_LIMIT : POSTS_LIMIT }}
                    />
                )}
            </div>
        </ContentContainer>
    )
}

export default Feed
