import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { IRootState } from 'interfaces'
import { BREAKPOINTS } from 'config/app'
import { SvgResource } from 'components'
import { useWindowResize } from 'hooks'
import style from './UserRank.module.css'

const UserRank = () => {
    const [windowWidth] = useWindowResize()

    const rank = useSelector((state: IRootState) => state.profile?.rating_sessia?.rank)
    const iconKey = useMemo(() => {
        if (rank?.icon) {
            return rank.icon.includes('white') ? rank.icon : `${rank.icon}_white`
        }
        return ''
    }, [rank])

    return (
        <div className={style.rating}>
            {rank && (
                <>
                    <SvgResource
                        classes={style.icon}
                        resourceKey={iconKey}
                        width={32}
                        height={32}
                    />
                    {windowWidth >= BREAKPOINTS.tabletLandscape && (
                        <div className={style.text}>
                            {rank.name}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default UserRank
