import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { APP_URL } from 'config/app'
import { CompanyRoleId } from 'enums'
import { Button } from 'components'
import { RegistrationFormWrap } from 'containers/Registration/components'
import * as userSelectors from 'containers/User/user-selectors'
import * as companySelectors from 'containers/Company/company-selectors'
import { RegistrationCompanyAction } from 'form-actions'
import { useAlertDialog } from 'hooks'
import { showAlertNotify } from 'utils/helpers'
import style from './RegistrationCompany.module.css'

const RegistrationCompany: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { showAlertDialog } = useAlertDialog()

    const user = useSelector(userSelectors.user)
    const company = useSelector(companySelectors.userCompany)

    const [isShowRegistration, setIsShowRegistration] = useState(false)
    const [isShowAwait, setIsShowAwait] = useState(false)

    const handlerClickCancel = () => {
        showAlertDialog({
            message: t('are_you_sure_cancel_application_text'),
            onConfirm: companyJoinRequestDelete,
        })
    }

    const companyJoinRequestDelete = () => {
        // TODO delete account
        showAlertNotify({ message: 'Обратитесь, пожалуйста, в службу поддержки' })
    }

    useEffect(() => {
        if (!user) {
            history.replace(APP_URL.auth)
        }
        if (!company) {
            setIsShowRegistration(true)
        } else if (company.company_role?.id === CompanyRoleId.awaiting) {
            setIsShowAwait(true)
            // TODO проверка роль раз в 7 сек /api/company/{id}
        }
        // TODO company need moderate && CompanyRoleId === can moderate
        // else if ()
        // TODO ? redirect to LK
        // else
    }, [])

    return (
        <>
            {isShowRegistration && (
                <RegistrationFormWrap>
                    <RegistrationCompanyAction />
                </RegistrationFormWrap>
            )}

            {isShowAwait && (
                <div className={style.await}>
                    {t('reg_screen_application_status_info_text')}

                    <div className={style.awaitControls}>
                        <Button
                            size="size44"
                            text={t('bt_cancel_the_application')}
                            onClick={handlerClickCancel}
                        />
                    </div>
                </div>
            )}
        </>
    )
}

export default RegistrationCompany
