import {
    UseQueryOptions,
    useInfiniteQuery,
    useQuery, UseInfiniteQueryOptions,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ITransaction } from 'interfaces'
import { TPayoutHistoryProps } from 'services/PayoutService'
import { PayoutService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TPayoutHistoryProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<ITransaction[], string, ITransaction[], TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    ITransaction[],
    TError,
    ITransaction[],
    ITransaction[],
    TQueryKey
>

const key = 'payoutHistory'

/**
 * Хук API получение списка транзакций
 */
export default function useFetchPayoutHistory(params: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return PayoutService.fetchPayoutHistory(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

export function useFetchInfinitePayoutHistory(params: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, params], () => {
        return PayoutService.fetchPayoutHistory(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}
