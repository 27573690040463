import React from 'react'
import cn from 'classnames'

import style from './TableListCol.module.css'

export type TableListColPropType = {
    classes?: string
}

const TableListCol: React.FC<TableListColPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.col, classes)}>
            {children}
        </div>
    )
}

export default TableListCol
