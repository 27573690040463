import React from 'react'
import cn from 'classnames'

import style from './TableListFooter.module.css'

export type TableListFooterPropType = {
    classes?: string
}

const TableListFooter: React.FC<TableListFooterPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.footer, classes)}>
            {children}
        </div>
    )
}

export default TableListFooter
