import React from 'react'
import cn from 'classnames'

import style from './ContentContainer.module.css'

type ContentContainerPropType = {
    classes?: string
    styles?: object
    size?: 'half' | 'three-quarters' | 'full'
}

const ContentContainer: React.FC<ContentContainerPropType> = ({
    children,
    classes,
    styles,
    size = '',
}) => {
    return (
        <main
            className={cn(
                style.content,
                size ? style[`content_${size}`] : '',
                classes,
            )}
            style={styles}
        >
            {children}
        </main>
    )
}

export default ContentContainer
