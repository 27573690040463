import React from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { FormDataType, FieldName, formDataInitial } from 'forms/WarehouseSuppliesForm/WarehouseSuppliesForm'
import { WarehouseSuppliesForm } from 'forms'

type WarehouseSuppliesActionPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    goods: { id: number, name: string }[] // FIXME
}

// FIXME on ready api request
const STATUSES = [
    { id: 1, name: 'Order' },
    { id: 2, name: 'In transit' },
    { id: 3, name: 'Acceptance in progress' },
    { id: 4, name: 'In stock' },
]

const WarehouseSuppliesAction: React.FC<WarehouseSuppliesActionPropType> = ({
    classes,
    classesBody,
    classesFooter,
    goods,
}) => {
    const { t } = useTranslation()

    const handlerSubmit = (params: FormDataType) => {
        //
    }

    return (
        <Form
            initialValues={formDataInitial}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.dateOrder]) {
                    errors[FieldName.dateOrder] = t('error_field_is_empty')
                }
                if (!values[FieldName.dateSupply]) {
                    errors[FieldName.dateSupply] = t('error_field_is_empty')
                }
                if (!values[FieldName.goods].length) {
                    errors[FieldName.goods] = true
                }

                return errors
            }}
            render={({ handleSubmit }) => (
                <WarehouseSuppliesForm
                    classes={classes}
                    classesBody={classesBody}
                    classesFooter={classesFooter}
                    isSubmitting={false}
                    statuses={STATUSES}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default WarehouseSuppliesAction
