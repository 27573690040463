import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TMarketServiceError,
    TCompanyVendorCodesProps,
    TCompanyVendorCodesResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TCompanyVendorCodesProps
export type TQueryFnOpts = UseQueryOptions<
    TCompanyVendorCodesResponse,
    [string, AxiosError<TMarketServiceError, TFetchFnParams>],
    TCompanyVendorCodesResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.companyVendorCodes

/**
 * Hook API company goods vendor codes
 */
export default function useFetchCompanyVendorCodes(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user}`, params], () => {
        return MarketService.fetchCompanyVendorCodes(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
