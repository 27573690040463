import React from 'react'
import cn from 'classnames'

import { SortKey } from 'enums'
import { Button } from 'components'
import style from './TableListSort.module.css'

type TSort = keyof typeof SortKey

export type TableListSortPropType = {
    classes?: string
    sort?: TSort
    disabled?: boolean
    onClick: (value: TSort) => void
}

const TableListSort: React.FC<TableListSortPropType> = ({
    classes,
    sort,
    disabled,
    onClick,
}) => {
    const handlerClick = () => {
        switch (sort) {
            case 'ASC':
                onClick('DESC')
                break
            case 'DESC':
            default:
                onClick('ASC')
        }
    }

    return (
        <Button
            classes={cn(style.sort, classes)}
            styleType="transparent"
            disabled={disabled}
            onClick={handlerClick}
        >
            <span
                className={cn(style.arrow, style.arrow_up, { [style.arrow_active]: sort === 'DESC' })}
            />
            <span
                className={cn(style.arrow, style.arrow_down, { [style.arrow_active]: sort === 'ASC' })}
            />
        </Button>
    )
}

export default TableListSort
