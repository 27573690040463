import React from 'react'
import { Form } from 'react-final-form'

import { FormDataType, FieldName } from 'forms/CompanyOrdersPresetsForm/CompanyOrdersPresetsForm'
import { CompanyOrdersPresetsForm } from 'forms'

const CompanyOrdersPresetsAction: React.FC = () => {
    const handlerSubmit = (params: FormDataType) => {
        //
    }

    return (
        <Form
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.preset]) {
                    errors[FieldName.preset] = true
                }

                return errors
            }}
            render={({ submitting, handleSubmit }) => (
                <CompanyOrdersPresetsForm
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default CompanyOrdersPresetsAction
