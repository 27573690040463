import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useAppMode } from 'containers/App/hooks'
import style from './RegAgreement.module.css'

const RegAgreement: React.FC = () => {
    const { t, i18n } = useTranslation()
    const { isBusinessMode } = useAppMode()

    const agreementLink = useMemo(() => {
        return isBusinessMode ? t('business_agreement_link') : t('kicksback_user_agreement_link')
    }, [i18n.language])

    const policyLink = useMemo(() => {
        return t('kicksback_privacy_policy_link')
    }, [i18n.language])
    // TODO check for kicksback
    const cookieLink = useMemo(() => {
        switch (i18n.language) {
            case 'ru':
                return 'https://www.sessia.com/lang/ru/cookie.html'
            case 'vn':
                return 'https://www.sessia.com/lang/vn/cookie.html'
            case 'de':
                return 'https://www.sessia.com/lang/de/cookie.html'
            case 'cn':
                return 'https://www.sessia.com/lang/cn/cookie.html'
            case 'en':
            default:
                return 'https://www.sessia.com/cookie.html'
        }
    }, [i18n.language])

    return (
        <>
            {t('By registering, you consent with')}
            {' '}
            <a className={style.link} href={agreementLink} target="_blank" rel="noopener noreferrer">
                {t('the Terms of Use')}
            </a>
            ,
            {' '}
            <a className={style.link} href={policyLink} target="_blank" rel="noopener noreferrer">
                {t('Use of Data Policy')}
            </a>
            {' '}
            {t('and')}
            &nbsp;
            <a className={style.link} href={cookieLink} target="_blank" rel="noopener noreferrer">
                {t('Cookie Policy')}
            </a>
        </>
    )
}

export default RegAgreement
