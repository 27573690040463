import React from 'react'
import { Form } from 'react-final-form'

import { IPersonalStore } from 'interfaces'
import { FormDataType, FieldName, formDataInitial } from 'forms/DeliveryServicesFilterForm/DeliveryServicesFilterForm'
import { DeliveryServicesFilterForm } from 'forms'

type DeliveryServicesFilterActionPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    stores?: IPersonalStore[]
    onSuccess: (value: FormDataType) => void
}

const DeliveryServicesFilterAction: React.FC<DeliveryServicesFilterActionPropType> = ({
    classes,
    classesBody,
    classesFooter,
    stores,
    onSuccess,
}) => {
    const handlerSubmit = (params: FormDataType) => {
        onSuccess(params)
    }

    return (
        <Form
            initialValues={formDataInitial}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.store]) {
                    errors[FieldName.store] = true
                }

                return errors
            }}
            render={({ submitting, handleSubmit }) => (
                <DeliveryServicesFilterForm
                    classes={classes}
                    classesBody={classesBody}
                    classesFooter={classesFooter}
                    stores={stores}
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default DeliveryServicesFilterAction
