import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ICurrency } from 'interfaces'
import {
    InfoBlock,
    Tabs,
    Popover,
    CurrencyFilterList,
} from 'components'
import style from './WalletNav.module.css'

export enum TabItems {
    transactions = 1,
    reports = 2,
}

type WalletNavPropType = {
    activeTabId: number
    currencyId?: number
    onChangeCurrency: (data: ICurrency) => void
    onChangeTab: (id: number) => void
}

const WalletNav: React.FC<WalletNavPropType> = ({
    children,
    activeTabId,
    currencyId,
    onChangeCurrency,
    onChangeTab,
}) => {
    const { t } = useTranslation()

    const [isOpenCurrencyFilterList, setIsOpenCurrencyFilterList] = useState(false)

    const TAB_ITEMS = [
        { id: TabItems.transactions, name: t('help_context_wallet_title') },
        // { id: TabItems.reports, name: t('help_context_reports_title') },
    ]

    const handlerOpenCurrencyFilterList = () => {
        setIsOpenCurrencyFilterList(true)
    }

    const handlerCloseCurrencyFilterList = () => {
        setIsOpenCurrencyFilterList(false)
    }

    const handlerChangeCurrency = (data: ICurrency) => {
        onChangeCurrency(data)
        setIsOpenCurrencyFilterList(false)
    }

    return (
        <InfoBlock classes={style.block}>
            <Popover
                classes={style.currencyDropDown}
                isAutoClose={false}
                isOpen={isOpenCurrencyFilterList}
                position="bottom"
                side="left"
                trigger={<Popover.TriggerDots position="vertical" />}
                onOpen={handlerOpenCurrencyFilterList}
                onClose={handlerCloseCurrencyFilterList}
            >
                <div className={style.currencyTitle}>
                    {t('select_currency')}
                </div>

                <CurrencyFilterList
                    classes={style.currencyList}
                    defaultCurrencyId={currencyId}
                    onChange={handlerChangeCurrency}
                />
            </Popover>

            <InfoBlock.Body classes={style.body}>
                {children}
            </InfoBlock.Body>

            <InfoBlock.Footer classes={style.footer}>
                <Tabs
                    classes={style.tabs}
                    classesItem={style.tabsItem}
                    items={TAB_ITEMS}
                    active={activeTabId}
                    onChange={onChangeTab}
                />
            </InfoBlock.Footer>
        </InfoBlock>
    )
}

export default WalletNav
