import { useDispatch } from 'react-redux'

import { ISettings } from 'interfaces'
import { AppSettings } from 'enums'
import { setSettings, updateSettings } from 'containers/App/app-actions'
import { StorageService } from 'services'
import {
    // CLIENT_STORAGE_SETTINGS_REPORTS_NEW_KEY,
    CLIENT_STORAGE_SETTINGS_FEED_TILE,
    CLIENT_STORAGE_SETTINGS_SHOP_CATALOG_EDIT,
} from 'config/app'

const SETTINGS_MAP = {
    // [AppSettings.reportsNew]: CLIENT_STORAGE_SETTINGS_REPORTS_NEW_KEY,
    [AppSettings.feedPostTile]: CLIENT_STORAGE_SETTINGS_FEED_TILE,
    [AppSettings.shopCatalogUserView]: CLIENT_STORAGE_SETTINGS_SHOP_CATALOG_EDIT,
} as const

/**
 * Hook redux store app settings persist local storage settings
 */
export default function useAppSettings() {
    const dispatch = useDispatch()

    /** Get settings to redux store */
    const init = () => {
        const settings = {} as ISettings

        Object.entries(SETTINGS_MAP).forEach(([id, key]) => {
            const value = StorageService.getItem(key)

            // if (id === AppSettings.reportsNew && value === null) {
            //     value = true
            //     StorageService.setItem(SETTINGS_MAP[id], value)
            // }

            settings[id] = value
        })

        dispatch(setSettings(settings))
    }

    const update = (id: keyof typeof AppSettings, value: any) => {
        StorageService.setItem(SETTINGS_MAP[id], value)
        dispatch(updateSettings(id, value))
    }

    return {
        map: SETTINGS_MAP,
        init,
        update,
    }
}
