import { useDispatch } from 'react-redux'

import { IConfig } from 'interfaces'
import { AppMode } from 'enums'
import { CLIENT_STORAGE_APP_ID_KEY, REQUEST_HEADER_NAME_APP_ID } from 'config/app'
import { APP_MODE } from 'config/api'
import { setConfig } from 'containers/App/app-actions'
import { StorageService } from 'services'
import { isObject } from 'utils/helpers'

type TInitProps = {
    enabled?: boolean
}

export const initial: IConfig = {
    primaryColor: APP_MODE === AppMode.user ? '#613bfe' : '#000',
    companyIds: [],
    forbidden: {},
}

const setAppId = (id: string) => {
    StorageService.setItem(CLIENT_STORAGE_APP_ID_KEY, id, 'session')
}

const setAppPrimaryColor = (color: string) => {
    document.documentElement.style.setProperty('--primary-color', color)
}

const getConfigData = (data: IConfig) => {
    return Object.entries(data).reduce<IConfig>((acc, [key, value]) => {
        switch (key) {
            case REQUEST_HEADER_NAME_APP_ID:
                return value ? { ...acc, [key]: value } : acc
            case 'primaryColor':
                return value ? { ...acc, [key]: value } : acc
            case 'companyIds':
                return value && Array.isArray(value) ? { ...acc, [key]: value } : acc
            case 'forbidden':
                return value && isObject(value) ? { ...acc, [key]: value } : acc
            default:
                return acc
        }
    }, initial)
}

/**
 * Hook app config
 */
export default function useAppConfig() {
    const dispatch = useDispatch()

    /** Fetch config to redux store */
    const init = ({ enabled }: TInitProps = {}) => {
        if (enabled === false) {
            if (initial.primaryColor) {
                setAppPrimaryColor(initial.primaryColor)
            }

            dispatch(setConfig(initial))
            return Promise.resolve(initial)
        }

        return fetch('/apprc.json', {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        })
            .then((res) => {
                if (res.status === 200) {
                    return res.json() as Promise<IConfig>
                }
                return Promise.reject()
            })
            .then((data) => {
                const config = getConfigData(data)

                if (config[REQUEST_HEADER_NAME_APP_ID]) {
                    setAppId(config[REQUEST_HEADER_NAME_APP_ID] as string)
                }
                if (config.primaryColor) {
                    setAppPrimaryColor(config.primaryColor)
                }

                dispatch(setConfig(config))
                return Promise.resolve(config)
            })
            .catch((err) => {
                if (initial[REQUEST_HEADER_NAME_APP_ID]) {
                    setAppId(initial[REQUEST_HEADER_NAME_APP_ID] as string)
                }
                if (initial.primaryColor) {
                    setAppPrimaryColor(initial.primaryColor)
                }

                dispatch(setConfig(initial))
                return Promise.reject([err, initial])
            })
    }

    return {
        init,
    }
}
