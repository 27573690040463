import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

import { FormDataType as ServicesFilterFormDataType } from 'forms/DeliveryServicesFilterForm/DeliveryServicesFilterForm'
import { ContentContainer, Block } from 'layout'
import { PageHeader, Button, Pagination } from 'components'
import { useFetchPersonalStores } from 'containers/Market/hooks'
import { DeliveryServicesFilterAction } from 'form-actions'
import { DeliveryServicesList } from './components'
import style from './DeliveryServices.module.css'

// FIXME
type TData = {
    id: number
    name: string
    store: string[]
    type: number[]
    rate: number[]
}

type TColumn = {
    id: number
    key: keyof TData
    name: string
}

const LIST_COLUMNS: TColumn[] = [
    {
        id: 1,
        key: 'id',
        name: 'ID',
    },
    {
        id: 2,
        key: 'name',
        name: 'Название',
    },
    {
        id: 3,
        key: 'store',
        name: 'Магазин', // reports_store
    },
    {
        id: 4,
        key: 'type',
        name: 'Тип доставки', // delivery_type
    },
    {
        id: 5,
        key: 'rate',
        name: 'Тариф', // report_rate
    },
]

const DeliveryServices: React.FC = () => {
    const { t } = useTranslation()
    const dividerRef = useRef<HTMLDivElement>(null)

    const [isOpenFilter, setIsOpenFilter] = useState(true)
    const [isOpenList, setIsOpenList] = useState(false)

    const { data: dataPersonalStores } = useFetchPersonalStores()

    // FIXME fetch data
    const [dataServices] = useState([
        {
            id: 1,
            name: 'Project V Европа',
            store: ['Vision - Europe (OLD) PROJECT', 'V - Первый заказ %'], // [1, 2],
            type: [{ id: 1, name: 'Самовывоз' }, { id: 2, name: 'Курьер' }],
            rate: [12, 12],
        },
        {
            id: 2,
            name: 'Почта России',
            store: ['NRK1987. для COFFEECELL sales'], // [3],
            type: [],
            rate: [12],
        },
    ])

    const handlerClickFilter = () => {
        setIsOpenFilter((prevState) => !prevState)
    }

    const handlerSuccessServicesFilter = (values: ServicesFilterFormDataType) => {
        // TODO
        setIsOpenList(true)
    }

    const handlerChangePagination = (value: number) => {
        // TODO
    }

    useEffect(() => {
        if (dataServices) {
            setIsOpenList(true)
            setTimeout(() => {
                if (dividerRef.current) {
                    dividerRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
                }
            }, 300)
        }
    }, [dataServices])

    return (
        <ContentContainer size="three-quarters">
            <PageHeader title={t('delivery')} />

            <Block classes={cn(style.block, style.block_gray)}>
                <Block.Header classes={style.blockHeader}>
                    <div className={style.blockTitle}>
                        {t('reports_filter')}
                    </div>
                    <div className={style.blockActions}>
                        <Button
                            textUpper={false}
                            classes={style.blockAction}
                            styleType="small"
                            size="size30"
                            text={t('clear')}
                        />
                        <Button
                            textUpper={false}
                            classes={style.blockAction}
                            styleType="small"
                            size="size30"
                            text={isOpenFilter ? t('hide') : t('show')}
                            onClick={handlerClickFilter}
                        />
                    </div>
                </Block.Header>
                <Block.BodyCollapse
                    isOpen={isOpenFilter}
                    classesContent={style.blockBody}
                >
                    <DeliveryServicesFilterAction
                        classesBody={style.filterFormBody}
                        classesFooter={style.filterFormFooter}
                        stores={dataPersonalStores}
                        onSuccess={handlerSuccessServicesFilter}
                    />
                </Block.BodyCollapse>
            </Block>
            <div className={style.divider} ref={dividerRef} />
            <Block classes={style.block}>
                <Block.Header classes={style.blockHeader}>
                    <Block.HeaderInner classes={style.blockHeaderInner}>
                        <div className={style.blockTitle}>
                            {t('delivery_services_list')}
                        </div>
                        <div className={style.blockActions}>
                            <Button
                                disabled
                                textUpper={false}
                                classes={style.blockAction}
                                styleType="small"
                                size="size30"
                            >
                                <span className={style.blockActionText_large}>
                                    {t('add_delivery_service')}
                                </span>
                                <span className={style.blockActionText_shot}>
                                    {t('add')}
                                </span>
                            </Button>
                        </div>
                    </Block.HeaderInner>
                </Block.Header>
                <Block.BodyCollapse
                    isOpen={isOpenList}
                    classesContent={style.blockBody}
                >
                    <DeliveryServicesList
                        columns={LIST_COLUMNS}
                        data={dataServices}
                    />
                    <Pagination
                        classes={style.pagination}
                        current={5}
                        total={124}
                        onChange={handlerChangePagination}
                    />
                </Block.BodyCollapse>
            </Block>
        </ContentContainer>
    )
}

export default DeliveryServices
