import React, { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import {
    TStoreServiceError,
    TAddStoreCatalogProps,
    TUpdateStoreCatalogProps,
    TAddStoreCatalogResponse,
    TUpdateStoreCatalogResponse,
} from 'services/StoreService'
import {
    FormDataType,
    FieldName,
    BG_COLOR_DEFAULT,
    formDataInitial,
} from 'forms/ShopHeaderForm/ShopHeaderForm'
import { useFetchStoreCatalogTree, useMutationStoreCatalog } from 'containers/Store/hooks'
import { ShopHeaderForm } from 'forms'

export type TCommonShopHeaderProps = Pick<TAddStoreCatalogProps, 'storeId'> | TUpdateStoreCatalogProps

type TError = [string, AxiosError<TStoreServiceError, TAddStoreCatalogProps>]
    | [string, AxiosError<TStoreServiceError, TUpdateStoreCatalogProps>]

type ShopHeaderActionPropType = {
    classes?: string
    shopHeaderProps: TCommonShopHeaderProps
    onSuccess?: (data: TAddStoreCatalogResponse | TUpdateStoreCatalogResponse) => void
    onError?: (err: TError) => void
}

const ShopHeaderAction: React.FC<ShopHeaderActionPropType> = ({
    classes,
    shopHeaderProps,
    onSuccess,
    onError,
}) => {
    const { t } = useTranslation()

    const [initialValues, setInitialValues] = useState<FormDataType>({
        ...formDataInitial,
        ...shopHeaderProps,
    })

    const {
        data: dataStoreCatalogTree,
    } = useFetchStoreCatalogTree({
        storeId: shopHeaderProps.storeId,
        tree_only: true,
    })

    const { add: addStoreCatalog, update: updateStoreCatalog } = useMutationStoreCatalog()

    const handlerSubmit = ({ id, color, ...params }: FormDataType) => {
        const headerParams: TAddStoreCatalogProps = {
            storeId: shopHeaderProps.storeId,
            type: 'header',
            color: color?.replace('#', ''),
            ...params,
        }

        if (id) {
            return updateStoreCatalogAction({ id, ...headerParams })
                .then(onSuccess)
                .catch(onError)
        }

        return addStoreCatalogAction(headerParams)
            .then(onSuccess)
            .catch(onError)
    }

    function addStoreCatalogAction(params: TAddStoreCatalogProps) {
        return addStoreCatalog.mutateAsync(params)
    }

    function updateStoreCatalogAction(params: TUpdateStoreCatalogProps) {
        return updateStoreCatalog.mutateAsync(params)
    }

    useEffect(() => {
        if (dataStoreCatalogTree?.length) {
            const rootParentId = dataStoreCatalogTree[0].id

            setInitialValues((prevState) => {
                const data = 'id' in shopHeaderProps
                    ? dataStoreCatalogTree.find((item) => item.id === shopHeaderProps.id)
                    : undefined

                if (data) {
                    const {
                        name,
                        parent = rootParentId,
                        sort,
                        bg_color = BG_COLOR_DEFAULT,
                    } = data

                    return {
                        ...prevState,
                        name,
                        parent,
                        order: sort,
                        color: bg_color.includes('#') ? bg_color : `#${bg_color}`,
                    }
                }

                return { ...prevState, parent: rootParentId }
            })
        }
    }, [dataStoreCatalogTree])

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.parent]) {
                    errors[FieldName.parent] = t('error_field_is_empty')
                }
                if (values[FieldName.name] === undefined || values[FieldName.name] === '') {
                    errors[FieldName.name] = t('error_field_is_empty')
                }

                return errors
            }}
            render={({ handleSubmit, submitting }) => (
                <ShopHeaderForm
                    classes={classes}
                    isSubmitting={submitting}
                    catalog={dataStoreCatalogTree}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ShopHeaderAction
