import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IOrder, IOrderLog } from 'interfaces'
import { TableList, NoDataInfo } from 'components'
import { defaultDateFormat, dateTimeFormat } from 'utils/helpers'
import style from './OrdersOrderHistoryList.module.css'

export enum ColumnKey {
    date = 'date',
    description = 'description',
}

type TColumn = {
    key: keyof typeof ColumnKey
    translateKey: string
    checked: boolean
}

type OrdersOrderHistoryListPropType = {
    columns?: TColumn[]
    data?: IOrder
}

export const LIST_COLUMNS: TColumn[] = [
    { key: ColumnKey.date, translateKey: 'date', checked: true },
    { key: ColumnKey.description, translateKey: 'order_log_action', checked: true },
]

const OrdersOrderHistoryList: React.FC<OrdersOrderHistoryListPropType> = ({
    columns = LIST_COLUMNS,
    data,
}) => {
    const { t, i18n } = useTranslation()

    const getFormattedValue = (key: keyof typeof ColumnKey, values: IOrderLog) => {
        switch (key) {
            case 'date':
                return `${defaultDateFormat(values.action_date)} ${dateTimeFormat(values.action_date, i18n.language, { timeStyle: 'medium' })}`
            case 'description':
                return values.action_description
            default:
                return values[key]
        }
    }

    return (
        <>
            {data?.logs && !data.logs.length && (
                <NoDataInfo text={t('no_data')} />
            )}
            {data?.logs && data.logs.length && (
                <TableList classes={style.list}>
                    <TableList.Header classes={style.header}>
                        {columns.map((item) => (
                            <TableList.Col classes={cn(style.col, style.col_header)} key={item.key}>
                                <div className={style.headerTitle}>
                                    {t(item.translateKey)}
                                </div>
                            </TableList.Col>
                        ))}
                    </TableList.Header>
                    <TableList.Body>

                        {data.logs.map((item) => (
                            <TableList.Row classes={style.row} key={item.id}>
                                {columns.map((itemColumn) => (
                                    <TableList.Col classes={cn(style.col, style.col_body)} key={itemColumn.key}>
                                        <div className={style.colTitle}>
                                            {t(itemColumn.translateKey)}
                                        </div>
                                        <div className={style.colInner}>
                                            {getFormattedValue(itemColumn.key, item)}
                                        </div>
                                    </TableList.Col>
                                ))}
                            </TableList.Row>
                        ))}
                    </TableList.Body>
                </TableList>
            )}
        </>
    )
}

export default OrdersOrderHistoryList
