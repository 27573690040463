import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IPersonalStore } from 'interfaces'
import { TSetStoreLoyaltySettingsMutationFnError } from 'containers/Store/hooks/useStoreLoyaltySettings'
import { APP_URL } from 'config/app'
import { Block } from 'layout'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import { useFetchPersonalStores, useQueryDataPersonalStores } from 'containers/Market/hooks'
import { Loader, ErrorMsg } from 'components'
import { CompanyStoreSettingsAction } from 'form-actions'
import { AuthService, MarketService } from 'services'
import { getRequestError, showAlertNotify } from 'utils/helpers'
import style from './RegistrationStoreSettings.module.css'

const RegistrationStoreSettings: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const config = useSelector(appSelectors.config)
    const user = useSelector(userSelectors.user)

    const [store, setStore] = useState<IPersonalStore>()

    const { update: updatePersonalStores } = useQueryDataPersonalStores()

    const {
        isInitialLoading: isLoading,
        data: dataPersonalStores,
        error: errorPersonalStores,
    } = useFetchPersonalStores()

    const handlerSuccessStoreSettings = () => {
        if (store) {
            updatePersonalStores({ ...store, has_kick_loyalty_settings: true })
        }
        authorizeAction()
    }

    const handlerErrorStoreSettings = (err: TSetStoreLoyaltySettingsMutationFnError) => {
        showAlertNotify({ type: 'error', message: err[0] })
    }

    function goToAuth() {
        history.replace(APP_URL.auth)
    }

    function authorizeAction() {
        AuthService.authorizeBusiness(user, config)
            .then((route) => {
                if (route) {
                    history.push(route)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
    }

    useEffect(() => {
        if (!user) {
            goToAuth()
        }
    }, [])

    useEffect(() => {
        if (dataPersonalStores) {
            const personalStore = dataPersonalStores.find((item) => {
                return MarketService.isNeedModeratePersonalStoreLoyaltySettings(item)
            })

            if (personalStore) {
                setStore(personalStore)
            } else {
                goToAuth()
            }
        }
    }, [dataPersonalStores])

    return (
        <>
            {isLoading && (
                <Loader />
            )}

            {!isLoading && errorPersonalStores && (
                <ErrorMsg error={errorPersonalStores[0]} />
            )}

            {store && (
                <Block classes={style.block}>
                    <div className={style.storeName}>
                        {store.name}
                    </div>

                    <CompanyStoreSettingsAction
                        store={store}
                        onSuccess={handlerSuccessStoreSettings}
                        onError={handlerErrorStoreSettings}
                    />
                </Block>
            )}
        </>
    )
}

export default RegistrationStoreSettings
