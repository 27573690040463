import {
    ICompany,
    ICompanyUser,
    ICompanyRole,
    ICompanyProps,
} from 'interfaces'
import { CompanyRoleId } from 'enums'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

export type fetchCompanyProps = {
    id?: number
}

export type addCompanyProps = ICompanyProps

export type updatedCompanyProps = ICompanyProps & { id: number }

/**
 * Service API Company
 */
class CompanyService {
    static isCanEditCompany(roleId: number): boolean {
        const CAN_MODERATE = {
            [CompanyRoleId.admin]: true,
            [CompanyRoleId.cashier]: true,
            [CompanyRoleId.editor]: false,
            [CompanyRoleId.awaiting]: false,
        }

        return Object.prototype.hasOwnProperty.call(CAN_MODERATE, roleId) && CAN_MODERATE[roleId]
    }

    static fetchCompany({ id }: fetchCompanyProps = {}) {
        return requestClient<ICompanyUser>(API_URL.company, { params: { id } })
    }

    static addCompany(params: addCompanyProps) {
        return requestClient<ICompany>(API_URL.companyAdd, { method: 'post', data: params })
    }

    static updateCompany({ id, ...params }: updatedCompanyProps) {
        return requestClient<ICompany>(`${API_URL.companyUpdate}/${id}`, { method: 'put', data: params })
    }

    static fetchCompanyBusinessRoles() {
        return requestClient<ICompanyRole[]>(API_URL.companyBusinessRoles)
    }
}

export default CompanyService
