import { IClient, IClientSearch } from 'interfaces'
import { FriendFilterSortBy } from 'enums'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

export type TClientServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TClientsProps = {
    limit?: number
    offset?: number
    is_with_orders?: 0|1
}

export type TClientsSearchProps = {
    name?: string
    age_from?: number
    age_to?: number
    country?: number
    city?: number
    gender?: 0 | 1
    memberId?: string
    phone_number?: string
    sort_by?: keyof typeof FriendFilterSortBy
    q?: string
    is_with_photo_only?: 0 | 1
    is_with_orders?: boolean
    limit?: number
    offset?: number
}

export type TClientsResponse = {
    total: number
    list: IClient[]
}

export type TClientsSearchResponse = {
    count: number
    results: IClientSearch[]
}

/**
 * API clients
 */
class ClientService {
    static fetchClients(params?: TClientsProps) {
        return requestClient<TClientsResponse>(API_URL.clients, { params })
    }

    static searchClients(params?: TClientsSearchProps) {
        return requestClient<TClientsSearchResponse>(API_URL.clientsSearch, { method: 'post', data: params })
    }
}

export default ClientService
