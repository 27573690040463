import React from 'react'
import { InfiniteData } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPost } from 'interfaces'
import { LoadDataType } from 'components/InfiniteScroll/InfiniteScroll'
import { ContentBackgroundBottom } from 'layout'
import {
    PostCard,
    PostTile,
    NoDataInfo,
    ErrorMsg,
    InfiniteScroll,
} from 'components'
import style from './Posts.module.css'

export type PostsPropType = {
    isLoading: boolean
    isHideLoader: boolean
    isPostTile?: boolean
    isShowAvatar?: boolean
    data?: InfiniteData<IPost[]>
    error?: string | null
    noDataTitleKey?: string
    noDataTextKey?: string
    onLoad: (data: LoadDataType) => void
}

const Posts: React.FC<PostsPropType> = ({
    data,
    isLoading,
    isHideLoader,
    isPostTile,
    isShowAvatar,
    error,
    noDataTitleKey = 'posts_by_user_my_empty',
    noDataTextKey = 'posts_by_user_my_empty_message',
    onLoad,
}) => {
    const { t } = useTranslation()

    return (
        <>
            <ContentBackgroundBottom />

            {!isLoading && !error && (!data?.pages.length || !data.pages[0].length) && (
                <NoDataInfo
                    title={t(noDataTitleKey)}
                    text={t(noDataTextKey)}
                />
            )}

            {!isLoading && error && (
                <ErrorMsg error={error} />
            )}

            <div className={cn(style.container, { [style.container_tiles]: isPostTile })}>
                <InfiniteScroll
                    options={{ rootMargin: '0px 0px 50% 0px' }}
                    isHideLoader={isHideLoader}
                    onLoad={onLoad}
                >
                    {data?.pages.map((page, i) => (
                        /* eslint-disable-next-line */
                        <React.Fragment key={i}>
                            {page.map((post) => (
                                isPostTile ? (
                                    <PostTile
                                        post={post}
                                        isShowAvatar={isShowAvatar}
                                        key={post.id}
                                    />
                                ) : (
                                    <PostCard
                                        classes={style.post}
                                        post={post}
                                        key={post.id}
                                    />
                                )
                            ))}
                        </React.Fragment>
                    ))}
                </InfiniteScroll>
            </div>
        </>
    )
}

export default Posts
