import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { ICountry, IPhoneData } from 'interfaces'
import { PhoneDataFields, SmsCodeField } from 'enums'
import { FormDataType } from 'forms/AuthPhoneForm/AuthPhoneForm'
import {
    USER_SMS_CODE_MASK_CHARS_COUNT,
    ADMIN_SMS_CODE_MASK_CHARS_COUNT,
    GRANT_TYPE_SMS,
    GRANT_TYPE_SMS_BUSINESS,
} from 'config/app'
import { useAppMode } from 'containers/App/hooks'
import * as appSelectors from 'containers/App/app-selectors'
import { AuthPhoneForm } from 'forms'
import { useForm } from 'hooks'
import { AuthService, UserService } from 'services'
import { TValidationFieldRule } from 'utils/validators'
import { getDigitsFromString } from 'utils/helpers'

export type AuthPhoneActionPropType = {
    classes?: string
    isDisabledSubmit?: boolean
    onError?: (message: string) => void
    onEndSmsTimer: () => void
    onCallAuth: (data: IPhoneData) => void
    onComplete?: (data: IPhoneData) => void // требуется аутентификация номера телефона
    onSuccessPhone: () => void // имеется аутентификация данного номера
    onSuccessCode: () => void
}

const FORM_VALIDATION_RULES: TValidationFieldRule[] = [{
    field: PhoneDataFields.phone,
    rules: [{
        rule: 'phone',
        options: {},
        error: '',
    }],
}]

const authService = new AuthService()

const setRulePhoneMask = (mask: string = '', errorText: string = ''): TValidationFieldRule[] => {
    return FORM_VALIDATION_RULES.map((item) => {
        if (item.field === PhoneDataFields.phone) {
            const rules = item.rules.map((rule) => {
                return rule.rule === 'phone' ? { ...rule, options: { mask }, error: errorText } : rule
            })
            return { ...item, rules }
        }
        return item
    })
}

const AuthPhoneAction: React.FC<AuthPhoneActionPropType> = ({
    children,
    classes,
    isDisabledSubmit,
    onError = () => {},
    onEndSmsTimer,
    onCallAuth,
    onComplete = () => {},
    onSuccessPhone = () => {},
    onSuccessCode,
}) => {
    const { t, i18n: { language } } = useTranslation()
    const { appMode, isBusinessMode } = useAppMode()

    const country = useSelector(appSelectors.country)
    const countries = useSelector(appSelectors.countries)

    const [selectedCountry, setSelectedCountry] = useState<ICountry>()
    const [isDisabled, setIsDisabled] = useState(typeof isDisabledSubmit === 'boolean' ? isDisabledSubmit : false)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const [isShowSmsConfirm, setIsShowSmsConfirm] = useState(false)
    const [isDisabledPhone, setIsDisabledPhone] = useState(false)
    const [isDisabledSmsConfirm, setIsDisabledSmsConfirm] = useState(false)

    const userPhoneData = UserService.getPhoneData()

    const {
        formData,
        formErrors,
        setFormValue,
        setFormError,
        validateForm,
    } = useForm<FormDataType>(userPhoneData || {
        [PhoneDataFields.countryId]: 0,
        [PhoneDataFields.countryCode]: '',
        [PhoneDataFields.phone]: '',
        [SmsCodeField.smsCode]: '',
    })

    const prevAuthData = useMemo(() => {
        const { [PhoneDataFields.countryId]: id, [PhoneDataFields.phone]: value } = formData
        const userAuthData = UserService.getAuthData()

        if (id && value && userAuthData?.length) {
            return userAuthData.find(({ appMode: mode, countryId, phone }) => {
                return mode === appMode && countryId === id && phone === value
            })
        }

        return undefined
    }, [formData])

    const buttonSubmitText = useMemo(() => {
        if (isSubmitted && !isDisabled) {
            return t('Send again')
        }

        return t('continue_')
    }, [isDisabled, isSubmitted, language])

    const handlerSubmitPhone = () => {
        setIsSubmitted(true)
        setIsDisabled(true)
        onError('')

        if (prevAuthData) {
            const {
                countryId,
                countryCode,
                phone,
                ...auth
            } = prevAuthData

            AuthService.saveAuthData(auth)
            onSuccessPhone()
        } else {
            const isValid = validateForm(
                { [PhoneDataFields.phone]: formData[PhoneDataFields.phone] },
                true,
                setRulePhoneMask(selectedCountry?.mask, t('Enter your phone number')),
            )

            AuthService.clearAuthData()

            if (formErrors[SmsCodeField.smsCode]) {
                setFormValue(SmsCodeField.smsCode, '')
                setFormError(SmsCodeField.smsCode, false)
            }
            if (isValid) {
                authAction()
            }
        }
    }

    const handlerSubmitCode = (code: string) => {
        confirmSMSAuth(code)
    }

    const handlerChangeCountry = (changedCountry: ICountry) => {
        const { id, code } = changedCountry

        if (selectedCountry?.id !== id) {
            setSelectedCountry(changedCountry)
            setFormValue(PhoneDataFields.countryId, id)
            setFormValue(PhoneDataFields.countryCode, code)
        }
    }

    const handlerChangePhone = (changedPhone: string) => {
        setFormValue(PhoneDataFields.phone, changedPhone)
    }

    const handlerChangeCode = (changedCode: string) => {
        const codeCharsCount = changedCode.length
        setFormValue(SmsCodeField.smsCode, changedCode)
        setFormError(SmsCodeField.smsCode, false)

        if (codeCharsCount === USER_SMS_CODE_MASK_CHARS_COUNT || codeCharsCount === ADMIN_SMS_CODE_MASK_CHARS_COUNT) {
            handlerSubmitCode(changedCode)
        }
    }

    function authAction() {
        setIsDisabledPhone(true)
        setIsDisabled(true)
        authService.auth(appMode, {
            country_id: Number(formData[PhoneDataFields.countryId]),
            country_code: formData[PhoneDataFields.countryCode],
            phone_number: formData[PhoneDataFields.phone],
        })
            .then((res) => {
                if (res && 'access_token' in res && 'refresh_token' in res) {
                    AuthService.saveAuthData({
                        ...res,
                        appMode,
                        grantType: isBusinessMode ? GRANT_TYPE_SMS_BUSINESS : GRANT_TYPE_SMS,
                    })
                    onSuccessPhone()
                }
                if (res && 'error' in res) {
                    const { error, error_description: errorDesc } = res || {}

                    switch (error) {
                        case 'access_denied':
                            /**
                             *  Need sms code from user: show sms code field
                             */
                            if (errorDesc === 'Send SMS') {
                                setIsShowSmsConfirm(true)
                            } else if (errorDesc) {
                                onError(errorDesc)
                            }
                            break
                        default:
                            onError(t('update_error'))
                    }
                }
            })
            .catch((err) => {
                const { error, error_description: errorDesc } = err?.response?.data || {}

                setIsDisabled(false)
                switch (error) {
                    case 'access_denied':
                        if (errorDesc === 'Limit SMS') {
                            onError(t('sms_limit'))
                        } else if (errorDesc) {
                            onError(errorDesc)
                        }
                        break
                    case 'invalid_scope':
                        onError(t('Enter your phone number'))
                        break
                    default:
                        onError(t('update_error'))
                }

                setIsDisabledPhone(false)
            })
    }

    function confirmSMSAuth(code: string) {
        setIsDisabledSmsConfirm(true)
        authService.confirmSMSAuth(appMode, {
            country_id: Number(formData[PhoneDataFields.countryId]),
            country_code: formData[PhoneDataFields.countryCode],
            phone_number: formData[PhoneDataFields.phone],
            validation_code: code,
        })
            .then((res) => {
                if ('error' in res) {
                    const { error, error_description: errorDesc } = res

                    if (error === 'invalid_scope') {
                        setFormError(SmsCodeField.smsCode, errorDesc)
                    } else if (error === 'access_denied') {
                        setFormError(SmsCodeField.smsCode, errorDesc)
                    }

                    setIsDisabledSmsConfirm(false)
                    setIsDisabledPhone(false)
                } else {
                    onSuccessCode()
                }
            })
            .catch((err) => {
                const { error, error_description: errorDesc } = err?.response?.data || {}

                if (error && errorDesc) {
                    if (error === 'invalid_scope') {
                        setFormError(SmsCodeField.smsCode, errorDesc)
                    } else if (error === 'access_denied') {
                        setFormError(SmsCodeField.smsCode, errorDesc)
                    }
                }

                setIsDisabledSmsConfirm(false)
                setIsDisabledPhone(false)
            })
    }

    useEffect(() => {
        const phoneData = UserService.getPhoneData()
        const {
            [PhoneDataFields.countryId]: countryId,
            [PhoneDataFields.countryCode]: countryCode,
            [PhoneDataFields.phone]: phone,
        } = phoneData || {}

        const isPhoneData = countryId && countryCode && phone ? !!getDigitsFromString(phone) : false

        if (!selectedCountry && isPhoneData && countries.length) {
            const prevCountry = countries.find((item) => item.id === countryId)

            if (prevCountry) {
                setSelectedCountry(prevCountry)
            }
        } else if (country) {
            setSelectedCountry(country)
            setFormValue(PhoneDataFields.countryId, country.id)
            setFormValue(PhoneDataFields.countryCode, country.code)
        }
    }, [country])

    useEffect(() => {
        if (typeof isDisabledSubmit === 'boolean') {
            setIsDisabled(isDisabledSubmit)
        }
    }, [isDisabledSubmit])

    useEffect(() => {
        const {
            [PhoneDataFields.countryId]: countryId,
            [PhoneDataFields.countryCode]: countryCode,
            [PhoneDataFields.phone]: phone,
        } = formData

        UserService.savePhoneData({
            [PhoneDataFields.countryId]: countryId,
            [PhoneDataFields.countryCode]: countryCode,
            [PhoneDataFields.phone]: phone,
        })

        if (formData[PhoneDataFields.phone]) {
            // setIsDisabled(false)
        }
        if (formErrors[PhoneDataFields.phone]) {
            setFormError(PhoneDataFields.phone, false)
        }
    }, [formData])

    useEffect(() => {
        if (formData[PhoneDataFields.phone]) {
            setIsDisabled(false)
        }
    }, [formData[PhoneDataFields.phone]])

    return (
        <AuthPhoneForm
            classes={classes}
            data={formData}
            errors={formErrors}
            country={selectedCountry}
            countries={countries}
            buttonText={buttonSubmitText}
            isDisabledPhone={isDisabledPhone}
            isShowSmsConfirm={isShowSmsConfirm}
            isDisabledSmsConfirm={isDisabledSmsConfirm}
            isDisabled={isDisabled}
            onEndSmsTimer={onEndSmsTimer}
            onCallAuth={onCallAuth}
            onChangeCountry={handlerChangeCountry}
            onChangePhone={handlerChangePhone}
            onChangeCode={handlerChangeCode}
            onSubmit={handlerSubmitPhone}
        >
            {children}
        </AuthPhoneForm>
    )
}

export default AuthPhoneAction
