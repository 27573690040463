import React, { useMemo } from 'react'
import {
    useHistory,
    useLocation,
    NavLink,
    matchPath,
    generatePath,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IStoreProfile } from 'interfaces'
import { APP_URL } from 'config/app'
import { InfoBlock, Avatar, Tabs } from 'components'

import styleLabel from 'styles/modules/label.module.css'
import style from './StoreNav.module.css'

type StoreNavPropType = {
    storeId: number
    catalogId?: number
    data?: Partial<IStoreProfile>
    basketCounts?: number
    activeId?: number
    onChange?: (id: number, extra?: any) => void
}

const StoreNav: React.FC<StoreNavPropType> = ({
    storeId,
    catalogId,
    data = {},
    basketCounts = 0,
    activeId,
    onChange = () => {},
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { pathname } = useLocation()

    const tabs = useMemo(() => [{
        id: 1,
        name: t('catalog'),
        extra: {
            path: APP_URL.store,
        },
    }, {
        id: 2,
        name: t('basket'),
        extra: {
            path: APP_URL.basket,
        },
    }, {
        id: 3,
        name: t('orders'),
        extra: {
            path: APP_URL.orders,
        },
    }], [basketCounts])

    const activeTabId = useMemo(getActiveTab, [pathname])
    const profileUrl = useMemo(() => generatePath(APP_URL.storeProfile, { id: storeId }), [storeId])

    const handlerChangeTab = (tabId: number, extra?: any) => {
        onChange(tabId, extra)

        if (extra?.path) {
            history.push(generatePath(extra.path, { id: storeId }))
        }
    }

    function getActiveTab() {
        if (activeId) {
            return activeId
        }
        if (catalogId) {
            return 1
        }

        const activeItem = tabs.find((item) => {
            return item?.extra.path
                ? !!matchPath(pathname, { path: item.extra.path, exact: true })
                : false
        })

        if (activeItem) {
            return activeItem.id
        }

        return 0
    }

    return (
        <InfoBlock classes={style.block}>
            <div className={style.labels}>
                {data.kick_loyalty_settings && (
                    <div className={cn(styleLabel.label, styleLabel.labelWhite)}>
                        {data.kick_loyalty_settings}
                    </div>
                )}
                {data.kick_max_loyalty_settings && (
                    <div className={cn(styleLabel.label, styleLabel.labelBlue)}>
                        {data.kick_max_loyalty_settings}
                    </div>
                )}
            </div>
            <InfoBlock.Body classes={style.body}>
                <NavLink to={profileUrl}>
                    <Avatar
                        classes={style.image}
                        src={data.icon_image || data.logo_image}
                        isResize={!data.icon_image}
                        width={54}
                        height={54}
                    />
                </NavLink>
                <div className={style.info}>
                    <NavLink className={style.infoTitle} to={profileUrl}>
                        {data.name}
                    </NavLink>
                    <div className={style.infoDesc}>
                        {data.sub_title}
                    </div>
                </div>
            </InfoBlock.Body>
            <InfoBlock.Footer classes={style.footer}>
                <Tabs
                    classes={style.tabs}
                    classesItem={style.tabsItem}
                    items={tabs}
                    active={activeTabId}
                    onChange={handlerChangeTab}
                />
            </InfoBlock.Footer>
        </InfoBlock>
    )
}

export default StoreNav
