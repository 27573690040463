import React from 'react'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    Button,
    Input,
    Tooltip,
} from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './ReportsPaymentPresetForm.module.css'

// FIXME on ready backend
export enum FieldName {
    id = 'id',
    name = 'name',
}

export type FormDataType = {
    [FieldName.id]?: number
    [FieldName.name]: string
}

type ShopGoodsFormPropType = {
    classes?: string
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.name]: '',
}

const ReportsPaymentPresetForm: React.FC<ShopGoodsFormPropType> = ({
    classes,
    isSubmitting,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { hasValidationErrors } = useFormState<FormDataType>()

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                    <div className={styleForm.groupItem}>
                        <div className={styleForm.fieldHead}>
                            {t('reports_presets_add')}
                        </div>
                    </div>
                    <div className={cn(
                        styleForm.groupItem,
                        styleForm.groupItem_maxWidth,
                        style.groupItem,
                        style.groupItem_flex,
                    )}
                    >
                        <Tooltip
                            classes={style.tooltip}
                            position="left"
                            tooltip={(
                                <div className={cn(style.tooltipHint, style.tooltipHint_widthAuto)}>
                                    {t('reports_presets_add_hint')}
                                </div>
                            )}
                        >
                            <Tooltip.TriggerInfo />
                        </Tooltip>
                    </div>
                </div>
                <Field
                    <FormDataType[FieldName.name]>
                    name={FieldName.name}
                    render={({ input, meta }) => (
                        <div className={styleForm.field}>
                            <Input
                                {...input}
                                classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                styleType="defaultBusiness"
                            />
                            <div className={styleForm.error}>
                                {meta.dirty && meta.error}
                            </div>
                        </div>
                    )}
                />
            </div>
            <div className={cn(styleForm.controls, styleForm.controls_32, style.actions)}>
                <Button
                    classes={style.action}
                    type="submit"
                    size="size46"
                    text={t('save')}
                    disabled={hasValidationErrors}
                />
            </div>
        </form>
    )
}

export default ReportsPaymentPresetForm
