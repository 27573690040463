import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { APP_URL } from 'config/app'
import { RegistrationFormWrap } from 'containers/Registration/components'
import * as userSelectors from 'containers/User/user-selectors'
import { RegistrationUserAction } from 'form-actions'
import { getURLSearchParams } from 'utils/helpers'

const RegistrationUser: React.FC = () => {
    const history = useHistory()

    const user = useSelector(userSelectors.user)

    const [guarantorCode, setGuarantorCode] = useState('')

    useEffect(() => {
        const { id, invite } = getURLSearchParams(history.location.search)

        if (!user) {
            history.push(APP_URL.auth)
        }
        if (id || invite) {
            setGuarantorCode(id || invite)
        }
    }, [])

    return (
        <RegistrationFormWrap>
            <RegistrationUserAction
                guarantorCode={guarantorCode}
            />
        </RegistrationFormWrap>
    )
}

export default RegistrationUser
