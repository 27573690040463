import React from 'react'
import cn from 'classnames'

import style from './Switch.module.css'

type SwitchPropType = {
    classes?: string
    classesSwitch?: string
    classesSwitchActive?: string
    classesControl?: string
    classesControlActive?: string
    name: string
    checked?: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Switch: React.FC<SwitchPropType> = ({
    classes,
    classesSwitch,
    classesSwitchActive,
    classesControl,
    classesControlActive,
    name,
    checked,
    onChange,
}) => {
    return (
        <label className={cn(style.label, classes)}>
            <input
                className={style.input}
                type="checkbox"
                name={name}
                defaultChecked={!!checked}
                onChange={onChange}
            />
            <span className={cn(
                style.switch,
                classesSwitch,
                classesSwitchActive && checked ? classesSwitchActive : undefined,
            )}
            />
            <span className={cn(
                style.control,
                { [style.control_active]: checked },
                classesControl,
                classesControlActive && checked ? classesControlActive : undefined,
            )}
            />
        </label>
    )
}

export default Switch
