import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICountry, ICity } from 'interfaces'
import { validationFormErrorsType } from 'utils/validators'
import {
    Input,
    Button,
    CountryDataSelect,
    CityDataSelect,
} from 'components'
import { isDataUrl, maskNormalizer } from 'utils/helpers'

import styleForm from 'styles/modules/form.module.css'
import style from '../RegistrationUserForm/RegistrationUserForm.module.css'

export type FormDataType = {
    name: string
    juridical_name: string
    country_id: number
    city: number
    phone_number: string
    email: string
    site?: string
    logo?: string // base64
}

export type RegistrationCompanyFormPropType = {
    data: FormDataType
    errors: validationFormErrorsType
    country?: ICountry
    city?: ICity
    countries: ICountry[]
    isSubmitting: boolean
    isDisabled: boolean
    onChangeCountry: (data: ICountry) => void
    onChangeCity: (data: ICity) => void
    onChangePhone: (data: string) => void
    onChangePhoto: (data: File) => void
    onChange: (name: string, value: string) => void
    onSubmit: () => void
}

const RegistrationCompanyForm: React.FC<RegistrationCompanyFormPropType> = ({
    data,
    errors,
    country,
    city,
    countries,
    isSubmitting,
    isDisabled,
    onChangeCountry,
    onChangeCity,
    onChangePhone,
    onChangePhoto,
    onChange,
    onSubmit,
}) => {
    const { logo } = data

    const { t } = useTranslation()

    const avatarPreview: string = useMemo(() => {
        if (logo) {
            return isDataUrl(logo) ? logo : `data:image/png;base64,${logo}`
        }

        return ''
    }, [logo])

    const phoneMask = useMemo(() => {
        return country?.mask ? maskNormalizer(country.mask) : undefined
    }, [country])

    const handlerSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit()
    }

    const handlerChangePhoto = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = target
        const [file] = files || []

        onChangePhoto(file)
    }

    const handlerChangeFormData = ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = currentTarget
        onChange(name, value)
    }

    const handlerAcceptPhone = ({ value }: HTMLInputElement) => {
        onChangePhone(value)
    }

    const handlerChangePhone = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        onChangePhone(currentTarget.value)
    }

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={handlerSubmit}>
            {avatarPreview && (
                <div className={cn(styleForm.row, styleForm.row_12)}>
                    <img
                        className={style.avatarPreview}
                        src={avatarPreview}
                        alt=""
                    />
                </div>
            )}

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    type="file"
                    styleType="default"
                    onChange={handlerChangePhoto}
                />
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={cn({ [styleForm.invalid]: errors.name })}
                    name="name"
                    placeholder={`${t('company_registration_name')} *`}
                    value={data.name}
                    onChange={handlerChangeFormData}
                />
                {errors.name && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.name}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={cn({ [styleForm.invalid]: errors.juridical_name })}
                    name="juridical_name"
                    placeholder={`${t('company_registration_name_official')} *`}
                    value={data.juridical_name}
                    onChange={handlerChangeFormData}
                />
                {errors.juridical_name && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.juridical_name}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <div className={cn(
                    style.group,
                    styleForm.group,
                    styleForm.group_alignCenter,
                    { [styleForm.invalid]: errors.phone },
                )}
                >
                    <CountryDataSelect
                        isShowSelectArrow
                        classes={cn(
                            styleForm.groupItem,
                            styleForm.groupItem_static,
                            styleForm.groupItem_minWidth,
                        )}
                        classesField={style.selectField}
                        countries={countries}
                        selected={country}
                        onChange={onChangeCountry}
                    />

                    {country && (
                        <>
                            <div className={style.fieldCountryCode}>
                                {country?.code}
                            </div>

                            {phoneMask ? (
                                <Input
                                    focus
                                    classes={style.input}
                                    styleType="clear"
                                    type="tel"
                                    name="phone"
                                    placeholder={`${t('company_registration_phone')} *`}
                                    mask={phoneMask}
                                    value={data.phone_number}
                                    onAccept={handlerAcceptPhone}
                                />
                            ) : (
                                <Input
                                    classes={style.input}
                                    styleType="clear"
                                    type="tel"
                                    maxLength={30}
                                    name="phone"
                                    value={data.phone_number}
                                    onChange={handlerChangePhone}
                                />
                            )}
                        </>
                    )}
                </div>
                {errors.phone_number && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.phone_number}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <CityDataSelect
                    classes={cn(
                        styleForm.group,
                        style.group,
                        { [styleForm.invalid]: errors.city },
                    )}
                    classesField={style.selectField}
                    countryId={country?.id}
                    countryLang={country?.lang}
                    selected={city}
                    placeholder={`${t('select_city')} *`}
                    onChange={onChangeCity}
                />
                {errors.city && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.city}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={cn({ [styleForm.invalid]: errors.email })}
                    name="email"
                    placeholder={`${t('company_registration_email')} *`}
                    value={data.email}
                    onChange={handlerChangeFormData}
                />
                {errors.email && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.email}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_12)}>
                <Input
                    classes={cn({ [styleForm.invalid]: errors.site })}
                    name="site"
                    placeholder={t('company_registration_site')}
                    value={data.site}
                    onChange={handlerChangeFormData}
                />
                {errors.site && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.site}
                    </div>
                )}
            </div>

            <div className={style.controls}>
                <Button
                    text={t('Сomplete the registration')}
                    size="size44"
                    type="submit"
                    disabled={isDisabled}
                />
            </div>
        </form>
    )
}

export default RegistrationCompanyForm
