import React, { useState, useMemo, useRef } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICatalogItem } from 'interfaces'
import {
    CatalogTree,
    Cropper,
    Input,
    Button,
    Modal,
    FileDrop,
    Tooltip,
    SvgResource,
} from 'components'
import { ImagePreview } from 'forms/StoreForm/components'
import { useAlertDialog, useUploadFiles } from 'hooks'
import styleForm from 'styles/modules/form.module.css'
import style from './ShopFolderForm.module.css'

export enum FieldName {
    id = 'id',
    name = 'name',
    parent = 'parent',
    code = 'code',
    order = 'order',
    photo = 'photo',
    remove_photo = 'remove_photo'
}

export type FormDataType = {
    [FieldName.id]?: number
    [FieldName.name]: string
    [FieldName.parent]: number
    [FieldName.order]?: number
    [FieldName.photo]?: string
    [FieldName.remove_photo]?: boolean
}

type ShopFolderPropType = {
    classesBody?: string
    classesFooter?: string
    isSubmitting: boolean
    isCanDelete?: boolean
    catalog?: ICatalogItem[]
    onPreview?: () => void
    onError?: (err: any) => void
    onChange?: (data: FormDataType) => void
    onRemove: (data: FormDataType) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const ACCEPT_IMAGE = 'image/jpeg,image/png'
const IMAGE_ASPECT_RATIO = 1.33

export const formDataInitial: FormDataType = {
    [FieldName.name]: '',
    [FieldName.parent]: 0,
}

const ShopFolderForm: React.FC<ShopFolderPropType> = ({
    classesBody,
    classesFooter,
    isSubmitting,
    isCanDelete,
    catalog,
    onPreview,
    onError,
    onChange,
    onRemove,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const { showAlertDialog } = useAlertDialog()

    const inputFileRef = useRef<HTMLInputElement>(null)
    const [image, setImage] = useState<string>() // base64
    const [imageCrop, setImageCrop] = useState<string>() // base64
    const [isActiveFileDropzone, setIsActiveFileDropzone] = useState(false)
    const [isOpenModalCatalog, setIsOpenModalCatalog] = useState(false)
    const [isOpenModalCropper, setIsOpenModalCropper] = useState(false)

    const { change } = useForm<FormDataType>()
    const { values, hasValidationErrors } = useFormState<FormDataType>({
        onChange: (formState) => {
            if (onChange) {
                onChange(formState.values)
            }
        },
    })

    const parent = useMemo(() => {
        return catalog && values[FieldName.parent]
            ? catalog.find((item) => item.id === values[FieldName.parent])
            : undefined
    }, [values[FieldName.parent]])

    const { upload: uploadFiles } = useUploadFiles()

    const handlerClickCategory = (value: ICatalogItem) => {
        change(FieldName.parent, value.id)
        setIsOpenModalCatalog(false)
    }

    const handlerAddFiles = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = currentTarget

        if (files?.length) {
            uploadFilesAction(files)
        }
    }

    const handlerDropFiles = (files: FileList) => {
        if (files?.length) {
            uploadFilesAction(files)
        }
    }

    const handlerClickDropzone = () => {
        if (inputFileRef.current) {
            inputFileRef.current.click()
        }
    }

    const handlerCrop = (data: string) => {
        setImageCrop(`data:image/jpg;base64,${data}`)
    }

    const handlerSaveImage = () => {
        change(FieldName.photo, imageCrop)
        change(FieldName.remove_photo, undefined)
        setIsOpenModalCropper(false)
    }

    const handlerDragOverFiles = () => {
        setIsActiveFileDropzone(true)
    }

    const handlerRemovePhoto = () => {
        change(FieldName.photo, undefined)
        change(FieldName.remove_photo, true)
        setIsActiveFileDropzone(false)
    }

    const handlerRemoveCategory = (value: FormDataType) => {
        showAlertDialog({
            onConfirm: () => {
                onRemove(value)
            },
        })
    }

    function uploadFilesAction(files: FileList) {
        uploadFiles(files, { isUpload: false })
            .then(([data]) => {
                const { image: imageEl, imageBase64 } = data

                if (imageEl && imageBase64) {
                    const aspectRatio = Number((imageEl.width / imageEl.height).toFixed(2))

                    if (aspectRatio !== IMAGE_ASPECT_RATIO) {
                        setImage(imageBase64)
                        setIsOpenModalCropper(true)
                    } else {
                        change(FieldName.photo, imageBase64)
                        change(FieldName.remove_photo, undefined)
                    }
                }
            })
            .catch((err) => {
                if (onError) {
                    onError(err)
                }
            })
    }

    return (
        <>
            <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <div className={classesBody}>
                    <div className={cn(styleForm.row, styleForm.row_24)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={styleForm.fieldHead}>
                                    {t('dialog_pm_add_category')}
                                </div>
                            </div>
                            <div className={cn(
                                styleForm.groupItem,
                                styleForm.groupItem_maxWidth,
                                style.groupItem,
                                style.groupItem_flex,
                            )}
                            >
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {t('catatlogue_parent_hint')}
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            name={FieldName.parent}
                            render={({ input, meta }) => (
                                <div className={styleForm.field}>
                                    <Input
                                        {...input}
                                        styleType="defaultBusiness"
                                        value={parent?.name ?? ''}
                                        disabled={!parent}
                                        onFocus={(e) => {
                                            setIsOpenModalCatalog(true)
                                            e.currentTarget.blur()
                                        }}
                                        onChange={() => {}}
                                    />
                                    <SvgResource
                                        classes={style.fieldArrow}
                                        resourceKey="dropdpwn_daw_svg"
                                        isImgTag={false}
                                        width={18}
                                        height={11}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_24)}>
                        <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                            <div className={styleForm.groupItem}>
                                <div className={styleForm.fieldHead}>
                                    {t('catalog_code')}
                                </div>
                            </div>
                            <div className={cn(
                                styleForm.groupItem,
                                styleForm.groupItem_maxWidth,
                                style.groupItem,
                                style.groupItem_flex,
                            )}
                            >
                                <Tooltip
                                    classes={style.tooltip}
                                    position="right"
                                    tooltip={(
                                        <div className={style.tooltipHint}>
                                            {t('catalogue_code_hint')}
                                        </div>
                                    )}
                                >
                                    <Tooltip.TriggerInfo />
                                </Tooltip>
                            </div>
                        </div>
                        <Field
                            name={FieldName.code}
                            render={({ input, meta }) => (
                                <div className={styleForm.field}>
                                    <Input
                                        {...input}
                                        classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                        styleType="defaultBusiness"
                                    />
                                    <div className={styleForm.error}>
                                        {meta.dirty && meta.error}
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_24)}>
                        <div className={cn(styleForm.group, styleForm.group_fullWidth)}>
                            <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth, style.groupItem)}>
                                <div className={styleForm.fieldHead}>
                                    {t('screen_pm_name_title')}
                                </div>
                                <Field
                                    name={FieldName.name}
                                    render={({ input, meta }) => (
                                        <div className={styleForm.field}>
                                            <Input
                                                {...input}
                                                classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                                styleType="defaultBusiness"
                                            />
                                            <div className={styleForm.error}>
                                                {meta.dirty && meta.error}
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className={cn(styleForm.groupItem, style.groupItem)}>
                                <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                                    <div className={styleForm.groupItem}>
                                        <div className={styleForm.fieldHead}>
                                            {t('pm_sorting_title')}
                                        </div>
                                    </div>
                                    <div className={cn(
                                        styleForm.groupItem,
                                        styleForm.groupItem_maxWidth,
                                        style.groupItem,
                                        style.groupItem_flex,
                                    )}
                                    >

                                        <Tooltip
                                            classes={style.tooltip}
                                            position="right"
                                            tooltip={(
                                                <div className={cn(style.tooltipHint, style.tooltipHint_widthAuto)}>
                                                    {t('number_sort_hint')}
                                                </div>
                                            )}
                                        >
                                            <Tooltip.TriggerInfo />
                                        </Tooltip>
                                    </div>
                                </div>
                                <Field
                                    name={FieldName.order}
                                    render={({ input, meta }) => (
                                        <div className={styleForm.field}>
                                            <Input
                                                {...input}
                                                classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                                styleType="defaultBusiness"
                                                inputMode="numeric"
                                                pattern="[0-9]*"
                                                onChange={(e) => {
                                                    if (e.currentTarget.validity.valid) {
                                                        input.onChange(e.currentTarget.value)
                                                    }
                                                }}
                                            />
                                            <div className={styleForm.error}>
                                                {meta.dirty && meta.error}
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_24)}>
                        <Field
                            <FormDataType[FieldName.photo]>
                            name={FieldName.photo}
                            render={({ input }) => (
                                <div className={styleForm.field}>
                                    <label className={cn(styleForm.group, style.fieldLabel)}>
                                        <Input
                                            classes={styleForm.inputFile}
                                            styleType="clear"
                                            type="file"
                                            accept={ACCEPT_IMAGE}
                                            name={input.name}
                                            ref={inputFileRef}
                                            onChange={handlerAddFiles}
                                        />
                                        <div className={styleForm.fieldHead}>
                                            {t('screen_pm_add_photo')}
                                        </div>
                                        <div className={style.iconAddPhoto} />
                                    </label>
                                    <FileDrop
                                        classes={cn(style.fileDropzone, {
                                            [style.fileDropzone_active]: isActiveFileDropzone,
                                            [style.fileDropzone_clickable]: !input.value,
                                        })}
                                        onDragLeave={() => setIsActiveFileDropzone(false)}
                                        onDragOver={handlerDragOverFiles}
                                        onDrop={handlerDropFiles}
                                        onClick={!input.value ? handlerClickDropzone : undefined}
                                    >
                                        {input.value ? (
                                            <ImagePreview
                                                src={input.value}
                                                onDelete={handlerRemovePhoto}
                                            />
                                        ) : (
                                            <div className={cn(style.fileDropzoneText, {
                                                [style.fileDropzoneText_active]: isActiveFileDropzone,
                                            })}
                                            >
                                                {t('dropzone_hint')}
                                            </div>
                                        )}
                                    </FileDrop>
                                </div>
                            )}
                        />
                    </div>
                </div>
                <div className={cn(styleForm.controls_32, style.actions, classesFooter)}>
                    {onPreview && (
                        <Button
                            classes={style.action}
                            styleType="bordered"
                            size="size46"
                            text={t('preview')}
                            onClick={onPreview}
                        />
                    )}
                    {isCanDelete && values[FieldName.id] && (
                        <Button
                            classes={style.action}
                            styleType="bordered"
                            size="size46"
                            text={t('delete')}
                            onClick={() => handlerRemoveCategory(values)}
                        />
                    )}
                    <Button
                        classes={style.action}
                        type="submit"
                        size="size46"
                        text={t('save')}
                        disabled={hasValidationErrors}
                    />
                </div>
            </form>

            <Modal
                isOpen={isOpenModalCatalog}
                size="smallCenter"
                onClose={() => setIsOpenModalCatalog(false)}
            >
                <Modal.Header
                    isCloseButton
                    classesTitle={style.modalCatalogTitle}
                    title={t('screen_pm_select_category')}
                    titlePos="left"
                />
                <Modal.Body classes={style.modalCatalogBody}>
                    {catalog && (
                        <CatalogTree
                            data={catalog}
                            onClick={handlerClickCategory}
                        />
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                classes={style.modalCrop}
                isOpen={isOpenModalCropper}
                size="fullWidth"
                onClose={() => {
                    if (inputFileRef.current) {
                        /** fix chrome: reset input value on close modal */
                        inputFileRef.current.value = ''
                    }
                    setIsOpenModalCropper(false)
                }}
            >
                <Modal.Body classes={style.modalCropBody}>
                    <Cropper
                        className={style.cropper}
                        aspectRatio={IMAGE_ASPECT_RATIO}
                        source={image}
                        guides={false}
                        center={false}
                        onCrop={handlerCrop}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="submit"
                        size="size46"
                        text={t('save')}
                        onClick={handlerSaveImage}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShopFolderForm
