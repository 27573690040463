import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IUserUpdateProps } from 'interfaces'
import { FormDataType, FieldName, formDataInitial } from 'forms/UserForm/UserForm'
import { REGEXP_EMAIL } from 'config/app'
import { updateUser, appLogoutUser } from 'containers/User/user-actions'
import { UserForm } from 'forms'
import { TranslationService, UserService } from 'services'
import { useThunkDispatch, useAlertDialog } from 'hooks'
import { getRequestError, showAlertNotify } from 'utils/helpers'
import { useAppSelector } from 'store'

export type UserActionPropType = {}

const UserAction: React.FC<UserActionPropType> = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const thunkDispatch = useThunkDispatch()
    const { showAlertDialog } = useAlertDialog()

    const user = useAppSelector((state) => state.user)
    const countries = useAppSelector((state) => state.app.countries)
    const languages = useAppSelector((state) => state.app.languages)

    const [initialValues] = useState<FormDataType>({
        ...formDataInitial,
        [FieldName.name]: user.name,
        [FieldName.surname]: user.surname,
        [FieldName.email]: user.email,
        [FieldName.profileStatus]: user.profileStatus,
        [FieldName.birthDate]: user.birth_date,
        [FieldName.gender]: user.gender,
        [FieldName.photo]: user.photo, // url
        [FieldName.city]: user.city?.id,
        [FieldName.language]: user.language?.id,
    })

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handlerSubmit = (params: FormDataType) => {
        updateUserAction(params)
    }

    const handlerClickDeleteUser = () => {
        showAlertDialog({
            isSetPerformText: false,
            isSetQuestionText: false,
            message: t('remove_account_message'),
            onConfirm: deleteUserAction,
        })
    }

    function updateUserAction(params: IUserUpdateProps) {
        setIsSubmitting(true)
        thunkDispatch(updateUser(params))
            .then((userData) => {
                if (userData) {
                    TranslationService.changeAppLanguage(userData.language.lang_short)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function deleteUserAction() {
        UserService.deleteUser()
            .then(() => {
                dispatch(appLogoutUser({ isRemoveAuthData: true }))
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.name]) {
                    errors[FieldName.name] = t('error_field_is_empty')
                }
                if (!values[FieldName.surname]) {
                    errors[FieldName.surname] = t('error_field_is_empty')
                }
                if (!values[FieldName.email]) {
                    errors[FieldName.email] = t('error_field_is_empty')
                }
                if (values[FieldName.email] && !REGEXP_EMAIL.test(values[FieldName.email])) {
                    errors[FieldName.email] = t('invalid_email')
                }
                if (!values[FieldName.city]) {
                    errors[FieldName.city] = t('select_city')
                }

                return errors
            }}
            render={({ handleSubmit }) => (
                <UserForm
                    countries={countries}
                    country={user.country}
                    city={user.city}
                    languages={languages}
                    language={user.language}
                    user={user}
                    isSubmitting={isSubmitting}
                    onDeleteUser={handlerClickDeleteUser}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default UserAction
