import React from 'react'
import { TransitionGroup as ReactTransitionGroup } from 'react-transition-group'
import { TransitionGroupProps } from 'react-transition-group/TransitionGroup'

export type TransitionGroupPropType<T extends keyof JSX.IntrinsicElements = 'div'> = {
    classes?: string
} & Omit<TransitionGroupProps<T>, 'classNames'>

const TransitionGroup: React.FC<TransitionGroupPropType> = ({ children, classes, ...props }) => {
    return (
        <ReactTransitionGroup className={classes} {...props}>
            {children}
        </ReactTransitionGroup>
    )
}

export default TransitionGroup
