import React from 'react'
import { Form } from 'react-final-form'

import { FormDataType, formDataInitial } from 'forms/WarehouseSuppliesFilterForm/WarehouseSuppliesFilterForm'
import { WarehouseSuppliesFilterForm } from 'forms'

type WarehouseFilterActionPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    onSuccess: (value: FormDataType) => void
}

const WarehouseSuppliesFilterAction: React.FC<WarehouseFilterActionPropType> = ({
    classes,
    classesBody,
    classesFooter,
    onSuccess,
}) => {
    const handlerSubmit = (params: FormDataType) => {
        onSuccess(params)
    }

    return (
        <Form
            initialValues={formDataInitial}
            onSubmit={handlerSubmit}
            render={({ handleSubmit }) => (
                <WarehouseSuppliesFilterForm
                    classes={classes}
                    classesBody={classesBody}
                    classesFooter={classesFooter}
                    isSubmitting={false}
                    stores={[]}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default WarehouseSuppliesFilterAction
