import React, { useEffect, useMemo, useState } from 'react'
import {
    Switch,
    Route,
    Redirect,
    useLocation,
    matchPath,
    generatePath,
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import { useAppMode } from 'containers/App/hooks'
import { PageHeader, LinkBlock, CardV2 } from 'components'
import { useWindowResize } from 'hooks'
import { scrollTop } from 'utils/helpers'
import ReportsPayment from './ReportsPayment/ReportsPayment'
import ReportsProduct from './ReportsProduct/ReportsProduct'
import ReportsIndicator from './ReportsIndicator/ReportsIndicator'

enum Report {
    payment = 'payment',
    product = 'product',
    indicator = 'indicator',
}

type TReport = {
    id: number
    name: string
    url: string
}

const Reports: React.FC = () => {
    const { t } = useTranslation()
    const { pathname } = useLocation()
    const [windowWidth] = useWindowResize()
    const { isUserMode, isBusinessMode } = useAppMode()

    const [reportsData] = useState<Record<keyof typeof Report, TReport>>({
        [Report.payment]: { id: 1, name: t('reports_payment'), url: generatePath(APP_URL.reportsPayment) },
        [Report.product]: { id: 2, name: t('reports_product'), url: generatePath(APP_URL.reportsProduct) },
        [Report.indicator]: { id: 3, name: t('reports_indicator'), url: generatePath(APP_URL.reportsIndicator) },
    })

    const reports = useMemo(() => {
        if (isBusinessMode) {
            return [
                reportsData[Report.payment],
                // FIXME on ready backend
                // reportsData[Report.product],
                reportsData[Report.indicator],
            ]
        }
        if (isUserMode) {
            return [reportsData[Report.indicator]]
        }

        return []
    }, [])

    const isParentReports = useMemo(() => {
        return !!matchPath(pathname, { path: APP_URL.reports, exact: true })
    }, [pathname])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.desktop) {
            return CardSize.thirdWidth
        }
        if (windowWidth > BREAKPOINTS.tablet) {
            return CardSize.halfWidth
        }

        return CardSize.fullWidth
    }, [windowWidth])

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <>
            {isParentReports ? (
                <ContentContainer size="three-quarters">
                    <PageHeader
                        title={t('reports')}
                    />
                    <CardContainer>
                        {reports.map((item) => (
                            <CardV2
                                size={itemSize}
                                url={item.url}
                                key={item.id}
                            >
                                <LinkBlock>
                                    {item.name}
                                </LinkBlock>
                            </CardV2>
                        ))}
                    </CardContainer>
                </ContentContainer>
            ) : (
                <Switch>
                    {isBusinessMode && (
                        <Route exact path={APP_URL.reportsPayment} component={ReportsPayment} />
                    )}
                    {isBusinessMode && (
                        <Route exact path={APP_URL.reportsProduct} component={ReportsProduct} />
                    )}
                    <Route exact path={APP_URL.reportsIndicator} component={ReportsIndicator} />
                    <Redirect from="*" to={APP_URL.reports} />
                </Switch>
            )}
        </>
    )
}

export default Reports
