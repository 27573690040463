import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useForm, useField, Field } from 'react-final-form'
import { useDebouncedCallback } from 'use-debounce'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICountry, ICity } from 'interfaces'
import { TRangeValue } from 'components/MultiRangeSlider/MultiRangeSlider'
import { FriendFilterSortBy } from 'enums'
import {
    Button,
    Checkbox,
    Radio,
    CountryDataSelect,
    CityDataSelect,
    MultiRangeSlider,
} from 'components'
import * as appSelectors from 'containers/App/app-selectors'
import { getId } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './ClientFilterForm.module.css'

export enum FieldName {
    country = 'country',
    city = 'city',
    gender = 'gender',
    age_from = 'age_from',
    age_to = 'age_to',
    is_with_photo_only = 'is_with_photo_only',
    sort_by = 'sort_by',
}

export type FormDataType = {
    [FieldName.country]?: number
    [FieldName.city]?: number
    [FieldName.gender]?: 1 | 0
    [FieldName.age_from]?: number
    [FieldName.age_to]?: number
    [FieldName.is_with_photo_only]?: 1 | 0
    [FieldName.sort_by]?: keyof typeof FriendFilterSortBy
}

type ClientFilterFormPropType = {
    classes?: string
    initCityData?: ICity
    ageRangeMin: number
    ageRangeMax: number
    isSubmitting: boolean
    onReset: () => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.country]: undefined,
    [FieldName.city]: undefined,
    [FieldName.gender]: undefined,
    [FieldName.age_from]: undefined,
    [FieldName.age_to]: undefined,
    [FieldName.is_with_photo_only]: undefined,
    [FieldName.sort_by]: undefined,
}

const ClientFilterForm: React.FC<ClientFilterFormPropType> = ({
    classes,
    initCityData,
    ageRangeMin,
    ageRangeMax,
    isSubmitting,
    onReset,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const debouncedChangeAge = useDebouncedCallback(changeAge, 500)

    const countries = useSelector(appSelectors.countries)

    const { getState, reset } = useForm<FormDataType>()

    const [formInitialValues] = useState(getState().initialValues)
    const [sliderRangeKey, setSliderRangeKey] = useState(getId())

    const [country, setCountry] = useState<ICountry | undefined>(formInitialValues[FieldName.country]
        ? countries.find((item) => item.id === formInitialValues[FieldName.country])
        : undefined)

    const [city, setCity] = useState<ICity>()

    const [rangeAge, setRangeAge] = useState<TRangeValue>({
        from: formInitialValues[FieldName.age_from] ?? ageRangeMin,
        to: formInitialValues[FieldName.age_to] ?? ageRangeMax,
    })

    const { input: inputAgeFrom } = useField<number>(FieldName.age_from)
    const { input: inputAgeTo } = useField<number>(FieldName.age_to)

    const handlerChangeRangeAge = (value: TRangeValue) => {
        setRangeAge(value)
        debouncedChangeAge(value)
    }

    const handlerReset = () => {
        setCountry(undefined)
        setCity(undefined)
        setRangeAge({ from: ageRangeMin, to: ageRangeMax })
        setSliderRangeKey(getId())
        reset(formDataInitial)
        onReset()
    }

    function changeAge({ from, to }: TRangeValue) {
        inputAgeFrom.onChange(from)
        inputAgeTo.onChange(to)
    }

    useEffect(() => {
        if (initCityData) {
            setCity(initCityData)
        }
    }, [initCityData])

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_24)}>
                <Field
                    name={FieldName.country}
                    render={({ input }) => (
                        <CountryDataSelect
                            isCanEmpty
                            isShowSelectArrow
                            classesField={style.fieldSelect}
                            countries={countries}
                            selected={country}
                            selectedIsIcon={false}
                            placeholder={t('country')}
                            onChange={(value) => {
                                setCountry(value)
                                return input.onChange(value.id)
                            }}
                        />
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_24, styleForm.row_32)}>
                <Field
                    name={FieldName.city}
                    render={({ input }) => (
                        <CityDataSelect
                            classesField={style.fieldSelect}
                            countryId={country?.id}
                            countryLang={country?.isoCode}
                            selected={city}
                            placeholder={t('city')}
                            disabled={!country}
                            onChange={(value) => {
                                setCity(value)
                                return input.onChange(value.id)
                            }}
                        />
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_24, styleForm.row_32)}>
                <div className={style.fieldText}>
                    {t('gender')}
                </div>
                <div className={style.switchBox}>
                    <Field
                        name={FieldName.gender}
                        render={({ input }) => (
                            <Button
                                classes={cn(style.switch, { [style.switch_active]: input.value === '' })}
                                size="size32"
                                text={t('any')}
                                onClick={() => input.onChange('')}
                            />
                        )}
                    />
                    <Field
                        name={FieldName.gender}
                        render={({ input }) => (
                            <Button
                                classes={cn(style.switch, { [style.switch_active]: input.value === 1 })}
                                size="size32"
                                text={t('male')}
                                onClick={() => input.onChange(1)}
                            />
                        )}
                    />
                    <Field
                        name={FieldName.gender}
                        render={({ input }) => (
                            <Button
                                classes={cn(style.switch, { [style.switch_active]: input.value === 0 })}
                                size="size32"
                                text={t('female')}
                                onClick={() => input.onChange(0)}
                            />
                        )}
                    />
                </div>
            </div>
            <div className={cn(
                styleForm.row,
                styleForm.row_32,
                styleForm.row_vAlignCenter,
                styleForm.row_jSpaceBetween,
            )}
            >
                <div className={style.fieldText}>
                    {t('search_filter_has_avatar')}
                </div>
                <Field
                    name={FieldName.is_with_photo_only}
                    render={({ input }) => (
                        <Checkbox
                            checked={Boolean(input.value)}
                            name={input.name}
                            onChange={(e) => input.onChange(Number(e.target.checked))}
                        />
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_32)}>
                <div className={style.fieldText}>
                    {t('age')}
                </div>
                <div className={style.subField}>
                    <MultiRangeSlider
                        classes={style.range}
                        min={ageRangeMin}
                        max={ageRangeMax}
                        from={inputAgeFrom.value}
                        to={inputAgeTo.value}
                        key={sliderRangeKey}
                        onChange={handlerChangeRangeAge}
                    />
                    <div className={style.age}>
                        {`${rangeAge.from}-${rangeAge.to}`}
                    </div>
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_32)}>
                <div className={style.fieldText}>
                    {t('pm_sorting_title')}
                </div>
                <div className={style.subField}>
                    <div className={style.subFieldText}>
                        {t('sort_by_default')}
                    </div>
                    <Field
                        name={FieldName.sort_by}
                        render={({ input }) => (
                            <Radio
                                checked={input.value === ''}
                                name={input.name}
                                value=""
                                onChange={() => input.onChange('')}
                            />
                        )}
                    />
                </div>
                <div className={style.subField}>
                    <div className={style.subFieldText}>
                        {t('sort_by_posts')}
                    </div>
                    <Field
                        name={FieldName.sort_by}
                        render={({ input }) => (
                            <Radio
                                checked={input.value === FriendFilterSortBy.posts_count}
                                name={input.name}
                                value={FriendFilterSortBy.posts_count}
                                onChange={() => input.onChange(FriendFilterSortBy.posts_count)}
                            />
                        )}
                    />
                </div>
            </div>
            <div className={cn(styleForm.controls, styleForm.controls_40, style.controls)}>
                <Button
                    textUpper
                    classes={style.control}
                    size="size46"
                    type="submit"
                    text={t('apply')}
                />
                <Button
                    textUpper
                    classes={style.control}
                    styleType="bordered"
                    size="size46"
                    text={t('clear')}
                    onClick={handlerReset}
                />
            </div>
        </form>
    )
}

export default ClientFilterForm
