import React, {
    useEffect,
    useState,
    useRef,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { TCompanyOrdersProps } from 'services/MarketService'
import { BREAKPOINTS } from 'config/app'
import { ContentContainer, Block } from 'layout'
import {
    PageHeader,
    Button,
    Checkbox,
    SvgResource,
    Pagination,
    Popover,
    Modal,
} from 'components'
import { useSearchParams, useFetchPaymentStatuses } from 'containers/App/hooks'
import { useFetchCompanyOrders } from 'containers/Orders/hooks'
import { CompanyOrdersPresetsAction, CompanyOrdersFilterAction } from 'form-actions'
import { FormDataType, FieldName, formDataInitial } from 'forms/CompanyOrdersFilterForm/CompanyOrdersFilterForm'
import { useWindowResize } from 'hooks'
import { useAppSelector } from 'store'
import { getId, showAlertNotify } from 'utils/helpers'
import { CompanyOrdersList, CompanyOrdersPresets } from './components'
import { LIST_COLUMNS } from './components/CompanyOrdersList/CompanyOrdersList'
import style from './OrdersOrders.module.css'

const LIMIT_ORDERS = 20

const OrdersOrders: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()
    const { pathname } = useLocation()
    const { page } = useSearchParams<{ page?: string }>()
    const [windowWidth] = useWindowResize()
    const ordersRef = useRef<HTMLDivElement>(null)

    const company = useAppSelector((state) => state.company)

    const [ordersFilterInitialValues, setOrdersFilterInitialValues] = useState<FormDataType>({
        ...formDataInitial,
        [FieldName.formId]: getId(false),
    })
    const [ordersFilterProps, setOrdersFilterProps] = useState<Omit<TCompanyOrdersProps, 'companyId'>>()
    const [listColumns, setListColumns] = useState(LIST_COLUMNS)
    const [isOpenFilter, setIsOpenFilter] = useState(true)
    const [isOpenOrders, setIsOpenOrders] = useState(false)
    const [isOpenListSettings, setIsOpenListSettings] = useState(false)
    const [isOpenModalPresets, setIsOpenModalPresets] = useState(false)

    // eslint-disable-next-line
    const [datePresets, setDataPresets] = useState([
        { id: 1, name: 'Курица' },
        { id: 2, name: 'Овощи' },
        { id: 3, name: 'Мясо' },
        { id: 4, name: 'preset 4' },
        { id: 5, name: 'preset 5' },
        { id: 6, name: 'preset 6' },
        { id: 7, name: 'preset 6' },
        { id: 8, name: 'preset 6' },
    ])

    const {
        isInitialLoading: isLoadingCompanyOrders,
        isFetching: isFetchingCompanyOrders,
        data: dataCompanyOrders,
        error: errorCompanyOrders,
    } = useFetchCompanyOrders({
        companyId: company.id,
        ...ordersFilterProps,
        limit: LIMIT_ORDERS,
        offset: page && !Number.isNaN(Number(page)) ? Number(page) - 1 : 0,
    }, {
        enabled: (!!page && !Number.isNaN(Number(page))) || !!ordersFilterProps,
        keepPreviousData: true,
    })

    const { data: dataPaymentStatuses } = useFetchPaymentStatuses()

    const handlerSuccessOrdersFilter = (values: Omit<TCompanyOrdersProps, 'companyId'>) => {
        setOrdersFilterProps(values)
    }

    const handlerResetOrdersFilter = () => {
        setOrdersFilterInitialValues({ ...formDataInitial, [FieldName.formId]: getId(false) })
    }

    const handlerChangeListActiveColumn = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        setListColumns((prevState) => prevState.map((item) => {
            return item.key === currentTarget.name ? { ...item, checked: currentTarget.checked } : item
        }))
    }

    const handlerChangePagination = (value: number) => {
        setOrdersFilterProps((prevState) => ({ ...prevState, offset: value - 1 }))
        history.push({ pathname, search: `page=${value}` })
    }

    useEffect(() => {
        if (dataCompanyOrders) {
            setIsOpenOrders(true)
            setTimeout(() => {
                if (ordersRef.current) {
                    ordersRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
                }
            }, 300)
        }
    }, [dataCompanyOrders])

    useEffect(() => {
        if (errorCompanyOrders) {
            showAlertNotify({
                type: 'error',
                message: errorCompanyOrders[0],
            })
        }
    }, [errorCompanyOrders])

    return (
        <>
            <ContentContainer classes={style.content} size="three-quarters">
                <PageHeader title={t('orders')} />

                <Block classes={cn(style.block, style.block_gray)}>
                    <Block.Header classes={style.blockHeader}>
                        <div className={style.blockTitle}>
                            {t('order_filter')}
                        </div>
                        <div className={style.blockActions}>
                            <Button
                                disabled
                                textUpper={false}
                                classes={cn(style.blockAction, style.blockAction_violet)}
                                styleType="small"
                                size="size30"
                                text={t('presets')}
                                onClick={() => setIsOpenModalPresets(true)}
                            />
                            <Button
                                textUpper={false}
                                classes={style.blockAction}
                                styleType="small"
                                size="size30"
                                text={t('clear')}
                                onClick={() => handlerResetOrdersFilter()}
                            />
                            <Button
                                textUpper={false}
                                classes={style.blockAction}
                                styleType="small"
                                size="size30"
                                text={isOpenFilter ? t('hide') : t('show')}
                                onClick={() => setIsOpenFilter((prevState) => !prevState)}
                            />
                        </div>
                    </Block.Header>
                    <Block.BodyCollapse
                        isOpen={isOpenFilter}
                        classesContent={style.blockBody}
                    >
                        <CompanyOrdersFilterAction
                            isLoading={isLoadingCompanyOrders || isFetchingCompanyOrders}
                            classesBody={style.filterFormBody}
                            classesFooter={style.filterFormFooter}
                            initialValues={ordersFilterInitialValues}
                            company={company}
                            onSuccess={handlerSuccessOrdersFilter}
                        />
                    </Block.BodyCollapse>
                </Block>
                <div className={style.divider} ref={ordersRef} />
                <Block classes={style.block}>
                    <Block.Header classes={style.blockHeader}>
                        <Block.HeaderInner classes={style.blockHeaderInner}>
                            <div className={style.blockTitle}>
                                {t('order_list')}
                            </div>
                            <div className={style.blockActions}>
                                <Popover
                                    classes={cn(style.blockAction, style.blockActionSettings)}
                                    classesBody={style.listSettings}
                                    isOpen={isOpenListSettings}
                                    isShowArrow={false}
                                    isAutoClose={false}
                                    isPopup={windowWidth < BREAKPOINTS.tabletLandscape}
                                    trigger={(
                                        <SvgResource
                                            resourceKey="ic_list_svg"
                                            width={18}
                                            height={14}
                                        />
                                    )}
                                    position="bottom"
                                    side="left"
                                    isDisabled={!isOpenOrders}
                                    onOpen={() => setIsOpenListSettings(true)}
                                    onClose={() => setIsOpenListSettings(false)}
                                >
                                    {listColumns.map((item) => (
                                        <div className={style.ordersListSettingsItem} key={item.key}>
                                            <Checkbox
                                                styleType="small"
                                                name={item.key}
                                                text={t(item.translateKey)}
                                                checked={item.checked}
                                                onChange={handlerChangeListActiveColumn}
                                            />
                                        </div>
                                    ))}
                                </Popover>
                            </div>
                        </Block.HeaderInner>
                    </Block.Header>
                    <Block.BodyCollapse
                        isOpen={isOpenOrders}
                        classesContent={cn(style.blockBody, style.blockBody_list)}
                    >
                        <CompanyOrdersList
                            classes={cn(style.list, { [style.list_updating]: isFetchingCompanyOrders })}
                            columns={listColumns}
                            data={dataCompanyOrders?.data}
                            paymentStatuses={dataPaymentStatuses}
                        />
                        {!!dataCompanyOrders?.totalPages && dataCompanyOrders.totalPages > 1 && (
                            <Pagination
                                classes={style.pagination}
                                current={dataCompanyOrders.currentPage}
                                total={dataCompanyOrders.totalPages}
                                onChange={handlerChangePagination}
                            />
                        )}
                    </Block.BodyCollapse>
                </Block>
            </ContentContainer>

            <Modal
                isOpen={isOpenModalPresets}
                classes={style.modalPresets}
                size="smallCenter"
                onClose={() => setIsOpenModalPresets(false)}
            >
                <Modal.Header
                    classes={style.modalPresetsHeader}
                    classesTitle={style.modalPresetsTitle}
                    title={t('presets')}
                    titlePos="left"
                />
                <Modal.Body classes={style.modalPresetsBody}>
                    <CompanyOrdersPresets
                        classes={style.presets}
                        data={datePresets}
                        onDelete={() => {}}
                        onSelect={() => {}}
                    />
                    <CompanyOrdersPresetsAction />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrdersOrders
