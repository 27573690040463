import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    useQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TMarketServiceError,
    TGoodsAvailableStatusResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnError = AxiosError<TMarketServiceError>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string]
export type TQueryFnOpts = UseQueryOptions<
    TGoodsAvailableStatusResponse,
    TError,
    TGoodsAvailableStatusResponse,
    TQueryKey
>

const key = QueryCacheKeys.storeGoodsAvailableStatus

/**
 * Хук API получить статусы товара
 */
export default function useFetchGoodsAvailableStatus(opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`], () => {
        return MarketService.fetchGoodsAvailableStatus()
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
