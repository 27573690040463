import React from 'react'
import cn from 'classnames'

import style from './TableListHeader.module.css'

export type TableListHeaderPropType = {
    classes?: string
}

const TableListHeader: React.FC<TableListHeaderPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.header, classes)}>
            {children}
        </div>
    )
}

export default TableListHeader
