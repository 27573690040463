import React from 'react'
import cn from 'classnames'

import style from './ColorPointer.module.css'

type HuePointerPropType = {
    classes?: string
    top?: string
    left?: string
}

/**
 * Custom pointer component for libs: @uiw/react-color-[saturation|hue]
 */
const ColorPointer: React.FC<HuePointerPropType> = ({ classes, top, left }) => {
    return (
        <div
            className={cn(style.pointer, classes)}
            style={{ top, left }}
        />
    )
}

export default ColorPointer
