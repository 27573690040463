import React from 'react'

import { CardV2, TextSkeleton } from 'components'
import style from './ShopFolderPreview.module.css'

type ShopFolderPreviewPropType = {
    name?: string
    photo?: string
}

const ShopFolderPreview: React.FC<ShopFolderPreviewPropType> = ({ name, photo }) => {
    return (
        <div className={style.wrap}>
            <CardV2>
                <CardV2.Image
                    classes={style.image}
                    isPlaceholder={false}
                    src={photo}
                />
                <CardV2.Body
                    classes={style.body}
                    classesTitle={style.title}
                    title={name}
                >
                    {!name && (
                        <>
                            <div className={style.row}>
                                <TextSkeleton />
                            </div>
                            <div className={style.row}>
                                <TextSkeleton />
                            </div>
                        </>
                    )}
                </CardV2.Body>
            </CardV2>
        </div>
    )
}

export default ShopFolderPreview
