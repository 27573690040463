import React from 'react'
import { Form } from 'react-final-form'

import { FormDataType } from 'forms/OrderDetailsForm/OrderDetailsForm'
import { OrderDetailsForm } from 'forms'

const OrderDetailsAction: React.FC = () => {
    const handlerSubmit = (params: FormDataType) => {
        //
    }

    return (
        <Form
            onSubmit={handlerSubmit}
            render={({ handleSubmit, submitting }) => (
                <OrderDetailsForm
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default OrderDetailsAction
