import React, { useMemo } from 'react'
import { Field } from 'react-final-form'
import cn from 'classnames'

import { Checkbox, CustomSelect } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './OrderDeliveryDetailsForm.module.css'

export enum FieldName {
    paymentStatus = 'paymentStatus',
    delivery = 'delivery',
    deliveryType = 'deliveryType',
    deliveryComplete = 'deliveryComplete',
}

export type FormDataType = {
    [FieldName.paymentStatus]: number
    [FieldName.delivery]: number
    [FieldName.deliveryType]: number
    [FieldName.deliveryComplete]?: boolean
}

export type OrderDeliveryDetailsFormPropType = {
    isSubmitting: boolean
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

// FIXME on ready back
const STATUSES = [
    { id: 1, name: 'UNKNOWN' },
]

// FIXME on ready back
const DELIVERIES = [
    { id: 1, name: 'СДЭК' },
]

// FIXME on ready back
const TYPES = [
    { id: 1, name: '' },
]

const OrderDeliveryDetailsForm: React.FC<OrderDeliveryDetailsFormPropType> = ({
    isSubmitting,
    onSubmit,
}) => {
    const statusesData = useMemo(() => {
        return STATUSES.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    }, [])

    const deliveriesData = useMemo(() => {
        return DELIVERIES.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    }, [])

    const typesData = useMemo(() => {
        return TYPES.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    }, [])

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                    {/* TODO */}
                    Статус доставки
                </div>
                <Field
                    <FormDataType[FieldName.paymentStatus]>
                    name={FieldName.paymentStatus}
                    render={({ input, meta }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field classes={style.fieldSelect}>
                                {!!input.value && statusesData[input.value]}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {STATUSES.map((item) => (
                                    <CustomSelect.Option
                                        id={item.id}
                                        key={item.id}
                                        onClick={() => input.onChange(item.id)}
                                    >
                                        {item.name}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                    {/* TODO */}
                    Выбранная служба доставки
                </div>
                <Field
                    <FormDataType[FieldName.delivery]>
                    name={FieldName.delivery}
                    render={({ input, meta }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field classes={style.fieldSelect}>
                                {!!input.value && deliveriesData[input.value]}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {DELIVERIES.map((item) => (
                                    <CustomSelect.Option
                                        id={item.id}
                                        key={item.id}
                                        onClick={() => input.onChange(item.id)}
                                    >
                                        {item.name}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                    {/* TODO */}
                    Выбранный тип доставки
                </div>
                <Field
                    <FormDataType[FieldName.deliveryType]>
                    name={FieldName.deliveryType}
                    render={({ input, meta }) => (
                        <CustomSelect.Select>
                            <CustomSelect.Field classes={style.fieldSelect}>
                                {!!input.value && typesData[input.value]}
                            </CustomSelect.Field>
                            <CustomSelect.Options>
                                {TYPES.map((item) => (
                                    <CustomSelect.Option
                                        id={item.id}
                                        key={item.id}
                                        onClick={() => input.onChange(item.id)}
                                    >
                                        {item.name}
                                    </CustomSelect.Option>
                                ))}
                            </CustomSelect.Options>
                        </CustomSelect.Select>
                    )}
                />
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.fieldText}>
                    {/* TODO */}
                    Выбранный тариф доставки
                </div>
                <div className={style.fieldValue}>
                    Без стоимости. Узбекистан. 0-10,000 kg.
                    (Ташкент, Чиланзарский район, ул. Мукими, 21, ст.метро &quot;Новза&quot; тел +998712317214)
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.fieldText}>
                    {/* TODO */}
                    Адрес
                </div>
                <div className={style.fieldValue}>
                    Страна: Узбекистан
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.link}>
                    {/* TODO */}
                    Форма доставки
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <Field
                    <FormDataType[FieldName.deliveryComplete]>
                    name={FieldName.deliveryComplete}
                    render={({ input }) => (
                        <Checkbox
                            styleType="small"
                            checked={Boolean(input.value)}
                            name={input.name}
                            // TODO
                            text="Доставка завершена / не требуется?"
                            onChange={(e) => input.onChange(Number(e.target.checked))}
                        />
                    )}
                />
            </div>
        </form>
    )
}

export default OrderDeliveryDetailsForm
