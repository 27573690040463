import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TChatMicroServiceError,
    TChannelMembersProps,
    TChannelMembersResponse,
    TAddChannelMemberProps,
    TAddChannelMemberResponse,
    TDeleteChannelMemberProps,
    TDeleteChannelMemberResponse,
} from 'services/ChatMicroService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { ChatMicroService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TChannelMembersProps
export type TFetchFnResponse = TChannelMembersResponse
export type TFetchFnError = AxiosError<TChatMicroServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

export type TAddMutationFnError = [string, TAddChannelMemberResponse]
export type TDeleteMutationFnError = [string, TDeleteChannelMemberResponse]

const key = QueryCacheKeys.channelMembers

/**
 * Хук API получение списка юзеров в групповом чате
 */
export default function useFetchChannelMembers(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return ChatMicroService.fetchChannelMembers(params)
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationChannelMembers() {
    const { t } = useTranslation()

    const add = useMutation<
        TAddChannelMemberResponse,
        TAddMutationFnError,
        TAddChannelMemberProps,
        unknown
    >((props) => {
        return ChatMicroService.addChannelMember(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const remove = useMutation<
        TDeleteChannelMemberResponse,
        TDeleteMutationFnError,
        TDeleteChannelMemberProps,
        unknown
    >((props) => {
        return ChatMicroService.deleteChannelMember(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        add,
        remove,
    }
}
