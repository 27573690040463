import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Field } from 'react-final-form'
import cn from 'classnames'

import { Button, CustomSelect, Input } from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './DeliveryServicesFilterForm.module.css'

// FIXME on ready backend
export enum FieldName {
    name = 'name',
    store = 'store',
}

export type FormDataType = {
    [FieldName.name]: string
    [FieldName.store]: number
}

export type DeliveryServicesFilterFormPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    isSubmitting: boolean
    stores?: { id: number, name: string }[] // FIXME
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.name]: '',
    [FieldName.store]: 0,
}

const DeliveryServicesFilterForm: React.FC<DeliveryServicesFilterFormPropType> = ({
    classes,
    classesBody,
    classesFooter,
    isSubmitting,
    stores,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const storesData: Record<number, string> = useMemo(() => {
        return stores
            ? stores.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [stores])

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={classesBody}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        {/* TODO */}
                        Название
                    </div>
                    <Field
                        <FormDataType[FieldName.name]>
                        name={FieldName.name}
                        render={({ input }) => (
                            <Input
                                {...input}
                                styleType="defaultBusiness"
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                        {t('stores')}
                    </div>
                    <Field
                        <FormDataType[FieldName.store]>
                        name={FieldName.store}
                        render={({ input }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.fieldSelect}>
                                    {!!input.value && storesData[input.value]}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {stores?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            key={item.id}
                                            onClick={() => input.onChange(item.id)}
                                        >
                                            {item.name}
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
            </div>
            <div className={classesFooter}>
                <div className={style.controls}>
                    <Button
                        classes={style.control}
                        size="size44"
                        type="submit"
                        text={t('apply')}
                    />
                </div>
            </div>
        </form>
    )
}

export default DeliveryServicesFilterForm
