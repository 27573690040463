import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import { RegistrationUser, RegistrationCompany, RegistrationStoreSettings } from 'containers'
import { useAppMode } from 'containers/App/hooks'
import { Footer } from 'components'
import style from './HomePage.module.css'

/**
 * Страница регистрации данных
 *
 * @name HomePage
 * @memberOf module:Layouts
 */
const HomePage: React.FC = () => {
    const { appMode, isBusinessMode } = useAppMode()

    const redirectPath = isBusinessMode ? APP_URL.registrationCompany : APP_URL.registrationUser

    return (
        <>
            <div className={cn(style.page, style[`page_${appMode}`])}>
                <div className={style.content}>
                    <Switch>
                        <Route
                            exact
                            path={APP_URL.registrationUser}
                            component={RegistrationUser}
                        />
                        <Route
                            exact
                            path={APP_URL.registrationCompany}
                            component={RegistrationCompany}
                        />
                        <Route
                            exact
                            path={APP_URL.registrationStoreSettings}
                            component={RegistrationStoreSettings}
                        />

                        <Redirect from="*" to={redirectPath} />
                    </Switch>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default HomePage
