import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TChatMicroServiceError,
    TChannelProps,
    TChannelResponse,
    TCreateChannelProps,
    TCreateChannelResponse,
} from 'services/ChatMicroService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { ChatMicroService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TChannelProps
export type TFetchFnResponse = TChannelResponse
export type TFetchFnError = AxiosError<TChatMicroServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

export type TCreateMutationFnError = [string, AxiosError<TChatMicroServiceError, TChannelProps>]

const key = QueryCacheKeys.channel

/**
 * Хук API получение групповго чата
 */
export default function useFetchChannel(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return ChatMicroService.fetchChannel(params)
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationChannel() {
    const { t } = useTranslation()

    const create = useMutation<
        TCreateChannelResponse,
        TCreateMutationFnError,
        TCreateChannelProps,
        unknown
    >((props) => {
        return ChatMicroService.createChannel(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        create,
    }
}
