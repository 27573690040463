import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IOrder, IStoreCurrency } from '../../../../interfaces'
import { MARKET_ORDER_STATUS_READY_PAYMENT } from '../../../../config/app'
import { Button } from '../../../../components'
import { dateTimeFormat, priceFormat } from '../../../../utils/helpers'
import style from './StoreOrdersItem.module.css'

type StoreOrdersItemPropType = {
    data: IOrder
    currency?: IStoreCurrency
    isShowGoods?: boolean
    onClickPayment: (id: number) => void
}

const StoreOrdersItem: React.FC<StoreOrdersItemPropType> = ({
    data,
    currency,
    isShowGoods,
    onClickPayment,
}) => {
    const { id, payment_status: paymentStatus, invoice_link: invoiceLink } = data
    const { t } = useTranslation()

    const date = useMemo(() => dateTimeFormat(data.create_date), [data])
    const price = useMemo(() => priceFormat(data.order_sum, currency), [data, currency])

    const handlerClickPayment = () => {
        if (id) {
            onClickPayment(id)
        }
    }

    return (
        <tr className={style.row}>
            <td className={style.col}>
                {id}
            </td>
            <td className={style.col}>
                {date}
            </td>
            {isShowGoods && (
                <td className={style.col}>
                    {data.content.map((item, index) => (
                        <React.Fragment key={item.goods.id}>
                            {index > 0 && (', ')}

                            <span className={style.product}>
                                {`${item.goods.name} (${item.quantity})`}
                            </span>
                        </React.Fragment>
                    ))}
                </td>
            )}
            <td className={style.col}>
                <div>
                    {price}
                </div>

                {paymentStatus === MARKET_ORDER_STATUS_READY_PAYMENT && (
                    <div className={cn(style.text, style.text_action, style.text_danger)}>
                        {t('buy_fail')}
                    </div>
                )}
            </td>
            <td className={style.col}>
                {paymentStatus === MARKET_ORDER_STATUS_READY_PAYMENT && (
                    <Button
                        classes={cn(style.text, style.text_action, style.link)}
                        styleType="text"
                        text={t('market_order_purchase_button')}
                        onClick={handlerClickPayment}
                    />
                )}

                {invoiceLink && (
                    <a
                        className={cn(style.text, style.text_action, style.link)}
                        href={invoiceLink}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('item_market_order_invoice_url_title')}
                    </a>
                )}
            </td>
        </tr>
    )
}

export default StoreOrdersItem
