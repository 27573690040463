import { IReport } from 'interfaces'
import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

export type TReportServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TReportsProps = {
    displayFull?: boolean
    count?: boolean
}

export type TReportProps = {
    id: string
    displayFull?: boolean
    count?: boolean
}

export type TReportsFilterValueProps = {
    ids: string[]
}

export type TReportsResponse = IReport[]

export type TReportResponse = IReport[]

export type TReportsFilterValueResponse = IReport[]

class ReportService {
    static fetchReports(params: TReportsProps = {}) {
        return requestClient<TReportsResponse>(API_URL.reports, { params })
    }

    static fetchReport({ id, ...params }: TReportProps) {
        return requestClient<TReportResponse>(`${API_URL.reports}/${id}`, { params })
    }

    static fetchReportsFilterValue({ ids }: TReportsFilterValueProps) {
        return requestClient<TReportsFilterValueResponse>(API_URL.reportsSelected, { params: { id: ids } })
    }
}

export default ReportService
