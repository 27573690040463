import React, { useState } from 'react'

import { IReport } from 'interfaces'
import { CardContainer } from 'layout'
import { ReportList } from 'containers'
import { ReportItem } from 'containers/ReportList/components'
import { useFetchReports } from 'containers/Reports/hooks'
import { PageHeader, Loader, ErrorMsg } from 'components'

import style from './WalletReports.module.css'

type WalletReportsPropType = {
    classes?: string
    onClick?: (data?: IReport) => void
}

const WalletReports: React.FC<WalletReportsPropType> = ({ classes, onClick = () => {} }) => {
    const [reportId, setReportId] = useState<string | undefined>(undefined)
    const [reportTitle, setReportTitle] = useState('')

    const {
        isInitialLoading: isLoadingReports,
        data: reportsData,
        error: reportsError,
    } = useFetchReports({ displayFull: false, count: false })

    const handlerClickReport = (report: IReport) => {
        const {
            id,
            name,
            hasChilds,
            filterId,
            value,
        } = report

        if (hasChilds || !!(filterId && value)) {
            setReportId(id)
            setReportTitle(name)
        }

        onClick(report)
    }

    const handlerClickBack = () => {
        setReportId(undefined)
        onClick(undefined)
    }

    return (
        <>
            {reportId && (
                <PageHeader
                    classes={style.header}
                    title={reportTitle}
                    onClickBack={handlerClickBack}
                />
            )}

            {isLoadingReports && (
                <Loader />
            )}

            {!isLoadingReports && !reportId && reportsData && (
                <CardContainer classes={classes}>
                    {reportsData.map((item) => (
                        <ReportItem
                            data={item}
                            isSetReportLink={false}
                            key={item.id}
                            onClick={handlerClickReport}
                        />
                    ))}
                </CardContainer>
            )}

            {reportId && (
                <ReportList
                    id={reportId}
                    isSetReportLink={false}
                    onClick={handlerClickReport}
                />
            )}

            {reportsError && (
                <ErrorMsg error={reportsError[0]} />
            )}
        </>
    )
}

export default WalletReports
