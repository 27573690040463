import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICompanyData, ICountry, ICity } from 'interfaces'
import { validationFormErrorsType } from 'utils/validators'
import {
    Input,
    Image,
    Button,
    CountryDataSelect,
    CityDataSelect,
} from 'components'
import { maskNormalizer } from 'utils/helpers'
import { vGray5 } from 'styles/modules/variables.module.css'
import styleInput from 'components/Input/Input.module.css'
import styleForm from 'styles/modules/form.module.css'
import style from './CompanyForm.module.css'

export type FormDataType = ICompanyData

export type CompanyFormPropType = {
    data: FormDataType
    errors: validationFormErrorsType
    country?: ICountry
    city?: ICity
    countries: ICountry[]
    isSubmitting: boolean
    isDisabled: boolean
    onChangeCountry: (data: ICountry) => void
    onChangeCity: (data: ICity) => void
    onChangePhone: (data: string) => void
    onChangePhoto: (data: File) => void
    onChange: (name: string, value: string) => void
    onSubmit: () => void
}

const CompanyForm: React.FC<CompanyFormPropType> = ({
    data,
    errors,
    country,
    city,
    countries,
    isSubmitting,
    isDisabled,
    onChangeCountry,
    onChangeCity,
    onChangePhone,
    onChangePhoto,
    onChange,
    onSubmit,
}) => {
    const { logo } = data

    const { t } = useTranslation()

    const { avatar, isResizeAvatar } = useMemo(() => {
        const result = { avatar: '', isResizeAvatar: false }

        if (logo) {
            return logo.includes('http')
                ? { ...result, avatar: logo, isResizeAvatar: true }
                : { ...result, avatar: `data:image/png;base64,${logo}` }
        }

        return result
    }, [logo])

    const phoneMask = useMemo(() => {
        return country?.mask ? maskNormalizer(country.mask) : undefined
    }, [country])

    const handlerSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
        e.preventDefault()
        onSubmit()
    }

    const handlerChangePhoto = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = target
        const [file] = files || []

        onChangePhoto(file)
    }

    const handlerChangeFormData = ({ currentTarget }: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = currentTarget
        onChange(name, value)
    }

    const handlerAcceptPhone = ({ value }: HTMLInputElement) => {
        onChangePhone(value)
    }

    const handlerChangePhone = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        onChangePhone(currentTarget.value)
    }

    return (
        <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={handlerSubmit}>
            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label className={style.logoField}>
                    <Image
                        classesWrap={cn(styleForm.row, styleForm.row_32, style.logoWrap)}
                        classesImage={style.logoImage}
                        src={avatar}
                        isPlaceholder={false}
                        width={180}
                        height={180}
                        backgroundColor={vGray5}
                        rootMargin="0px"
                        resize={isResizeAvatar ? { quality: 90, width: 180, height: 180 } : undefined}
                        key={avatar}
                    />
                    <Input
                        classes={styleForm.inputFile}
                        styleType="clear"
                        type="file"
                        onChange={handlerChangePhoto}
                    />
                </label>
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('company_registration_name')}
                    </div>
                    <Input
                        classes={cn({ [styleForm.invalid]: errors.name })}
                        styleType="gray"
                        name="name"
                        placeholder={`${t('company_registration_name')} *`}
                        value={data.name}
                        onChange={handlerChangeFormData}
                    />
                </label>
                {errors.name && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.name}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('company_registration_name_official')}
                    </div>
                    <Input
                        classes={cn({ [styleForm.invalid]: errors.juridical_name })}
                        styleType="gray"
                        name="juridical_name"
                        placeholder={`${t('company_registration_name_official')} *`}
                        value={data.juridical_name}
                        onChange={handlerChangeFormData}
                    />
                </label>
                {errors.juridical_name && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.juridical_name}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('company_registration_phone')}
                    </div>
                    <div className={cn(
                        styleInput.gray,
                        style.group,
                        styleForm.group,
                        styleForm.group_alignCenter,
                        styleForm.group_noSidePadding,
                        { [styleForm.invalid]: errors.phone_number },
                    )}
                    >
                        <CountryDataSelect
                            isShowSelectArrow
                            classes={cn(
                                styleForm.groupItem,
                                styleForm.groupItem_static,
                                styleForm.groupItem_minWidth,
                            )}
                            classesField={style.selectField}
                            countries={countries}
                            selected={country}
                            onChange={onChangeCountry}
                        />

                        {country && (
                            <>
                                <div className={style.fieldCountryCode}>
                                    {country?.code}
                                </div>

                                {phoneMask ? (
                                    <Input
                                        classes={style.input}
                                        styleType="gray"
                                        type="tel"
                                        name="phone_number"
                                        placeholder={`${t('company_registration_phone')} *`}
                                        mask={phoneMask}
                                        value={data.phone_number}
                                        onAccept={handlerAcceptPhone}
                                    />
                                ) : (
                                    <Input
                                        classes={style.input}
                                        styleType="gray"
                                        type="tel"
                                        maxLength={30}
                                        name="phone_number"
                                        value={data.phone_number}
                                        onChange={handlerChangePhone}
                                    />
                                )}
                            </>
                        )}
                    </div>
                </label>
                {errors.phone_number && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.phone_number}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('city')}
                    </div>
                    <CityDataSelect
                        classes={cn(
                            styleInput.gray,
                            style.group,
                            styleForm.group,
                            styleForm.group_noSidePadding,
                            { [styleForm.invalid]: errors.city },
                        )}
                        classesField={style.selectField}
                        countryId={country?.id}
                        countryLang={country?.lang}
                        selected={city}
                        placeholder={`${t('select_city')} *`}
                        onChange={onChangeCity}
                    />
                </label>
                {errors.city && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.city}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('company_registration_email')}
                    </div>
                    <Input
                        classes={cn({ [styleForm.invalid]: errors.email })}
                        styleType="gray"
                        name="email"
                        placeholder={`${t('company_registration_email')} *`}
                        value={data.email}
                        onChange={handlerChangeFormData}
                    />
                </label>
                {errors.email && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.email}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.row, styleForm.row_32)}>
                <label>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_gray)}>
                        {t('company_registration_site')}
                    </div>
                    <Input
                        classes={cn({ [styleForm.invalid]: errors.site })}
                        styleType="gray"
                        name="site"
                        placeholder={t('company_registration_site')}
                        value={data.site}
                        onChange={handlerChangeFormData}
                    />
                </label>
                {errors.site && (
                    <div className={cn(styleForm.fieldError, styleForm.fieldError_static)}>
                        {errors.site}
                    </div>
                )}
            </div>

            <div className={cn(styleForm.controls, styleForm.controls_64)}>
                <Button
                    text={t('save')}
                    size="size44"
                    type="submit"
                    disabled={isDisabled}
                />
            </div>
        </form>
    )
}

export default CompanyForm
