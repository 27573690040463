import React, { useMemo } from 'react'

import { IStoreGoods } from 'interfaces'
import { CardSize } from 'enums'
import { CardV2 } from 'components'
import styleCatalog from '../../CatalogShowcase.module.css'

export type CatalogShowcaseGoodsPropType = {
    classes?: string
    data: IStoreGoods
    size: keyof typeof CardSize
    url?: string
}

const CatalogShowcaseGoods: React.FC<CatalogShowcaseGoodsPropType> = ({
    children,
    classes,
    data,
    size,
    url,
}) => {
    const image = useMemo(() => {
        const { images } = data.container

        if (images) {
            const image1 = images.find((img) => img.format === 1)
            const image2 = images.find((img) => img.format === 2)

            return image1?.path || image2?.path || ''
        }

        return ''
    }, [data])

    return (
        <CardV2
            classes={classes}
            size={size}
            url={url}
        >
            <CardV2.Image
                src={image}
                width={400}
            />
            <CardV2.Body
                classes={styleCatalog.body}
                classesTitle={styleCatalog.title}
                title={data.container.name}
            />
            {children}
        </CardV2>
    )
}

export default CatalogShowcaseGoods
