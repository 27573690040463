import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPayout } from 'interfaces'
import { Image } from 'components'
import { numberFormat, getCountFractionDigits } from 'utils/helpers'
import style from './WalletPayout.module.css'

type WalletPayoutPropType = {
    classes?: string
    payout: IPayout
    index: number
    onClick: (data: IPayout) => void
}

const WalletPayout: React.FC<WalletPayoutPropType> = ({
    classes,
    payout,
    index,
    onClick,
}) => {
    const {
        currencyIcon,
        currencyShowCode,
        currencyPlaceholderColor,
    } = payout

    const { t } = useTranslation()

    const amountSum = useMemo(() => {
        const { balanceAmount, roundscale } = payout
        const fractionDigits = getCountFractionDigits(roundscale)

        return numberFormat(balanceAmount, {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        })
    }, [payout])

    const handlerClick = () => {
        onClick(payout)
    }

    return (
        <div
            className={cn(style.payout, classes)}
            style={{ backgroundColor: currencyPlaceholderColor }}
            role="button"
            tabIndex={index}
            onClick={handlerClick}
            onKeyDown={handlerClick}
        >
            <Image
                classesWrap={style.background}
                src={currencyIcon}
                isPlaceholder={false}
                width={400}
                height={267}
                resize={{ width: 400, quality: 90 }}
            />

            <div className={style.body}>
                <div className={style.balance}>
                    <div className={style.amount}>
                        {amountSum}
                    </div>
                    <div className={style.currency}>
                        {currencyShowCode}
                    </div>
                </div>
                <div className={style.desc}>
                    {t('balance')}
                </div>
            </div>
        </div>
    )
}

export default WalletPayout
