import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    InvalidateQueryFilters,
    InvalidateOptions,
    useQueryClient,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IBasket } from 'interfaces'
import { TBasketProps, TBasketUpdateProps, TBasketClearProps } from 'services/MarketService'
import { MarketService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TBasketProps
export type TError = string
export type TQueryKey = [string, TFetchFnParams] | string[]
export type TQueryFnOpts = UseQueryOptions<IBasket, TError, IBasket, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    IBasket,
    TError,
    IBasket,
    IBasket,
    TQueryKey
>

const key = 'basket'

/**
 * Хук API получить корзину магазина
 */
export default function useFetchBasket(params: TFetchFnParams, {
    cacheTime = 60 * 1000, // 1m
    staleTime = 60 * 1000, // 1m
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchBasket(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

export function useMutationBasket() {
    const { t } = useTranslation()
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const update = useMutation<IBasket, string, TBasketUpdateProps, unknown>((props) => {
        return MarketService.updateBasket(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        onSuccess: (data, { storeId }) => {
            setQueryData(data, { storeId })
        },
    })

    const clear = useMutation<IBasket, string, TBasketClearProps, unknown>((props) => {
        return MarketService.clearBasket(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject(getRequestError(err) || t('update_error'))
            })
    }, {
        onSuccess: (data, { storeId }) => {
            setQueryData(data, { storeId })
        },
    })

    function setQueryData(data: IBasket, params: TFetchFnParams) {
        return queryClient.setQueryData<IBasket>([`${key}-${user.id}`, params], (cacheData) => {
            if (cacheData) {
                return { ...cacheData, ...data }
            }

            return data
        })
    }

    return {
        update,
        clear,
    }
}

export function useInvalidateBasket(
    params: TFetchFnParams,
    { exact = true, ...filters }: InvalidateQueryFilters = {},
    options: InvalidateOptions = {},
) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = () => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}
