import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    SetDataOptions,
    Updater,
    useQueryClient,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IPost } from 'interfaces'
import {
    TCommunityServiceError,
    TPostProps,
    TPostResponse,
    TAddPostProps,
    TUpdatePostProps,
    TDeletePostProps,
    TLikePostProps,
    TLikePostResponse,
} from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TPostProps
export type TFetchFnResponse = TPostResponse
export type TFetchFnError = AxiosError<TCommunityServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

export type TAddPostMutationFnError = [string, AxiosError<TCommunityServiceError, TAddPostProps>]
export type TUpdatePostMutationFnError = [string, AxiosError<TCommunityServiceError, TUpdatePostProps>]
export type TDeletePostMutationFnError = [string, AxiosError<TCommunityServiceError, TDeletePostProps>]
export type TLikePostMutationFnError = [string, AxiosError<TCommunityServiceError, TLikePostProps>]

const key = QueryCacheKeys.post

/**
 * Хук API получение поста
 */
export default function useFetchPost(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchPost(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useSetQueryDataPost() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const setQueryData = (
        params: TFetchFnParams,
        updater: Updater<TFetchFnResponse | undefined, TFetchFnResponse | undefined>,
        options?: SetDataOptions,
    ) => {
        queryClient.setQueryData([`${key}-${user.id}`, params], updater, options)
    }

    return {
        setQueryData,
    }
}

export function useMutationPost() {
    const { t } = useTranslation()

    const addPost = useMutation<
        IPost,
        TAddPostMutationFnError,
        TAddPostProps,
        unknown
    >((props) => {
        return CommunityService.addPost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const updatePost = useMutation<
        IPost,
        TUpdatePostMutationFnError,
        TUpdatePostProps,
        unknown
    >((props) => {
        return CommunityService.updatePost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const deletePost = useMutation<
        IPost,
        TDeletePostMutationFnError,
        TDeletePostProps,
        unknown
    >((props) => {
        return CommunityService.deletePost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const likePost = useMutation<
        TLikePostResponse,
        TLikePostMutationFnError,
        TLikePostProps,
        unknown
    >((props) => {
        return CommunityService.likePost(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        addPost,
        updatePost,
        deletePost,
        likePost,
    }
}
