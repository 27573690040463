import { AppMode } from 'enums'

const {
    NODE_ENV,
    BUILD_ENV,
    RUN_ENV,
    MODE_ENV,
    REACT_APP_HOSTNAME,
    REACT_APP_API_HOST_PROD,
    REACT_APP_API_HOST_CANARY,
    REACT_APP_API_HOST_TEST,
    REACT_APP_API_HOST_DEV,
    REACT_APP_CDN_HOST,
    REACT_APP_WSS_CHAT_TOKEN_PROD,
    REACT_APP_WSS_CHAT_TOKEN_DEV,
    REACT_APP_WSS_CHAT_HOST_PROD,
    REACT_APP_WSS_CHAT_HOST_DEV,
    REACT_APP_MS_CHAT_HOST_PROD,
    REACT_APP_MS_CHAT_HOST_DEV = '',
} = process.env

let apiHost = ''
let wssChatToken = ''
let wssChatHost = ''
let msChatHost = ''

if ((NODE_ENV === 'production' && BUILD_ENV === 'production') || (NODE_ENV === 'development' && RUN_ENV === 'production')) { // production api
    apiHost = REACT_APP_API_HOST_PROD as string
    wssChatToken = REACT_APP_WSS_CHAT_TOKEN_PROD as string
    wssChatHost = REACT_APP_WSS_CHAT_HOST_PROD as string
    msChatHost = REACT_APP_MS_CHAT_HOST_PROD as string
} else if ((NODE_ENV === 'production' && BUILD_ENV === 'stage') || (NODE_ENV === 'development' && RUN_ENV === 'stage')) { // stage api
    apiHost = REACT_APP_API_HOST_TEST as string
    wssChatToken = REACT_APP_WSS_CHAT_TOKEN_DEV as string
    wssChatHost = REACT_APP_WSS_CHAT_HOST_DEV as string
    msChatHost = REACT_APP_MS_CHAT_HOST_DEV as string
} else { // run dev mode api
    apiHost = REACT_APP_API_HOST_DEV as string
}

const APP_MODE = MODE_ENV === AppMode.user || MODE_ENV === AppMode.business ? MODE_ENV : AppMode.user

const IS_PRODUCTION = NODE_ENV === 'production' && BUILD_ENV === 'production'
const HOSTNAME = REACT_APP_HOSTNAME as string
const API_BASE = 'api'

const API_HOST = apiHost
const API_BASE_URL = `${API_HOST}/${API_BASE}`

const API_HOST_PRODUCTION = REACT_APP_API_HOST_PROD as string
const API_PRODUCTION_PATH = `${API_HOST_PRODUCTION}/${API_BASE}`

const API_HOST_CANARY = REACT_APP_API_HOST_CANARY as string
const API_CANARY_PATH = `${API_HOST_CANARY}/${API_BASE}`

const CDN_HOST_DEFAULT = REACT_APP_CDN_HOST as string

const IMAGE_PATH = `${API_HOST_PRODUCTION}/img/upload/mobile-resource`
const IMAGE_RESOURCE_PATH = `${API_PRODUCTION_PATH}/directory/app-resource`
const IMAGE_SIM_PATH = `${API_BASE_URL}/sim/files/download`

const WSS_CHAT_TOKEN = wssChatToken
const WSS_CHAT_BASE_URL = wssChatHost
const MS_CHAT_BASE_URL = msChatHost

const API_URL = {
    auth: 'oauth/v2/token',

    /* user */
    users: 'users',
    profile: 'profile/{userId}?version=v2',
    profileFriends: 'profile/{userId}/friends',
    friends: 'friends/',
    friendsDelete: 'friends/delete',
    friendsMutual: 'friends/mutual-friends',
    friendsRequest: 'friends/request',
    friendsRequestConfirm: 'friends/request/confirm',
    friendsRequestReject: 'friends/request/reject',
    friendsSearch: 'users/friends/search',
    friendsChatSearch: 'users/friends/search/chat',
    logout: 'users/logout',
    allLogout: 'users/all_logout',
    guarantor: 'users/guarantor',
    requestCallAuth: 'users/requestCallAuth',
    callAuthStatus: 'users/callAuthStatus',
    friendsStore: '/users/friends/search/by-store/{id}',
    changeAccount: '/users/changeAccount/{id}',
    filterAccounts: '/filters/accounts',
    filterAccountsForSales: '/filters/accounts-for-sales',
    accountStatus: 'users/account/status',
    tags: 'users/tags/{accountId}',
    counterHistory: 'users/counterHistory/v2',
    counts: 'users/menu-counts/',
    countsDelete: 'users/menu-counts/delete',
    ratingList: 'users/rating-sessia/list',
    ratingTypeSum: 'users/rating-sessia/type-sum',
    userDelete: 'users/delete-me-pls',
    userHodgepodge: 'users/hodgepodge',
    subscription: 'subscription',

    /* directory */
    languages: 'language',
    countries: 'directory/countries?version=v2',
    translations: 'directory/app-translations?type=webApp',
    cities: 'directory/cities',
    resources: 'directory/app-resources',
    resource: 'directory/app-resource/{key}',
    currencies: 'directory/currency',
    ratingRanks: 'directory/rating-sessia-ranks',
    productTypes: 'directory/product-types/',
    paymentMethods: 'directory/payments/methods',
    paymentStatuses: 'directory/payments/statuses',

    /* agreement */
    agreement: 'agreement/company',
    agreementSign: 'agreement/item',

    /* community */
    postsCommunity: 'community/posts?version=v2',
    postsUser: 'community/posts/user/{userId}?version=v2',
    post: 'community/post/{id}/{page}?version=v2',
    postAdd: 'community/post/add',
    postUpdate: 'community/post/update/{id}',
    postLike: 'community/like/{id}',
    postLikes: 'community/post/likes/{id}',
    comments: 'community/comments/{post_id}',
    comment: 'community/comment/{id}',
    commentAdd: 'community/comment/add',
    commentRestore: 'community/comment/{id}/restore',
    communitySettings: 'community/settings',
    communityDefaultImages: 'community/posts/default-images',

    /* company */
    company: 'company',
    companyAdd: 'company/add',
    companyUpdate: 'company/update',
    companyBusinessRoles: 'company/roles',

    /* clients */
    clients: 'clients/',
    clientsSearch: 'clients/search',

    /* market */
    marketCategories: 'directory/store-categories',
    store: 'market/store',
    storeSettings: 'market/store/settings?version=v2',
    storeProfile: 'market/store/{store_id}/profile',
    personalStore: 'market/personal-store',
    catalog: 'market/{store_id}/showcase-tree',
    catalogCategory: 'market/showcase-tree',
    showcase: 'market/{store_id}/showcase',
    goods: 'market/goods',
    goodsAvailableStatus: 'market/goods/availableStatus',
    popularGoods: 'market/{store_id}/popular-goods',
    basket: 'market/{store_id}/basket',
    basketFromOrder: 'market/{store_id}/basket/from-order/{order_id}',
    orders: 'market/{store_id}/orders',
    orderStoreCustomers: 'market/store-customers/order/{order_id}',
    paymentUrl: 'market/orders/{order_id}/paymentUrl',
    deliveryStatuses: 'market/delivery/status_list',
    deliveryCost: 'market/delivery-cost',
    deliveryCountries: 'market/delivery-countries/{store_id}',
    deliveryAddresses: 'market/delivery-addresses',
    orderDeliveryList: 'market/delivery/list/{order_id}',
    companyOrders: 'market/company/{company_id}/orders',
    companyOrder: 'market/{store_id}/orders/{order_id}',
    companyVendorCodes: 'market/company/{company_id}/vendor-codes',

    cpaLink: 'cpa/link/{store_id}',

    /* marketplace */
    marketplace: 'marketplace',

    /* payout */
    payoutMarketOptions: 'payout/payoutMarketOptions',
    payoutHistory: 'payout/v2/history',
    payoutHistoryByStores: 'payout/v2/historyByStores',
    payoutOptions: 'payout/v2/payoutOptions',

    /* kicks */
    storeLoyaltySettings: 'kicks/{store_id}/loyaltySettings',

    /* social network */
    subscribe: 'subscribe',
    unsubscribeUser: 'subscribe/{userId}',
    unsubscribeStore: 'subscribe/unsubscribes/stores',
    unsubscribeStoreUser: 'subscribe/unsubscribes/stores/{store_id}/{user_id}',
    unsubscribeCompany: 'unsubscribes/company',
    blocks: 'social/blocks',
    blockUser: 'social/blocks/add',
    unBlockUser: 'social/blocks/delete/{id}',

    /* support */
    support: 'support',
    ticket: 'support/ticket',
    ticketCategory: 'support/ticket-category',
    ticketMessage: 'support/ticket/{id}/message',

    /* sessia instant messenger */
    filesUpload: 'sim/files/upload-file-part',
    conversationList: 'sim/conversation/list',
    chatHistory: 'sim/message/user/{user_id}/history?version=v2',
    channelHistory: 'sim/message/channel/{channel_id}/history?version=v2',
    channel: 'sim/channel',
    channelJoinUser: 'sim/channel/{id}/member/join',
    chatMessage: 'sim/message/user',
    channelMessage: 'sim/message/channel',
    channelMembers: 'sim/channel/members',
    channelMemberList: 'sim/channel/{id}/member/list',
    messageMarkRead: 'sim/message/mark-read/{id}',
    contactSearch: 'sim/contact/search-by-name',

    country: 'country/identify',

    reports: 'reports/v2',
    reportsSelected: 'reports/v2/selected',

    captcha: 'captcha/{captcha_name}',
    captchaGeetest: 'captcha/geetest',

    stickers: 'stickers/menu',

    /* chat microservice */
    msChatConversationList: 'conversations/get_combined_by_user_id/',
    msChatConversationCountsNew: 'conversations/get_combined_counts_by_user_id/',
    msChatChannel: 'channels/get_by_id/{id}',
    msChatChannelMembers: 'channels/memberships/get_by_channel_id/{channelId}',
    msChatChannelMembersAdd: 'channels/memberships/add',
    msChatChannelMembersDelete: 'channels/memberships/delete_by_channel_id/{channelId}',
    msChatUserBelongsConversations: 'conversations/p2p/get_belongs_by_user_id/',
    msChatUserBelongsChannels: 'channels/get_belongs_by_user_id/',
    msChatUserBelongsMessages: 'messages/get_belongs_by_user_id/',
    msChatHistoryChat: 'messages/get_history_by_users_ids/{chatUserId}',
    msChatHistoryChannel: 'messages/get_history_by_channel_id/{channelId}',
    msChatChannelCreate: 'channels/create',

    /* websocket  */
    wssChat: 'connectionTunnel',
}

export {
    IS_PRODUCTION,
    HOSTNAME,
    APP_MODE,
    API_BASE,
    API_BASE_URL,
    API_URL,
    API_HOST,
    API_HOST_PRODUCTION,
    API_PRODUCTION_PATH,
    API_HOST_CANARY,
    API_CANARY_PATH,
    CDN_HOST_DEFAULT,
    IMAGE_PATH,
    IMAGE_RESOURCE_PATH,
    IMAGE_SIM_PATH,
    WSS_CHAT_TOKEN,
    WSS_CHAT_BASE_URL,
    MS_CHAT_BASE_URL,
}
