import { AxiosError } from 'axios'
import {
    QueryFilters,
    UseQueryOptions,
    useQueryClient,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TDirectoryServiceError,
    TCitiesProps,
    TCitiesResponse,
} from 'services/DirectoryService'
import { QueryCacheKeys } from 'enums'
import { DirectoryService } from 'services'
import { getRequestError } from 'utils/helpers'

const key = QueryCacheKeys.cities

export type TFetchFnParams = TCitiesProps
export type TFetchFnError = AxiosError<TDirectoryServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TCitiesResponse, TError, TCitiesResponse, TQueryKey>

/**
 * Хук API получение списка городов
 */
export default function useFetchCities(params: TFetchFnParams, {
    cacheTime = 3600 * 1000, // 1h
    staleTime = 3600 * 1000, // 1h
    ...opts
}: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key, params], () => {
        return DirectoryService.fetchCities(params)
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        cacheTime,
        staleTime,
        ...opts,
    })
}

/**
 * Query cache data cities
 */
export function useCacheCities(cacheKey = key) {
    const queryClient = useQueryClient()

    /**
     * Get match query cache
     */
    const get = (params: TFetchFnParams, filters: QueryFilters = {}) => {
        return queryClient.getQueryData<TCitiesResponse>([key, params], filters)
    }

    /**
     * Get all queries cache
     */
    const getAll = () => {
        return queryClient.getQueriesData<TCitiesResponse>([key])
    }

    return {
        get,
        getAll,
    }
}
