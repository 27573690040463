import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IOrder, IOrderGoods } from 'interfaces'
import { TableList, NoDataInfo, Link } from 'components'
import { numberFormat } from 'utils/helpers'
import style from './OrdersOrderContentsList.module.css'

export enum ColumnKey {
    name = 'name',
    quantity = 'quantity',
    price = 'price',
    payment_in_currency = 'payment_in_currency',
    payment_by_bonuses = 'payment_by_bonuses',
    cashback_2_client = 'cashback_2_client',
    commission = 'commission',
    net_amount = 'net_amount',
    total_amount = 'total_amount',
}

type TColumn = {
    key: keyof typeof ColumnKey
    translateKey: string
    checked: boolean
}

type OrdersOrderContentsListPropType = {
    columns?: TColumn[]
    data?: IOrder
}

export const LIST_COLUMNS: TColumn[] = [
    { key: ColumnKey.name, translateKey: 'name', checked: true },
    { key: ColumnKey.quantity, translateKey: 'market_product_quantity_short', checked: true },
    { key: ColumnKey.price, translateKey: 'order_goods_price', checked: true },
    { key: ColumnKey.payment_in_currency, translateKey: 'order_payment_currency', checked: true },
    { key: ColumnKey.payment_by_bonuses, translateKey: 'order_payment_bonus', checked: true },
    { key: ColumnKey.cashback_2_client, translateKey: 'Cashback клиенту', checked: true },
    { key: ColumnKey.commission, translateKey: 'order_commission', checked: true },
    { key: ColumnKey.net_amount, translateKey: 'order_net_amount', checked: true },
    { key: ColumnKey.total_amount, translateKey: 'order_total_amount', checked: true },
]

const OrdersOrderContentsList: React.FC<OrdersOrderContentsListPropType> = ({
    columns = LIST_COLUMNS,
    data,
}) => {
    const { t } = useTranslation()

    const getFormattedValue = (key: keyof typeof ColumnKey, values: IOrderGoods) => {
        switch (key) {
            case 'name':
                return (
                    <Link
                        className={cn(style.link, style.link_blue)}
                        url=""
                    >
                        {values.goods.name}
                    </Link>
                )
            case 'price':
                return (
                    <div className={style.colValue}>
                        {values.price ? `${numberFormat(values.price)} ${data?.store.currency}` : 0}
                    </div>
                )
            case 'payment_in_currency':
                return (
                    <div className={style.colValue}>
                        {values.payment_in_currency ? `${numberFormat(values.payment_in_currency)} ${data?.store.currency}` : 0}
                    </div>
                )
            case 'payment_by_bonuses':
                return (
                    <div className={style.colValue}>
                        {values.payment_by_bonuses ? numberFormat(values.payment_by_bonuses) : 0}
                    </div>
                )
            case 'cashback_2_client':
                return (
                    <div className={style.colValue}>
                        {values.cashback_2_client ? numberFormat(values.cashback_2_client) : 0}
                    </div>
                )
            case 'commission':
                return (
                    <div className={style.colValue}>
                        {values.commission ? numberFormat(values.commission) : 0}
                    </div>
                )
            case 'net_amount':
                return (
                    <div className={style.colValue}>
                        {values.net_amount ? `${numberFormat(values.net_amount)} ${data?.store.currency}` : 0}
                    </div>
                )
            case 'total_amount':
                return (
                    <div className={style.colValue}>
                        {values.total_amount ? `${numberFormat(values.total_amount)} ${data?.store.currency}` : 0}
                    </div>
                )
            default:
                return values[key]
        }
    }

    return (
        data ? (
            <TableList classes={style.list}>
                <TableList.Header classes={style.header}>
                    {columns.map((item) => (
                        <TableList.Col classes={cn(style.col, style.col_header)} key={item.key}>
                            <div className={style.headerTitle}>
                                {t(item.translateKey)}
                            </div>
                        </TableList.Col>
                    ))}
                </TableList.Header>
                <TableList.Body>
                    {!data.content.length && (
                        <NoDataInfo text={t('no_data')} />
                    )}
                    {data.content.map((item) => (
                        <TableList.Row classes={style.row} key={item.goods.id}>
                            {columns.map((itemColumn) => (
                                <TableList.Col classes={cn(style.col, style.col_body)} key={itemColumn.key}>
                                    <div className={style.colTitle}>
                                        {t(itemColumn.translateKey)}
                                    </div>
                                    <div className={style.colInner}>
                                        {getFormattedValue(itemColumn.key, item)}
                                    </div>
                                </TableList.Col>
                            ))}
                        </TableList.Row>
                    ))}
                </TableList.Body>
                <TableList.Footer classes={style.footer}>
                    <TableList.Row classes={style.row}>
                        {columns.map((itemColumn) => (
                            <TableList.Col
                                classes={cn(
                                    style.col,
                                    style.col_footer,
                                    { [style.col_mobile_hidden]: itemColumn.key !== 'name' && itemColumn.key !== 'total_amount' },
                                )}
                                key={itemColumn.key}
                            >
                                {itemColumn.key === 'name' && t('market_product_total')}
                                {itemColumn.key === 'total_amount' && (
                                    <div className={style.colValue}>
                                        {data && `${numberFormat(data.order_sum)} ${data.store.currency}`}
                                    </div>
                                )}
                            </TableList.Col>
                        ))}
                    </TableList.Row>
                </TableList.Footer>
            </TableList>
        ) : null
    )
}

export default OrdersOrderContentsList
