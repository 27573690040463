import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    SetDataOptions,
    Updater,
    useQueryClient,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TMarketServiceError,
    TCompanyOrderProps,
    TCompanyOrderResponse,
    TCompanyOrderUpdateProps,
    TCompanyOrderUpdateResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TCompanyOrderProps
export type TQueryFnOpts = UseQueryOptions<
    TCompanyOrderResponse,
    [string, AxiosError<TMarketServiceError, TFetchFnParams>],
    TCompanyOrderResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.companyOrder

/**
 * Hook API company order
 */
export default function useFetchCompanyOrder(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchCompanyOrder(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationCompanyOrder() {
    const { t } = useTranslation()

    const update = useMutation<
        TCompanyOrderUpdateResponse,
        [string, AxiosError<TMarketServiceError, TCompanyOrderUpdateProps>],
        TCompanyOrderUpdateProps
    >((props) => {
        return MarketService.updateCompanyOrder(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        update,
    }
}

/**
 * Operations with query cached data company order
 */
export function useQueryDataCompanyOrder() {
    const queryClient = useQueryClient()
    const user = useAppSelector((state) => state.user)

    /**
     * Update cached data of multiple queries
     */
    const setQueryData = (
        params: TCompanyOrderUpdateProps,
        updater: Updater<TCompanyOrderUpdateResponse | undefined, TCompanyOrderUpdateResponse | undefined>,
        options?: SetDataOptions,
    ) => {
        queryClient.setQueriesData([`${key}-${user.id}`, params], updater, options)
    }

    return {
        setQueryData,
    }
}
