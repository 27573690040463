import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TCommunityServiceError,
    TCommunityProps,
    TUnsubscribeCommunityProps,
    TUnsubscribeCommunityResponse,
} from 'services/CommunityService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { CommunityService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TCommunityProps
export type TFetchFnResponse = any
export type TFetchFnError = AxiosError<TCommunityServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

export type TUnsubscribeCommunityMutationFnError = [
    string,
    AxiosError<TCommunityServiceError, TUnsubscribeCommunityProps>
]

const key = QueryCacheKeys.communities

/**
 * Хук API получение списка сообществ
 */
export default function useFetchCommunity(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return CommunityService.fetchCommunity(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationCommunity() {
    const { t } = useTranslation()

    const unsubscribe = useMutation<
        TUnsubscribeCommunityResponse,
        TUnsubscribeCommunityMutationFnError,
        TUnsubscribeCommunityProps,
        unknown
    >((props) => {
        return CommunityService.unsubscribeCommunity(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        unsubscribe,
    }
}
