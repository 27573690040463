import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'

import { ICatalogItem, IGoods, IBreadcrumb } from 'interfaces'
import { APP_URL } from 'config/app'
import { Breadcrumbs } from 'components'

type ShopBreadcrumbsPropType = {
    storeId: number
    catalogId: number
    categories?: ICatalogItem[]
    goods?: IGoods
}

const StoreBreadcrumbs: React.FC<ShopBreadcrumbsPropType> = ({
    storeId,
    catalogId,
    categories = [],
    goods,
}) => {
    const breadcrumbs = useMemo(() => {
        if (catalogId) {
            const crumbs: IBreadcrumb[] = []
            let category = categories.find((item) => item.id === Number(catalogId))

            if (goods) {
                crumbs.push({ id: goods.id, name: goods.name })
            }

            while (category) {
                const {
                    id,
                    name,
                    view_type,
                    parent,
                } = category

                if (view_type === 2) {
                    crumbs.push({
                        id,
                        name,
                        url: (!goods && id !== Number(catalogId)) || goods ? getBreadcrumbUrl(id) : '',
                    })
                }

                if (parent) {
                    category = categories.find((item) => item.id === parent)
                } else {
                    category = undefined
                }
            }

            return crumbs.reverse()
        }

        return []
    }, [catalogId, categories])

    function getBreadcrumbUrl(id: number): string {
        return generatePath(APP_URL.catalog, {
            id: storeId,
            catalogId: id,
        })
    }

    return <Breadcrumbs items={breadcrumbs} />
}

export default StoreBreadcrumbs
