import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IFriendRequestProfile } from 'interfaces'
import { APP_URL } from 'config/app'
import { Avatar, Button } from 'components'
import styleCommon from 'styles/modules/common.module.css'
import style from './FriendsUserRequestItem.module.css'

type FriendsUserItemPropType = {
    data: IFriendRequestProfile
    isLoading?: boolean
    isMobileView?: boolean
    onFriendRequestConfirm?: (id: number) => void
    onFriendRequestReject?: (id: number) => void
}

const FriendsUserRequestItem: React.FC<FriendsUserItemPropType> = ({
    data,
    isLoading,
    isMobileView,
    onFriendRequestConfirm,
    onFriendRequestReject,
}) => {
    const { t } = useTranslation()

    const urlProfile = useMemo(() => {
        return data ? APP_URL.profile.replace(':id', String(data.id)) : ''
    }, [data])

    const handlerClickConfirm = () => {
        if (onFriendRequestConfirm) {
            onFriendRequestConfirm(data.id)
        }
    }

    const handlerClickReject = () => {
        if (onFriendRequestReject) {
            onFriendRequestReject(data.id)
        }
    }

    return (
        <div className={cn(style.item, { [styleCommon.updating]: isLoading })}>
            <NavLink to={urlProfile}>
                <Avatar
                    classes={style.avatar}
                    name={data.name}
                    surname={data.surname}
                    src={data.photo}
                    width={50}
                    height={50}
                />
            </NavLink>
            <div className={cn(style.content, { [style.content_mobile]: isMobileView })}>
                <div className={style.name}>
                    <NavLink className={style.nameLink} to={urlProfile}>
                        {`${data.name ?? ''} ${data.surname ?? ''}`}
                    </NavLink>
                </div>
                <div className={style.actions}>
                    <Button
                        textUpper
                        classes={style.action}
                        size={isMobileView ? 'size40' : 'size46'}
                        onClick={handlerClickConfirm}
                    >
                        {t('accept')}
                    </Button>
                    <Button
                        textUpper
                        classes={style.action}
                        styleType="bordered"
                        size={isMobileView ? 'size40' : 'size46'}
                        onClick={handlerClickReject}
                    >
                        {t('hide')}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default FriendsUserRequestItem
