import React, { useEffect, useMemo } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IPersonalStore, IGoods } from 'interfaces'
import { CardContainer } from 'layout'
import {
    Button,
    Checkbox,
    CustomSelect,
    Tag,
} from 'components'
import styleForm from 'styles/modules/form.module.css'
import style from './WarehouseLeftoversFilterForm.module.css'

// FIXME on ready backend
export enum FieldName {
    store = 'store',
    goods = 'goods',
    isDeleted = 'isDeleted',
    isService = 'isService',
}

export type FormDataType = {
    [FieldName.store]: number[]
    [FieldName.goods]: number[]
    [FieldName.isDeleted]?: 0|1
    [FieldName.isService]?: 0|1
}

export type WarehouseLeftoversFilterFormPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    stores?: IPersonalStore[]
    goods?: IGoods[]
    isSubmitting: boolean
    onChange?: (data: FormDataType) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.store]: [0],
    [FieldName.goods]: [],
}

const WarehouseLeftoversFilterForm: React.FC<WarehouseLeftoversFilterFormPropType> = ({
    classes,
    classesBody,
    classesFooter,
    stores,
    goods,
    isSubmitting,
    onChange,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { change } = useForm<FormDataType>()
    const { values, hasValidationErrors } = useFormState<FormDataType>()

    const storesData: Record<number, string> = useMemo(() => {
        return stores
            ? stores.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [stores])

    const goodsData: Record<number, string> = useMemo(() => {
        return goods
            ? goods.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [goods])

    const handlerClickAddStore = () => {
        const isSetEmptyStoreItem = values[FieldName.store].includes(0)

        if (!isSetEmptyStoreItem) {
            change(FieldName.store, [...values[FieldName.store], 0])
        }
    }

    useEffect(() => {
        if (onChange) {
            onChange(values)
        }
    }, [values])

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={classesBody}>
                {values[FieldName.store].map((storeId, index) => (
                    <div className={cn(styleForm.row, styleForm.row_20)} key={storeId}>
                        <CardContainer gap="15px">
                            <div className={style.block}>
                                {index === 0 && (
                                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                        {t('stores')}
                                    </div>
                                )}
                                <Field
                                    <FormDataType[FieldName.store]>
                                    name={FieldName.store}
                                    render={({ input }) => (
                                        <>
                                            <CustomSelect.Select>
                                                <CustomSelect.Field classes={style.fieldSelect}>
                                                    {!storeId && (
                                                        <div className={style.fieldSelectText}>
                                                            {t('market_select_store')}
                                                        </div>
                                                    )}
                                                    {!!storeId && storesData[storeId]}
                                                </CustomSelect.Field>
                                                <CustomSelect.Options>
                                                    {stores?.map((item) => (
                                                        <CustomSelect.Option
                                                            isActive={input.value.includes(item.id)}
                                                            id={item.id}
                                                            key={item.id}
                                                            onClick={() => {
                                                                input.onChange(input.value.map((val, idx) => {
                                                                    return idx === index ? item.id : val
                                                                }))
                                                            }}
                                                        >
                                                            {item.name}
                                                        </CustomSelect.Option>
                                                    ))}
                                                </CustomSelect.Options>
                                            </CustomSelect.Select>
                                            {!!storesData[storeId] && (
                                                <div className={style.fieldTags}>
                                                    <Tag
                                                        classes={style.fieldTag}
                                                        classesContent={style.tagContent}
                                                        classesCross={style.tagCross}
                                                        onDelete={() => {
                                                            /* TODO remove store goods */
                                                            input.onChange(input.value.reduce((acc, val, idx) => {
                                                                return idx === index ? acc : [...acc, val]
                                                            }, [] as number[]))
                                                        }}
                                                    >
                                                        {storesData[storeId]}
                                                    </Tag>
                                                </div>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            <div className={style.block}>
                                {index === 0 && (
                                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                        {/* TODO */}
                                        Продукт
                                    </div>
                                )}
                                <Field
                                    <FormDataType[FieldName.goods]>
                                    name={FieldName.goods}
                                    render={({ input }) => (
                                        <>
                                            <CustomSelect.Select>
                                                <CustomSelect.Field classes={style.fieldSelect}>
                                                    {!input.value.length && (
                                                        <div className={style.fieldSelectText}>
                                                            {/* TODO */}
                                                            Выберите один или несколько продуктов
                                                        </div>
                                                    )}
                                                    {!!input.value.length && (
                                                        goodsData[input.value[input.value.length - 1]]
                                                    )}
                                                </CustomSelect.Field>
                                                <CustomSelect.Options>
                                                    {goods?.map((item) => (
                                                        <CustomSelect.Option
                                                            isActive={input.value.includes(item.id)}
                                                            id={item.id}
                                                            key={item.id}
                                                            onClick={() => {
                                                                // FIXME api request data structure
                                                                input.onChange([...input.value, item.id])
                                                            }}
                                                        >
                                                            {item.name}
                                                        </CustomSelect.Option>
                                                    ))}
                                                </CustomSelect.Options>
                                            </CustomSelect.Select>
                                            {input.value.map((goodsId) => (
                                                <div className={style.fieldTags} key={goodsId}>
                                                    <Tag
                                                        classes={style.fieldTag}
                                                        classesContent={style.tagContent}
                                                        classesCross={style.tagCross}
                                                        onDelete={() => {
                                                            input.onChange(input.value.reduce((acc, val) => {
                                                                return val === goodsId ? acc : [...acc, val]
                                                            }, [] as number[]))
                                                        }}
                                                    >
                                                        {goodsData[goodsId]}
                                                    </Tag>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                />
                            </div>
                        </CardContainer>
                    </div>
                ))}
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Button
                        classes={style.actionAdd}
                        styleType="transparent"
                        onClick={handlerClickAddStore}
                    >
                        <div className={style.actionAddText}>
                            {t('add_store')}
                        </div>
                        <div className={style.iconAdd} />
                    </Button>
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        <FormDataType[FieldName.isDeleted]>
                        name={FieldName.isDeleted}
                        render={({ input }) => (
                            <Checkbox
                                {...input}
                                styleType="small"
                                // TODO
                                text="Показать товары с видимостью “Удален”"
                                value={String(input.value)}
                                checked={Boolean(input.value)}
                                onChange={(e) => {
                                    input.onChange(Number(e.target.checked))
                                }}
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        <FormDataType[FieldName.isService]>
                        name={FieldName.isService}
                        render={({ input }) => (
                            <Checkbox
                                {...input}
                                styleType="small"
                                // TODO
                                text="Показать товары с категорией “Услуга”"
                                value={String(input.value)}
                                checked={Boolean(input.value)}
                                onChange={(e) => {
                                    input.onChange(Number(e.target.checked))
                                }}
                            />
                        )}
                    />
                </div>
            </div>
            <div className={classesFooter}>
                <Button
                    classes={style.control}
                    size="size44"
                    type="submit"
                    text={t('refresh')}
                    disabled={hasValidationErrors}
                />
            </div>
        </form>
    )
}

export default WarehouseLeftoversFilterForm
