import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { ICountry, ICity, IUser } from 'interfaces'
import { FormDataType } from 'forms/RegistrationCompanyForm/RegistrationCompanyForm'
import { TValidationFieldRule } from 'utils/validators'
import { AppMode } from 'enums'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import { Modal, Button, Cropper } from 'components'
import { useForm } from 'hooks'
import {
    AuthService,
    AppService,
    CompanyService,
    UserService,
} from 'services'
import { RegistrationCompanyForm } from 'forms'
import { showAlertNotify, getRequestError } from 'utils/helpers'

export type RegistrationCompanyActionPropType = {}

const initFormData: FormDataType = {
    name: '',
    juridical_name: '',
    country_id: 0,
    city: 0,
    phone_number: '',
    email: '',
    logo: '',
}

const RegistrationCompanyAction: React.FC<RegistrationCompanyActionPropType> = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const FORM_VALIDATION_RULES: TValidationFieldRule[] = [{
        field: 'name',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'juridical_name',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'city',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'phone_number',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'email',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }]

    const config = useSelector(appSelectors.config)
    const user = useSelector(userSelectors.user)
    const countries = useSelector(appSelectors.countries)

    const [regDataCompany] = useState(UserService.getRegData<AppMode.business>())
    const [selectedCountry, setSelectedCountry] = useState<ICountry>()
    const [selectedCity, setSelectedCity] = useState<ICity>()
    const [imageAvatar, setImageAvatar] = useState<string>() // base64
    const [imageAvatarCropped, setImageAvatarCropped] = useState<string>() // base64

    const [isShowModalAvatar, setIsShowModalAvatar] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {
        formData,
        formErrors,
        setFormValue,
        setFormError,
        validateForm,
        isValidForm,
    } = useForm<FormDataType>(regDataCompany
        // city=0 fix for selectedCity, no api request for get city by id
        ? { ...initFormData, ...regDataCompany, city: 0 }
        : { ...initFormData },
    FORM_VALIDATION_RULES)

    const isDisabled = useMemo(() => !isValidForm(), [formData])

    const handlerSubmit = () => {
        if (validateForm()) {
            submitAction()
        }
    }

    const handlerChangeCountry = (data: ICountry) => {
        setFormValue('country_id', data.id)
        setSelectedCountry(data)
        resetCity()
        resetPhone()
    }

    const handlerChangeCity = (data: ICity) => {
        setFormValue('city', data.id)
        setSelectedCity(data)
    }

    const handlerChangePhone = (data: string) => {
        setFormValue('phone_number', data)
    }

    const handlerChangePhoto = (file: File) => {
        if (file) {
            uploadImageAction(file)
        }
    }

    const handlerChangeFormData = (name: string, value: string) => {
        setFormValue(name, value)
    }

    const handlerSetAvatar = () => {
        setFormValue('logo', imageAvatarCropped)
        setIsShowModalAvatar(false)
    }

    const handlerCropAvatar = (data: string) => {
        if (data) {
            setImageAvatarCropped(data)
        }
    }

    function getUserPhoneNumber(userData: IUser) {
        const { country_id: countryId, phone_number: phoneNumber } = userData.user_phones[0] || {}
        const countryPhone = countries.find((item) => item.id === countryId)

        if (countryPhone) {
            return phoneNumber.replace(countryPhone.code, '')
        }

        return ''
    }

    function resetCity() {
        setFormValue('city', 0)
        setSelectedCity(undefined)
    }

    function resetPhone() {
        setFormValue('phone_number', '')
    }

    function submitAction() {
        const {
            country_id,
            phone_number,
            email,
            ...params
        } = formData

        setIsSubmitting(true)
        CompanyService.addCompany({
            ...params,
            email_list: [email],
            phone_list: [{ country_id, phone_number }],
        })
            .then(({ data }) => {
                UserService.clearRegData()
                authorizeAction(user)
            })
            .catch((err) => {
                const { data } = err?.response || {}
                const errorText = getRequestError(err)

                if (Object.getPrototypeOf(data) === Object.prototype) {
                    Object.entries(data).forEach(([key, value]) => {
                        if (key in formData && typeof value === 'string') {
                            setFormError(key, value)
                        }
                    })
                }

                setIsSubmitting(false)
                showAlertNotify({ type: 'error', message: errorText || t('update_error') })
            })
    }

    function uploadImageAction(file: File) {
        AppService.uploadImageToBase64(file)
            .then((res) => {
                setImageAvatar(res)
                setIsShowModalAvatar(true)
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err?.message })
            })
    }

    function authorizeAction(updatedUser: IUser) {
        AuthService.authorizeBusiness(updatedUser, config)
            .then((route) => {
                if (route) {
                    history.push(route)
                }
            })
            .catch((err) => {
                const [error] = err?.response?.data || []

                if (error && typeof error === 'string') {
                    showAlertNotify({ type: 'error', message: error })
                }
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    useEffect(() => {
        if (regDataCompany) {
            const { country_id: countryId } = regDataCompany
            const country = countries.find((item) => item.id === countryId)

            if (country) {
                setSelectedCountry(country)
            }
        } else if (user) {
            setFormValue('country_id', user.country.id)
            setFormValue('phone_number', getUserPhoneNumber(user))
            setSelectedCountry(user.country)
        } else if (countries.length) {
            setFormValue('country_id', countries[0].id)
            setSelectedCountry(countries[0])
        }
    }, [])

    useEffect(() => {
        UserService.saveRegData(formData)
    }, [formData])

    return (
        <>
            <RegistrationCompanyForm
                data={formData}
                errors={formErrors}
                country={selectedCountry}
                city={selectedCity}
                countries={countries}
                isSubmitting={isSubmitting}
                isDisabled={isDisabled}
                onChangeCountry={handlerChangeCountry}
                onChangeCity={handlerChangeCity}
                onChangePhone={handlerChangePhone}
                onChangePhoto={handlerChangePhoto}
                onChange={handlerChangeFormData}
                onSubmit={handlerSubmit}
            />

            <Modal
                isOpen={isShowModalAvatar}
                size="medium"
                onClose={() => setIsShowModalAvatar(false)}
            >
                <Modal.Header title={t('edit_photo')} />
                <Modal.Body>
                    <Cropper
                        className="cropperRound"
                        source={imageAvatar}
                        onCrop={handlerCropAvatar}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        text={t('install')}
                        size="size40"
                        onClick={handlerSetAvatar}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default RegistrationCompanyAction
