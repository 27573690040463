import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb, IShopFilter } from 'interfaces'
import { TShopsProps } from 'services/MarketplaceService'
import { APP_URL } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import {
    PageTitle,
    Breadcrumbs,
    InfiniteScroll,
    ErrorMsg,
} from 'components'
import { useFetchMarkerCategories, useFetchInfiniteShops, useFetchInfiniteShopsStatic } from 'containers/Market/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { scrollTop } from 'utils/helpers'
import { ShopItem, ShopFilter } from './components'
import style from './Shops.module.css'

type TFilterProps = Record<IShopFilter['filter']['key'], IShopFilter['filter']['value']>

const LIMIT = 12
const OFFSET = 0
const FILTER_ACTIVE_ITEM_ID_DEFAULT = 1
const FILTER_ITEMS: IShopFilter[] = [
    {
        id: 1,
        translateKey: 'by_distance',
        active: false,
        filter: { key: 'sort_by', value: 'dist' },
    }, {
        id: 2,
        translateKey: 'by_cashbacks',
        marker: 'markerWhite',
        active: false,
        filter: { key: 'sort_by', value: 'cashback' },
    }, {
        id: 3,
        translateKey: 'by_kickbacks',
        marker: 'markerBlue',
        active: false,
        filter: { key: 'sort_by', value: 'kickback' },
    },
    // {
    //     id: 4,
    //     translateKey: 'По рейтингу',
    //     marker: '',
    //     active: false,
    //     filter: { key: '', value: '' },
    // },
    {
        id: 5,
        translateKey: 'by_friends',
        marker: '',
        active: false,
        filter: { key: 'friends_only', value: true },
    },
]

const Shops: React.FC = () => {
    const { id: categoryId }: { id?: string } = useParams()
    const { t } = useTranslation()

    const geolocation = useSelector(userSelectors.geolocation)

    const [filterActiveItemId, setFilterActiveItemId] = useState<number | undefined>()
    const [shopsParams, setShopsParams] = useState<TShopsProps>({ limit: LIMIT, offset: OFFSET })
    const [isInitRequest, setIsInitRequest] = useState(false)
    const [isHideLoader, setIsHideLoader] = useState(false)

    const {
        data: dataMarkerCategories,
    } = useFetchMarkerCategories({
        enabled: !!categoryId,
    })

    const {
        isInitialLoading: isLoadingShops,
        data: dataShops,
        error: errorShops,
        fetchNextPage: fetchNextPageShops,
    } = useFetchInfiniteShops({
        category_id: categoryId,
        ...shopsParams,
    }, {
        enabled: !!categoryId && isInitRequest,
    })

    const {
        isInitialLoading: isLoadingShopsStatic,
        data: dataShopsStatic,
        error: errorShopsStatic,
        fetchNextPage: fetchNextPageShopsStatic,
    } = useFetchInfiniteShopsStatic({
        ...shopsParams,
    }, {
        enabled: !categoryId && isInitRequest,
    })

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        if (!categoryId) {
            return [
                { id: 1, name: t('stores'), url: APP_URL.shopsSettings },
                { id: 2, name: t('my_stores') },
            ]
        }
        if (dataMarkerCategories) {
            const category = dataMarkerCategories.find((item) => item.id === Number(categoryId))
            if (category) {
                return [
                    { id: 1, name: t('stores'), url: APP_URL.market },
                    { id: category.id, name: category.name },
                ]
            }
        }

        return [{ id: 1, name: t('stores'), url: APP_URL.market }]
    }, [categoryId, dataMarkerCategories])

    const isLoading = useMemo(() => {
        return isLoadingShops || isLoadingShopsStatic
    }, [isLoadingShops, isLoadingShopsStatic])

    const data = useMemo(() => {
        if (categoryId && dataShops) {
            return dataShops
        }
        if (!categoryId && dataShopsStatic) {
            return dataShopsStatic
        }

        return undefined
    }, [categoryId, dataShops, dataShopsStatic])

    const error = useMemo(() => {
        if (errorShops) {
            const [msg] = errorShops
            return msg
        }
        if (errorShopsStatic) {
            const [msg] = errorShopsStatic
            return msg
        }

        return undefined
    }, [errorShops, errorShopsStatic])

    const handlerOnLoad = () => {
        if (dataShops) {
            fetchNextPageShops()
        }
        if (dataShopsStatic) {
            fetchNextPageShopsStatic()
        }
    }

    const handlerClickShopFilter = (filters: IShopFilter[]) => {
        const params = filters.reduce<TFilterProps>((acc, item) => {
            return item.active ? { ...acc, [item.filter.key]: item.filter.value } : acc
        }, {})

        setShopsParams((prevState) => ({ ...prevState, ...params, offset: OFFSET }))
        setIsHideLoader(false)
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (geolocation) {
            const { lat, lon } = geolocation

            if (lat && lon) {
                setFilterActiveItemId(FILTER_ACTIVE_ITEM_ID_DEFAULT)
                setShopsParams((prevState) => ({ ...prevState, lat, lng: lon }))
            }

            setTimeout(() => setIsInitRequest(true))
        }
    }, [geolocation])

    useEffect(() => {
        if (dataShops) {
            const { pages } = dataShops
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoader(true)
            }
        }
    }, [dataShops])

    useEffect(() => {
        if (dataShopsStatic) {
            const { pages } = dataShopsStatic
            const pagesLen = pages.length

            if (pagesLen && pages[pagesLen - 1].length < LIMIT) {
                setIsHideLoader(true)
            }
        }
    }, [dataShopsStatic])

    useEffect(() => {
        if (error) {
            setIsHideLoader(true)
        }
    }, [error])

    return (
        <ContentContainer classes={style.content} size="three-quarters">
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            <div className={style.filters}>
                <ShopFilter
                    classes={cn(style.filter, { [style.filter_loading]: isLoading })}
                    items={FILTER_ITEMS}
                    activeId={filterActiveItemId}
                    disabled={!geolocation?.lat && !geolocation?.lon}
                    onClick={handlerClickShopFilter}
                />
            </div>

            <InfiniteScroll
                options={{ rootMargin: '0px 0px 25% 0px' }}
                isHideLoader={isHideLoader}
                onLoad={handlerOnLoad}
            >
                <CardContainer>
                    {data?.pages.map((page, i) => (
                        // eslint-disable-next-line
                        <React.Fragment key={i}>
                            {page.map((shop) => (
                                <ShopItem shop={shop} key={shop.id} />
                            ))}
                        </React.Fragment>
                    ))}
                </CardContainer>
            </InfiniteScroll>

            {!isLoading && !data && error && (
                <ErrorMsg error={error} />
            )}
        </ContentContainer>
    )
}

export default Shops
