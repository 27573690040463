import React from 'react'
import cn from 'classnames'

import { vGutter } from 'styles/modules/variables.module.css'
import style from './CardContainer.module.css'

type CardContainerPropType = {
    classes?: string
    gap?: string
}

const CardContainer: React.FC<CardContainerPropType> = ({
    children,
    classes,
    gap = vGutter,
}) => {
    return (
        <div
            className={cn(style.container, classes)}
            style={{ gap }}
        >
            {children}
        </div>
    )
}

export default CardContainer
