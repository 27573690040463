import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb } from 'interfaces'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import * as appSelectors from 'containers/App/app-selectors'
import { useFetchPersonalStores } from 'containers/Market/hooks'
import {
    PageTitle,
    Breadcrumbs,
    ErrorMsg,
    Loader,
} from 'components'
import { useWindowResize } from 'hooks'
import { scrollTop } from 'utils/helpers'
import { StoreItem, StoreItemAdd } from './components'
import style from './Stores.module.css'

/**
 * Personal stores business
 */
const Stores: React.FC = () => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const { forbidden: forbiddenConfig } = useSelector(appSelectors.config)

    const {
        isInitialLoading: isLoading,
        data: dataPersonalStores,
        error: errorPersonalStores,
    } = useFetchPersonalStores()

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        return [
            { id: 1, name: t('stores') },
        ]
    }, [])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <ContentContainer classes={style.content} size="half">
            <PageTitle>
                <Breadcrumbs items={breadcrumbs} />
            </PageTitle>

            {isLoading && (
                <Loader />
            )}

            {!isLoading && errorPersonalStores && (
                <ErrorMsg error={errorPersonalStores[0]} />
            )}

            {!isLoading && dataPersonalStores && (
                <CardContainer>
                    {dataPersonalStores.map((item) => (
                        !item.is_closed && (
                            <StoreItem
                                itemSize={itemSize}
                                data={item}
                                key={item.id}
                            />
                        )
                    ))}
                    {forbiddenConfig.storeAdd !== false && (
                        <StoreItemAdd
                            itemSize={itemSize}
                            url={APP_URL.storeCreate}
                        />
                    )}
                </CardContainer>
            )}
        </ContentContainer>
    )
}

export default Stores
