import { QueryClient } from '@tanstack/react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 300 * 1000, // 5m
            cacheTime: 600 * 1000, // 10m
            retry: (failureCount, error) => {
                const [, err] = Array.isArray(error) ? error : []
                const { status, response } = err || {}
                const statusError = status || response?.status

                if (statusError === 401 || statusError === 403 || statusError === 404) {
                    return false
                }

                return failureCount + 1 < 3
            },
        },
    },
})

export default queryClient
