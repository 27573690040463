import React from 'react'

import { ICommunityDefaultImage } from 'interfaces'
import { CardV2 } from 'components'

type PhotoBankImageSelectorImagePropType = {
    data: ICommunityDefaultImage
    onClick: (data: ICommunityDefaultImage) => void
}

const PhotoBankImageSelectorImage: React.FC<PhotoBankImageSelectorImagePropType> = ({ data, onClick }) => {
    const handlerClick = () => {
        onClick(data)
    }
    return (
        <CardV2
            size="thirdWidth"
            key={data.id}
            onClick={handlerClick}
        >
            <CardV2.Image
                src={data.image_web_path}
                width={400}
            />
        </CardV2>
    )
}

export default PhotoBankImageSelectorImage
