import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { FormDataType, FieldName, formDataInitial } from 'forms/ReportsPaymentForm/ReportsPaymentForm'
import { useFetchCurrencies } from 'containers/App/hooks'
import { useFetchStores } from 'containers/Store/hooks'
import { ReportsPaymentForm } from 'forms'

type ReportsPaymentActionPropType = {
    classes?: string
    onChange?: (data: FormDataType) => void
    onSuccess?: (data:FormDataType) => void
}

const ReportsPaymentAction: React.FC<ReportsPaymentActionPropType> = ({
    classes,
    onChange,
    onSuccess,
}) => {
    const { t } = useTranslation()

    const [initialValues, setInitialValues] = useState<FormDataType>(formDataInitial)

    // FIXME useFetch on ready api request
    const [dataDatePeriod] = useState([
        { id: 1, name: t('calendar_period_day') },
        { id: 2, name: t('calendar_period_month') },
        { id: 3, name: t('calendar_period_year') },
    ])

    // FIXME useFetch on ready api request
    const [dataDivideByStores] = useState([
        { id: 0, name: 'Выключена' },
        { id: 1, name: 'Включена' },
    ])

    const { data: dataCurrencies } = useFetchCurrencies()

    const { data: dataStores } = useFetchStores()

    const handlerSubmit = (params: FormDataType) => {
        if (onSuccess) {
            onSuccess(params)
        }

        return Promise.resolve()
    }

    useEffect(() => {
        if (dataDatePeriod?.length) {
            /** Set default date period */
            setInitialValues((prevState) => {
                if (!prevState[FieldName.period]) {
                    return {
                        ...prevState,
                        [FieldName.period]: dataDatePeriod[0].id,
                    }
                }

                return prevState
            })
        }
    }, [dataDatePeriod])

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (!values[FieldName.store].length) {
                    errors[FieldName.store] = true
                }
                if (values[FieldName.dateFrom] && values[FieldName.dateTo]) {
                    const dateFrom = values[FieldName.dateFrom]!
                    const dateTo = values[FieldName.dateTo]!

                    /** error: end date before the start date */
                    if (new Date(dateFrom) > new Date(dateTo)) {
                        errors[FieldName.dateTo] = true
                    }
                }

                return errors
            }}
            render={({ handleSubmit, submitting }) => (
                <ReportsPaymentForm
                    classes={classes}
                    isSubmitting={submitting}
                    datePeriod={dataDatePeriod}
                    stores={dataStores}
                    divideByStores={dataDivideByStores}
                    currencies={dataCurrencies}
                    onChange={onChange}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default ReportsPaymentAction
