import {
    IPersonalStore,
} from 'interfaces'
import { TUpdatePersonalStoreProps } from 'services/MarketService'

export default class PostDto {
    static getPersonalStoreProps(data: IPersonalStore, currencyId: number): TUpdatePersonalStoreProps {
        const {
            id,
            name,
            category,
            sub_title: subTitle,
            description,
            address,
            logo_image: logo,
            icon_image: icon,
            rewardAutoApproveDays,
            is_closed: isClosed,
            emailAlertSettings,
        } = data || {}

        return {
            id,
            name,
            category: category.id,
            currency: currencyId,
            subTitle,
            description,
            address,
            logo,
            icon,
            rewardAutoApproveDays,
            isClosed,
            emailAlertSettings: {
                notifyShopAdmins: emailAlertSettings.notifyShopAdmins,
                notifyShopCashiers: emailAlertSettings.notifyShopCashiers,
                notifySomebodyElse: emailAlertSettings.notifySomebodyElse,
            },
        }
    }
}
