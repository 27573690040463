import React from 'react'
import { NavLink } from 'react-router-dom'

import { IBreadcrumb } from 'interfaces'
import { SvgResource } from 'components'

import style from './Breadcrumbs.module.css'

type BreadcrumbsPropType = {
    items: IBreadcrumb[]
}

const Breadcrumbs: React.FC<BreadcrumbsPropType> = ({ items }) => {
    return (
        <ul className={style.breadcrumbs}>
            {items.map((item, index) => (
                item.name && (
                    <React.Fragment key={`fragment${item.id}`}>
                        {(index !== 0) && (index % 1 === 0) && (
                            <li className={style.item} key={`separator${item.id}`}>
                                <span className={style.separator}>
                                    <SvgResource
                                        resourceKey="business_wallet_details_arrow_right_black_svg"
                                        width={18}
                                        height={14}
                                    />
                                </span>
                            </li>
                        )}
                        <li className={style.item} key={item.id}>
                            {item.url ? (
                                <NavLink className={style.link} to={item.url}>
                                    {item.name}
                                </NavLink>
                            ) : (
                                <span className={style.text}>
                                    {item.name}
                                </span>
                            )}
                        </li>
                    </React.Fragment>
                )
            ))}
        </ul>
    )
}

export default Breadcrumbs
