import React from 'react'
import cn from 'classnames'

import style from './Checkbox.module.css'

type CheckboxPropType = {
    name: string
    classes?: string
    classesBox?: string
    classesText?: string
    styleType?: 'default' | 'small'
    text?: string
    value?: string
    checked?: boolean
    disabled?: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: React.FC<CheckboxPropType> = ({
    name,
    classes,
    classesBox,
    classesText,
    styleType = 'default',
    text,
    value,
    checked,
    disabled,
    onChange,
}) => {
    return (
        <label className={cn(style.label, classes)}>
            <input
                className={style.checkbox}
                type="checkbox"
                name={name}
                value={value}
                checked={!!checked}
                disabled={disabled}
                onChange={onChange}
            />
            <span className={cn(style.box, style[`box_${styleType}`], classesBox)} />
            {text && (
                <span className={cn(style.text, classesText)}>
                    {text}
                </span>
            )}
        </label>
    )
}

export default Checkbox
