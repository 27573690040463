import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { DatePeriod } from 'enums'
import { Arrow, Button } from 'components'
import { dateTimeFormat } from 'utils/helpers'
import style from './DateChanger.module.css'

type DateChangerPropType = {
    classes?: string
    period: DatePeriod
    date: Date
    onChange: (date: Date) => void
}

/**
 * Slider change date
 */
const DateChanger: React.FC<DateChangerPropType> = ({
    classes,
    period,
    date,
    onChange,
}) => {
    const { t, i18n } = useTranslation()

    const dateFormatted = useMemo(() => {
        switch (period) {
            case DatePeriod.day: {
                const datePart1 = dateTimeFormat(date, i18n.language, { day: '2-digit', month: 'long' }, { sliceRusYearStr: true })
                const datePart2 = dateTimeFormat(date, i18n.language, { weekday: 'long' }, { sliceRusYearStr: true })
                const datePart3 = dateTimeFormat(date, i18n.language, { year: 'numeric' }, { sliceRusYearStr: true })

                return `${datePart1}, ${datePart2} ${datePart3}`
            }
            case DatePeriod.month:
                return dateTimeFormat(date, i18n.language, { month: 'long', year: 'numeric' }, { sliceRusYearStr: true })
            case DatePeriod.year:
                return dateTimeFormat(date, i18n.language, { year: 'numeric' }, { sliceRusYearStr: true })
            default:
                return ''
        }
    }, [period, date])

    const dateBefore = useMemo(() => {
        switch (period) {
            case DatePeriod.day:
                return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
            case DatePeriod.month:
                return new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())
            case DatePeriod.year:
                return new Date(date.getFullYear() - 1, date.getMonth(), date.getDate())
            default:
                return undefined
        }
    }, [period, date])

    const dateAfter = useMemo(() => {
        switch (period) {
            case DatePeriod.day:
                return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
            case DatePeriod.month:
                return new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
            case DatePeriod.year:
                return new Date(date.getFullYear() + 1, date.getMonth(), date.getDate())
            default:
                return undefined
        }
    }, [period, date])

    const dateBeforeTitle = useMemo(() => {
        switch (period) {
            case DatePeriod.day:
                return t('calendar_previous_day')
            case DatePeriod.month:
                return t('calendar_previous_month')
            case DatePeriod.year:
                return t('calendar_previous_year')
            default:
                return ''
        }
    }, [period, date])

    const dateAfterTitle = useMemo(() => {
        switch (period) {
            case DatePeriod.day:
                return t('calendar_next_day')
            case DatePeriod.month:
                return t('calendar_next_month')
            case DatePeriod.year:
                return t('calendar_next_year')
            default:
                return ''
        }
    }, [period, date])

    const handlerClickPrev = () => {
        if (dateBefore) {
            onChange(dateBefore)
        }
    }

    const handlerClickNext = () => {
        if (dateAfter) {
            onChange(dateAfter)
        }
    }

    return (
        <div className={cn(style.wrap, classes)}>
            <Button
                classes={cn(style.action, style.action_left)}
                styleType="transparent"
                title={dateBeforeTitle}
                onClick={handlerClickPrev}
            >
                <Arrow
                    classes={style.arrow}
                    direction="left"
                />
            </Button>
            <div className={cn(style.date, { [style.date_capitalize]: period === DatePeriod.month })}>
                {dateFormatted}
            </div>
            <Button
                classes={cn(style.action, style.action_right)}
                styleType="transparent"
                title={dateAfterTitle}
                onClick={handlerClickNext}
            >
                <Arrow
                    classes={style.arrow}
                    direction="right"
                />
            </Button>
        </div>
    )
}

export default DateChanger
