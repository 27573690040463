import React, { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import cn from 'classnames'

import { TooltipTriggerInfo } from './components'
import style from './Tooltip.module.css'

type TooltipPropType = {
    classes?: string
    isOpen?: boolean
    position?: 'top' | 'bottom' | 'left' | 'right'
    side?: 'start' | 'end' | 'center'
    tooltip: JSX.Element | string
}

type TooltipComposition = {
    TriggerInfo: React.FC
}

const Tooltip: React.FC<TooltipPropType> & TooltipComposition = ({
    children,
    classes,
    isOpen = false,
    position = 'bottom',
    side = 'center',
    tooltip,
}) => {
    const debouncedShow = useDebouncedCallback(showCallback, 400)
    const debouncedHide = useDebouncedCallback(hideCallback, 400)

    const [isShow, setIsShow] = useState(isOpen)

    const handlerMouseEnter = () => {
        debouncedShow()
    }

    const handlerMouseLeave = () => {
        debouncedHide()
    }

    const handlerTouchStart = () => {
        setIsShow(!isShow)
    }

    function showCallback() {
        setIsShow(true)
    }

    function hideCallback() {
        setIsShow(false)
    }

    return (
        <div
            className={cn(style.wrap, classes)}
            onMouseEnter={handlerMouseEnter}
            onMouseLeave={handlerMouseLeave}
            onTouchStart={handlerTouchStart}
        >
            <div className={style.trigger}>
                {children}
            </div>
            {isShow && (
                <div className={cn(style.tooltip, style[`tooltip_${position}`], style[`tooltip_${side}`])}>
                    <div className={style.tooltipBody}>
                        {tooltip}
                    </div>
                </div>
            )}
        </div>
    )
}

Tooltip.TriggerInfo = TooltipTriggerInfo
export default Tooltip
