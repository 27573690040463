import React from 'react'
import cn from 'classnames'

import { NavBar, MenuNavMainPublic, Footer } from 'components'
import { Auth } from 'containers'
import { useAppMode } from 'containers/App/hooks'
import preview from 'assets/svg/business_auth_preview.svg'
import style from './AuthPage.module.css'

/**
 * Страница авторизации
 *
 * @name AuthPage
 * @memberOf module:Layouts
 */
const AuthPage: React.FC = () => {
    const { appMode, isUserMode, isBusinessMode } = useAppMode()

    return (
        <>
            <div className={cn(style.page, style[`page_${appMode}`])}>
                {isUserMode && (
                    <NavBar classes={style.navBar}>
                        <MenuNavMainPublic />
                    </NavBar>
                )}

                <div className={cn(style.content, style[`content_${appMode}`])}>
                    {isBusinessMode && (
                        <>
                            <div className={style.content_form}>
                                <Auth />
                            </div>

                            <div className={style.content_preview}>
                                <object
                                    className={style.preview}
                                    data={preview}
                                    type="image/svg+xml"
                                    aria-label="kickback"
                                />
                            </div>
                        </>
                    )}

                    {isUserMode && (
                        <Auth />
                    )}
                </div>
            </div>

            {isUserMode && (
                <Footer />
            )}
        </>
    )
}

export default AuthPage
