import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    useQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TStoreServiceError,
    TStoreShowcaseProps,
    TStoreShowcaseResponse,
} from 'services/StoreService'
import { QueryCacheKeys } from 'enums'
import { StoreService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TStoreShowcaseProps
export type TQueryFnOpts = UseQueryOptions<
    TStoreShowcaseResponse,
    [string, AxiosError<TStoreServiceError, TFetchFnParams>],
    TStoreShowcaseResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.storeShowcase

/**
 * Хук API получить витрину магазина
 */
export default function useFetchStoreShowcase(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return StoreService.fetchStoreShowcase(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
