import React, { useState } from 'react'

import { SlideDownHeaderPropType } from './components/SlideDownHeader/SlideDownHeader'
import { SlideDownBodyPropType } from './components/SlideDownBody/SlideDownBody'
import { SlideDownHeader, SlideDownBody } from './components'
import { Context } from './Context'

type SlideDownPropType = {
    classes?: string
    isOpened?: boolean
}

type MenuLisComposition = {
    Header: React.FC<SlideDownHeaderPropType>
    Body: React.FC<SlideDownBodyPropType>
}

const SlideDown: React.FC<SlideDownPropType> & MenuLisComposition = ({
    children,
    classes,
    isOpened = false,
}) => {
    const [context, setContext] = useState({ isOpen: isOpened, setOpen })

    function setOpen(open: boolean) {
        setContext((prevState) => ({ ...prevState, isOpen: open }))
    }

    return (
        <div className={classes}>
            <Context.Provider value={context}>
                {children}
            </Context.Provider>
        </div>
    )
}

SlideDown.Header = SlideDownHeader
SlideDown.Body = SlideDownBody

export default SlideDown
