import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { APP_URL } from 'config/app'
import style from './MenuStatic.module.css'

type MenuStaticPropType = {
    isBusiness: boolean
    isPublic?: boolean
    classesNav?: string
    classesItem?: string
    classesLink?: string
}

const MenuStatic: React.FC<MenuStaticPropType> = ({
    isBusiness,
    isPublic = true,
    classesNav,
    classesItem,
    classesLink,
}) => {
    const { t, i18n } = useTranslation()

    const lang = useMemo(() => i18n.language, [i18n.language])

    return (
        <ul className={cn(style.nav, classesNav)}>
            {isBusiness ? (
                <>
                    <li className={classesItem}>
                        <a
                            className={classesLink}
                            href={t('kicksback_privacy_policy_link')}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('privacy')}
                        </a>
                    </li>
                    <li className={classesItem}>
                        <a
                            className={classesLink}
                            href={t('business_agreement_link')}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('business_agreement_title')}
                        </a>
                    </li>
                </>
            ) : (
                <>
                    <li className={classesItem}>
                        <a
                            className={classesLink}
                            href={lang === 'en' ? `https://kicksback.app/${lang}/` : 'https://kicksback.app/'}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('About us')}
                        </a>
                    </li>
                    <li className={classesItem}>
                        <a
                            className={classesLink}
                            href={lang === 'en' ? `https://kicksback.app/${lang}/#business-tools` : 'https://kicksback.app/#business-tools'}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('For business')}
                        </a>
                    </li>
                    <li className={classesItem}>
                        <a
                            className={classesLink}
                            href={lang === 'en' ? `https://kicksback.app/${lang}/media/` : 'https://kicksback.app/media/'}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('Media')}
                        </a>
                    </li>
                    <li className={classesItem}>
                        <a
                            className={classesLink}
                            href={`https://www.sessia.com/kicksback_privacy_policy_${lang}.html`}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            {t('privacy')}
                        </a>
                    </li>
                    {isPublic && (
                        <li className={classesItem}>
                            <NavLink className={classesLink} to={APP_URL.support}>
                                {t('support')}
                            </NavLink>
                        </li>
                    )}
                </>
            )}
        </ul>
    )
}

export default MenuStatic
