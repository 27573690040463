import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    UseInfiniteQueryOptions,
    useQuery,
    useInfiniteQuery,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TChatMicroServiceError,
    THistoryChannelProps,
    THistoryChannelResponse,
    THistoryChannelPaginatedProps,
    THistoryChannelPaginatedResponse,
} from 'services/ChatMicroService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { ChatMicroService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = THistoryChannelProps
export type TFetchFnResponse = THistoryChannelResponse
export type TFetchFnError = AxiosError<TChatMicroServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

export type TInfiniteFnParams = THistoryChannelPaginatedProps
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    THistoryChannelPaginatedResponse,
    TError,
    THistoryChannelPaginatedResponse,
    THistoryChannelPaginatedResponse,
    TQueryKey
>

const key = QueryCacheKeys.chatHistoryChannel

export default function useFetchHistoryChannel(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return ChatMicroService.fetchHistoryChannel(params)
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useFetchInfiniteHistoryChannel(initParams: TInfiniteFnParams, opts: TInfiniteQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useInfiniteQuery([`${key}-${user.id}`, initParams], ({ pageParam = initParams }) => {
        return ChatMicroService.fetchHistoryChannelPaginated(pageParam)
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, {
        getNextPageParam: (lastPage, pages) => {
            return { ...initParams, page: pages.length + 1 }
        },
        ...opts,
    })
}
