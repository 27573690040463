import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IBreadcrumb,
    ITicket,
    ITicketItem,
    ITicketMessage,
    ITicketMessageGroup,
    ITicketMessageProps,
} from 'interfaces'
import { APP_URL } from 'config/app'
import { ContentContainer, Block } from 'layout'
import {
    PageHeader,
    Breadcrumbs,
    TicketStatusTag,
    UserMessageFormWrap,
    Loader,
    ErrorMsg,
} from 'components'
import { fetchCount, deleteCount } from 'containers/User/user-actions'
import { useFetchTickets, useFetchTicket } from 'containers/Support/hooks'
import { TicketMessageAction } from 'form-actions'
import { getDateValues, getId, scrollTop } from 'utils/helpers'
import useUserGUID from 'hooks/useUserGUID'
import { useAppSelector } from 'store'
import { TicketMessageGroup, TicketMessageAvatar, TicketMessage } from './components/index'
import style from './Ticket.module.css'

const getMessageGroups = (messages: ITicketMessage[]) => {
    return messages.reduce<ITicketMessageGroup[]>((acc, item) => {
        const date = new Date(item.create_date)

        if (acc.length && acc[0].messages.length) {
            const prevItem = acc[0].messages[0]
            const {
                year: prevYear,
                month: prevMonth,
                day: prevDay,
            } = getDateValues(new Date(prevItem.create_date))
            const { year: currentYear, month: currentMonth, day: currentDay } = getDateValues(date)
            const isMatchDate = currentYear === prevYear && currentMonth === prevMonth && currentDay === prevDay

            // add message to first group
            if (isMatchDate) {
                return acc.map((groupItem, groupIndex) => {
                    if (groupIndex === 0) {
                        return { ...groupItem, messages: [item, ...groupItem.messages] }
                    }
                    return groupItem
                })
            }
        }

        return [{ id: getId(false), date: item.create_date, messages: [item] }, ...acc]
    }, [])
}

const Ticket: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const guid = useUserGUID()

    const user = useAppSelector((state) => state.user)
    const counts = useAppSelector((state) => state.counts)

    const [ticket, setTicket] = useState<ITicket>()
    const [ticketGUID, setTicketGUID] = useState<string>()

    const [ticketMessageProps] = useState<ITicketMessageProps>({
        id: Number(id),
        generated_user_id: guid,
        message: '',
    })

    const [error, setError] = useState<string>()

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        return [
            { id: 1, name: t('support_title'), url: APP_URL.support },
            { id: 2, name: ticket?.category.name ?? '' },
        ]
    }, [ticket])

    const {
        isInitialLoading: isLoadingTickets,
        data: dataTickets,
        error: errorTickets,
    } = useFetchTickets({ guid })

    const {
        isInitialLoading: isLoadingTicket,
        data: dataTicket,
        error: errorTicket,
    } = useFetchTicket({
        id,
        guid: ticketGUID!,
        lang: user?.language.id,
    }, {
        enabled: !!ticketGUID,
        staleTime: 0,
    })

    const messagesGroups = useMemo(() => {
        return ticket?.messages?.length ? getMessageGroups(ticket.messages) : []
    }, [ticket])

    function getCurrentTicketGUID(ticketList: ITicketItem[]) {
        const currentTicket = id ? ticketList.find((item) => item.id === Number(id)) : undefined

        if (currentTicket) {
            setTicketGUID(currentTicket.generated_user_id)
        } else {
            setError(t('nothing_found'))
        }
    }

    function fetchCountAction() {
        dispatch(fetchCount({ counter: 'support_count' }))
    }

    function resetCountAction() {
        dispatch(deleteCount({ counter: 'support_count' }))
    }

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        if (user) {
            fetchCountAction()
        }

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (dataTickets) {
            getCurrentTicketGUID(dataTickets)
        }
    }, [dataTickets])

    useEffect(() => {
        if (dataTicket) {
            setTicket(dataTicket)

            if (counts?.support_count) {
                resetCountAction()
            }
        }
    }, [dataTicket])

    return (
        <ContentContainer size="half">
            <PageHeader classesContent={style.pageHeaderContent}>
                <Breadcrumbs items={breadcrumbs} />
                <TicketStatusTag status={ticket?.status_id} />
            </PageHeader>

            {(isLoadingTickets || isLoadingTicket) && (
                <Loader />
            )}

            {(error || errorTickets || errorTicket) && (
                <ErrorMsg
                    error={error || (errorTickets && errorTickets[0]) || (errorTicket && errorTicket[0]) || t('update_error')}
                />
            )}

            {!!messagesGroups.length && (
                <Block classes={style.ticket}>
                    <div className={style.ticketItems}>
                        {messagesGroups.map((group) => (
                            <TicketMessageGroup data={group} key={group.id}>
                                {group.messages.map((item, index, items) => (
                                    <React.Fragment key={item.id}>
                                        {/* eslint-disable-next-line */}
                                        {(index === 0 || items[index - 1].is_by_ticket_owner !== item.is_by_ticket_owner) && (
                                            <div className={style.avatarWrap}>
                                                <TicketMessageAvatar
                                                    classes={cn({ [style.avatarUser]: item.is_by_ticket_owner })}
                                                    data={item.is_by_ticket_owner ? user : undefined}
                                                />
                                                {!item.is_by_ticket_owner && (
                                                    <div className={style.avatarName}>
                                                        {t('support_title')}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <TicketMessage
                                            data={{ user: item.is_by_ticket_owner ? user : undefined, message: item }}
                                        />
                                    </React.Fragment>
                                ))}
                            </TicketMessageGroup>
                        ))}
                    </div>

                    <UserMessageFormWrap classes={style.formFieldWrap} user={user}>
                        <TicketMessageAction
                            ticketMessageProps={ticketMessageProps}
                        />
                    </UserMessageFormWrap>
                </Block>
            )}
        </ContentContainer>
    )
}

export default Ticket
