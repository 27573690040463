import React, { useEffect, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { TClientsSearchProps } from 'services/ClientService'
import { FormDataType } from 'forms/ClientFilterForm/ClientFilterForm'
import { CardSize } from 'enums'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import {
    PageHeader,
    Button,
    UserCard,
    Loader,
    Modal,
    Icon,
    Spinner,
    NoDataInfo,
    InfiniteScroll,
    ErrorMsg,
} from 'components'
import { useWindowResize } from 'hooks'
import { ClientFilterAction } from 'form-actions'
import { numberFormat, parseTpl } from 'utils/helpers'
import { ClientsSearch } from './components'
import { useFetchInfiniteClients, useSearchInfiniteClients } from './hooks'
import style from './Clients.module.css'

const LIMIT_CLIENTS = 12
const OFFSET_CLIENTS = 0

const Clients: React.FC = () => {
    const { t } = useTranslation()
    const [windowWidth] = useWindowResize()

    const [clientsSearchText, setClientsSearchText] = useState('')
    const [clientsSearchFilters, setClientsSearchFilters] = useState<TClientsSearchProps>()
    const [isShowModalSearch, setIsShowModalSearch] = useState(false)
    const [isShowModalFilters, setIsShowModalFilters] = useState(false)
    const [isHideLoaderClients, setIsHideLoaderClients] = useState(false)
    const [isHideLoaderSearchClients, setIsHideLoaderSearchClients] = useState(false)

    const {
        isInitialLoading: isLoadingClients,
        data: dataClients,
        error: errorClients,
        fetchNextPage: fetchNextPageClients,
    } = useFetchInfiniteClients({
        limit: LIMIT_CLIENTS,
        offset: OFFSET_CLIENTS,
    })

    const {
        isInitialLoading: isLoadingSearchClients,
        isFetching: isFetchingSearchClients,
        data: dataSearchClients,
        fetchNextPage: fetchNextPageSearchClients,
    } = useSearchInfiniteClients({
        limit: LIMIT_CLIENTS,
        offset: OFFSET_CLIENTS,
        q: clientsSearchText,
        ...clientsSearchFilters,
    }, {
        enabled: !!clientsSearchText || !!clientsSearchFilters,
    })

    const clientsText = useMemo(() => {
        if (dataSearchClients?.pages.length) {
            return parseTpl(t('found_people_number'), { d: '' }, { prefix: '%', suffix: '%' })
        }

        return t('clients')
    }, [dataClients, dataSearchClients])

    const clientsCount = useMemo(() => {
        if (dataSearchClients?.pages.length) {
            return numberFormat(dataSearchClients.pages[0].count)
        }
        if (dataClients?.pages.length) {
            return numberFormat(dataClients.pages[0].total)
        }

        return undefined
    }, [dataClients, dataSearchClients])

    const isSetClientsSearchFilters = useMemo(() => {
        return clientsSearchFilters && Object.values(clientsSearchFilters).some((item) => item !== undefined)
    }, [clientsSearchFilters])

    const itemSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerLoadClients = () => {
        if (!isLoadingClients) {
            fetchNextPageClients()
        }
    }

    const handlerLoadSearchClients = () => {
        if (!isLoadingSearchClients) {
            fetchNextPageSearchClients()
        }
    }

    const handlerSearch = (value: string) => {
        setClientsSearchText(value)
        setIsShowModalSearch(false)
    }

    const handlerResetSearch = () => {
        setClientsSearchText('')
    }

    const handlerFilters = (value: FormDataType) => {
        setClientsSearchFilters(value)
        setIsShowModalFilters(false)
        setIsShowModalSearch(false)
    }

    const handlerResetFilters = () => {
        setClientsSearchFilters(undefined)
    }

    useEffect(() => {
        if (dataClients) {
            const { pages } = dataClients
            const pagesLen = pages.length
            const lastListLen = pagesLen ? pages[pagesLen - 1].list.length : 0

            if (lastListLen < LIMIT_CLIENTS) {
                setIsHideLoaderClients(true)
            }
        }
    }, [dataClients])

    useEffect(() => {
        if (dataSearchClients) {
            const { pages } = dataSearchClients
            const pagesLen = pages.length
            const lastListLen = pagesLen ? pages[pagesLen - 1].results.length : 0

            if (lastListLen < LIMIT_CLIENTS) {
                setIsHideLoaderSearchClients(true)
            }
        }
    }, [dataSearchClients])

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                <PageHeader
                    classesContent={style.headerContent}
                    title={clientsText}
                    titlePos="none"
                >
                    {clientsCount !== undefined && (
                        <div className={style.headerContentCount}>
                            {clientsCount}
                        </div>
                    )}
                    <div className={style.headerContentActions}>
                        <Button
                            classes={style.headerContentAction}
                            styleType="transparent"
                            onClick={() => setIsShowModalSearch(true)}
                        >
                            <Icon
                                isImgTag={false}
                                classes={style.iconSearch}
                                resourceKey="ic_search_white_svg"
                                width={26}
                                height={26}
                                notify={!!clientsSearchText || isSetClientsSearchFilters}
                            />
                        </Button>
                    </div>
                </PageHeader>

                {isLoadingClients && (
                    <Loader />
                )}

                {(isLoadingSearchClients || isFetchingSearchClients) && (
                    <Spinner
                        classes={style.loaderSearch}
                        size="medium"
                        position="top"
                    />
                )}

                {!isLoadingClients
                    && !dataSearchClients
                    && !errorClients
                    && dataClients?.pages.length
                    && dataClients.pages[0].total === 0
                && (
                    <NoDataInfo text={t('you_have_no_clients_in_sessia')} />
                )}

                {!isLoadingSearchClients
                    && dataSearchClients?.pages.length
                    && dataSearchClients.pages[0].count === 0
                && (
                    <NoDataInfo text={t('nothing_found')} />
                )}

                {errorClients && (
                    <ErrorMsg error={errorClients[0]} />
                )}

                <CardContainer>
                    {dataSearchClients && (
                        <InfiniteScroll
                            options={{ rootMargin: '0px 0px 50% 0px' }}
                            isHideLoader={isHideLoaderSearchClients}
                            onLoad={handlerLoadSearchClients}
                        >
                            {dataSearchClients.pages.map((page, i) => (
                                /* eslint-disable-next-line */
                                <React.Fragment key={i}>
                                    {page.results.map((item) => (
                                        <UserCard
                                            size={itemSize}
                                            name={item.name}
                                            surname={item.surname}
                                            photo={item.photo_file_name}
                                            online={item.is_online}
                                            url={generatePath(APP_URL.profile, { id: item.id })}
                                            key={item.id}
                                        />
                                    ))}
                                </React.Fragment>
                            ))}
                        </InfiniteScroll>
                    )}
                    {!isFetchingSearchClients && !dataSearchClients && dataClients && (
                        <InfiniteScroll
                            options={{ rootMargin: '0px 0px 50% 0px' }}
                            isHideLoader={isHideLoaderClients}
                            onLoad={handlerLoadClients}
                        >
                            {dataClients.pages.map((page, i) => (
                                /* eslint-disable-next-line */
                                <React.Fragment key={i}>
                                    {page.list.map((item) => (
                                        <UserCard
                                            size={itemSize}
                                            name={item.name}
                                            surname={item.surname}
                                            photo={item.photo}
                                            online={item.is_online}
                                            url={generatePath(APP_URL.profile, { id: item.id })}
                                            key={item.id}
                                        />
                                    ))}
                                </React.Fragment>
                            ))}
                        </InfiniteScroll>
                    )}
                </CardContainer>
            </ContentContainer>

            <Modal
                classes={style.modalSearch}
                isOpen={isShowModalSearch}
                onClose={() => setIsShowModalSearch(false)}
            >
                <Modal.Body>
                    <ClientsSearch
                        classes={style.search}
                        searchText={clientsSearchText}
                        isSetFilters={isSetClientsSearchFilters}
                        onClickFilters={() => setIsShowModalFilters(true)}
                        onReset={handlerResetSearch}
                        onSearch={handlerSearch}
                    />
                </Modal.Body>
            </Modal>

            <Modal
                isOpen={isShowModalFilters}
                classesOverlay={style.modalFiltersOverlay}
                onClose={() => setIsShowModalFilters(false)}
            >
                <Modal.Header
                    isCloseButton
                    title={t('settings_tab')}
                    titlePos="center"
                />
                <Modal.Body>
                    <ClientFilterAction
                        classes={style.filter}
                        filterProps={clientsSearchFilters}
                        onReset={handlerResetFilters}
                        onSuccess={handlerFilters}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Clients
