import React, { useMemo } from 'react'
import cn from 'classnames'

import { Arrow } from 'components'
import { PaginationItem } from './components'
import style from './Pagination.module.css'

type PaginationPropType = {
    classes?: string
    current: number
    total: number
    position?: 'left' | 'center' | 'right'
    maxShow?: number
    onChange: (value: number) => void
}

const Pagination: React.FC<PaginationPropType> = ({
    classes,
    current,
    total,
    position = 'center',
    maxShow = 3,
    onChange,
}) => {
    const items = useMemo(() => {
        return Array(total).fill(0).map((item, index) => {
            return index + 1
        })
    }, [total])

    return (
        <div className={cn(style.pagination, style[`pagination_${position}`], classes)}>
            <PaginationItem
                disabled={current === 1}
                onClick={() => onChange(current - 1)}
            >
                <Arrow
                    classes={cn(style.arrow, { [style.arrow_disabled]: current === 1 })}
                    direction="left"
                />
            </PaginationItem>
            {current > maxShow && (
                <>
                    <PaginationItem onClick={() => onChange(1)}>
                        1
                    </PaginationItem>
                    <PaginationItem onClick={() => {}}>
                        ...
                    </PaginationItem>
                </>
            )}
            {items.map((value, index) => (
                (value > current - maxShow && value < current + maxShow) && (
                    <PaginationItem
                        isActive={value === current}
                        key={value}
                        onClick={() => value !== current && onChange(value)}
                    >
                        {value}
                    </PaginationItem>
                )
            ))}
            {total > maxShow + 1 && current < (total - maxShow - 1) && (
                <>
                    <PaginationItem disabled onClick={() => {}}>
                        ...
                    </PaginationItem>
                    <PaginationItem onClick={() => onChange(total)}>
                        {total}
                    </PaginationItem>
                </>
            )}
            <PaginationItem
                disabled={current === total}
                onClick={() => onChange(current + 1)}
            >
                <Arrow
                    classes={cn(style.arrow, { [style.arrow_disabled]: current === total })}
                    direction="right"
                />
            </PaginationItem>
        </div>
    )
}

export default Pagination
