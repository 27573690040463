import React from 'react'

import { IStoreProfile } from 'interfaces'
import { Card } from 'components'
import style from './CatalogProductSelectorStore.module.css'

type CatalogProductSelectorStorePropType = {
    data: IStoreProfile
    onClick: (data: IStoreProfile) => void
}

const CatalogProductSelectorStore: React.FC<CatalogProductSelectorStorePropType> = ({
    data,
    onClick,
}) => {
    const handlerClick = () => {
        onClick(data)
    }

    return (
        <Card.Item classes={style.item} onClick={handlerClick}>
            <Card.Image src={data.logo_image} />
            <Card.Body title={data.name} />
        </Card.Item>
    )
}

export default CatalogProductSelectorStore
