import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    InvalidateOptions,
    InvalidateQueryFilters,
    useQueryClient,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TStoreServiceError,
    TStoreCatalogProps,
    TStoreCatalogResponse,
    TAddStoreCatalogProps,
    TAddStoreCatalogResponse,
    TUpdateStoreCatalogProps,
    TUpdateStoreCatalogResponse,
    TRemoveStoreCatalogProps,
    TRemoveStoreCatalogResponse,
} from 'services/StoreService'
import { QueryCacheKeys } from 'enums'
import { StoreService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TStoreCatalogProps
export type TQueryFnOpts = UseQueryOptions<
    TStoreCatalogResponse,
    [string, AxiosError<TStoreServiceError, TFetchFnParams>],
    TStoreCatalogResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.storeCatalog

/**
 * Хук API получить вложенные каталоги магазина
 */
export default function useFetchStoreCatalog(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return StoreService.fetchStoreCatalog(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useInvalidateStoreCatalog() {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = (
        params: TFetchFnParams,
        { exact = true, ...filters }: InvalidateQueryFilters = {},
        options: InvalidateOptions = {},
    ) => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}

export function useMutationStoreCatalog() {
    const { t } = useTranslation()

    const add = useMutation<
        TAddStoreCatalogResponse,
        [string, AxiosError<TStoreServiceError, TAddStoreCatalogProps>],
        TAddStoreCatalogProps
    >((props) => {
        return StoreService.addStoreCatalog(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const update = useMutation<
        TUpdateStoreCatalogResponse,
        [string, AxiosError<TStoreServiceError, TUpdateStoreCatalogProps>],
        TUpdateStoreCatalogProps
    >((props) => {
        return StoreService.updateStoreCatalog(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    const remove = useMutation<
        TRemoveStoreCatalogResponse,
        [string, AxiosError<TStoreServiceError, TRemoveStoreCatalogProps>],
        TRemoveStoreCatalogProps
    >((props) => {
        return StoreService.removeStoreCatalog(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        add,
        update,
        remove,
    }
}
