import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IRatingSum } from 'interfaces'
import { BREAKPOINTS } from 'config/app'
import { ContentContainer, SideBarContainer, ContentBackgroundBottom } from 'layout'
import { InfoBlock, Loader, Chevron } from 'components'
import { useWindowResize } from 'hooks'
import { useFetchRatingRanks } from 'containers/App/hooks'
import { useFetchRatingList, useFetchRatingTypeSum } from 'containers/User/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { scrollTop } from 'utils/helpers'
import { RatingItem, RatingRank, RatingTypeSum } from './components'
import style from './Rating.module.css'

const Rating: React.FC = () => {
    const { t } = useTranslation()

    const [windowWidth] = useWindowResize()

    const profile = useSelector(userSelectors.profile)

    const [ratingTypeSumFiltered, setRatingTypeSumFiltered] = useState<IRatingSum[]>([])

    const { rating_sessia: rating } = useMemo(() => profile || {}, [profile])
    const chevronSize = useMemo(() => (windowWidth < BREAKPOINTS.tablet ? 8 : 12), [windowWidth])

    const { isLoading: isLoadingRatingRanks, data: ratingRanksData } = useFetchRatingRanks()
    const { data: ratingListData } = useFetchRatingList()
    const { isLoading: isLoadingRatingTypeSum, data: ratingTypeSumData } = useFetchRatingTypeSum()

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    useEffect(() => {
        if (ratingTypeSumData) {
            setRatingTypeSumFiltered(ratingTypeSumData.sort((a, b) => (a.total < b.total ? 1 : -1)))
        }
    }, [ratingTypeSumData])

    return (
        <>
            <ContentContainer classes={style.content} size="half">
                <ContentBackgroundBottom isWidthGutter />

                <InfoBlock classes={style.infoBlock}>
                    <InfoBlock.Body classes={style.title}>
                        {t('rating_sessia_my_achievements')}
                    </InfoBlock.Body>
                    <InfoBlock.Footer>
                        {isLoadingRatingRanks && (
                            <Loader />
                        )}

                        {!!ratingRanksData?.length && (
                            <div className={style.ranks}>
                                {ratingRanksData.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        <RatingRank
                                            isActive={item.id === rating?.rank.id}
                                            data={item}
                                        />

                                        {index < ratingRanksData.length - 1 && (
                                            <Chevron
                                                classes={cn(
                                                    style.ranksChevron,
                                                    {
                                                        [style.ranksChevron_active]: item.id === rating?.rank.id,
                                                    },
                                                )}
                                                type="right"
                                                size={chevronSize}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </InfoBlock.Footer>
                </InfoBlock>

                {ratingListData?.map((item) => (
                    <InfoBlock classes={style.infoBlock} key={item.id}>
                        <InfoBlock.Body classes={style.title}>
                            {item.name}
                        </InfoBlock.Body>
                        <InfoBlock.Footer>
                            <RatingItem data={item} />
                        </InfoBlock.Footer>
                    </InfoBlock>
                ))}
            </ContentContainer>

            <SideBarContainer classes={style.sideBar} position="right">
                <InfoBlock>
                    <InfoBlock.Body classes={style.title}>
                        {t('rating_sessia_type_sum_title')}
                    </InfoBlock.Body>
                    <InfoBlock.Footer>
                        {isLoadingRatingTypeSum && (
                            <Loader />
                        )}

                        {!!ratingTypeSumFiltered.length && (
                            <div className={style.sideBarBlock}>
                                {ratingTypeSumFiltered.map((item) => (
                                    <RatingTypeSum data={item} key={item.id} />
                                ))}
                            </div>
                        )}
                    </InfoBlock.Footer>
                </InfoBlock>
            </SideBarContainer>
        </>
    )
}

export default Rating
