import { API_URL } from 'config/api'
import { IStoreLoyalty, IStoreLoyaltyProps } from 'interfaces'
import { parseTpl } from 'utils/helpers'
import requestClient from 'utils/requestClient'

export type TKicksServiceError = {
    status: number
    type: string
    title: string // example: An error occurred
    detail: string // example: Not Found
}

export type TStoreLoyaltySettingsProps = {
    storeId: number
}
export type TSetStoreLoyaltySettingsProps = TStoreLoyaltySettingsProps & IStoreLoyaltyProps

export type TStoreLoyaltySettingsResponse = IStoreLoyalty

export type TSetStoreLoyaltySettingsResponse = IStoreLoyalty

/**
 * API kicks
 */
class KicksService {
    static fetchStoreLoyaltySettings({ storeId }: TStoreLoyaltySettingsProps) {
        const url = parseTpl(API_URL.storeLoyaltySettings, { store_id: storeId })
        return requestClient<TStoreLoyaltySettingsResponse>(url)
    }

    static setStoreLoyaltySettings({ storeId, ...params }: TSetStoreLoyaltySettingsProps) {
        const url = parseTpl(API_URL.storeLoyaltySettings, { store_id: storeId })
        return requestClient<TSetStoreLoyaltySettingsResponse>(url, { method: 'post', data: params })
    }
}

export default KicksService
