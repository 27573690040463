import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IPayout } from 'interfaces'
import { InfoBlock } from 'components'
import {
    getCountFractionDigits,
    getImageResizeSrc,
    priceFormat,
    numberFormat,
} from 'utils/helpers'
import style from './WalletPayoutCurrent.module.css'

type WalletPayoutCurrentPropType = {
    payout: IPayout
}

const WalletPayoutCurrent: React.FC<WalletPayoutCurrentPropType> = ({ payout }) => {
    const { currencyPlaceholderColor } = payout

    const { t } = useTranslation()

    const amountSum = useMemo(() => {
        const {
            balanceAmount,
            currencyIsoCode,
            currencyShowCode,
            roundscale,
        } = payout
        const fractionDigits = getCountFractionDigits(roundscale)
        const options = {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits,
        }

        if (currencyIsoCode === 'KICKS_SESSIA') {
            return priceFormat(balanceAmount, { symbol: currencyShowCode, leftSymbolPlacement: false, isoCode: '' }, options)
        }

        return numberFormat(balanceAmount, { ...options, style: 'currency', currency: currencyIsoCode })
    }, [payout])

    const bgImage = useMemo(() => {
        const { currencyIcon } = payout
        return getImageResizeSrc({ src: currencyIcon, width: 700, quality: 90 })
    }, [payout])

    return (
        <InfoBlock
            classes={style.block}
            styles={{
                backgroundColor: currencyPlaceholderColor,
                backgroundImage: `url(${bgImage})`,
            }}
        >
            <InfoBlock.Body classes={style.body}>
                <div className={style.amountSum}>
                    {amountSum}
                </div>
                <div className={style.amountDesc}>
                    {t('balance')}
                </div>
            </InfoBlock.Body>
        </InfoBlock>
    )
}

export default WalletPayoutCurrent
