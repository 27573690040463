import React, { Children, useMemo, useState } from 'react'

import { InfiniteScroll } from 'components'

export type ListPropType = {
    limit?: number
}

/**
 * Компонент отображения списка по частям с автоподгрузкой
 * @param {object} params
 * @param params.children
 * @param {number} [params.limit]
 */
const List: React.FC<ListPropType> = ({ children, limit = 10 }) => {
    const [counts] = useState(Children.count(children))
    const [itemsLimit, setItemsLimit] = useState(limit)
    const [isHideLoader, setIsHideLoader] = useState(false)

    const list = useMemo(() => {
        return itemsLimit ? Children.toArray(children).slice(0, itemsLimit) : Children.toArray(children)
    }, [itemsLimit])

    const handlerLoad = () => {
        const nextLimit = itemsLimit + limit

        setItemsLimit(nextLimit)

        if (nextLimit >= counts) {
            setIsHideLoader(true)
        }
    }

    return (
        <InfiniteScroll isHideLoader={isHideLoader} onLoad={handlerLoad}>
            {list}
        </InfiniteScroll>
    )
}

export default List
