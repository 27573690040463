import React from 'react'
import cn from 'classnames'

import style from './TextSkeleton.module.css'

type TextSkeletonPropType = {
    classes?: string
    width?: number
    height?: number
}

const TextSkeleton: React.FC<TextSkeletonPropType> = ({ classes, width, height }) => {
    return (
        <div
            className={cn(style.pls, classes)}
            style={{ width, height }}
        />
    )
}

export default TextSkeleton
