import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

// import { } from 'interfaces'
import { APP_URL } from 'config/app'
import {
    NoDataInfo,
    Link,
    Tag,
    TableList,
    Switch,
} from 'components'
// import { numberFormat } from 'utils/helpers'
import style from './DeliveryServicesList.module.css'

// FIXME
type TData = {
    id: number
    name: string
    store: string[]
    type: { id: number, name: string }[]
    rate: number[]
}

type TColumn = {
    id: number
    key: keyof TData
    name: string
}

type DeliveryServicesListPropType = {
    columns: TColumn[]
    data?: TData[] // TODO
}

// const NUMBER_FORMAT_PARAMS: Intl.NumberFormatOptions = { minimumFractionDigits: 3 }
// const NUMBER_FORMAT_LOCAL = 'en-En' // TODO ?

const DELIVERIES = [
    { id: 1, name: 'Самовывоз' },
    { id: 2, name: 'Курьер' },
]

const DeliveryServicesList: React.FC<DeliveryServicesListPropType> = ({ columns, data }) => {
    const { t } = useTranslation()

    const deliveriesData = useMemo(() => {
        return DELIVERIES.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
    }, [DELIVERIES])

    const getFormattedValue = (key: keyof TData, values: TData) => {
        if (key === 'name') {
            return (
                /* TODO */
                <Link className={style.link} url="">
                    {values[key]}
                </Link>
            )
        }
        if (key === 'store') {
            return values[key].map((item, index) => (
                /* eslint-disable-next-line */
                <div className={style.rowStore} key={index}>
                    {item}
                </div>
            ))
        }
        if (key === 'type') {
            return values[key].map((item, index) => (
                <div className={style.rowType} key={item.id}>
                    <Link
                        className={style.link}
                        url={generatePath(APP_URL.deliveryTypeEdit, { id: values.id, typeId: item.id })}
                    >
                        {deliveriesData[item.id]}
                    </Link>
                    <Switch
                        classesSwitch={style.switch}
                        classesControl={style.switchControl}
                        classesControlActive={style.switchControl_active}
                        name=""
                        checked={index === 1}
                        onChange={() => {}}
                    />
                </div>
            ))
        }
        if (key === 'rate') {
            return values[key].map((item, index) => (
                /* eslint-disable-next-line */
                <div className={style.rowTag} key={index}>
                    <Tag
                        classes={style.tag}
                        classesContent={style.tagContent}
                    >
                        {item}
                    </Tag>
                </div>
            ))
        }

        return values[key]
    }

    const colTextCenter: Partial<Record<keyof any, boolean>> = {
        count: true,
        countIn: true,
        countOut: true,
        price: true,
        cost: true,
        visibility: true,
    }

    return (
        data ? (
            <TableList classes={style.list}>
                <TableList.Header classes={style.header}>
                    <TableList.Row>
                        {columns.map((item) => (
                            <TableList.Col
                                classes={cn(
                                    style.col,
                                    style.col_header,
                                    {
                                        [style.col_headerCenter]: colTextCenter[item.key],
                                    },
                                )}
                                key={item.key}
                            >
                                {item.name}
                            </TableList.Col>
                        ))}
                        <TableList.Col classes={cn(style.col, style.col_header)}>
                            {t('actions_title')}
                        </TableList.Col>
                    </TableList.Row>
                </TableList.Header>
                <TableList.Body classes={style.body}>
                    {!data.length && (
                        <NoDataInfo text={t('no_data')} />
                    )}
                    {data.map((item) => (
                        <TableList.Row classes={style.row} key={item.id}>
                            {columns.map((itemColumn) => (
                                <TableList.Col classes={cn(style.col, style.col_body)} key={itemColumn.key}>
                                    <div className={style.colTitle}>
                                        {itemColumn.name}
                                    </div>
                                    <div className={cn(style.colInner, {
                                        [style.colInner_center]: colTextCenter[itemColumn.key],
                                    })}
                                    >
                                        {getFormattedValue(itemColumn.key, item)}
                                    </div>
                                </TableList.Col>
                            ))}
                            <TableList.Col classes={cn(style.col, style.col_body)}>
                                <div className={style.colTitle}>
                                    {t('actions_title')}
                                </div>
                                <Link
                                    className={style.actionLink}
                                    url={generatePath(APP_URL.deliveryTypeAdd, { id: item.id })}
                                >
                                    {t('add_delivery_type')}
                                </Link>
                            </TableList.Col>
                        </TableList.Row>
                    ))}
                </TableList.Body>
            </TableList>
        ) : null
    )
}

export default DeliveryServicesList
