import { useLocation } from 'react-router-dom'

import { getURLSearchParams } from 'utils/helpers'

/**
 * Hook is read the query string in the URL for the current location
 */
export default function useSearchParams<T extends Record<string, string> = {}>() {
    const { search } = useLocation()

    return getURLSearchParams<T>(search)
}
