import React, { Suspense, lazy, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { APP_URL, BREAKPOINTS } from 'config/app'
import {
    Container,
    PageContainer,
    SideBarContainer,
    Block,
} from 'layout'
import {
    NavBar,
    NavMenu,
    MenuNav,
    MenuStatic,
    UserInfo,
    FooterLK,
    Loader,
} from 'components'
import {
    Feed,
    Support,
    Ticket,
    Company,
} from 'containers'
import { useApp, useAppMode } from 'containers/App/hooks'
import { fetchProfile } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { useWindowResize } from 'hooks'
import style from './LKPage.module.css'

const Clients = lazy(() => import('containers/Clients/Clients'))
const Delivery = lazy(() => import('containers/Delivery/Delivery'))
const Publication = lazy(() => import('containers/Publication/Publication'))
const Market = lazy(() => import('containers/Market/Market'))
const Shops = lazy(() => import('containers/Shops/Shops'))
const Shop = lazy(() => import('containers/Shop/Shop'))
const Stores = lazy(() => import('containers/Stores/Stores'))
const Store = lazy(() => import('containers/Store/Store'))
const StoreSettings = lazy(() => import('containers/StoreSettings/StoreSettings'))
const StoreEdit = lazy(() => import('containers/StoreEdit/StoreEdit'))
const StoreCreate = lazy(() => import('containers/StoreCreate/StoreCreate'))
const Goods = lazy(() => import('containers/Goods/Goods'))
const Reports = lazy(() => import('containers/Reports/Reports'))
const Rating = lazy(() => import('containers/Rating/Rating'))
const Report = lazy(() => import('containers/Report/Report'))
const Friends = lazy(() => import('containers/Friends/Friends'))
const FriendsRequest = lazy(() => import('containers/FriendsRequest/FriendsRequest'))
const FriendsMutual = lazy(() => import('containers/FriendsMutual/FriendsMutual'))
const Messenger = lazy(() => import('containers/Messenger/Messenger'))
const Orders = lazy(() => import('containers/Orders/Orders'))
const Settings = lazy(() => import('containers/Settings/Settings'))
// const SettingsLab = lazy(() => import('containers/SettingsLab/SettingsLab'))
const UserProfile = lazy(() => import('containers/UserProfile/UserProfile'))
const UserSettings = lazy(() => import('containers/UserSettings/UserSettings'))
const Wallet = lazy(() => import('containers/Wallet/Wallet'))
const Warehouse = lazy(() => import('containers/Warehouse/Warehouse'))

const DevModule = lazy(() => import('containers/DevModule/DevModule'))

/**
 * Страница личный кабинет авторизованного пользователя
 */
const LKPage: React.FC = () => {
    const { mainPrivateRoute } = useApp()
    const { appMode, isUserMode, isBusinessMode } = useAppMode()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const [windowWidth] = useWindowResize()

    const user = useSelector(userSelectors.user)

    const routes = (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route path={APP_URL.lenta} component={Feed} />
                <Route path={APP_URL.post} component={Publication} />

                <Route exact path={APP_URL.shopsFavorite} component={Shops} />
                <Route exact path={APP_URL.shopsSettings} component={Market} />
                <Route path={APP_URL.shop} component={Shop} />

                <Route exact path={APP_URL.market} component={Market} />
                <Route path={APP_URL.category} component={Shops} />

                <Route path={APP_URL.stores} component={Stores} />
                <Route exact path={APP_URL.storeCreate} component={StoreCreate} />
                <Route exact path={APP_URL.store} component={Store} />
                <Route path={APP_URL.storeProfile} component={Store} />
                <Route path={APP_URL.storeSettings} component={StoreSettings} />
                <Route path={APP_URL.storeEdit} component={StoreEdit} />
                <Route path={APP_URL.catalog} component={Store} />
                <Route path={APP_URL.basket} component={Store} />
                <Route path={APP_URL.orders} component={Store} />
                <Route path={APP_URL.orderMake} component={Store} />
                <Route path={APP_URL.orderPayment} component={Store} />

                <Route path={APP_URL.goods} component={Goods} />

                <Route path={APP_URL.clients} component={Clients} />

                <Route path={APP_URL.delivery} component={Delivery} />

                <Route path={APP_URL.reports} component={Reports} />
                <Route path={APP_URL.report} component={Report} />

                <Route path={APP_URL.support} component={Support} exact />
                <Route path={APP_URL.ticket} component={Ticket} />

                <Route path={APP_URL.profile} component={UserProfile} />
                <Route path={APP_URL.userSettings} component={UserSettings} />

                <Route path={APP_URL.companyOrders} component={Orders} />

                <Route exact path={APP_URL.settings} component={Settings} />

                <Route path={APP_URL.wallet} component={Wallet} />

                <Route exact path={APP_URL.friends} component={Friends} />
                <Route exact path={APP_URL.friendsRequest} component={FriendsRequest} />
                <Route path={APP_URL.friendsMutual} component={FriendsMutual} />

                <Route path={APP_URL.rating} component={Rating} />

                <Route path={APP_URL.messenger} component={Messenger} />

                <Route path={APP_URL.company} component={Company} />

                <Route path={APP_URL.warehouse} component={Warehouse} />

                {/* dev */}
                <Route path={APP_URL.account} component={DevModule} />
                <Route path={APP_URL.map} component={DevModule} />
                <Route path={APP_URL.kicks} component={DevModule} />
                <Route path={APP_URL.popular} component={DevModule} />
                <Route path={APP_URL.invite} component={DevModule} />
                <Route path={APP_URL.receipt} component={DevModule} />
                <Route path={APP_URL.lessons} component={DevModule} />

                <Redirect from="*" to={mainPrivateRoute} />
            </Switch>
        </Suspense>
    )

    useEffect(() => {
        dispatch(fetchProfile({ userId: user.id }))
    }, [])

    return (
        <>
            <header>
                <NavBar>
                    <NavMenu />
                </NavBar>
            </header>

            <Container>
                <PageContainer classes={style.pageContainer}>
                    {windowWidth >= BREAKPOINTS.tabletLandscape && (
                        <SideBarContainer position="left">
                            {isUserMode && (
                                <Block classes={style.userInfoBlock}>
                                    <UserInfo />
                                </Block>
                            )}
                            <div className={cn(style.menuNav, style[`menuNav_${appMode}`])}>
                                <MenuNav />
                            </div>

                            <FooterLK>
                                {isUserMode && (
                                    <div className={style.footerText}>
                                        {t('Socialize with friends, make purchases, and get kickbacks!')}
                                    </div>
                                )}
                                <MenuStatic
                                    isBusiness={isBusinessMode}
                                    isPublic={false}
                                    classesNav={cn(style.menuStaticNav, style[`menuStaticNav_${appMode}`])}
                                    classesItem={cn(style.menuStaticItem, style[`menuStaticItem_${appMode}`])}
                                    classesLink={cn(style.menuStaticLink, style[`menuStaticLink_${appMode}`])}
                                />
                            </FooterLK>
                        </SideBarContainer>
                    )}

                    {routes}
                </PageContainer>
            </Container>

            {windowWidth < BREAKPOINTS.tabletLandscape && (
                <MenuNav isMobile />
            )}
        </>
    )
}

export default LKPage
