import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { QRCodeSVG } from 'qrcode.react'

import { IPostOrder } from 'interfaces'
import { dateTimeFormat, parseTpl } from 'utils/helpers'
import style from './PostMainImageCheckData.module.css'

type PostMainImageCheckDataPropType = {
    data: IPostOrder
}

const PostMainImageCheckData: React.FC<PostMainImageCheckDataPropType> = ({ data }) => {
    const { t, i18n } = useTranslation()

    const createDate = useMemo(() => dateTimeFormat(data.create_date, i18n.language), [data])

    return (
        <div className={style.checkData}>
            <div className={style.block}>
                <div className={style.title}>
                    {data.store_name}
                </div>
            </div>
            <div className={style.block}>
                <div className={style.products}>
                    {parseTpl(t('ico_check_goods_count'), {
                        '%@': data.quantity,
                    }, {
                        prefix: '',
                        suffix: '',
                    })}
                </div>
                <div className={style.total}>
                    <span className={style.totalText}>
                        {t('market_product_total')}
                    </span>
                    {data.left_currency_symbol_placement ? (
                        <span className={style.totalSum}>
                            {data.currency}
                            {' '}
                            {data.order_sum}
                        </span>
                    ) : (
                        <span className={style.totalSum}>
                            {data.order_sum}
                            {' '}
                            {data.currency}
                        </span>
                    )}
                </div>
            </div>
            <div className={style.info}>
                <div className={style.infoQR}>
                    <QRCodeSVG
                        value={data.store_name}
                        bgColor="#000000"
                        fgColor="#FFFFFF"
                        size={54}
                        includeMargin={false}
                    />
                </div>
                <div>
                    <div className={style.infoItem}>
                        №
                        {' '}
                        {data.id}
                    </div>
                    <div className={style.infoItem}>
                        {createDate}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostMainImageCheckData
