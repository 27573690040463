import React, { useMemo } from 'react'
import cn from 'classnames'

import { IReport } from 'interfaces'
import { APP_URL } from 'config/app'
import { API_HOST } from 'config/api'
import { Card } from 'components'
import { parseTpl } from 'utils/helpers'
import style from './ReportFolderActive.module.css'

type ReportActivePropType = {
    classes?: string
    data: IReport
}

const getUrl = ({ id }: IReport) => {
    return parseTpl(APP_URL.report, { ':id': id }, { prefix: '', suffix: '' })
}
/**
 * @deprecated
 */
const ReportFolderActive: React.FC<ReportActivePropType> = ({ classes, data }) => {
    const url = useMemo(() => getUrl(data), [data])

    return (
        <Card.Item
            classes={cn(style.folder, classes)}
            url={url}
        >
            <Card.Image
                src={`${API_HOST}${data.icon}`}
                backgroundColor={data.iconBackgroundColor}
                width={data.width || 400}
                height={data.height || 300}
            />

            <Card.Body classes={style.body}>
                <span className={style.text}>
                    {data.name}
                </span>
            </Card.Body>
        </Card.Item>
    )
}

export default ReportFolderActive
