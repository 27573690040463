import React, { useEffect } from 'react'
import {
    Switch,
    Route,
    Redirect,
    useLocation,
} from 'react-router-dom'

import { APP_URL } from 'config/app'
import { scrollTop } from 'utils/helpers'
import OrdersOrders from './OrdersOrders/OrdersOrders'
import OrdersOrder from './OrdersOrder/OrdersOrder'

const Orders: React.FC = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [pathname])

    return (
        <Switch>
            <Route exact path={APP_URL.companyOrders} component={OrdersOrders} />
            <Route exact path={APP_URL.companyOrder} component={OrdersOrder} />
            <Redirect from="*" to={APP_URL.companyOrders} />
        </Switch>
    )
}

export default Orders
