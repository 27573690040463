import React, { useState, useEffect } from 'react'
import { Form } from 'react-final-form'
import { useDebouncedCallback } from 'use-debounce'

import { IPersonalStore, IStoreLoyalty } from 'interfaces'
import { TSetStoreLoyaltySettingsProps } from 'services/KicksService'
import { TSetStoreLoyaltySettingsMutationFnError } from 'containers/Store/hooks/useStoreLoyaltySettings'
import { FormDataType, FieldName, formDataInitial } from 'forms/CompanyStoreSettingsForm/CompanyStoreSettingsForm'
import { INPUT_DEBOUNCE_TIME } from 'config/app'
import { useFetchStoreLoyaltySettings, useMutationStoreLoyaltySettings } from 'containers/Store/hooks'
import { CompanyStoreSettingsForm } from 'forms'

export type CompanyStoreSettingsActionPropType = {
    store: IPersonalStore
    onSuccess?: (data: IStoreLoyalty) => void
    onError?: (err: TSetStoreLoyaltySettingsMutationFnError) => void
}

const CompanyStoreSettingsAction: React.FC<CompanyStoreSettingsActionPropType> = ({
    store,
    onSuccess,
    onError,
}) => {
    const [initialValues, setInitialValues] = useState<FormDataType>(formDataInitial)
    const [storeLoyalty, setStoreLoyalty] = useState<IStoreLoyalty>()

    const debounceChangeStoreLoyaltySettings = useDebouncedCallback(changeStoreLoyaltySettings, INPUT_DEBOUNCE_TIME)

    const {
        data: dataStoreLoyaltySettings,
    } = useFetchStoreLoyaltySettings({
        storeId: store.id,
    })

    const { set: setStoreLoyaltySettings } = useMutationStoreLoyaltySettings()

    const handlerSubmit = (params: FormDataType) => {
        return submitStoreLoyaltySettings(params)
    }

    const handlerChange = (params: FormDataType) => {
        debounceChangeStoreLoyaltySettings(params)
    }

    function changeStoreLoyaltySettings(params: FormDataType) {
        setStoreLoyaltySettingsAction({ storeId: store.id, ...params })
            .then((data) => {
                setStoreLoyalty(data)
            })
            .catch(onError)
    }

    function submitStoreLoyaltySettings(params: FormDataType) {
        return setStoreLoyaltySettingsAction({ storeId: store.id, ...params, need_save: true })
            .then(onSuccess)
            .catch(onError)
    }

    function setStoreLoyaltySettingsAction(params: TSetStoreLoyaltySettingsProps) {
        return setStoreLoyaltySettings.mutateAsync(params)
    }

    useEffect(() => {
        if (dataStoreLoyaltySettings) {
            setInitialValues((prevState) => ({
                ...prevState,
                [FieldName.cashback_value]: dataStoreLoyaltySettings.cashback_user_receives_percent,
                [FieldName.kickback_value]: dataStoreLoyaltySettings.kickback_user_receives_percent,
            }))
            setStoreLoyalty(dataStoreLoyaltySettings)
        }
    }, [dataStoreLoyaltySettings])

    return (
        <Form
            onSubmit={handlerSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => (
                <CompanyStoreSettingsForm
                    isSubmitting={submitting}
                    storeLoyalty={storeLoyalty}
                    onChange={handlerChange}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default CompanyStoreSettingsAction
