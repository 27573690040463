import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'
import {
    UseQueryOptions,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TReportServiceError,
    TReportsFilterValueProps,
    TReportsFilterValueResponse,
} from 'services/ReportService'
import { QueryCacheKeys } from 'enums'
import { ReportService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TReportsFilterValueProps
export type TFetchFnError = AxiosError<TReportServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TReportsFilterValueResponse, TError, TReportsFilterValueResponse, TQueryKey>

const key = QueryCacheKeys.report

/**
 * Хук API получить значения фильтра в отчете
 */
export default function useFetchReportFilterValue(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return ReportService.fetchReportsFilterValue(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
