import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import cn from 'classnames'

import { IConfig, IUser } from 'interfaces'
import { COUNTS_TIME_TO_UPDATE } from 'config/app'
import Routes from 'routes'
import {
    Loader,
    AlertDialog,
    AlertNotify,
    ModalContent,
} from 'components'
import { useAppMode, useAppConfig, useAppSettings } from 'containers/App/hooks'
import { fetchUser, fetchCounts } from 'containers/User/user-actions'
import * as userSelectors from 'containers/User/user-selectors'
import { showAlertNotify } from 'utils/helpers'
import { AuthService, TranslationService } from 'services'
import { useThunkDispatch } from 'hooks'
import {
    setDarkDoor,
    getCurrentPosition,
    fetchLanguages,
    fetchCountries,
    fetchCountryIdentify,
    fetchResources,
    fetchStickers,
} from './app-actions'
import style from './App.module.css'

type AppPropType = {}

/**
 * Application main container
 *
 * @name App
 * @memberOf module:Modules
 */
const App: React.FC<AppPropType> = () => {
    const { search } = useLocation()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const thunkDispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()

    const { appMode, isUserMode, isBusinessMode } = useAppMode()
    const { init: initAppConfig } = useAppConfig()
    const { init: initAppSettings } = useAppSettings()

    const user = useSelector(userSelectors.user)
    const isAuthorized = useSelector(userSelectors.authorize)

    const [isLoading, setIsLoading] = useState(true)

    function startUserAuthorize(appConfig: IConfig) {
        fetchUserAction()
            .then((userData) => {
                authorizeAction(userData, appConfig)
            })
            .catch(() => {})
    }

    function fetchUserAction() {
        return thunkDispatch(fetchUser())
            .then((data: IUser) => {
                // change app lang by user country lang
                TranslationService.changeAppLanguage(data.language.lang_short)
                return data
            })
            .catch((err) => {
                const { response, code } = err || {}
                const { error, error_description: errorDesc } = response?.data || {}
                let errorText = t('update_error')

                setIsLoading(false)

                if (error === 'invalid_grant') {
                    errorText = errorDesc
                } else if (code === 'ERR_NETWORK') {
                    errorText = t('no_internet')
                }

                showAlertNotify({ type: 'error', message: errorText })
                return Promise.reject(err)
            })
    }

    function authorizeAction(userData: IUser, configData: IConfig) {
        authorizeModeAction(userData, configData)
            .then((route) => {
                if (route) {
                    history.push(route)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err.error || t('update_error') })
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    function authorizeModeAction(userData: IUser, configData: IConfig) {
        if (isUserMode) {
            return AuthService.authorize(userData, location)
        }
        if (isBusinessMode) {
            return AuthService.authorizeBusiness(userData, configData)
        }

        return Promise.reject()
    }

    useEffect(() => {
        const darkDoorUrlParams = AuthService.getDarkDoorUrlParams(search)

        setIsLoading(true)
        initAppConfig({ enabled: isBusinessMode })
            .then((configData) => {
                TranslationService.startUpdate()
                dispatch(getCurrentPosition())
                dispatch(fetchLanguages())
                dispatch(fetchCountries())
                dispatch(fetchResources())

                if (AuthService.isDarkDoorAuth(darkDoorUrlParams)) {
                    dispatch(setDarkDoor(darkDoorUrlParams))
                    startUserAuthorize(configData)
                } else if (AuthService.isAuthenticated()) {
                    startUserAuthorize(configData)
                } else {
                    dispatch(fetchCountryIdentify())
                    setIsLoading(false)
                }
            })
            .catch(() => {
                showAlertNotify({ type: 'error', message: t('update_error') })
            })
        initAppSettings()
    }, [])

    useEffect(() => {
        if (user) {
            Sentry.setUser({ id: String(user.id) })
            dispatch(fetchStickers())
        }

        return () => {
            Sentry.setUser(null)
        }
    }, [user])

    useEffect(() => {
        let countsTimerId = 0

        if (isAuthorized) {
            dispatch(fetchCounts())

            countsTimerId = window.setInterval(() => {
                dispatch(fetchCounts())
            }, COUNTS_TIME_TO_UPDATE)
        }

        return () => {
            if (countsTimerId) {
                clearInterval(countsTimerId)
            }
        }
    }, [isAuthorized])

    return (
        <>
            {isLoading ? (
                <Loader classes={style.loader} size="large" />
            ) : (
                <div className={cn(style.app, style[`app_${appMode}`])}>
                    <Routes isAuthorized={isAuthorized} />

                    <AlertDialog />

                    <ModalContent />
                </div>
            )}

            <AlertNotify />
        </>
    )
}

export default App
