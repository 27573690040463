import React from 'react'

import { SvgResource } from 'components'

const TooltipTriggerInfo: React.FC = () => {
    return (
        <SvgResource
            resourceKey="reg_business_help_svg"
        />
    )
}

export default TooltipTriggerInfo
