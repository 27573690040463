import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebouncedCallback } from 'use-debounce'
import cn from 'classnames'

import { INPUT_DEBOUNCE_TIME } from 'config/app'
import { Input } from 'components'
import styleCommon from 'styles/modules/common.module.css'
import style from './WalletFilter.module.css'

export type WalletFilterDateType = {
    from: string
    to: string
}

export type WalletFilterPropType = {
    classes?: string
    disabled?: boolean
    onChange: (date: WalletFilterDateType) => void
}

const WalletFilter: React.FC<WalletFilterPropType> = ({ classes, disabled, onChange }) => {
    const { t } = useTranslation()

    const [date, setDate] = useState<WalletFilterDateType>({ from: '', to: '' })
    const [isInit, setIsInit] = useState(false)

    const debounce = useDebouncedCallback(changeDateCallback, INPUT_DEBOUNCE_TIME + 200)

    const handlerChangeDate = (e: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget
        debounce(name, value)
    }

    function changeDateCallback(name: string, value: string) {
        setIsInit(true)
        setDate((prevState) => ({ ...prevState, [name]: value }))
    }

    useEffect(() => {
        if (isInit) {
            onChange(date)
        }
    }, [date])

    return (
        <div className={cn(style.filter, classes, { [styleCommon.updating]: disabled })}>
            <div className={style.item}>
                <Input
                    type="date"
                    name="from"
                    styleType="default"
                    placeholder={t('ui_posts_spinner_date_from_hint')}
                    onChange={handlerChangeDate}
                />
            </div>

            <div className={style.item}>
                <Input
                    type="date"
                    name="to"
                    styleType="default"
                    placeholder={t('ui_posts_spinner_date_to_hint')}
                    onChange={handlerChangeDate}
                />
            </div>
        </div>
    )
}

export default WalletFilter
