import React from 'react'
import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IOrder, IPaymentStatuses } from 'interfaces'
import { APP_URL } from 'config/app'
import { TableList, NoDataInfo, Link } from 'components'
import { dateTimeFormat, numberFormat } from 'utils/helpers'
import style from './CompanyOrdersList.module.css'

export enum ColumnKey {
    id = 'id',
    fio = 'fio',
    ordered_for = 'ordered_for',
    order_sum = 'order_sum',
    store = 'store',
    create_date = 'create_date',
    payment_date = 'payment_date',
    bonus = 'bonus',
    payment_status = 'payment_status',
    delivery = 'delivery',
    delivery_address = 'delivery_address',
    message = 'message',
}

export type TColumn = {
    key: keyof typeof ColumnKey
    translateKey: string
    checked: boolean
}

type CompanyOrdersListPropType = {
    classes?: string
    columns?: TColumn[]
    data?: IOrder[]
    paymentStatuses?: IPaymentStatuses
}

export const LIST_COLUMNS: TColumn[] = [
    { key: ColumnKey.id, translateKey: 'order_id', checked: true },
    { key: ColumnKey.fio, translateKey: 'full_name', checked: true },
    { key: ColumnKey.ordered_for, translateKey: 'order_recipient', checked: false },
    { key: ColumnKey.delivery, translateKey: 'delivery', checked: false },
    { key: ColumnKey.delivery_address, translateKey: 'Delivery address', checked: false },
    { key: ColumnKey.order_sum, translateKey: 'order_sum', checked: true },
    { key: ColumnKey.store, translateKey: 'store', checked: false },
    { key: ColumnKey.create_date, translateKey: 'order_date_create', checked: false },
    { key: ColumnKey.payment_date, translateKey: 'order_date_time_payment', checked: true },
    { key: ColumnKey.bonus, translateKey: 'order_bonus', checked: false },
    { key: ColumnKey.payment_status, translateKey: 'order_payment_status', checked: true },
    { key: ColumnKey.message, translateKey: 'comment', checked: false },
]

const CompanyOrdersList: React.FC<CompanyOrdersListPropType> = ({
    classes,
    columns = LIST_COLUMNS,
    data,
    paymentStatuses,
}) => {
    const { t, i18n } = useTranslation()

    const getFormattedValue = (key: keyof typeof ColumnKey, values: IOrder) => {
        switch (key) {
            case 'id':
                return (
                    <Link
                        className={style.link}
                        url={generatePath(APP_URL.companyOrder, { storeId: values.store.id, id: values.id })}
                    >
                        {values.id}
                    </Link>
                )
            case 'fio':
                return (
                    <Link
                        className={cn(style.link, style.link_blue)}
                        url={values.account ? generatePath(APP_URL.profile, { id: values.account.user.id }) : undefined}
                    >
                        {`${values.account?.user.surname ?? ''} ${values.account?.user.name ?? ''}`}
                    </Link>
                )
            case 'delivery':
                return values.delivery_method?.name
            case 'delivery_address':
                return values.delivery_address
            case 'ordered_for':
                return values.ordered_for && values.ordered_for.fio
            case 'order_sum':
                return `${numberFormat(values.order_sum)} ${values.store.currency}`
            case 'create_date':
                return dateTimeFormat(values.create_date, i18n.language, { dateStyle: 'short', timeStyle: 'medium' })
            case 'payment_date':
                return values.payment_date
                    && dateTimeFormat(values.payment_date, i18n.language, { dateStyle: 'short', timeStyle: 'medium' })
            case 'payment_status':
                return paymentStatuses ? paymentStatuses[values.payment_status] : values.payment_status
            case 'store':
                return (
                    <Link
                        className={cn(style.link, style.link_blue)}
                        url={generatePath(APP_URL.shop, { id: values.store.id })}
                    >
                        {values.store.name}
                    </Link>
                )
            default:
                return values[key]
        }
    }

    return (
        <>
            {data && !data.length && (
                <NoDataInfo classes={style.noDataText} text={t('no_data')} />
            )}
            {data && !!data.length && (
                <TableList classes={cn(style.list, classes)}>
                    <TableList.Header classes={style.header}>
                        {columns.map((item) => (
                            item.checked && (
                                <TableList.Col classes={cn(style.col, style.col_header)} key={item.key}>
                                    <div className={style.colInner}>
                                        <div className={style.headerTitle}>
                                            {t(item.translateKey)}
                                        </div>
                                        {item.key !== 'message' && (
                                            <TableList.Sort
                                                disabled
                                                classes={style.headerSort}
                                                onClick={() => {}}
                                            />
                                        )}
                                    </div>
                                </TableList.Col>
                            )
                        ))}
                    </TableList.Header>
                    <TableList.Body>
                        {data.map((item) => (
                            <TableList.Row classes={style.row} key={item.id}>
                                {columns.map((itemColumn) => (
                                    itemColumn.checked && (
                                        <TableList.Col classes={cn(style.col, style.col_body)} key={itemColumn.key}>
                                            <div className={style.colTitle}>
                                                {t(itemColumn.translateKey)}
                                            </div>
                                            <div className={style.colInner}>
                                                {getFormattedValue(itemColumn.key, item)}
                                            </div>
                                        </TableList.Col>
                                    )
                                ))}
                            </TableList.Row>
                        ))}
                    </TableList.Body>
                </TableList>
            )}
        </>
    )
}

export default CompanyOrdersList
