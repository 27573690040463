import { AxiosError } from 'axios'
import { useSelector } from 'react-redux'
import { useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TKicksServiceError,
    TStoreLoyaltySettingsProps,
    TStoreLoyaltySettingsResponse,
    TSetStoreLoyaltySettingsProps,
    TSetStoreLoyaltySettingsResponse,
} from 'services/KicksService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { KicksService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TStoreLoyaltySettingsProps
export type TFetchFnError = AxiosError<TKicksServiceError>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string]
export type TQueryFnOpts = UseQueryOptions<
    TStoreLoyaltySettingsResponse,
    TError,
    TStoreLoyaltySettingsResponse,
    TQueryKey
>

export type TSetStoreLoyaltySettingsMutationFnError = [
    string,
    AxiosError<TKicksServiceError, TSetStoreLoyaltySettingsProps>
]

const key = QueryCacheKeys.storeLoyaltySettings

/**
 * Хук API получить настройки лояльности магазина
 */
export default function useFetchStoreLoyaltySettings(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`], () => {
        return KicksService.fetchStoreLoyaltySettings(params)
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

/**
 * Mutation store loyalty settings
 * after mutation do update query cached data store loyalty settings
 */
export function useMutationStoreLoyaltySettings() {
    const { t } = useTranslation()

    const set = useMutation<
        TSetStoreLoyaltySettingsResponse,
        TSetStoreLoyaltySettingsMutationFnError,
        TSetStoreLoyaltySettingsProps
    >((props) => {
        return KicksService.setStoreLoyaltySettings(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        set,
    }
}
