import React, { useState } from 'react'
import cn from 'classnames'

import { ICatalogItem } from 'interfaces'
import { Button, SvgResource } from 'components'
import style from './CatalogTreeItem.module.css'

type CatalogTreeItemPropType = {
    classes?: string
    data: ICatalogItem
    onClick?: (data: ICatalogItem) => void
}

const CatalogTreeItem: React.FC<CatalogTreeItemPropType> = ({ classes, data, onClick }) => {
    const [gap] = useState(data.relative_level > 1 ? (data.relative_level - 1) * 50 : 0)

    const handlerClick = () => {
        if (onClick) {
            onClick(data)
        }
    }

    return (
        <Button
            classes={cn(style.item, classes)}
            style={{ marginLeft: gap }}
            styleType="transparent"
            onClick={handlerClick}
        >
            {data.view_type === 2 && (
                <SvgResource
                    classes={style.iconItem}
                    resourceKey="ic_attach_doc_svg"
                    width={32}
                    height={26}
                />
            )}
            <div className={style.name}>
                {data.name}
            </div>
        </Button>
    )
}

export default CatalogTreeItem
