import React, { useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { CardContainer } from 'layout'
import {
    Button,
    Checkbox,
    CustomSelect,
    DatePicker,
    Input,
    Modal,
    SvgResource,
} from 'components'
import { dateTimeFormat, defaultDateFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './WarehouseSuppliesForm.module.css'

// FIXME on ready backend
export enum FieldName {
    dateOrder = 'dateOrder',
    dateSupply = 'dateSupply',
    status = 'status',
    comment = 'comment',
    sender = 'sender',
    isInternal = 'isInternal',
    goods = 'goods',
}

type TDateField = FieldName.dateOrder | FieldName.dateSupply

export type FormDataType = {
    [FieldName.dateOrder]: string
    [FieldName.dateSupply]: string
    [FieldName.status]: number
    [FieldName.comment]?: string
    [FieldName.sender]?: string
    [FieldName.isInternal]: 0|1
    [FieldName.goods]: number[] // goods ids
}

export type WarehouseFilterFormPropType = {
    classes?: string
    classesBody?: string
    classesFooter?: string
    isSubmitting: boolean
    goods?: { id: number, name: string }[] // FIXME on ready api request
    statuses?: { id: number, name: string }[] // FIXME on ready api request
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    [FieldName.dateOrder]: '',
    [FieldName.dateSupply]: '',
    [FieldName.status]: 0,
    [FieldName.isInternal]: 0,
    [FieldName.goods]: [],
}

const DATE_TIME_OPTS: Intl.DateTimeFormatOptions = {
    dateStyle: 'short',
    timeStyle: 'medium',
}

const WarehouseSuppliesForm: React.FC<WarehouseFilterFormPropType> = ({
    classes,
    classesBody,
    classesFooter,
    isSubmitting,
    statuses,
    onSubmit,
}) => {
    const { t, i18n } = useTranslation()

    const { change } = useForm<FormDataType>()
    const { values, hasValidationErrors } = useFormState<FormDataType>()

    const [currentDateField, setCurrentDateField] = useState<TDateField>()
    const [dateCalendar, setDateCalendar] = useState(new Date())
    const [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)

    const statusesData: Record<number, string> = useMemo(() => {
        return statuses
            ? statuses.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [statuses])

    const dateSelected = useMemo(() => {
        return currentDateField && values[currentDateField]
            ? new Date(values[currentDateField] as string)
            : undefined
    }, [values, currentDateField])

    return (
        <>
            <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <div className={classesBody}>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <CardContainer gap="15px">
                            <div className={style.rowFiled}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Дата заказа
                                </div>
                                <Field
                                    <FormDataType[FieldName.dateOrder]>
                                    name={FieldName.dateOrder}
                                    render={({ input, meta }) => (
                                        <div className={cn(styleForm.group, style.field)}>
                                            <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                                <Input
                                                    styleType="defaultBusiness"
                                                    name={input.name}
                                                    value={input.value
                                                        && dateTimeFormat(input.value, i18n.language, DATE_TIME_OPTS)}
                                                    onFocus={(e) => {
                                                        e.currentTarget.blur()
                                                    }}
                                                    onBlur={() => {
                                                        setCurrentDateField(FieldName.dateOrder)
                                                        setDateCalendar(input.value
                                                            ? new Date(input.value)
                                                            : new Date())
                                                        setIsOpenModalCalendar(true)
                                                    }}
                                                    onChange={() => {}}
                                                />
                                                <div className={styleForm.error}>
                                                    {meta.dirty && meta.error}
                                                </div>
                                            </div>
                                            <div className={cn(styleForm.groupItem, styleForm.groupItem_alignCenter)}>
                                                <SvgResource
                                                    isImgTag={false}
                                                    classes={cn(
                                                        // styleForm.fieldIcon,
                                                        style.fieldIcon,
                                                        style.iconCalendar,
                                                    )}
                                                    resourceKey="ic_kicks_calendar2_svg"
                                                    width={20}
                                                    height={20}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                            <div className={style.rowFiled}>
                                <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                    {/* TODO */}
                                    Дата поставки
                                </div>
                                <Field
                                    <FormDataType[FieldName.dateSupply]>
                                    name={FieldName.dateSupply}
                                    render={({ input, meta }) => (
                                        <div className={cn(styleForm.group, style.field)}>
                                            <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                                                <Input
                                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                                    styleType="defaultBusiness"
                                                    name={input.name}
                                                    value={input.value
                                                        && dateTimeFormat(input.value, i18n.language, DATE_TIME_OPTS)}
                                                    onFocus={(e) => {
                                                        e.currentTarget.blur()
                                                    }}
                                                    onBlur={() => {
                                                        setCurrentDateField(FieldName.dateSupply)
                                                        setDateCalendar(input.value
                                                            ? new Date(input.value)
                                                            : new Date())
                                                        setIsOpenModalCalendar(true)
                                                    }}
                                                    onChange={() => {}}
                                                />
                                                <div className={styleForm.error}>
                                                    {meta.dirty && meta.error}
                                                </div>
                                            </div>
                                            <div className={cn(styleForm.groupItem, styleForm.groupItem_alignCenter)}>
                                                <SvgResource
                                                    isImgTag={false}
                                                    classes={cn(
                                                        // styleForm.fieldIcon,
                                                        style.fieldIcon,
                                                        style.iconCalendar,
                                                    )}
                                                    resourceKey="ic_kicks_calendar2_svg"
                                                    width={20}
                                                    height={20}
                                                />
                                            </div>
                                        </div>
                                    )}
                                />
                            </div>
                        </CardContainer>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                            {/* TODO */}
                            Продукт
                        </div>
                        <Field
                            <FormDataType[FieldName.status]>
                            name={FieldName.status}
                            render={({ input }) => (
                                <CustomSelect.Select>
                                    <CustomSelect.Field classes={style.fieldSelect}>
                                        {!!input.value && statusesData[input.value]}
                                        {!input.value && statuses && statuses[0].name}
                                    </CustomSelect.Field>
                                    <CustomSelect.Options>
                                        {statuses?.map((item) => (
                                            <CustomSelect.Option
                                                id={item.id}
                                                key={item.id}
                                                onClick={() => input.onChange(item.id)}
                                            >
                                                {item.name}
                                            </CustomSelect.Option>
                                        ))}
                                    </CustomSelect.Options>
                                </CustomSelect.Select>
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                            {/* TODO */}
                            Комментарий
                        </div>
                        <Field
                            <FormDataType[FieldName.comment]>
                            name={FieldName.comment}
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    styleType="defaultBusiness"
                                />
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                            {/* TODO */}
                            Отправитель
                        </div>
                        <Field
                            <FormDataType[FieldName.sender]>
                            name={FieldName.sender}
                            render={({ input }) => (
                                <Input
                                    {...input}
                                    styleType="defaultBusiness"
                                />
                            )}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Field
                            <FormDataType[FieldName.isInternal]>
                            name={FieldName.isInternal}
                            render={({ input }) => (
                                <Checkbox
                                    {...input}
                                    styleType="small"
                                    // TODO
                                    text="Внутрення поставка?"
                                    checked={Boolean(input.value)}
                                    value={String(input.value)}
                                    onChange={(e) => {
                                        input.onChange(Number(e.target.checked))
                                    }}
                                />
                            )}
                        />
                    </div>
                </div>
                <div className={classesFooter}>
                    <Button
                        classes={style.control}
                        size="size44"
                        type="submit"
                        text={t('save')}
                        disabled={hasValidationErrors}
                    />
                </div>
            </form>

            <Modal
                isOpen={isOpenModalCalendar}
                size="extraSmallCenter"
                onClose={() => setIsOpenModalCalendar(false)}
            >
                <Modal.Body classes={style.modalCalendarBody}>
                    <DatePicker
                        isSetTime
                        date={dateCalendar}
                        dateSelected={dateSelected}
                        onChange={(value) => {
                            if (currentDateField) {
                                change(currentDateField, defaultDateFormat(value, true, true))
                            }
                            setIsOpenModalCalendar(false)
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WarehouseSuppliesForm
