import React, {
    useRef,
    useState,
    useEffect,
    useMemo,
} from 'react'
import ReactCropper, { ReactCropperProps, ReactCropperElement } from 'react-cropper'
import { useDebouncedCallback } from 'use-debounce'

import { Spinner } from 'components'
import 'cropperjs/dist/cropper.css'

type CropperPropType = ReactCropperProps & {
    source?: string // url | base64
    options?: {
        minWidth?: number
        minHeight?: number
        maxWidth?: number
        maxHeight?: number
    }
    onCrop: (base64: string, type: string) => void
}

/**
 * Обрезка изображений с UI
 * @see https://github.com/fengyuanchen/cropperjs#options
 */
const Cropper: React.FC<CropperPropType> = ({
    source,
    zoomOnWheel = false,
    aspectRatio = 1,
    viewMode = 1,
    options = {},
    onCrop,
    ...props
}) => {
    const DEFAULT_OPTIONS = {
        minWidth: 180,
        minHeight: 180,
        maxWidth: 1080,
        maxHeight: 1080,
    }
    const cropDebounced = useDebouncedCallback(cropCallback, 300)
    const cropperRef = useRef<ReactCropperElement>(null)

    const [isProcessing, setIsProcessing] = useState(false)

    const sourceType = useMemo(() => {
        if (source) {
            const [type] = source.match(/image\/\w+/) || []
            return type
        }
        return undefined
    }, [source])

    const handlerCrop = () => {
        cropDebounced(cropperRef?.current)
    }

    function cropCallback(el: ReactCropperElement | null) {
        const cropper = el && el.cropper

        if (cropper) {
            const dataImage = cropper
                .getCroppedCanvas({ ...DEFAULT_OPTIONS, ...options })
                .toDataURL(sourceType)
            const [data, base64] = dataImage.split(',')
            const [type = ''] = data.match(/image\/\w+/) || []

            setIsProcessing(false)
            onCrop(base64, type)
        }
    }

    useEffect(() => {
        if (source) {
            setIsProcessing(true)
        }
    }, [source])

    return (
        <>
            {isProcessing && <Spinner size="medium" />}

            <ReactCropper
                {...props}
                zoomOnWheel={zoomOnWheel}
                aspectRatio={aspectRatio}
                viewMode={viewMode}
                src={source}
                crop={handlerCrop}
                ref={cropperRef}
            />
        </>
    )
}

export default Cropper
