import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    IStoreHeader,
    IStoreGoods,
    IStoreFolder,
    IStoreProfile,
    IStoreCatalog,
    IStoreCurrency,
    IBasketWallet,
    IGoods,
    IGoodsData,
} from 'interfaces'
import { TBasketUpdateProps } from 'services/MarketService'
import { CardSize } from 'enums'
import { APP_URL, PRODUCT_TYPE_PROMOTION_DESCRIPTION, BREAKPOINTS } from 'config/app'
import { useFetchBasket, useMutationBasket } from 'containers/Market/hooks'
import { useBasketGoods } from 'containers/StoreBasket/hooks'
import {
    Loader,
    Modal,
    Button,
    ErrorMsg,
    Iframe,
    CatalogShowcase,
} from 'components'
import { getStoreCurrency, showAlertNotify, parseTpl } from 'utils/helpers'
import { MarketService } from 'services'
import { useWindowResize } from 'hooks'
import { CatalogItems } from './components'
import style from './Catalog.module.css'

type CatalogPropType = {
    classes?: string
    isLoading?: boolean
    isShowEmptyText?: boolean
    isSetItemLink: boolean
    isShowBuyButton: boolean
    storeProfile: IStoreProfile
    storeCatalog?: (IStoreHeader | IStoreFolder | IStoreGoods)[]
    storeGoods?: IGoods[]
    onClickFolder?: (data: IStoreFolder) => void
    onClickGoods?: (data: IGoodsData) => void
}

const CATALOG_DEFAULT: IStoreCatalog = {
    headers: [],
    folders: [],
    goods: [],
}

/**
 * FIXME:
 * not actual
 */
const prepareCatalog = (data: (IStoreHeader | IStoreFolder | IStoreGoods)[]): IStoreCatalog | undefined => {
    if (!data.length) {
        return undefined
    }
    return data.reduce((acc, item) => {
        const storeCatalog = { ...acc }

        if (item.type === 'header') {
            return { ...storeCatalog, headers: [...storeCatalog.headers, item] }
        }
        if (item.type === 'folder') {
            return { ...storeCatalog, folders: [...storeCatalog.folders, item] }
        }
        if (item.type === 'goods') {
            return { ...storeCatalog, goods: [...storeCatalog.goods, item] }
        }

        return storeCatalog
    }, CATALOG_DEFAULT)
}

/**
 * Catalog - user view store catalog
 * TODO refactoring:
 * - to components
 * - replace CatalogItems for CatalogShowcase items
 */
const Catalog: React.FC<CatalogPropType> = ({
    classes,
    isLoading = false,
    isShowEmptyText = true,
    isSetItemLink,
    isShowBuyButton,
    storeProfile,
    storeCatalog,
    storeGoods,
    onClickFolder,
    onClickGoods,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const [windowWidth] = useWindowResize()

    const [goodsIframe, setGoodsIframe] = useState<IGoodsData>()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isOpenGoodsModalIframe, setIsOpenGoodsModalIframe] = useState(false)

    const { data: dataBasket } = useFetchBasket({ storeId: storeProfile.id }, { enabled: !!storeProfile.id }) // !!dataStoreProfile

    const { goodsInBasketDefaultPrice } = useBasketGoods({ basket: dataBasket, goodsId: goodsIframe?.id })

    const { update: updateBasket } = useMutationBasket()

    const catalog: IStoreCatalog | undefined = useMemo(() => {
        if (storeGoods) {
            return {
                headers: [],
                folders: [],
                goods: storeGoods.map((item) => ({ type: 'goods', sort: item.sort, container: item })),
            }
        }
        if (storeCatalog) {
            return prepareCatalog(storeCatalog)
        }

        return undefined
    }, [storeCatalog, storeGoods])

    const storeCurrency: IStoreCurrency | undefined = useMemo(() => {
        return storeProfile ? getStoreCurrency(storeProfile) : undefined
    }, [storeProfile])

    const payoutCurrency = useMemo(() => {
        return MarketService.getPayoutCurrency(dataBasket?.market_wallet_list)
    }, [dataBasket])

    const wallets: IBasketWallet[] = useMemo(() => dataBasket?.market_wallet_list || [], [dataBasket])

    const itemsSize = useMemo(() => {
        if (windowWidth > BREAKPOINTS.tabletLandscape) {
            return CardSize.thirdWidth
        }
        return CardSize.halfWidth
    }, [windowWidth])

    const handlerCloseModalGoodsIframe = () => {
        setGoodsIframe(undefined)
        setIsOpenGoodsModalIframe(false)
    }

    const handlerClickGoods = (data: IGoodsData) => {
        if (isSetItemLink && data.landing_link) {
            setGoodsIframe(data)
            setIsOpenGoodsModalIframe(true)
        } else if (onClickGoods) {
            onClickGoods(data)
        }
    }

    const handlerAddGoodsToBasket = ({ id }: IGoodsData, count: number) => {
        addToBasketAction(id, count)
    }

    const handlerAddGoodsToBasketCV = ({ id }: IGoodsData, count: number) => {
        addToBasketAction(id, count, MarketService.getPayoutCurrency(wallets))
    }

    const handlerAddLandingGoodsToBasket = () => {
        if (goodsInBasketDefaultPrice?.quantity) {
            history.push(parseTpl(APP_URL.basket, { ':id': storeProfile.id }, { prefix: '', suffix: '' }))
        } else if (goodsIframe) {
            addToBasketAction(goodsIframe.id, 1)
        }
    }

    /**
     * FIXME to parent container
     */
    function addToBasketAction(goodsId: number, count: number, paymentCurrency: string = '') {
        const params: TBasketUpdateProps = {
            storeId: storeProfile.id,
            goods: goodsId,
            quantity: count,
        }

        if (paymentCurrency) {
            params.payment_by = paymentCurrency
        }

        setIsSubmitting(true)
        updateBasket.mutate(params, {
            onSuccess: (res) => {
                if (res?.warning_list?.length) {
                    showAlertNotify({
                        type: 'error',
                        message: res.warning_list[0].description || t('update_error'),
                    })
                }
            },
            onError: (err) => {
                showAlertNotify({ type: 'error', message: err[0] })
            },
            onSettled: () => {
                setIsSubmitting(false)
                setIsOpenGoodsModalIframe(false)
            },
        })
    }

    return (
        <>
            {isLoading && (
                <Loader />
            )}

            {!isLoading
                && isShowEmptyText
                && ((storeCatalog && !storeCatalog.length) || (storeGoods && !storeGoods.length))
            && (
                <ErrorMsg error={t('goods_not_found')} />
            )}

            {!isLoading && catalog && (
                <CatalogShowcase classes={cn(style.catalog, classes)}>
                    <CatalogItems
                        isSetItemLink={isSetItemLink}
                        isShowBuyButton={isShowBuyButton}
                        storeId={storeProfile.id}
                        catalog={catalog}
                        basket={dataBasket}
                        itemsSize={itemsSize}
                        storeCurrency={storeCurrency}
                        payoutCurrency={payoutCurrency}
                        onClickFolder={onClickFolder}
                        onClickGoods={handlerClickGoods}
                        onAddGoodsToBasket={handlerAddGoodsToBasket}
                        onAddGoodsToBasketCV={handlerAddGoodsToBasketCV}
                    />
                </CatalogShowcase>
            )}

            <Modal
                isOpen={isOpenGoodsModalIframe}
                classes={style.modalIframe}
                classesOverlay={style.modalIframeOverlay}
                size="large"
                onClose={handlerCloseModalGoodsIframe}
            >
                <Modal.Header isCloseButton>
                    {goodsIframe?.name}
                </Modal.Header>
                <Modal.Body classes={style.modalIframeBody}>
                    {goodsIframe?.landing_link ? (
                        <Iframe
                            title={goodsIframe.name}
                            src={goodsIframe.landing_link}
                        />
                    ) : (
                        <ErrorMsg error={t('update_error')} />
                    )}
                </Modal.Body>
                {goodsIframe?.product_type?.id !== PRODUCT_TYPE_PROMOTION_DESCRIPTION && (
                    <Modal.Footer>
                        <Button
                            size="size44"
                            text={goodsInBasketDefaultPrice?.quantity ? t('market_in_basket') : t('Add to cart')}
                            disabled={isSubmitting}
                            onClick={handlerAddLandingGoodsToBasket}
                        />
                    </Modal.Footer>
                )}
            </Modal>
        </>
    )
}

export default Catalog
