import React, { useContext } from 'react'
import cn from 'classnames'
import { Collapse } from 'react-collapse'

import { Context } from '../../Context'
import style from './SlideDownBody.module.css'

export type SlideDownBodyPropType = {
    classes?: string
}

const SlideDownBody: React.FC<SlideDownBodyPropType> = ({ children, classes }) => {
    const { isOpen } = useContext(Context)

    return (
        <Collapse isOpened={isOpen}>
            <div className={cn(style.body, classes)}>
                {children}
            </div>
        </Collapse>
    )
}

export default SlideDownBody
