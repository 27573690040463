import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
    APP_URL,
    MARKET_PAYMENT_VIRTUAL,
    MARKET_PAYMENT_BY_BANK_TRANSFER,
    MARKET_PAYMENT_BY_CASH,
    MARKET_PAYMENT_BY_TERMINAL,
    MARKET_PAYMENT_BY_BANK_SCORE,
    MARKET_PAYMENT_POST_PAYMENT,
    MARKET_ORDER_STATUS_WAITING_PAYMENT,
    MARKET_ORDER_STATUS_READY_PAYMENT,
    MARKET_ORDER_STATUS_PAID,
    MARKET_ORDER_STATUS_3,
    MARKET_ORDER_STATUS_11,
    MARKET_ORDER_STATUS_15,
    MARKET_ORDER_STATUS_16,
    MARKET_ORDER_STATUS_200,
    EVENT_TYPE_ORDER_PAYMENT_SUCCESS,
    // EVENT_TYPE_ORDER_PAYMENT_ERROR,
} from 'config/app'
import {
    ICity,
    ICountry,
    IDeliveryAddress,
    IDeliveryCost,
    IOrder,
    IStoreCurrency,
} from 'interfaces'
import {
    formDataType,
    OrderProductType,
    ReceiveType,
    SubmitDataType,
} from 'forms/OrderMakeForm/OrderMakeForm'
import { TAddOrderProps } from 'services/MarketService'
import { TValidationFieldRule } from 'utils/validators'
import { Modal, Button, Loader } from 'components'
import { useMutationBasket } from 'containers/Market/hooks'
import { useFetchStoreProfile } from 'containers/Store/hooks'
import * as userSelectors from 'containers/User/user-selectors'
import { OrderMakeForm } from 'forms'
import { DeliveryAddressAction } from 'form-actions'
import { MarketService, DirectoryService } from 'services'
import { useAlertDialog, useForm } from 'hooks'
import {
    getId,
    getStoreCurrency,
    parseTpl,
    showAlertNotify,
    getRequestError,
} from 'utils/helpers'
import eventBus from 'utils/EventBus'
import styleModalBody from 'components/Modal/ModalBody/ModalBody.module.css'
import styleModalFooter from 'components/Modal/ModalFooter/ModalFooter.module.css'
import styleForm from 'styles/modules/form.module.css'
import { DeliveryAddressActionPropType } from './DeliveryAddressAction'

export type OrderMakeActionPropType = {
    storeId: number
    storeUrl: string
    orderProducts: OrderProductType[]
    sumPaymentByBonus: number
    sumPaymentByPayout: number
    payDates: string[]
    isDelivery: boolean
    isDarkDoorMode: boolean
}

const PARSE_URL_TPL_PARAMS = { prefix: '', suffix: '' }

const OrderMakeAction: React.FC<OrderMakeActionPropType> = ({
    storeId,
    storeUrl,
    orderProducts,
    sumPaymentByBonus,
    sumPaymentByPayout,
    payDates,
    isDelivery,
    isDarkDoorMode,
}) => {
    const { t } = useTranslation()
    const history = useHistory()
    const { showAlertDialog } = useAlertDialog()

    const user = useSelector(userSelectors.user)

    const [order, setOrder] = useState<IOrder>()
    const [receiveType, setReceiveType] = useState<ReceiveType>('delivery')
    const [countries, setCountries] = useState<ICountry[]>([])
    const [country, setCountry] = useState<ICountry>()
    const [city, setCity] = useState<ICity>()

    const [addresses, setAddresses] = useState<IDeliveryAddress[]>([])
    const [delivery, setDelivery] = useState<IDeliveryCost[]>([])
    const [pickup, setPickup] = useState<IDeliveryCost[]>([])
    const [addressEditData, setAddressEditData] = useState<DeliveryAddressActionPropType['data']>({})

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingDelivery, setIsLoadingDelivery] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [isOpenModalAddAddress, setIsOpenModalAddAddress] = useState(false)
    const [isOpenModalEditAddress, setIsOpenModalEditAddress] = useState(false)
    const [isOpenModalDarkDoorPayment, setIsOpenModalDarkDoorPayment] = useState(false)

    const { data: dataStoreProfile } = useFetchStoreProfile({ id: storeId })

    const { clear: clearBasket } = useMutationBasket()

    const storeCurrency: undefined | IStoreCurrency = useMemo(() => {
        return dataStoreProfile ? getStoreCurrency(dataStoreProfile) : undefined
    }, [dataStoreProfile])

    const FORM_VALIDATION_RULES: TValidationFieldRule[] = [{
        field: 'country', // for user notify, not need in the order request
        rules: isDelivery ? [{ rule: 'required', error: t('error_field_is_empty') }] : [],
    }, {
        field: 'city', // for user notify, not need in the order request
        rules: isDelivery ? [{ rule: 'required', error: t('error_field_is_empty') }] : [],
    }, {
        field: 'pay_date',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'delivery_address',
        rules: isDelivery && receiveType === 'delivery' ? [{ rule: 'required', error: t('select_address') }] : [],
    }, {
        field: 'delivery_method',
        rules: isDelivery
            ? [{ rule: 'required', error: receiveType === 'delivery' ? t('select_tariff') : t('select_pickup') }]
            : [],
    }]

    const {
        formData,
        formErrors,
        setFormValue,
        setFormError,
        validateForm,
    } = useForm<formDataType>({
        products: orderProducts,
    }, FORM_VALIDATION_RULES)

    const handlerSubmit = ({ service }: SubmitDataType) => {
        const {
            products,
            delivery_method,
            pay_date,
            ...data
        } = formData

        if (validateForm() && products.length && pay_date) {
            const params = {
                storeId,
                random_id: getId(true),
                payment_service: service,
                products,
                pay_date,
                ...data,
            }

            if (isDarkDoorMode && !service) {
                setIsOpenModalDarkDoorPayment(true)
            } else if (isDelivery && delivery_method) {
                addOrderAction({ ...params, delivery_method }, service)
            } else if (!isDelivery) {
                addOrderAction(params, service)
            }
        }
    }

    const handlerChangeFormData = (el: HTMLInputElement | HTMLTextAreaElement) => {
        const { name, value } = el

        if ('checked' in el) {
            setFormValue(name, el.checked)
        } else {
            setFormValue(name, value)
        }

        setFormError(name, false)
    }

    const handlerChangeCountry = (changedCountry: ICountry) => {
        setCountry(changedCountry)
        setCity(undefined)
        setDelivery([])

        setFormValue('city', undefined)
        setFormValue('delivery_address', undefined)
        setFormValue('delivery_method', undefined)
        setFormError('country', false)
    }

    const handlerChangeCity = (changedCity: ICity) => {
        setCity(changedCity)
        setDelivery([])

        setFormValue('delivery_address', undefined)
        setFormValue('delivery_method', undefined)
        setFormError('city', false)
    }

    const handlerChangeReceiveType = (type: ReceiveType) => {
        setReceiveType(type)

        setFormValue('delivery_method', undefined)
        setFormValue('delivery_address', undefined)
        setFormError('delivery_method', false)
        setFormError('delivery_address', false)
    }

    const handlerChangePayDate = (selectedDate: string) => {
        setFormValue('pay_date', selectedDate)
        setFormError('pay_date', false)
    }

    const handlerSelectBuyer = (selectedBuyer: number | undefined) => {
        setFormValue('buyer_account_id', selectedBuyer)
    }

    const handlerSelectDeliveryAddress = (addressId: number) => {
        setFormValue('delivery_address', addressId)
        setFormError('delivery_address', false)
    }

    const handlerAddDeliveryAddress = () => {
        setIsOpenModalAddAddress(true)
    }

    const handlerAddedDeliveryAddress = (data: IDeliveryAddress) => {
        if (data) {
            setAddresses((prevState) => [data, ...prevState])
            setFormValue('delivery_address', data.id)
            setFormError('delivery_address', false)
            setIsOpenModalAddAddress(false)
        }
    }

    const handlerEditDeliveryAddress = ({ country: countryData, ...data }: IDeliveryAddress) => {
        setAddressEditData({
            country: countries.find((item) => item.id === countryData.id),
            ...data,
        })
        setIsOpenModalEditAddress(true)
    }

    const handlerDeleteDeliveryAddress = (addressData: IDeliveryAddress) => {
        showAlertDialog({
            message: t('address_delete_header'),
            payload: addressData,
            onConfirm: deleteDeliveryAddress,
        })
    }

    const handlerEditedDeliveryAddress = (addressData: IDeliveryAddress) => {
        if (city?.id && city.id === addressData.city.id) {
            setAddresses((prevState) => prevState.map((item) => {
                return item.id === addressData.id ? { ...item, ...addressData } : item
            }))
        } else {
            setAddresses((prevState) => prevState.filter((item) => {
                return item.id !== addressData.id
            }))
        }

        setIsOpenModalEditAddress(false)
    }

    const handlerSelectPickup = (pickupId?: number) => {
        setFormValue('delivery_method', pickupId)
        setFormError('delivery_method', false)
    }

    const handlerSelectDeliveryTariff = (tariffId: number) => {
        setFormValue('delivery_method', tariffId)
        setFormError('delivery_method', false)
    }

    const handlerCloseModalAddAddress = () => {
        setIsOpenModalAddAddress(false)
    }

    const handlerCloseModalEditAddress = () => {
        setIsOpenModalEditAddress(false)
    }

    const handlerCloseModalDarkDoorPayment = () => {
        setIsOpenModalDarkDoorPayment(false)
    }

    function deleteDeliveryAddress(addressData: IDeliveryAddress) {
        deleteDeliveryAddressesAction(addressData.id)
    }

    function fetchDeliveryCountriesAction(): Promise<ICountry[]> {
        return MarketService.fetchDeliveryCountries({ storeId: Number(storeId) })
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    setCountries(data)
                }
                return data
            })
    }

    function addOrderAction(params: TAddOrderProps, service?: number) {
        setIsDisabled(true)
        MarketService.addOrder(params)
            .then(({ data }) => {
                if (data) {
                    const { payment_status: paymentStatus, payment_url: paymentUrl, redirectUrl } = data

                    setOrder((prevState) => ({ ...prevState, ...data }))
                    // metrics.reachGoal('ORDER_MAKE', { order_price: data.order_sum, currency: data.store.currency })

                    if (isDarkDoorMode) {
                        setIsOpenModalDarkDoorPayment(false)

                        // order make without online payment
                        if (service === MARKET_PAYMENT_BY_CASH || service === MARKET_PAYMENT_BY_TERMINAL) {
                            clearBasketAction()
                                .catch(() => {})
                                .finally(() => {
                                    window.location.assign(`https://crm.sessia.com/shop/orders/edit/${data.id}`)
                                })
                        } else if (paymentStatus === MARKET_ORDER_STATUS_WAITING_PAYMENT && !paymentUrl) {
                            clearBasketAction()
                                .catch(() => {})
                                .finally(() => {
                                    history.push(storeUrl)
                                })
                        } else if (redirectUrl) {
                            window.location.assign(redirectUrl)
                        }
                    }
                    // order make with online payment
                    if (paymentStatus === MARKET_ORDER_STATUS_WAITING_PAYMENT && paymentUrl) {
                        history.push(parseTpl(APP_URL.orderPayment, { ':id': storeId, ':orderId': data.id }, PARSE_URL_TPL_PARAMS))
                    } else if (paymentStatus === MARKET_ORDER_STATUS_READY_PAYMENT) {
                        clearBasketAction()
                            .catch(() => {})
                            .finally(() => {
                                history.push(parseTpl(APP_URL.orders, { ':id': storeId }, PARSE_URL_TPL_PARAMS))
                            })
                    } else if (paymentStatus === MARKET_ORDER_STATUS_3
                        || paymentStatus === MARKET_ORDER_STATUS_11
                        || paymentStatus === MARKET_ORDER_STATUS_15
                        || paymentStatus === MARKET_ORDER_STATUS_16
                        || paymentStatus === MARKET_ORDER_STATUS_200
                    ) {
                        orderPaymentError(t('buy_fail'))
                    } else if (paymentStatus === MARKET_ORDER_STATUS_PAID) {
                        orderPaymentSuccess()
                    }
                } else {
                    orderPaymentError()
                }
            })
            .catch((err) => {
                const errorText = getRequestError(err)

                showAlertNotify({
                    type: 'error',
                    message: errorText || t('update_error'),
                })
            })
            .finally(() => {
                setIsDisabled(false)
            })
    }

    function setOrderParamsAction(params: TAddOrderProps) {
        MarketService.addOrder(params)
            .then(({ data }) => {
                if (data?.can_purchase) {
                    setOrder(data)
                    setIsDisabled(false)
                } else {
                    showAlertNotify({ type: 'error', message: t('update_error') })
                }
            })
            .catch((err) => {
                const { response } = err || {}
                const { data } = response || {}

                if (data) {
                    const errors = Object.entries(data).reduce((acc, [key, value]) => {
                        return Array.isArray(value) ? { ...acc, [key]: value[0] } : { ...acc, [key]: value }
                    }, {})

                    Object.keys(errors).forEach((key) => {
                        setFormError(key, errors[key])
                    })
                }
            })
    }

    function fetchDeliveryUserCityAction(countryId: number, countryLang: string) {
        DirectoryService.fetchCities({
            id: countryId,
            lang: countryLang,
            limit: 1,
            offset: 0,
            q: user.city.ru_name,
        })
            .then(({ data }) => {
                const [userCity] = data || []

                if (userCity?.ru_name === user.city.ru_name) {
                    setCity(userCity)
                }
            })
            .catch(() => {})
    }

    function fetchDeliveryAddressesAction() {
        setIsLoadingDelivery(true)
        MarketService.fetchDeliveryAddresses()
            .then(({ data }) => {
                if (Array.isArray(data)) {
                    const addressesCurrentCity = data.filter((item) => city?.id && city.id === item.city.id)

                    setAddresses(addressesCurrentCity)

                    if (addressesCurrentCity.length === 1) {
                        setFormValue('delivery_address', addressesCurrentCity[0].id)
                    }
                }
            })
            .catch(() => {})
            .finally(() => {
                setIsLoadingDelivery(false)
            })
    }

    function fetchDeliveryCostAction() {
        if (country?.id && city?.id && formData?.products) {
            MarketService.fetchDeliveryCost({ country: country.id, city: city.id, products: formData.products })
                .then(({ data }) => {
                    if (Array.isArray(data)) {
                        setDelivery(data.filter((item) => !item.delivery_type.pickup))
                        setPickup(data.filter((item) => item.delivery_type.pickup))
                    }
                })
                .catch(() => {})
        }
    }

    function deleteDeliveryAddressesAction(addressId: number) {
        MarketService.deleteDeliveryAddresses({ id: addressId })
            .then((response) => {
                if (response) {
                    setAddresses((prevState) => {
                        return prevState.filter((item) => item.id !== addressId)
                    })

                    if (formData.delivery_address === addressId) {
                        setFormValue('delivery_address', undefined)
                    }
                }
            })
            .catch(() => {})
    }

    function clearBasketAction() {
        setIsLoading(true)
        return clearBasket.mutateAsync({ storeId }, {
            onSettled: () => {
                setIsLoading(false)
            },
        })
    }

    function orderPaymentSuccess() {
        showAlertNotify({
            type: 'success',
            action: EVENT_TYPE_ORDER_PAYMENT_SUCCESS,
            title: t('buy_congratulation'),
            message: t('buy_congratulation_text'),
            imageKey: 'baba_prazdnik_webp',
        })
    }

    function orderPaymentError(message?: string) {
        showAlertNotify({
            type: 'error',
            // action: EVENT_TYPE_ORDER_PAYMENT_ERROR,
            title: '',
            message: message || t('An error occurred while completing your order. We apologize!'),
        })
    }

    const handlerEventOrderPaymentSuccess = () => {
        clearBasketAction()
            .catch(() => {})
            .finally(() => {
                history.push(storeUrl)
            })
    }

    useEffect(() => {
        eventBus
            .on(EVENT_TYPE_ORDER_PAYMENT_SUCCESS, handlerEventOrderPaymentSuccess)

        if (isDelivery) {
            fetchDeliveryCountriesAction()
                .then((data) => {
                    const currentCountry = user?.country ? data.find((item) => item.id === user.country.id) : undefined

                    if (currentCountry) {
                        setCountry(currentCountry)
                        fetchDeliveryUserCityAction(currentCountry.id, currentCountry.lang)
                    }
                })
                .catch(() => {})
        }

        return () => {
            eventBus
                .off(EVENT_TYPE_ORDER_PAYMENT_SUCCESS, handlerEventOrderPaymentSuccess)
        }
    }, [])

    useEffect(() => {
        if (isDelivery && country) {
            // Not need for order request, only for get delivery city
            setFormValue('country', country.id)
        }
    }, [country, isDelivery])

    useEffect(() => {
        if (isDelivery && city) {
            // Not need for order request, only for get delivery addresses and cost
            setFormValue('city', city.id)
            fetchDeliveryAddressesAction()

            if (formData?.products) {
                fetchDeliveryCostAction()
            }
        }
    }, [city, isDelivery, formData?.products])

    useEffect(() => {
        const {
            products,
            pay_date,
            delivery_method,
            delivery_address,
            ...data
        } = formData

        if (products.length && pay_date) {
            const params = {
                storeId,
                random_id: getId(true),
                payment_service: MARKET_PAYMENT_VIRTUAL,
                products,
                pay_date,
                ...data,
            }
            const isDeliveryToAddress = receiveType === 'delivery'

            if (isDelivery && isDeliveryToAddress && delivery_method && delivery_address) {
                setOrderParamsAction({ ...params, delivery_method, delivery_address })
            } else if (isDelivery && !isDeliveryToAddress && delivery_method) {
                setOrderParamsAction({ ...params, delivery_method })
            } else if (!isDelivery) {
                setOrderParamsAction(params)
            } else {
                setIsDisabled(true)
            }
        }
    }, [formData])

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <OrderMakeForm
                    data={formData}
                    errors={formErrors}
                    isLoadingDelivery={isLoadingDelivery}
                    isDelivery={isDelivery}
                    isDisabled={isDisabled}
                    countries={countries}
                    country={country}
                    city={city}
                    receiveType={receiveType}
                    addresses={addresses}
                    delivery={delivery}
                    pickup={pickup}
                    currency={storeCurrency}
                    payDates={payDates}
                    order={order}
                    sumPaymentByBonus={sumPaymentByBonus}
                    sumPaymentByPayout={sumPaymentByPayout}
                    submitText={isDarkDoorMode ? t('Order payment') : t('Complete an order')}
                    submitService={isDarkDoorMode ? undefined : MARKET_PAYMENT_BY_BANK_TRANSFER}
                    onChangeCountry={handlerChangeCountry}
                    onChangeCity={handlerChangeCity}
                    onChangeReceiveType={handlerChangeReceiveType}
                    onSelectDeliveryAddress={handlerSelectDeliveryAddress}
                    onAddDeliveryAddress={handlerAddDeliveryAddress}
                    onEditDeliveryAddress={handlerEditDeliveryAddress}
                    onDeleteDeliveryAddress={handlerDeleteDeliveryAddress}
                    onSelectPickup={handlerSelectPickup}
                    onSelectDeliveryTariff={handlerSelectDeliveryTariff}
                    onChangePayDate={handlerChangePayDate}
                    onSelectBuyer={handlerSelectBuyer}
                    onChange={handlerChangeFormData}
                    onSubmit={handlerSubmit}
                />
            )}

            <Modal
                isOpen={isOpenModalAddAddress}
                size="medium"
                onClose={handlerCloseModalAddAddress}
            >
                <Modal.Header
                    titlePos="left"
                    title={t('New address')}
                    isCloseButton
                />
                <DeliveryAddressAction
                    actionType="addDeliveryAddresses"
                    data={{ countries, country, city }}
                    classesContent={styleModalBody.body}
                    classesControls={styleModalFooter.footer}
                    onSuccess={handlerAddedDeliveryAddress}
                />
            </Modal>

            <Modal
                isOpen={isOpenModalEditAddress}
                size="medium"
                onClose={handlerCloseModalEditAddress}
            >
                <Modal.Header
                    titlePos="left"
                    title={t('edit_address_title')}
                    isCloseButton
                />
                <DeliveryAddressAction
                    actionType="updateDeliveryAddresses"
                    data={{
                        countries,
                        country,
                        city,
                        ...addressEditData,
                    }}
                    classesContent={styleModalBody.body}
                    classesControls={styleModalFooter.footer}
                    onSuccess={handlerEditedDeliveryAddress}
                />
            </Modal>

            <Modal
                isOpen={isOpenModalDarkDoorPayment}
                size="medium"
                onClose={handlerCloseModalDarkDoorPayment}
            >
                <Modal.Header
                    titlePos="left"
                    title={t('payment_method_title')}
                    isCloseButton
                />
                <Modal.Body>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Button
                            size="size44"
                            text={t('market_order_purchase_button')}
                            onClick={() => handlerSubmit({ service: MARKET_PAYMENT_BY_BANK_TRANSFER })}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Button
                            size="size44"
                            text={t('Cash payment')}
                            onClick={() => handlerSubmit({ service: MARKET_PAYMENT_BY_CASH })}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Button
                            size="size44"
                            text={t('Payment through the terminal')}
                            onClick={() => handlerSubmit({ service: MARKET_PAYMENT_BY_TERMINAL })}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Button
                            size="size44"
                            text={t('Payment by bank account')}
                            onClick={() => handlerSubmit({ service: MARKET_PAYMENT_BY_BANK_SCORE })}
                        />
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_20)}>
                        <Button
                            size="size44"
                            text={t('Post-payment')}
                            onClick={() => handlerSubmit({ service: MARKET_PAYMENT_POST_PAYMENT })}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrderMakeAction
