import React, { CanvasHTMLAttributes, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ChartConfiguration, ChartDataset } from 'components/Chart/Chart'
import { Chart } from 'components'
import noDataText from 'components/Chart/plugins/noDataText'
import { dateTimeFormat } from 'utils/helpers'
import { vGray14 } from 'styles/modules/variables.module.css'

enum ChartItemField {
    nal,
    terminal,
    getaway,
    bank,
    cv,
    total,
}

type ReportsChartViewPropType = {
    data: any[]
    options?: CanvasHTMLAttributes<HTMLCanvasElement>
}

type TChartItemData = [
    nal: number,
    terminal: number,
    getaway: number,
    bank: number,
    cv: number,
    total: number,
]

const DATASET_PARAMS: Partial<ChartDataset> = {
    cubicInterpolationMode: 'monotone',
    borderWidth: 2,
    pointBorderWidth: 0,
    pointRadius: 0,
}

/**
 * Chart item values sorted by chart item field
 */
const getChartItemValues = (data: TChartItemData) => {
    return Object.entries(data).reduce<number[]>((acc, [key, value]) => {
        acc[ChartItemField[key]] = value
        return acc
    }, [])
}

const ReportsChartView: React.FC<ReportsChartViewPropType> = ({ data, options }) => {
    const { t, i18n } = useTranslation()

    const chartConfig: ChartConfiguration = useMemo(() => ({
        type: 'line',
        data: {
            labels: data.map((item) => dateTimeFormat(item.date, i18n.language, { day: '2-digit', month: 'short' })),
            datasets: data.reduce((acc, values) => {
                const dataItem = getChartItemValues(values)

                if (!acc.length) {
                    return initDatasets([{
                        label: t('reports_by_cash'),
                        backgroundColor: '#FF0000',
                        borderColor: '#FF0000',
                        data: [dataItem[ChartItemField.nal]],
                    }, {
                        label: t('reports_by_terminal'),
                        backgroundColor: '#0034FF',
                        borderColor: '#0034FF',
                        data: [dataItem[ChartItemField.terminal]],
                    }, {
                        label: t('reports_by_getaway'),
                        backgroundColor: '#26B1FF',
                        borderColor: '#26B1FF',
                        data: [dataItem[ChartItemField.getaway]],
                    }, {
                        label: t('reports_bank'),
                        backgroundColor: '#DD9325',
                        borderColor: '#DD9325',
                        data: [dataItem[ChartItemField.bank]],
                    }, {
                        label: t('reports_by_bonus'),
                        backgroundColor: '#9747FF',
                        borderColor: '#9747FF',
                        data: [dataItem[ChartItemField.cv]],
                    }, {
                        label: t('reports_just_money'),
                        backgroundColor: '#58D60C',
                        borderColor: '#58D60C',
                        data: [dataItem[ChartItemField.total]],
                    }])
                }

                // @ts-ignore FIXME type by backend
                return acc.map((item, index) => ({ ...item, data: [...item.data, dataItem[index]] }))
            }, []),
        },
        options: {
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    align: 'start',
                    // https://www.chartjs.org/docs/3.9.1/configuration/legend.html#legend-label-configuration
                },
                noDataText: {
                    enabled: !data.length,
                    font: '16px',
                    fontColor: vGray14,
                    text: t('no_data'),
                },
            },
        },
        plugins: [noDataText],
    }), [data])

    function initDatasets(values: ChartDataset[]) {
        return values.map((item) => ({
            ...DATASET_PARAMS,
            ...item,
        }))
    }

    return (
        <Chart
            config={chartConfig}
            options={options}
        />
    )
}

export default ReportsChartView
