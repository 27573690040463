import React from 'react'
import { Form } from 'react-final-form'

import { FormDataType } from 'forms/OrderDeliveryDetailsForm/OrderDeliveryDetailsForm'
import { OrderDeliveryDetailsForm } from 'forms'

const OrderDeliveryDetailsAction: React.FC = () => {
    const handlerSubmit = (params: FormDataType) => {
        //
    }

    return (
        <Form
            onSubmit={handlerSubmit}
            render={({ handleSubmit, submitting }) => (
                <OrderDeliveryDetailsForm
                    isSubmitting={submitting}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default OrderDeliveryDetailsAction
