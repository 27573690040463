import React, { useState, useEffect } from 'react'
import cn from 'classnames'

import { IMAGE_RESOURCE_PATH } from '../../config/api'
import { Spinner } from '../index'
import { useOnLoadImage } from '../../hooks'
import styleCommon from '../../styles/modules/common.module.css'

type ImgResourcePropType = {
    resourceKey: string
    classes?: string
    isShowLoader?: boolean
    width?: number
    height?: number
    onClick?: () => void
}

/**
 * Component get remote image src by resource key
 * @param {string} resourceKey
 * @param {string} [classes]
 * @param {boolean} [isShowLoader]
 * @param {number} [width]
 * @param {number} [height]
 * @param {function} [onClick]
 * @return {HTMLImageElement}
 */
const ImgResource: React.FC<ImgResourcePropType> = ({
    resourceKey,
    classes,
    isShowLoader = true,
    width,
    height,
    onClick = () => {},
}) => {
    const [url, setUrl] = useState('')
    const [loadedImg, error] = useOnLoadImage(url)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (resourceKey) {
            setUrl(`${IMAGE_RESOURCE_PATH}/${resourceKey}`)
        }
    }, [resourceKey])

    useEffect(() => {
        if (loadedImg || error) {
            setIsLoading(false)
        }
    }, [loadedImg, error])

    return (
        <>
            {isShowLoader && isLoading && <Spinner size="medium" />}
            {/* eslint-disable-next-line */}
            <img
                className={cn(styleCommon.image, { [styleCommon.image_loading]: isLoading }, classes)}
                src={loadedImg ? loadedImg.src : ''}
                width={width}
                height={height}
                alt=""
                onClick={onClick}
            />
        </>
    )
}

export default ImgResource
