import React from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { InputPropType } from 'components/Input/Input'
import { Input } from 'components'
import style from './CustomSelectSearch.module.css'

export type CustomSelectSearchPropType = Omit<InputPropType, 'onChange'> & {
    classesWrap?: string
    onChange: (value: string) => void
}

const CustomSelectSearch: React.FC<CustomSelectSearchPropType> = ({
    classesWrap,
    placeholder,
    onChange,
    ...props
}) => {
    const { t } = useTranslation()

    const handlerSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
    }

    return (
        <div className={cn(style.search, classesWrap)}>
            <Input
                {...props}
                placeholder={placeholder || t('search')}
                onChange={handlerSearch}
            />
        </div>
    )
}

export default CustomSelectSearch
