import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IGoodsData } from '../../../../interfaces'
import { Spinner } from '../../../../components'
import { splitStr } from '../../../../utils/helpers'
import style from './CatalogGoodsIframe.module.css'

type StoreCatalogGoodsIframePropType = {
    item?: IGoodsData
}

const CatalogGoodsIframe: React.FC<StoreCatalogGoodsIframePropType> = ({ item }) => {
    const { t } = useTranslation()

    const errorText = useMemo(() => splitStr(t('update_error')), [])

    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(!item?.landing_link)

    const handlerLoad = () => {
        setIsLoading(false)
    }

    const handlerError = () => {
        setIsLoading(false)
        setIsError(true)
    }

    return (
        <div className={style.wrap}>
            {isLoading && !isError && (
                <Spinner size="large" position="center" />
            )}

            {!isError ? (
                <iframe
                    className={cn(style.iframe, isLoading ? style.iframe_loading : '')}
                    title="goods"
                    src={item?.landing_link}
                    onLoad={handlerLoad}
                    onError={handlerError}
                />
            ) : (
                <div className={style.errorText}>
                    {errorText.map((itemError) => (
                        <div key={itemError.id}>
                            {itemError.text}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default CatalogGoodsIframe
