import { AxiosError } from 'axios'
import {
    UseInfiniteQueryOptions,
    UseQueryOptions,
    useQuery,
    useMutation,
} from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import {
    TNetworkServiceError,
    TUnsubscribeCompanyProps,
    TUnsubscribeCompanyInfoResponse,
    TUnsubscribeCompanyResponse,
} from 'services/NetworkService'
import { QueryCacheKeys } from 'enums'
import { NetworkService } from 'services'
import * as userSelectors from 'containers/User/user-selectors'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TUnsubscribeCompanyProps
export type TFetchFnResponse = TUnsubscribeCompanyInfoResponse
export type TFetchFnError = AxiosError<TNetworkServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>
export type TInfiniteQueryFnOpts = UseInfiniteQueryOptions<
    TFetchFnResponse,
    TError,
    TFetchFnResponse,
    TFetchFnResponse,
    TQueryKey
>

export type TUnsubscribeStoreMutationFnError = [string, AxiosError<TNetworkServiceError, TUnsubscribeCompanyProps>]

const key = QueryCacheKeys.unsubscribeCompany

/**
 * Хук API получить получить отписку от комании
 */
export default function useFetchUnsubscribeCompany(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return NetworkService.fetchUnsubscribeCompany(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useMutationUnsubscribeCompany() {
    const { t } = useTranslation()

    const unsubscribeCompany = useMutation<
        TUnsubscribeCompanyResponse,
        TUnsubscribeStoreMutationFnError,
        TUnsubscribeCompanyProps,
        unknown
    >((props) => {
        return NetworkService.unsubscribeCompany(props)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    })

    return {
        unsubscribeCompany,
    }
}
