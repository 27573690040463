import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
    InvalidateOptions,
    InvalidateQueryFilters,
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from '@tanstack/react-query'

import {
    TChatMicroServiceError,
    TConversationCountsNewProps,
    TConversationCountsNewResponse,
} from 'services/ChatMicroService'
import { QueryCacheKeys } from 'enums'
import * as userSelectors from 'containers/User/user-selectors'
import { ChatMicroService } from 'services'
import { getRequestError } from 'utils/helpers'

export type TFetchFnParams = TConversationCountsNewProps
export type TFetchFnResponse = TConversationCountsNewResponse
export type TFetchFnError = AxiosError<TChatMicroServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TFetchFnResponse, TError, TFetchFnResponse, TQueryKey>

const key = QueryCacheKeys.conversationCountsNew

/**
 * Хук API общее количество чатов, в которых есть непрочитанные сообщения
 */
export default function useFetchConversationCountsNew(params: TFetchFnParams = {}, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useSelector(userSelectors.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return ChatMicroService.fetchConversationCountsNew(params)
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}

export function useInvalidateConversationCountsNew(
    params: TFetchFnParams = {}, {
        exact = true,
        ...filters
    }: InvalidateQueryFilters = {},
    options: InvalidateOptions = {},
) {
    const queryClient = useQueryClient()
    const user = useSelector(userSelectors.user)

    const invalidate = () => {
        return queryClient.invalidateQueries([`${key}-${user.id}`, params], { exact, ...filters }, options)
    }

    return {
        invalidate,
    }
}
