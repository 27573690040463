import React from 'react'
import cn from 'classnames'

import { Button } from 'components'
import style from './PaginationItem.module.css'

type PaginationItemPropType = {
    isActive?: boolean
    disabled?: boolean
    onClick: () => void
}

const PaginationItem: React.FC<PaginationItemPropType> = ({
    children,
    isActive,
    disabled,
    onClick,
}) => {
    return (
        <Button
            classes={cn(style.item, { [style.item_active]: isActive })}
            styleType="custom"
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </Button>
    )
}

export default PaginationItem
