import { API_URL } from 'config/api'
import requestClient from 'utils/requestClient'

/**
 * API agreement
 */
class AgreementService {
    static getAgreement() {
        return requestClient(API_URL.agreement)
    }

    static setAgreementSigned(id: number) {
        return requestClient(`${API_URL.agreementSign}/${id}/sign`, { method: 'post' })
    }
}

export default AgreementService
