import React, {
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IUser, IPhoneData, IConfig } from 'interfaces'
import { PhoneDataFields } from 'enums'
import {
    ANDROID_APP_STORE,
    ANDROID_APP_BUSINESS_STORE,
    IOS_APP_STORE,
    IOS_APP_BUSINESS_STORE,
} from 'config/app'
import { useAppMode } from 'containers/App/hooks'
import { fetchUser } from 'containers/User/user-actions'
import { SvgResource, ErrorMsg } from 'components'
import { AuthService, TranslationService } from 'services'
import { useThunkDispatch } from 'hooks'
import { AuthPhoneAction } from 'form-actions'
import {
    showAlertNotify,
    getRequestError,
    splitStr,
} from 'utils/helpers'
import { useAppSelector } from 'store'
import styleForm from 'styles/modules/form.module.css'
import style from './Auth.module.css'

const Auth: React.FC = () => {
    const isMounted = useRef(false)
    const { t, i18n } = useTranslation()
    const thunkDispatch = useThunkDispatch()
    const history = useHistory()
    const location = useLocation()
    const { appMode, isUserMode, isBusinessMode } = useAppMode()

    const appConfig = useAppSelector((state) => state.app.config)

    const [authError, setAuthError] = useState('')
    const [isDisabledAuthPhoneSubmit, setIsDisabledAuthPhoneSubmit] = useState<boolean>()

    const textPhoneSendInfo = useMemo(() => {
        return splitStr(t('phone_send_info'))
            .map((item) => item.text)
            .join(' ')
    }, [i18n.language])

    const handlerAuthPhoneSuccess = () => {
        startUserAuthorize()
    }

    const handlerAuthSmsCodeSuccess = () => {
        startUserAuthorize()
    }

    const handlerAuthError = (errorMessage: string) => {
        setAuthError(errorMessage)
    }

    const handlerEndSmsTimer = () => {
        setIsDisabledAuthPhoneSubmit(false)
    }

    const handlerCallAuth = (data: IPhoneData) => {
        const { [PhoneDataFields.countryCode]: code, [PhoneDataFields.phone]: phone } = data
        const matchNumbers = `${code}${phone}`.match(/\d+/g)

        if (matchNumbers) {
            setIsDisabledAuthPhoneSubmit(true)
            getAuthByCallAction(matchNumbers.join(''))
        }
    }

    function startUserAuthorize() {
        fetchUserAction()
            .then((userData) => {
                authorizeAction(userData)
            })
            .catch(() => {})
    }

    function authorizeAction(userData: IUser) {
        authorizeModeAction(userData, appConfig)
            .then((route) => {
                if (route) {
                    history.push(route)
                }
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err.error || t('update_error') })
            })
            .finally(() => {
                if (isMounted.current) {
                    setIsDisabledAuthPhoneSubmit(false)
                }
            })
    }

    function fetchUserAction() {
        return thunkDispatch(fetchUser())
            .then((data) => {
                if (data.language.lang_short !== i18n.language) {
                    TranslationService.changeAppLanguage(data.language.lang_short)
                }
                return data
            })
            .catch((err) => {
                const { response, code } = err || {}
                const { error, error_description: errorDesc } = response?.data || {}
                let errorText = t('update_error')

                if (error === 'invalid_grant') {
                    errorText = errorDesc
                } else if (code === 'ERR_NETWORK') {
                    errorText = t('no_internet')
                }

                setIsDisabledAuthPhoneSubmit(false)
                showAlertNotify({ type: 'error', message: errorText })
                return Promise.reject(err)
            })
    }

    function getAuthByCallAction(phone: string) {
        AuthService.getCallAuth(phone)
            .then(() => {
                //
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: getRequestError(err) || t('update_error') })
            })
    }

    function authorizeModeAction(userData: IUser, configData: IConfig) {
        if (isUserMode) {
            return AuthService.authorize(userData, location)
        }
        if (isBusinessMode) {
            return AuthService.authorizeBusiness(userData, configData)
        }

        return Promise.reject()
    }

    useEffect(() => {
        isMounted.current = true

        return () => {
            isMounted.current = false
        }
    }, [])

    return (
        <div className={cn(style.auth, style[`auth_${appMode}`])}>
            <div className={cn(style.header, style[`header_${appMode}`])}>
                <div className={cn(style.logoWrap, style[`logoWrap_${appMode}`])}>
                    <SvgResource
                        classes={style.logo}
                        resourceKey="auth_kickback_logo"
                        width={200}
                        height={100}
                    />
                </div>
                <div className={cn(style.headerDesc, style[`headerDesc_${appMode}`])}>
                    {isUserMode && textPhoneSendInfo}
                    {isBusinessMode && t('Enter your phone')}
                </div>
            </div>
            <div className={cn(style.body, style[`body_${appMode}`])}>
                <AuthPhoneAction
                    classes={style.form}
                    isDisabledSubmit={isDisabledAuthPhoneSubmit}
                    onError={handlerAuthError}
                    onEndSmsTimer={handlerEndSmsTimer}
                    onCallAuth={handlerCallAuth}
                    onSuccessPhone={handlerAuthPhoneSuccess}
                    onSuccessCode={handlerAuthSmsCodeSuccess}
                />
            </div>
            {authError && (
                <ErrorMsg
                    classes={cn(style.error, styleForm.dangerText)}
                    error={authError}
                />
            )}
            <div className={cn(style.apps, style[`apps_${appMode}`])}>
                <a
                    className={style.appLink}
                    rel="noreferrer noopener"
                    target="_blank"
                    aria-label="googleplay"
                    href={isBusinessMode ? ANDROID_APP_BUSINESS_STORE : ANDROID_APP_STORE}
                >
                    <SvgResource resourceKey="google_play_button" />
                </a>
                <a
                    className={style.appLink}
                    rel="noreferrer noopener"
                    target="_blank"
                    aria-label="appstore"
                    href={isBusinessMode ? IOS_APP_BUSINESS_STORE : IOS_APP_STORE}
                >
                    <SvgResource resourceKey="appstore_button" />
                </a>
            </div>
        </div>
    )
}

export default Auth
