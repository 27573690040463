import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Search, Button, Icon } from 'components'
import style from './ClientsSearch.module.css'

type SearchBarPropType = {
    classes?: string
    searchText?: string
    isSetFilters?: boolean
    onClickFilters: () => void
    onReset: () => void
    onSearch: (value: string) => void
}

const ClientsSearch: React.FC<SearchBarPropType> = ({
    classes,
    searchText,
    isSetFilters,
    onClickFilters,
    onReset,
    onSearch,
}) => {
    const { t } = useTranslation()

    const [text, setText] = useState(searchText ?? '')

    const handlerClickSearch = () => {
        onSearch(text)
    }

    const handlerSearch = (value: string) => {
        handleSearchText(value)
    }

    const handlerSubmit = (value: string) => {
        handleSearchText(value)

        if (value) {
            onSearch(value)
        }
    }

    function handleSearchText(value: string) {
        setText(value)

        if (!value) {
            onReset()
        }
    }

    return (
        <div className={cn(style.searchBar, classes)}>
            <Search
                focus
                classesInput={style.searchInput}
                placeholder={t('search_clients_hint')}
                defaultValue={searchText}
                onSearch={handlerSearch}
                onSubmit={handlerSubmit}
            />
            <div className={style.actions}>
                <Button
                    classes={style.action}
                    styleType="transparent"
                    title={t('search')}
                    onClick={handlerClickSearch}
                >
                    <Icon
                        isImgTag={false}
                        classes={style.iconSearch}
                        resourceKey="ic_search_white_svg"
                        width={32}
                        height={32}
                        notify={!!searchText}
                    />
                </Button>
                <Button
                    classes={style.action}
                    styleType="transparent"
                    title={t('staff_additional_settings')}
                    onClick={onClickFilters}
                >
                    <Icon
                        isImgTag={false}
                        classes={style.iconFilter}
                        resourceKey="ic_filters2_svg"
                        width={32}
                        height={32}
                        notify={isSetFilters}
                    />
                </Button>
            </div>
        </div>
    )
}

export default ClientsSearch
