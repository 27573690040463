import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory, generatePath } from 'react-router-dom'

import {
    ICountry,
    ICity,
    ICompanyUser,
    IPersonalStore,
} from 'interfaces'
import { FormDataType } from 'forms/CompanyForm/CompanyForm'
import { TValidationFieldRule } from 'utils/validators'
import { QueryCacheKeys } from 'enums'
import { APP_URL } from 'config/app'
import { addCompany, updateCompany, fetchCompanyUser } from 'containers/Company/company-actions'
import { fetchUser } from 'containers/User/user-actions'
import * as appSelectors from 'containers/App/app-selectors'
import * as userSelectors from 'containers/User/user-selectors'
import { Modal, Button, Cropper } from 'components'
import { CompanyForm } from 'forms'
import { useForm, useThunkDispatch } from 'hooks'
import { AppService, MarketService } from 'services'
import { showAlertNotify, getRequestError } from 'utils/helpers'
import queryClient from 'queryClient'

export type CompanyActionPropType = {
    company?: ICompanyUser
}

const initFormData: FormDataType = {
    name: '',
    juridical_name: '',
    country_id: 0,
    city: 0,
    phone_number: '',
    email: '',
    site: '',
    logo: '',
}

const CompanyAction: React.FC<CompanyActionPropType> = ({ company }) => {
    const { t } = useTranslation()
    const thunkDispatch = useThunkDispatch()
    const history = useHistory()

    const FORM_VALIDATION_RULES: TValidationFieldRule[] = [{
        field: 'name',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'juridical_name',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'city',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'phone_number',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }, {
        field: 'email',
        rules: [{ rule: 'required', error: t('error_field_is_empty') }],
    }]

    const user = useSelector(userSelectors.user)
    const countries = useSelector(appSelectors.countries)

    const [selectedCountry, setSelectedCountry] = useState<ICountry>()
    const [selectedCity, setSelectedCity] = useState<ICity>()
    const [imageAvatar, setImageAvatar] = useState<string>() // base64
    const [imageAvatarCropped, setImageAvatarCropped] = useState<string>() // base64

    const [isShowModalAvatar, setIsShowModalAvatar] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const {
        formData,
        formErrors,
        setFormValue,
        validateForm,
        isValidForm,
    } = useForm<FormDataType>(initFormData, FORM_VALIDATION_RULES)

    const isDisabled = useMemo(() => !isValidForm(), [formData])

    const handlerSubmit = () => {
        if (validateForm()) {
            if (company?.id) {
                updateCompanyAction(company.id)
            } else {
                processAddCompany()
            }
        }
    }

    const handlerChangeCountry = (data: ICountry) => {
        setFormValue('country_id', data.id)
        setSelectedCountry(data)
        resetCity()
        resetPhone()
    }

    const handlerChangeCity = (data: ICity) => {
        setFormValue('city', data.id)
        setSelectedCity(data)
    }

    const handlerChangePhone = (data: string) => {
        setFormValue('phone_number', data)
    }

    const handlerChangePhoto = (file: File) => {
        if (file) {
            uploadImageAction(file)
        }
    }

    const handlerChangeFormData = (name: string, value: string) => {
        setFormValue(name, value)
    }

    const handlerSetAvatar = () => {
        setFormValue('logo', imageAvatarCropped)
        setIsShowModalAvatar(false)
    }

    const handlerCropAvatar = (data: string) => {
        // TODO debounce
        if (data) {
            setImageAvatarCropped(data)
        }
    }

    function setFormData(companyData: ICompanyUser) {
        const { city, phone_list } = companyData

        if (city) {
            const { id, ru_name } = city || {}
            setSelectedCity({ id, ru_name })
        }
        if (phone_list) {
            const [phoneData] = phone_list || []

            if (phoneData) {
                const { country_id: countryId } = phoneData || {}
                const country = countries.find((item) => item.id === countryId)

                if (country) {
                    setSelectedCountry(country)
                }
            }
        }

        setFormValue('name', companyData?.name ?? '')
        setFormValue('juridical_name', companyData?.juridical_name ?? '')
        setFormValue('country_id', companyData?.city.country.id ?? 0)
        setFormValue('city', companyData?.city.id ?? 0)
        setFormValue('phone_number', companyData?.phone_list.length ? companyData.phone_list[0].phone_number : '')
        setFormValue('email', companyData?.email_list.length ? companyData.email_list[0] : '')
        setFormValue('site', companyData?.site ?? '')
        setFormValue('logo', companyData?.logo ?? '')
    }

    function resetCity() {
        setFormValue('city', 0)
        setSelectedCity(undefined)
    }

    function resetPhone() {
        setFormValue('phone_number', '')
    }

    function getStoreSettingsUrl(storeId: number) {
        return generatePath(APP_URL.storeSettings, { id: storeId })
    }

    function processAddCompany() {
        setIsSubmitting(true)
        addCompanyAction()
            .then(() => {
                return thunkDispatch(fetchUser())
            })
            .then(() => {
                return thunkDispatch(fetchCompanyUser())
            })
            .then(() => {
                return queryClient.fetchQuery<IPersonalStore[]>(
                    [`${QueryCacheKeys.personalStores}-${user.id}`],
                    () => {
                        return MarketService.fetchPersonalStores()
                            .then(({ data: personalStores }) => {
                                return personalStores
                            })
                            .catch((err) => {
                                return Promise.reject(err)
                            })
                    },
                )
            })
            .then((data): Promise<string | void> => {
                if (Array.isArray(data)) {
                    if (data.length) {
                        const storeNeedSetting = data.find((item) => {
                            return MarketService.isNeedModeratePersonalStoreLoyaltySettings(item)
                        })

                        if (storeNeedSetting) {
                            return Promise.resolve(getStoreSettingsUrl(storeNeedSetting.id))
                        }

                        return Promise.resolve()
                    }

                    return MarketService.addPersonalStore()
                        .then(({ data: personalStore }) => {
                            /** add new personal store to query cache */
                            queryClient.setQueryData<IPersonalStore[]>(
                                [`${QueryCacheKeys.personalStores}-${user.id}`],
                                (queryData) => {
                                    return queryData ? [...queryData, personalStore] : [personalStore]
                                },
                            )
                            return getStoreSettingsUrl(personalStore.id)
                        })
                        .catch((err) => {
                            return Promise.reject(err)
                        })
                }

                return Promise.reject()
            })
            .then((route) => {
                setIsSubmitting(false)

                if (route) {
                    history.push(route)
                }
            })
            .catch((err) => {
                const errorText = getRequestError(err)

                setIsSubmitting(false)
                showAlertNotify({ type: 'error', message: errorText || t('update_error') })
            })
    }

    function addCompanyAction() {
        const {
            country_id,
            phone_number,
            email,
            ...params
        } = formData

        return thunkDispatch(addCompany({
            phone_list: [{ country_id, phone_number }],
            email_list: [email],
            ...params,
        }))
    }

    function updateCompanyAction(companyId: number) {
        const {
            country_id,
            phone_number,
            email,
            ...params
        } = formData

        setIsSubmitting(true)
        thunkDispatch(updateCompany({
            id: companyId,
            phone_list: [{ country_id, phone_number }],
            email_list: [email],
            ...params,
        }))
            .then(() => {
                //
            })
            .catch((err) => {
                const errorText = getRequestError(err)
                showAlertNotify({ type: 'error', message: errorText || t('update_error') })
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    function uploadImageAction(file: File) {
        AppService.uploadImageToBase64(file)
            .then((res) => {
                setImageAvatar(res)
                setIsShowModalAvatar(true)
            })
            .catch((err) => {
                showAlertNotify({ type: 'error', message: err?.message })
            })
    }

    useEffect(() => {
        if (company) {
            setFormData(company)
        } else if (user) {
            setFormValue('country_id', user.user_phones.length ? user.user_phones[0].country_id : user.country.id)
            setFormValue('phone_number', user.user_phones.length ? user.user_phones[0].phone_number : '')
            setFormValue('city', user.city ? user.city.id : 0)
            setSelectedCountry(user.country)
            setSelectedCity(user.city)
        } else if (countries.length) {
            setFormValue('country_id', countries[0].id)
            setSelectedCountry(countries[0])
        }
    }, [company])

    return (
        <>
            <CompanyForm
                data={formData}
                errors={formErrors}
                country={selectedCountry}
                city={selectedCity}
                countries={countries}
                isSubmitting={isSubmitting}
                isDisabled={isDisabled}
                onChangeCountry={handlerChangeCountry}
                onChangeCity={handlerChangeCity}
                onChangePhone={handlerChangePhone}
                onChangePhoto={handlerChangePhoto}
                onChange={handlerChangeFormData}
                onSubmit={handlerSubmit}
            />

            <Modal
                isOpen={isShowModalAvatar}
                size="medium"
                onClose={() => setIsShowModalAvatar(false)}
            >
                <Modal.Header title={t('edit_photo')} />
                <Modal.Body>
                    <Cropper
                        className="cropperRound"
                        source={imageAvatar}
                        onCrop={handlerCropAvatar}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        text={t('install')}
                        size="size40"
                        onClick={handlerSetAvatar}
                    />
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CompanyAction
