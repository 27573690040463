import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { parseTpl } from 'utils/helpers'
import style from './FooterLK.module.css'

type FooterLKPropType = {
    classes?: string
}

const FooterLK: React.FC<FooterLKPropType> = ({ children, classes }) => {
    const { t, i18n } = useTranslation()

    const copyright = useMemo(() => {
        return parseTpl(t('copyright_text'), {
            '%s': new Date().getFullYear(),
        }, {
            prefix: '',
            suffix: '',
        })
    }, [i18n.language])

    return (
        <div className={cn(style.footer, classes)}>
            {children}

            <div className={style.copyright}>
                {copyright}
            </div>
        </div>
    )
}

export default FooterLK
