import React, { useReducer, useState, useMemo } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IMarket, ICurrency } from 'interfaces'
import { TAddPersonalStoreProps } from 'services/MarketService'
import { Transition } from 'layout'
import {
    Button,
    CustomSelect,
    Input,
    Radio,
    Textarea,
    Switch,
    SvgResource,
    Modal,
} from 'components'
import { useAlertDialog, useUploadFiles } from 'hooks'
import { plural } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import { ImagePreview } from './components'
import style from './StoreForm.module.css'

export enum FieldName {
    name = 'name',
    subTitle = 'subTitle',
    description = 'description',
    address = 'address',
    category = 'category',
    currency = 'currency',
    logo = 'logo',
    icon = 'icon',
    isClosed = 'isClosed',
    notifyShopAdmins = 'notifyShopAdmins',
    notifyShopCashiers = 'notifyShopCashiers',
    notifySomebodyElse = 'notifySomebodyElse',
    rewardAutoApproveDays = 'rewardAutoApproveDays'
}

export type FormDataType = {
    id?: number
    companyId?: number
    [FieldName.name]: string
    [FieldName.category]: number
    [FieldName.currency]: number
    [FieldName.notifyShopAdmins]: boolean
    [FieldName.notifyShopCashiers]: boolean
    [FieldName.notifySomebodyElse]: string
    [FieldName.isClosed]?: boolean
    [FieldName.subTitle]?: string
    [FieldName.description]?: string
    [FieldName.address]?: string
    [FieldName.logo]?: string
    [FieldName.icon]?: string
    [FieldName.rewardAutoApproveDays]?: TAddPersonalStoreProps[FieldName.rewardAutoApproveDays]
}

type StoreFormPropType = {
    isSubmitting: boolean
    categories?: IMarket[]
    currencies?: ICurrency[]
    onErrorFile?: (e: unknown) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const formDataInitial: FormDataType = {
    id: undefined,
    companyId: undefined,
    [FieldName.name]: '',
    [FieldName.category]: 0,
    [FieldName.currency]: 0,
    [FieldName.notifyShopAdmins]: false,
    [FieldName.notifyShopCashiers]: false,
    [FieldName.notifySomebodyElse]: '',
    [FieldName.isClosed]: false,
}

enum DaysType {
    preset = 'preset',
    specify = 'specify'
}

enum DaysFieldName {
    rightAway = 'rightAway',
    manually = 'manually',
    specify = 'specify'
}

type TDaysState = {
    [DaysType.preset]: number | undefined // DAYS_PRESET
    [DaysType.specify]: number | '' // '' - fix empty value controlled input
}

type TReducerAction = { type: keyof typeof DaysType, payload: number | undefined | '' }

const ACCEPT_IMAGE = 'image/jpeg,image/png,image/gif'

const DAYS_PRESET = {
    [DaysFieldName.rightAway]: 0,
    [DaysFieldName.manually]: -1,
}

const daysReducer = (state: TDaysState, action: TReducerAction): TDaysState => {
    switch (action.type) {
        case 'preset':
            return { preset: action.payload !== '' ? action.payload : undefined, specify: '' }
        case 'specify':
            return { preset: undefined, specify: action.payload !== undefined ? action.payload : '' }
        default:
            return state
    }
}

const daysInitial = (value: FormDataType[FieldName.rewardAutoApproveDays]): TDaysState => {
    const isPresetValue = value === DAYS_PRESET[DaysFieldName.rightAway]
        || value === DAYS_PRESET[DaysFieldName.manually]

    return {
        [DaysType.preset]: isPresetValue ? value : DAYS_PRESET[DaysFieldName.rightAway],
        [DaysType.specify]: !isPresetValue && value ? value : '',
    }
}

const StoreForm: React.FC<StoreFormPropType> = ({
    isSubmitting,
    categories,
    currencies,
    onErrorFile,
    onSubmit,
}) => {
    const { t } = useTranslation()
    const { showAlertDialog } = useAlertDialog()
    const { upload: uploadFiles } = useUploadFiles()

    const { change, submit } = useForm()
    const { values, hasValidationErrors } = useFormState<FormDataType>()

    const [daysState, dispatch] = useReducer(daysReducer, daysInitial(values[FieldName.rewardAutoApproveDays]))

    const [isNotify, setIsNotify] = useState(!!(values[FieldName.notifyShopAdmins]
        || values[FieldName.notifyShopCashiers]
        || values[FieldName.notifySomebodyElse]))

    const [isOpenModalDays, setIsOpenModalDays] = useState(false)

    const categoriesData = useMemo(() => {
        return categories ? categories.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {}) : {}
    }, [categories])

    const currenciesData = useMemo(() => {
        return currencies ? currencies.reduce((acc, item) => ({ ...acc, [item.id]: item.iso }), {}) : {}
    }, [currencies])

    const daysInputValue = useMemo(() => {
        const value = values[FieldName.rewardAutoApproveDays]

        if (value === DAYS_PRESET[DaysFieldName.rightAway]) {
            return t('right_away')
        }
        if (value === DAYS_PRESET[DaysFieldName.manually]) {
            return t('in_the_manual_mode')
        }
        if (value) { // specify
            const pluralDays = plural(value, [t('days_1x'), t('days_2x'), t('days_5x')])
            return `${t('in')} ${value} ${pluralDays}`
        }

        return ''
    }, [values[FieldName.rewardAutoApproveDays]])

    const handlerAddFiles = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
        const { name, files } = currentTarget

        if (files?.length) {
            uploadFiles(files, { isUpload: false })
                .then(([data]) => {
                    const { imageBase64 } = data

                    if (imageBase64) {
                        change(name, imageBase64)
                    }
                })
                .catch((e) => {
                    if (onErrorFile) {
                        onErrorFile(e)
                    }
                })
        }
    }

    const handlerClickNotify = () => {
        setIsNotify((prevState) => !prevState)
    }

    const handlerChangeDaysSpecify = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value.replace(/[^\d]/g, '')
        dispatch({ type: 'specify', payload: value.length ? Number(value) : '' })
    }

    const handlerDeleteStore = () => {
        showAlertDialog({
            isSetPerformText: false,
            isSetQuestionText: false,
            message: t('dialog_warning_close_store_message'),
            payload: { id: values.id },
            onConfirm: () => {
                change(FieldName.isClosed, true)
                submit()
            },
        })
    }

    const handlerCloseModalDays = () => {
        setIsOpenModalDays(false)
    }

    return (
        <>
            <form className={cn({ [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                {values.id && (
                    <div className={cn(styleForm.row, styleForm.row_20, styleForm.row_vAlignCenter)}>
                        <Field
                            name={FieldName.isClosed}
                            type="checkbox"
                            render={({ input }) => (
                                <Button
                                    classes={style.actionDelete}
                                    styleType="transparent"
                                    onClick={handlerDeleteStore}
                                >
                                    <div className={style.actionDeleteText}>
                                        {t('delete_store')}
                                    </div>
                                    <SvgResource
                                        isImgTag={false}
                                        classes={style.iconDelete}
                                        resourceKey="ic_action_delete2_svg"
                                        width={19}
                                        height={20}
                                    />
                                </Button>
                            )}
                        />
                    </div>
                )}
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.name}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    {...input}
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={t('market_name')}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.subTitle}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    {...input}
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={t('market_sub_title')}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        <FormDataType[FieldName.category]>
                        name={FieldName.category}
                        render={({ input, meta }) => (
                            <>
                                <CustomSelect.Select>
                                    <CustomSelect.Field classes={style.selectField} disabled={!categories}>
                                        <div className={cn(
                                            style.selectField_placeholder,
                                            { [style.selectField_placeholderTop]: !!input.value },
                                        )}
                                        >
                                            {t('business_type')}
                                        </div>
                                        <div className={style.selectField_value}>
                                            {!!input.value && categoriesData[input.value]}
                                        </div>
                                    </CustomSelect.Field>
                                    <CustomSelect.Options>
                                        {categories?.map((item) => (
                                            <CustomSelect.Option
                                                id={item.id}
                                                isActive={item.id === input.value}
                                                key={item.id}
                                                onClick={input.onChange}
                                            >
                                                {item.name}
                                            </CustomSelect.Option>
                                        ))}
                                    </CustomSelect.Options>
                                </CustomSelect.Select>
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        <FormDataType[FieldName.currency]>
                        name={FieldName.currency}
                        render={({ input, meta }) => (
                            <>
                                <CustomSelect.Select>
                                    <CustomSelect.Field classes={style.selectField} disabled={!categories}>
                                        <div className={cn(
                                            style.selectField_placeholder,
                                            { [style.selectField_placeholderTop]: !!input.value },
                                        )}
                                        >
                                            {t('currency')}
                                        </div>
                                        <div className={style.selectField_value}>
                                            {!!input.value && currenciesData[input.value]}
                                        </div>
                                    </CustomSelect.Field>
                                    <CustomSelect.Options>
                                        {currencies?.map((item) => (
                                            <CustomSelect.Option
                                                id={item.id}
                                                isActive={item.id === input.value}
                                                key={item.id}
                                                onClick={input.onChange}
                                            >
                                                {item.iso}
                                            </CustomSelect.Option>
                                        ))}
                                    </CustomSelect.Options>
                                </CustomSelect.Select>
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.address}
                        render={({ input, meta }) => (
                            <>
                                <Input
                                    {...input}
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={t('address')}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.description}
                        render={({ input, meta }) => (
                            <>
                                <Textarea
                                    {...input}
                                    isAutoHeight
                                    classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                    styleType="dynamic"
                                    dynamicPlaceholder={t('market_description')}
                                    rows={5}
                                />
                                <div className={styleForm.error}>
                                    {meta.dirty && meta.error}
                                </div>
                            </>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.icon}
                        render={({ input }) => (
                            <div className={styleForm.field}>
                                <label className={cn(styleForm.group, style.fieldLabel)}>
                                    <Input
                                        classes={styleForm.inputFile}
                                        styleType="clear"
                                        type="file"
                                        accept={ACCEPT_IMAGE}
                                        name={input.name}
                                        onChange={handlerAddFiles}
                                    />
                                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_large)}>
                                        {t('store_icon')}
                                    </div>
                                    <div className={style.iconAddPhoto} />
                                </label>
                                {input.value && (
                                    <ImagePreview
                                        src={input.value}
                                        onDelete={() => input.onChange(undefined)}
                                    />
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <Field
                        name={FieldName.logo}
                        render={({ input }) => (
                            <div className={styleForm.field}>
                                <label className={cn(styleForm.group, style.fieldLabel)}>
                                    <Input
                                        classes={styleForm.inputFile}
                                        styleType="clear"
                                        type="file"
                                        accept={ACCEPT_IMAGE}
                                        name={input.name}
                                        onChange={handlerAddFiles}
                                    />
                                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_large)}>
                                        {t('store_image')}
                                    </div>
                                    <div className={style.iconAddPhoto} />
                                </label>
                                {input.value && (
                                    <ImagePreview
                                        src={input.value}
                                        onDelete={() => input.onChange(undefined)}
                                    />
                                )}
                            </div>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20, styleForm.row_jSpaceBetween)}>
                    <Input
                        styleType="dynamic"
                        name={FieldName.rewardAutoApproveDays}
                        dynamicPlaceholder={t('loyalty_accrual')}
                        value={daysInputValue}
                        onFocus={(e) => {
                            setIsOpenModalDays(true)
                            e.currentTarget.blur()
                        }}
                        onChange={() => {}}
                    />
                    <SvgResource
                        classes={style.fieldArrow}
                        resourceKey="dropdpwn_daw_svg"
                        isImgTag={false}
                        width={18}
                        height={11}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20, styleForm.row_jSpaceBetween)}>
                    <div className={style.fieldText}>
                        {t('market_personal_notify_email_hint')}
                    </div>
                    <Switch
                        name="notify"
                        checked={isNotify}
                        onChange={handlerClickNotify}
                    />
                </div>
                <Transition.Group classes={cn(styleForm.row, styleForm.row_20)}>
                    {isNotify && (
                        <Transition.CSSTransition in classNames="fade-in" timeout={300}>
                            <div className={cn(styleForm.row, styleForm.row_20, styleForm.row_jSpaceBetween)}>
                                <div className={style.fieldText}>
                                    {t('market_personal_notify_admins_hint')}
                                </div>
                                <Field
                                    name={FieldName.notifyShopAdmins}
                                    type="checkbox"
                                    render={({ input }) => (
                                        <Switch {...input} />
                                    )}
                                />
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20, styleForm.row_jSpaceBetween)}>
                                <div className={style.fieldText}>
                                    {t('market_personal_notify_cashiers_hint')}
                                </div>
                                <Field
                                    name={FieldName.notifyShopCashiers}
                                    type="checkbox"
                                    render={({ input }) => (
                                        <Switch {...input} />
                                    )}
                                />
                            </div>
                            <div className={cn(styleForm.row, styleForm.row_20)}>
                                <Field
                                    name={FieldName.notifySomebodyElse}
                                    render={({ input, meta }) => (
                                        <>
                                            <Input
                                                {...input}
                                                classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                                styleType="dynamic"
                                                dynamicPlaceholder={t('market_personal_notify_somebody_hint')}
                                                value={input.value && input.value.replace(/^\s+/, '')}
                                            />
                                            <div className={styleForm.error}>
                                                {meta.dirty && meta.error}
                                            </div>
                                        </>
                                    )}
                                />
                            </div>
                        </Transition.CSSTransition>
                    )}
                </Transition.Group>
                <div className={styleForm.controls_40}>
                    <Button
                        classes={style.action}
                        type="submit"
                        size="size46"
                        text={t('save')}
                        disabled={hasValidationErrors}
                    />
                </div>
            </form>

            <Modal
                isOpen={isOpenModalDays}
                size="smallCenter"
                onClose={handlerCloseModalDays}
            >
                <Modal.Body classes={style.modalBody}>
                    <div className={cn(styleForm.row, styleForm.row_12)}>
                        <div className={cn(styleForm.row, styleForm.row_12)}>
                            <Radio
                                name="days"
                                checked={daysState.preset === DAYS_PRESET[DaysFieldName.rightAway]}
                                text={t('right_away')}
                                onChange={() => {
                                    dispatch({ type: 'preset', payload: DAYS_PRESET[DaysFieldName.rightAway] })
                                }}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_12)}>
                            <Radio
                                name="days"
                                checked={daysState.preset === DAYS_PRESET[DaysFieldName.manually]}
                                text={t('in_the_manual_mode')}
                                onChange={() => {
                                    dispatch({ type: 'preset', payload: DAYS_PRESET[DaysFieldName.manually] })
                                }}
                            />
                        </div>
                        <div className={cn(styleForm.row, styleForm.row_12)}>
                            <Radio
                                name="days"
                                checked={daysState.preset === undefined}
                                text={t('specify_the_period_days')}
                                onChange={() => dispatch({ type: 'preset', payload: undefined })}
                            />
                        </div>
                    </div>
                    <div className={cn(styleForm.row, styleForm.row_12)}>
                        <Input
                            styleType="default"
                            inputMode="numeric"
                            onFocus={() => dispatch({ type: 'preset', payload: undefined })}
                            onChange={handlerChangeDaysSpecify}
                            value={daysState.specify}
                        />
                    </div>
                    <div className={cn(styleForm.controls, styleForm.controls_20)}>
                        <Field
                            <FormDataType[FieldName.rewardAutoApproveDays]>
                            name={FieldName.rewardAutoApproveDays}
                            render={({ input }) => (
                                <Button
                                    classes={style.modalAction}
                                    styleType="default"
                                    size="size46"
                                    text={t('ok')}
                                    disabled={daysState.preset !== 0 && daysState.preset !== -1 && !daysState.specify}
                                    onClick={() => {
                                        input.onChange(daysState.preset !== undefined
                                            ? daysState.preset
                                            : daysState.specify)
                                        setIsOpenModalDays(false)
                                    }}
                                />
                            )}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default StoreForm
