import React, { useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { TIntersectionObserverProps } from 'hooks/useIntersectionObserver'
import { CardSize } from 'enums'
import { useIntersectionObserver } from 'hooks'
import CardImage, { CardImagePropType } from './CardImage/CardImage'
import CardBody, { CardBodyPropType } from './CardBody/CardBody'
import CardFooter, { CardFooterPropType } from './CardFooter/CardFooter'
import { Context } from './Context'
import style from './Card.module.css'

export type CardPropType = {
    classes?: string
    size?: keyof typeof CardSize
    url?: string
    target?: '_blank'
    observerOpts?: TIntersectionObserverProps
    onIntersecting?: () => void
    onClick?: () => void
}

type CardComposition = {
    Image: React.FC<CardImagePropType>
    Body: React.FC<CardBodyPropType>
    Footer: React.FC<CardFooterPropType>
}

const CardV2: React.FC<CardPropType> & CardComposition = ({
    children,
    classes,
    size = CardSize.fullWidth,
    url,
    target,
    observerOpts = {},
    onIntersecting = () => {},
    onClick,
}) => {
    const OBSERVER_OPTS_DEFAULT = {
        rootMargin: '50% 0px 50% 0px',
        freezeOnceVisible: true,
    }

    const ref = useRef(null)
    const [isIntersecting] = useIntersectionObserver(ref, { ...OBSERVER_OPTS_DEFAULT, ...observerOpts })

    const [context, setContext] = useState({ isIntersecting: false })

    const bodyTpl = (
        <Context.Provider value={context}>
            {children}
        </Context.Provider>
    )

    const handlerClick = () => {
        if (onClick) {
            onClick()
        }
    }

    useEffect(() => {
        if (isIntersecting) {
            setContext({ isIntersecting: true })
            onIntersecting()
        }
    }, [isIntersecting])

    if (url) {
        return (
            target ? (
                // external link
                <a
                    className={cn(style.card, style.card_link, style[`card_${size}`], classes)}
                    href={url}
                    target={target}
                    ref={ref}
                >
                    {bodyTpl}
                </a>
            ) : (
                // inner link
                <NavLink
                    className={cn(style.card, style.card_link, style[`card_${size}`], classes)}
                    to={url}
                    ref={ref}
                >
                    {bodyTpl}
                </NavLink>
            )
        )
    }

    return (
        onClick ? (
            <div
                className={cn(style.card, style[`card_${size}`], classes)}
                role="button"
                tabIndex={0}
                ref={ref}
                onKeyDown={() => {}}
                onClick={handlerClick}
            >
                {bodyTpl}
            </div>
        ) : (
            <div
                className={cn(style.card, style[`card_${size}`], classes)}
                ref={ref}
            >
                {bodyTpl}
            </div>
        )
    )
}

CardV2.Image = CardImage
CardV2.Body = CardBody
CardV2.Footer = CardFooter

export default CardV2
