import React, { useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { ICurrency } from 'interfaces'
import {
    CustomSelect,
    DatePicker,
    Button,
    Input,
    Radio,
    Tooltip,
    Switch,
    Tag,
    SvgResource,
    Modal,
} from 'components'
import { dateTimeFormat, defaultDateFormat } from 'utils/helpers'
import styleForm from 'styles/modules/form.module.css'
import style from './ReportsPaymentForm.module.css'

// FIXME on ready backend
export enum FieldName {
    id = 'id',
    period = 'period',
    dateFrom = 'dateFrom',
    dateTo = 'dateTo',
    store = 'store',
    currency = 'currency',
    isDivideByStores = 'isDivideByStores',
    isCumulative = 'isCumulative',
}

export type FormDataType = {
    [FieldName.id]?: number
    [FieldName.period]: number
    [FieldName.store]: number[]
    [FieldName.isDivideByStores]: 0|1
    [FieldName.isCumulative]: 0|1
    [FieldName.dateFrom]: string
    [FieldName.dateTo]: string
    [FieldName.currency]?: number
}

type ShopGoodsFormPropType = {
    classes?: string
    isSubmitting: boolean
    datePeriod?: { id: number, name: string }[] // FIXME on ready api request
    stores?: { id: number, name: string }[] // FIXME on ready api request
    divideByStores?: { id: number, name: string }[] // FIXME on ready api request
    currencies?: ICurrency[]
    onChange?: (data: FormDataType) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

type TDateField = FieldName.dateFrom | FieldName.dateTo

export const formDataInitial: FormDataType = {
    [FieldName.period]: 0,
    [FieldName.store]: [],
    [FieldName.dateFrom]: defaultDateFormat(new Date()),
    [FieldName.dateTo]: defaultDateFormat(new Date()),
    [FieldName.isDivideByStores]: 0,
    [FieldName.isCumulative]: 0,
}

const ReportsPaymentForm: React.FC<ShopGoodsFormPropType> = ({
    classes,
    isSubmitting,
    datePeriod,
    stores,
    divideByStores,
    currencies,
    onChange,
    onSubmit,
}) => {
    const { t, i18n } = useTranslation()

    const { change } = useForm<FormDataType>()
    const { values, hasValidationErrors } = useFormState<FormDataType>({
        onChange: (formState) => {
            if (onChange) {
                onChange(formState.values)
            }
        },
    })

    const [currentDateField, setCurrentDateField] = useState<TDateField>()
    const [dateCalendar, setDateCalendar] = useState(new Date())
    const [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)

    const datePeriodData: Record<number, string> = useMemo(() => {
        return datePeriod
            ? datePeriod.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [datePeriod])

    const storesData: Record<number, string> = useMemo(() => {
        return stores
            ? stores.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [stores])

    const divideByStoresData: Record<number, string> = useMemo(() => {
        return divideByStores
            ? divideByStores.reduce((acc, item) => ({ ...acc, [item.id]: item.name }), {})
            : {}
    }, [divideByStores])

    const currenciesData: Record<number, ICurrency> = useMemo(() => {
        return currencies
            ? currencies.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
            : {}
    }, [currencies])

    const dateSelected = useMemo(() => {
        return currentDateField && values[currentDateField]
            ? new Date(values[currentDateField] as string)
            : undefined
    }, [values, currentDateField])

    return (
        <>
            <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                        <div className={styleForm.groupItem}>
                            <div className={styleForm.fieldHead}>
                                {t('reports_group_by_date')}
                            </div>
                        </div>
                        <div className={cn(
                            styleForm.groupItem,
                            styleForm.groupItem_maxWidth,
                            style.groupItem,
                            style.groupItem_flex,
                        )}
                        >
                            <Tooltip
                                classes={style.tooltip}
                                position="right"
                                tooltip={(
                                    <div className={style.tooltipHint}>
                                        {t('reports_group_by_date_hint')}
                                    </div>
                                )}
                            >
                                <Tooltip.TriggerInfo />
                            </Tooltip>
                        </div>
                    </div>
                    <Field
                        <FormDataType[FieldName.period]>
                        name={FieldName.period}
                        render={({ input, meta }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.selectField} disabled={!datePeriod}>
                                    {!!input.value && datePeriodData[input.value]}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {datePeriod?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            key={item.id}
                                            onClick={input.onChange}
                                        >
                                            <Radio
                                                classesText={style.selectOptionText}
                                                name={input.name}
                                                size="small"
                                                checked={item.id === input.value}
                                                text={item.name}
                                            />
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {t('date')}
                    </div>
                    <div className={styleForm.group}>
                        <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth, style.groupItem)}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                {t('reports_date_start')}
                            </div>
                            <Field
                                <FormDataType[FieldName.dateFrom]>
                                name={FieldName.dateFrom}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            styleType="defaultBusiness"
                                            name={input.name}
                                            value={input.value && dateTimeFormat(input.value, i18n.language)}
                                            onFocus={(e) => {
                                                setCurrentDateField(FieldName.dateFrom)
                                                setDateCalendar(input.value
                                                    ? new Date(input.value)
                                                    : new Date())
                                                setIsOpenModalCalendar(true)
                                                e.currentTarget.blur()
                                            }}
                                            onChange={() => {}}
                                        />
                                        <SvgResource
                                            isImgTag={false}
                                            classes={cn(
                                                styleForm.fieldIcon,
                                                style.fieldIcon,
                                                style.iconCalendar,
                                            )}
                                            resourceKey="ic_kicks_calendar2_svg"
                                            width={24}
                                            height={24}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth, style.groupItem)}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_small)}>
                                {t('reports_date_end')}
                            </div>
                            <Field
                                <FormDataType[FieldName.dateTo]>
                                name={FieldName.dateTo}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="defaultBusiness"
                                            name={input.name}
                                            value={input.value && dateTimeFormat(input.value, i18n.language)}
                                            onFocus={(e) => {
                                                setCurrentDateField(FieldName.dateTo)
                                                setDateCalendar(input.value
                                                    ? new Date(input.value)
                                                    : new Date())
                                                setIsOpenModalCalendar(true)
                                                e.currentTarget.blur()
                                            }}
                                            onChange={() => {}}
                                        />
                                        <SvgResource
                                            isImgTag={false}
                                            classes={cn(
                                                styleForm.fieldIcon,
                                                style.fieldIcon,
                                                style.iconCalendar,
                                            )}
                                            resourceKey="ic_kicks_calendar2_svg"
                                            width={24}
                                            height={24}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {`${t('reports_store')}*`}
                    </div>
                    <Field
                        <FormDataType[FieldName.store]>
                        name={FieldName.store}
                        render={({ input }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field
                                    classes={cn(style.selectField, style.selectField_heightAuto)}
                                >
                                    <div className={style.selectFieldWrapTag}>
                                        {input.value.map((item, idx, items) => (
                                            <Tag
                                                classes={style.tag}
                                                key={item}
                                                onDelete={() => {
                                                    input.onChange(items.filter((val) => val !== item))
                                                }}
                                            >
                                                {storesData[item]}
                                            </Tag>
                                        ))}
                                    </div>
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {stores?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            isActive={input.value.includes(item.id)}
                                            key={item.id}
                                            onClick={() => input.onChange([...input.value, item.id])}
                                        >
                                            {item.name}
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={styleForm.fieldHead}>
                        {t('reports_group_store')}
                    </div>
                    <Field
                        <FormDataType[FieldName.isDivideByStores]>
                        name={FieldName.isDivideByStores}
                        render={({ input, meta }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.selectField} disabled={!divideByStores}>
                                    {divideByStoresData[input.value]}
                                </CustomSelect.Field>
                                <CustomSelect.Options>
                                    {divideByStores?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            key={item.id}
                                            onClick={() => input.onChange(item.id)}
                                        >
                                            <Radio
                                                classesText={style.selectOptionText}
                                                name={input.name}
                                                size="small"
                                                checked={item.id === input.value}
                                                text={item.name}
                                            />
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.group, styleForm.group_alignTop)}>
                        <div className={styleForm.groupItem}>
                            <div className={styleForm.fieldHead}>
                                {t('reports_lead_to')}
                            </div>
                        </div>
                        <div className={cn(
                            styleForm.groupItem,
                            styleForm.groupItem_maxWidth,
                            style.groupItem,
                            style.groupItem_flex,
                        )}
                        >
                            <Tooltip
                                classes={style.tooltip}
                                position="right"
                                tooltip={(
                                    <div className={style.tooltipHint}>
                                        {t('reports_lead_to_hint')}
                                    </div>
                                )}
                            >
                                <Tooltip.TriggerInfo />
                            </Tooltip>
                        </div>
                    </div>
                    <Field
                        <FormDataType[FieldName.currency]>
                        name={FieldName.currency}
                        render={({ input, meta }) => (
                            <CustomSelect.Select>
                                <CustomSelect.Field classes={style.selectField} disabled={!currencies}>
                                    {!!input.value && currenciesData[input.value].showCode}
                                </CustomSelect.Field>
                                <CustomSelect.Options
                                    classes={style.selectOptions}
                                    classesList={style.selectOptionsList}
                                >
                                    {currencies?.map((item) => (
                                        <CustomSelect.Option
                                            id={item.id}
                                            isActive={item.id === input.value}
                                            key={item.id}
                                            onClick={() => input.onChange(item.id)}
                                        >
                                            <Radio
                                                classesText={style.selectOptionText}
                                                name={input.name}
                                                size="small"
                                                checked={item.id === input.value}
                                                text={item.showCode}
                                            />
                                        </CustomSelect.Option>
                                    ))}
                                </CustomSelect.Options>
                            </CustomSelect.Select>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_20)}>
                    <div className={cn(styleForm.group, styleForm.group_alignCenter)}>
                        <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth)}>
                            <div className={styleForm.fieldHead}>
                                {t('reports_cumulative_total')}
                            </div>
                        </div>
                        <div className={styleForm.groupItem}>
                            <Field
                                <FormDataType[FieldName.isCumulative]>
                                name={FieldName.isCumulative}
                                render={({ input, meta }) => (
                                    <Switch
                                        classes={style.switch}
                                        name={input.name}
                                        checked={Boolean(input.value)}
                                        onChange={({ target }) => input.onChange(Number(target.checked))}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={cn(styleForm.controls, styleForm.controls_32, style.actions)}>
                    <Button
                        classes={style.action}
                        type="submit"
                        size="size46"
                        text={t('show')}
                        disabled={hasValidationErrors}
                    />
                    <Button
                        disabled
                        classes={style.action}
                        styleType="bordered"
                        size="size46"
                        text={t('download')}
                    />
                </div>
            </form>

            <Modal
                isOpen={isOpenModalCalendar}
                size="smallCenter"
                onClose={() => setIsOpenModalCalendar(false)}
            >
                <Modal.Header
                    classes={style.modalCalendarTitle}
                    title={t('select_date')}
                    titlePos="center"
                />
                <Modal.Body classes={style.modalCalendarBody}>
                    <DatePicker
                        date={dateCalendar}
                        dateSelected={dateSelected}
                        onChange={(value) => {
                            if (currentDateField) {
                                change(currentDateField, defaultDateFormat(value))
                            }
                            setIsOpenModalCalendar(false)
                        }}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ReportsPaymentForm
