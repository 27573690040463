import React, {
    InputHTMLAttributes,
    useRef,
    useEffect,
    useState,
    useImperativeHandle,
    forwardRef,
} from 'react'
import { IMask, IMaskInput } from 'react-imask'
import cn from 'classnames'

import { getId } from 'utils/helpers'
import style from './Input.module.css'

export type InputPropType = {
    classes?: string
    styleType?: 'default' | 'defaultBusiness' | 'dynamic' | 'clear' | 'transparent' | 'gray'
    dynamicPlaceholder?: string
    focus?: boolean
    mask?: IMask.AnyMask
    dispatch?: IMask.MaskedDynamicOptions['dispatch'] // only masked input
    onAccept?: (el: HTMLInputElement, unmaskedValue: string) => void // only masked input
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'className'>

type PropsType = InputHTMLAttributes<HTMLInputElement> & {
    className?: string
}

type MaskPropsType = Exclude<PropsType, 'onChange'> & Pick<InputPropType, 'mask' | 'dispatch'> & {
    onAccept: (value: unknown, maskRef: IMask.InputMask<IMask.AnyMaskedOptions>, e?: InputEvent) => void
}

type MaskRefType = IMask.InputMask<IMask.MaskedPatternOptions> & { el: { input: HTMLInputElement } }

/**
 * Input component
 *
 * Masked input
 * @see https://imask.js.org/
 */
const Input = forwardRef<HTMLInputElement, InputPropType>(({
    classes = '',
    type = 'text',
    styleType = type === 'text' ? 'default' : '',
    inputMode = 'text',
    placeholder = '',
    dynamicPlaceholder = '',
    mask,
    focus = false,
    maxLength,
    onAccept = () => {},
    ...defaults
}, ref) => {
    const props: PropsType | MaskPropsType = {
        className: cn(style[styleType], classes),
        type,
        inputMode,
        placeholder: dynamicPlaceholder ? ' ' : placeholder,
        ...defaults,
    }

    const inputRef = useRef<HTMLInputElement>(null)
    const [id] = useState(getId(false))

    const handlerOnAccept = (value: unknown, maskRef: IMask.InputMask<IMask.AnyMaskedOptions>) => {
        const { el, unmaskedValue } = maskRef as MaskRefType
        onAccept(el.input, unmaskedValue)
    }

    useImperativeHandle(ref, () => inputRef.current!, [])

    useEffect(() => {
        if (focus && inputRef.current && 'focus' in inputRef.current) {
            inputRef.current.focus()
        }
    }, [focus, inputRef.current])

    return (
        <>
            {mask ? (
                <IMaskInput
                    {...props as MaskPropsType}
                    // @ts-ignore
                    id={id}
                    mask={mask}
                    lazy={false} // make placeholder always visible
                    inputRef={inputRef}
                    onAccept={handlerOnAccept}
                />
            ) : (
                <input
                    {...props}
                    id={id}
                    maxLength={maxLength}
                    ref={inputRef}
                />
            )}

            {styleType === 'dynamic' && dynamicPlaceholder && (
                <label className={style.placeholder} htmlFor={id}>
                    {dynamicPlaceholder}
                </label>
            )}
        </>
    )
})

export default Input
