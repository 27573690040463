import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ISticker } from 'interfaces'
import { API_HOST } from 'config/api'
import * as appSelectors from 'containers/App/app-selectors'
import { parseTpl } from 'utils/helpers'

export type StickerType = ISticker & { type: string }

export type StickerMenuType = { type: string }

export type StickerPropType = {
    data: StickerType | StickerMenuType
    classes?: string
    size?: 'small' | 'big'
    isMenuSticker?: boolean
}

const Sticker: React.FC<StickerPropType> = ({
    classes,
    data,
    size = 'small',
    isMenuSticker,
}) => {
    const { type } = data || {}

    const {
        menu_url: menuUrl,
        small_url: smallUrl,
        big_url: bigUrl,
    } = useSelector(appSelectors.stickers)

    const stickerSrc = useMemo(() => {
        if (isMenuSticker && type) {
            const path = parseTpl(menuUrl, { type })
            return `${API_HOST}/${path}`
        }
        if ('id' in data && type) {
            const pathTpl = size === 'small' ? smallUrl : bigUrl
            const path = parseTpl(pathTpl, { id: data.id, type })

            return `${API_HOST}${path}`
        }

        return ''
    }, [])

    return (
        <>
            {stickerSrc && (
                <img
                    className={classes}
                    src={stickerSrc}
                    alt={'id' in data ? String(data.id) : type}
                />
            )}
        </>
    )
}

export default Sticker
