import {
    IAuth,
    IAuthExtends,
    IAuthData,
    IUser,
    IConfig,
    IDarkDoorProps,
    IPersonalStore,
} from 'interfaces'
import { AppMode, CompanyRoleId, QueryCacheKeys } from 'enums'
import {
    IS_PRODUCTION,
    APP_MODE,
    API_HOST,
    API_URL,
} from 'config/api'
import {
    APP_URL,
    AUTH_PACKAGE,
    GRANT_TYPE_SMS,
    GRANT_TYPE_SMS_BUSINESS,
    GRANT_TYPE_CALL,
    SMS_AUTH_CLIENT_ID,
    SMS_AUTH_CLIENT_ID_BUSINESS,
    SMS_CLIENT_SECRET,
    SMS_CLIENT_SECRET_BUSINESS,
    CALL_AUTH_CLIENT_ID,
    CALL_CLIENT_SECRET,
    PHONE_ID,
    CHECK_CALL_AUTH_TIMER,
    USER_TAG_ID_CANARY_ZONE,
    URL_PARAM_RETURN_PATH_KEY,
} from 'config/app'
import { authorizeUser, fetchUser, fetchUserTags } from 'containers/User/user-actions'
import { fetchCompanyUser } from 'containers/Company/company-actions'
import {
    AppService,
    StorageService,
    UserService,
    MarketService,
} from 'services'
import requestClient from 'utils/requestClient'
import { getURLSearchParams } from 'utils/helpers'
import errorLog from 'utils/errorLog'
import queryClient from 'queryClient'
import store, { AppDispatch } from 'store'
import i18n from 'i18n'

export type TAuthProps = {
    grant_type: string
    package: string
    phone_id: string
    client_id: string
    client_secret: string
}

export type TAuthDataProps = {
    country_id: number// | string
    country_code: string
    phone_number: string
    name?: string
    surname?: string
    validation_code?: string
    guarantor_code?: string
}

export type TAuthErrorResponse = {
    error: string // access_denied|...
    error_description: string
}

export type TAuthResponse = IAuth | TAuthErrorResponse

export type TSMSAuthProps = TAuthDataProps & { validation_code: string }

export type TCallAuthProps = TAuthDataProps & { check_id: string }

export type TCallAuthResponse = {
    check_id: string
    call_phone: string
    call_phone_pretty: string
}

/**
 * Service API authentication
 *
 * @class AuthService
 */
class AuthService {
    protected params: TAuthProps
    protected isAbortConfirmCallAuth: boolean

    constructor() {
        /**
         * @protected
         * @type object
         * @property {string} grant_type
         * @property {string} package
         * @property {string} phone_id
         * @property {string} client_id
         * @property {string} client_secret
         */
        this.params = {
            grant_type: GRANT_TYPE_SMS,
            package: AUTH_PACKAGE,
            phone_id: PHONE_ID,
            client_id: SMS_AUTH_CLIENT_ID,
            client_secret: SMS_CLIENT_SECRET,
        }

        /**
         * @protected
         * @type boolean
         */
        this.isAbortConfirmCallAuth = false
    }

    /**
     * Check has user access and refresh tokens
     */
    static isAuthenticated(): boolean {
        const { access_token, refresh_token, appMode } = AuthService.getAuthData() || {}
        return !!access_token && !!refresh_token && APP_MODE === appMode
    }

    /**
     * Check required dark door auth params
     */
    static isDarkDoorAuth(params: IDarkDoorProps) {
        return !!params?.token && !!params?.company_id
    }

    /**
     * Get dark door auth url params
     */
    static getDarkDoorUrlParams(search: string = ''): IDarkDoorProps {
        const { token, company_id } = getURLSearchParams(search)
        return { token, company_id }
    }

    /**
     * Get auth data from local storage
     */
    static getAuthData(): null | IAuthExtends {
        return StorageService.getItem('auth')
    }

    /**
     * Save auth data to local storage
     */
    static saveAuthData(params: IAuthExtends): void {
        StorageService.setItem('auth', params)
    }

    /**
     * Save dark door auth data
     */
    static saveDarkDoorAuthData(params: Pick<IAuthData, 'access_token'>): void {
        StorageService.setItem('auth', params)
    }

    /**
     * Clear auth data from local storage
     */
    static clearAuthData(): void {
        StorageService.removeItem('auth')
    }

    /**
     * Запрос авторизации по телефону
     */
    static getCallAuth(phone: string) {
        return requestClient<TCallAuthResponse>(API_URL.requestCallAuth, { method: 'post', data: { phone } })
    }

    /**
     * Get access and refresh tokens
     */
    auth(mode: AppMode, data: TAuthDataProps): Promise<TAuthResponse> {
        const defaults = { package: AUTH_PACKAGE, phone_id: PHONE_ID }

        const defaultsUserMode = {
            grant_type: GRANT_TYPE_SMS,
            client_id: SMS_AUTH_CLIENT_ID,
            client_secret: SMS_CLIENT_SECRET,
        }

        const defaultsBusinessMode = {
            grant_type: GRANT_TYPE_SMS_BUSINESS,
            client_id: SMS_AUTH_CLIENT_ID_BUSINESS,
            client_secret: SMS_CLIENT_SECRET_BUSINESS,
        }

        let params: TAuthDataProps

        if (mode === AppMode.business) {
            params = { ...defaults, ...data, ...defaultsBusinessMode }
        } else {
            params = { ...defaults, ...data, ...defaultsUserMode }
        }

        return requestClient<TAuthResponse>(API_URL.auth, { baseURL: API_HOST, params })
            .then((res) => {
                const { data: dataRes } = res || {}

                if ((dataRes as IAuth)?.access_token) {
                    const authExtends: IAuthExtends = {
                        ...dataRes as IAuth,
                        grantType: mode === AppMode.business
                            ? defaultsBusinessMode.grant_type
                            : defaultsUserMode.grant_type,
                        appMode: mode,
                    }

                    const authData: IAuthData = {
                        ...authExtends,
                        countryId: params.country_id,
                        countryCode: params.country_code,
                        phone: params.phone_number,
                    }

                    AuthService.saveAuthData(authExtends)
                    UserService.saveAuthData(authData)
                }

                return dataRes
            })
            .catch((err) => {
                return Promise.reject(err)
            })
    }

    /**
     * Авторизация в пользовательской версии
     *
     * @return {Promise<void|string>} - route for redirect or void with set authorization
     */
    static authorize(user: IUser, location: any): Promise<void | string> {
        const { dispatch }: { dispatch: AppDispatch } = store

        return new Promise((resolve, reject) => {
            if (user) {
                return AuthService.checkUserAccounts(user)
                    .then((path) => {
                        if (path) {
                            return Promise.reject({ path })
                        }

                        return AuthService.checkUserProfile(user)
                    })
                    .then((path) => {
                        if (path) {
                            return Promise.reject({ path })
                        }

                        return AuthService.checkUserCompanyAccount(user)
                    })
                    .then(() => {
                        return AuthService.checkUserTagCanary(user)
                    })
                    .then(() => {
                        const { [URL_PARAM_RETURN_PATH_KEY]: path } = getURLSearchParams(location.search)

                        dispatch(authorizeUser(true))

                        return path ? resolve(path) : resolve()
                    })
                    .catch((e) => {
                        const { path } = e || {}

                        if (path) {
                            return resolve(path)
                        }

                        return reject(e)
                    })
            }

            return reject()
        })
    }

    /**
     * Авторизация в бизнес версии
     */
    static authorizeBusiness(user: IUser, config: IConfig): Promise<void | string> {
        const { dispatch }: { dispatch: AppDispatch } = store
        const { forbidden: forbiddenConfig } = config

        return new Promise((resolve, reject) => {
            if (user) {
                return AuthService.checkCompany()
                    .then((path) => {
                        if (path) {
                            const err = forbiddenConfig.companyAdd === false
                                ? { error: i18n.t('business_no_company_account') }
                                : { path }
                            return Promise.reject(err)
                        }

                        return AuthService.checkPersonalStores(user)
                    })
                    .then((path) => {
                        if (path) {
                            const err = forbiddenConfig.storeAdd === false
                                ? { error: 'Forbidden store add' }
                                : { path }
                            return Promise.reject(err)
                        }

                        return AuthService.checkUserProfile(user)
                    })
                    .then((path) => {
                        if (path) {
                            return Promise.reject({ path })
                        }

                        dispatch(authorizeUser(true))
                        return resolve()
                    })
                    .catch((e) => {
                        const { path } = e || {}

                        if (path) {
                            return resolve(path)
                        }

                        return reject(e)
                    })
            }

            return reject()
        })
    }

    /**
     * Проверка юзера на наличие аккаунтов компаний
     */
    static checkUserAccounts(user: IUser): Promise<void | string> {
        const { search } = window.location

        return new Promise((resolve) => {
            return UserService.isSetAccounts(user) ? resolve() : resolve(`${APP_URL.registrationUser}${search}`)
        })
    }

    /**
     * Проверка пользователя на заполненность обязательных полей
     */
    static checkUserProfile(user: IUser): Promise<void | string> {
        const { search } = window.location
        const { name, surname } = user // see RegistrationUserAction required fields

        return new Promise((resolve) => {
            return name && surname ? resolve() : resolve(`${APP_URL.registrationUser}${search}`)
        })
    }

    /**
     * Проверка пользователя на текущую компанию
     */
    static checkUserCompanyAccount(user: IUser): Promise<void> {
        const { dispatch }: { dispatch: AppDispatch } = store
        const companyAccountId5 = UserService.getCompanyAccountId5(user)

        return new Promise((resolve, reject) => {
            if (companyAccountId5 && companyAccountId5.company_id !== user.company_id) {
                return UserService.changeAccount(companyAccountId5.id)
                    .then(() => {
                        return dispatch(fetchUser())
                            .then(() => {
                                return resolve()
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    })
                    .catch((err) => {
                        return reject(err)
                    })
            }

            return resolve()
        })
    }

    /**
     * Проверка пользователя на наличие тега канарейки
     */
    static checkUserTagCanary(user: IUser): Promise<void> {
        const { dispatch }: { dispatch: AppDispatch } = store

        return new Promise((resolve, reject) => {
            dispatch(fetchUserTags({ accountId: user.account_id }))
                .then((data) => {
                    if (IS_PRODUCTION && data?.find((tag) => tag.colored_tag.id === USER_TAG_ID_CANARY_ZONE)) {
                        AppService.setRequestCanaryZone()
                    }

                    resolve()
                })
                .catch(() => {
                    reject()
                })
        })
    }

    /**
     * Проверка существования бизнес компании у пользователя
     */
    static checkCompany(): Promise<void | string> {
        const { dispatch }: { dispatch: AppDispatch } = store

        return new Promise((resolve, reject) => {
            return dispatch(fetchCompanyUser())
                .then((company) => {
                    const {
                        name,
                        juridical_name: fullName,
                        city,
                        email_list: [email],
                        phone_list: [phone],
                        company_role,
                    } = company || {}

                    switch (company_role?.id) {
                        case CompanyRoleId.admin:
                        case CompanyRoleId.cashier: {
                            // see RegistrationCompanyAction required fields
                            const needModerate = !name || !fullName || !city || !email || !phone
                            return needModerate ? resolve(APP_URL.registrationCompany) : resolve()
                        }
                        case CompanyRoleId.editor: // TODO ?
                            return resolve()
                        case CompanyRoleId.awaiting:
                            return resolve(APP_URL.registrationCompany)
                        default:
                            return reject()
                    }
                })
                .catch((err) => {
                    const { status } = err?.response || {}

                    if (status === 404) {
                        return resolve(APP_URL.registrationCompany)
                    }

                    return reject(err)
                })
        })
    }

    /**
     * Проверка магазинов в бизнес компании пользователя
     */
    static checkPersonalStores(user: IUser): Promise<void | string> {
        const queryCacheKey = QueryCacheKeys.personalStores

        return new Promise((resolve, reject) => {
            queryClient.fetchQuery(
                [`${queryCacheKey}-${user.id}`],
                () => {
                    return MarketService.fetchPersonalStores()
                        .then(({ data: personalStores }) => {
                            return personalStores
                        })
                        .catch((err) => {
                            return Promise.reject(err)
                        })
                },
            )
                .then((personalStores) => {
                    if (Array.isArray(personalStores)) {
                        if (personalStores.length) {
                            const storeNeedSetting = personalStores.find((item) => {
                                return MarketService.isNeedModeratePersonalStoreLoyaltySettings(item)
                            })

                            if (storeNeedSetting) {
                                return resolve(APP_URL.registrationStoreSettings)
                            }

                            return resolve()
                        }

                        return MarketService.addPersonalStore()
                            .then(({ data: personalStore }) => {
                                /** add new personal store to query cache */
                                queryClient.setQueryData<IPersonalStore[]>(
                                    [`${queryCacheKey}-${user.id}`],
                                    (queryData) => {
                                        return queryData ? [...queryData, personalStore] : [personalStore]
                                    },
                                )
                                return resolve(APP_URL.registrationStoreSettings)
                            })
                            .catch((err) => {
                                return reject(err)
                            })
                    }

                    return reject()
                })
                .catch((err) => {
                    return reject(err)
                })
        })
    }

    /**
     * Auth by SMS
     */
    confirmSMSAuth(scope: AppMode, data: TSMSAuthProps): Promise<TAuthResponse> {
        return this.auth(scope, data)
    }

    /**
     * Auth by phone
     */
    confirmCallAuth(scope: AppMode, data: TCallAuthProps): Promise<TAuthResponse> {
        const params: TAuthProps = {
            grant_type: GRANT_TYPE_CALL,
            package: '',
            phone_id: '',
            client_id: CALL_AUTH_CLIENT_ID,
            client_secret: CALL_CLIENT_SECRET,
        }

        return new Promise((resolve, reject) => {
            const intervalId = window.setInterval(() => {
                if (this.isAbortConfirmCallAuth) {
                    clearCheckers()
                    this.isAbortConfirmCallAuth = false
                    reject()
                }

                this.auth(scope, { ...params, ...data })
                    .then((res) => {
                        clearCheckers()
                        resolve(res)
                    })
                    .catch((err) => {
                        errorLog('confirmCallAuth', err)
                    })
            }, CHECK_CALL_AUTH_TIMER)

            const timeoutId = window.setTimeout(() => {
                clearInterval(intervalId)
                reject({
                    response: {
                        data: {
                            error_description: i18n.t('Call failed. Please try again.'),
                        },
                    },
                })
            }, 600000) // 10min

            function clearCheckers() {
                clearInterval(intervalId)
                clearTimeout(timeoutId)
            }
        })
    }

    /**
     * Отмена проверки авторизации по звонку
     */
    abortConfirmCallAuth(): void {
        if (!this.isAbortConfirmCallAuth) {
            this.isAbortConfirmCallAuth = true
        }
    }
}

export default AuthService
