import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { APP_URL } from 'config/app'
import { ContentContainer, CardContainer } from 'layout'
import { ReportItem } from 'containers/ReportList/components'
import { useFetchReports } from 'containers/Reports/hooks'
import {
    PageHeader,
    Loader,
    NoDataInfo,
    ErrorMsg,
} from 'components'
import { scrollTop } from 'utils/helpers'

const ReportsIndicator: React.FC = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const handlerClickBack = () => {
        history.push(APP_URL.reports)
    }

    const {
        isInitialLoading: isLoading,
        data: reportsData,
        error: reportsError,
    } = useFetchReports({ displayFull: false, count: false })

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [])

    return (
        <ContentContainer size="half">
            <PageHeader
                title={t('reports_indicator')}
                onClickBack={handlerClickBack}
            />

            {isLoading && (
                <Loader />
            )}

            {reportsData && !reportsData.length && (
                <NoDataInfo text={t('nothing_found')} />
            )}

            {reportsError && (
                <ErrorMsg error={reportsError[0]} />
            )}

            {!isLoading && reportsData?.length && (
                <CardContainer>
                    {reportsData.map((item) => (
                        <ReportItem data={item} key={item.id} />
                    ))}
                </CardContainer>
            )}
        </ContentContainer>
    )
}

export default ReportsIndicator
