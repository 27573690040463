import React from 'react'
import { Form } from 'react-final-form'

import { ICompanyUser } from 'interfaces'
import { TCompanyOrdersProps } from 'services/MarketService'
import { FormDataType, FieldName } from 'forms/CompanyOrdersFilterForm/CompanyOrdersFilterForm'
import { useFetchPaymentStatuses, useFetchPaymentMethods } from 'containers/App/hooks'
import { useFetchPersonalStores, useFetchDeliveryStatuses } from 'containers/Market/hooks'
import { CompanyOrdersFilterForm } from 'forms'
import { useAppSelector } from 'store'

type CompanyOrdersFilterActionPropType = {
    classesBody?: string
    classesFooter?: string
    initialValues?: FormDataType
    company: ICompanyUser
    isLoading: boolean
    onError?: (error: any) => void
    onSuccess: (value: Omit<TCompanyOrdersProps, 'companyId'>) => void
}

const CompanyOrdersFilterAction: React.FC<CompanyOrdersFilterActionPropType> = ({
    classesBody,
    classesFooter,
    initialValues,
    company,
    isLoading,
    onError = () => {},
    onSuccess,
}) => {
    const countries = useAppSelector((state) => state.app.countries)

    const { data: dataPersonalStores } = useFetchPersonalStores()

    const { data: dataDeliveryStatuses } = useFetchDeliveryStatuses()

    const { data: dataPaymentStatuses } = useFetchPaymentStatuses()

    const { data: dataPaymentMethods } = useFetchPaymentMethods()

    const handlerSubmit = ({
        [FieldName.formId]: formId,
        [FieldName.store]: stores,
        [FieldName.goods]: goods,
        [FieldName.vendor]: goodsVendors,
        [FieldName.deliveryStatus]: deliveryStatus,
        [FieldName.paymentStatus]: paymentStatus,
        [FieldName.paymentMethods]: paymentMethods,
        ...params
    }: FormDataType) => {
        onSuccess({
            [FieldName.store]: stores?.length ? stores.join(',') : undefined,
            [FieldName.goods]: goods?.length ? goods.join(',') : undefined,
            [FieldName.vendor]: goodsVendors?.length ? goodsVendors.join(',') : undefined,
            [FieldName.deliveryStatus]: deliveryStatus?.length ? deliveryStatus.join(',') : undefined,
            [FieldName.paymentStatus]: paymentStatus?.length ? paymentStatus.join(',') : undefined,
            [FieldName.paymentMethods]: paymentMethods?.length ? paymentMethods.join(',') : undefined,
            ...params,
        })
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            render={({ handleSubmit }) => (
                <CompanyOrdersFilterForm
                    isSubmitting={isLoading}
                    classesBody={classesBody}
                    classesFooter={classesFooter}
                    company={company}
                    countries={countries}
                    stores={dataPersonalStores}
                    deliveryStatuses={dataDeliveryStatuses}
                    paymentStatuses={dataPaymentStatuses}
                    paymentMethods={dataPaymentMethods}
                    onError={onError}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default CompanyOrdersFilterAction
