import React, { useMemo, useState } from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import Saturation from '@uiw/react-color-saturation'
import { HsvaColor, hexToHsva, hsvaToHex } from '@uiw/color-convert'
import Hue from '@uiw/react-color-hue'
import cn from 'classnames'

import { ICatalogItem } from 'interfaces'
import {
    CatalogTree,
    Input,
    Button,
    SvgResource,
    Modal,
} from 'components'
import { vPrimary1 } from 'styles/modules/variables.module.css'
import styleForm from 'styles/modules/form.module.css'
import style from './ShopHeaderForm.module.css'
import { ColorPointer } from './components'

export enum FieldName {
    id = 'id',
    name = 'name',
    parent = 'parent',
    order = 'order',
    color = 'color'
}

export type FormDataType = {
    [FieldName.id]?: number
    [FieldName.name]: string
    [FieldName.parent]: number
    [FieldName.color]: string
    [FieldName.order]?: number
}

type ShopHeaderFormPropType = {
    classes?: string
    isSubmitting: boolean
    catalog?: ICatalogItem[]
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

export const BG_COLOR_DEFAULT = '#FFFFFF'

export const formDataInitial: FormDataType = {
    [FieldName.name]: '',
    [FieldName.parent]: 0,
    [FieldName.color]: BG_COLOR_DEFAULT,
}

const ShopHeaderForm: React.FC<ShopHeaderFormPropType> = ({
    classes,
    isSubmitting,
    catalog,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { change } = useForm<FormDataType>()
    const { values, hasValidationErrors } = useFormState<FormDataType>()

    const [hsva, setHsva] = useState(hexToHsva(vPrimary1))
    const [isOpenModalCatalog, setIsOpenModalCatalog] = useState(false)

    const parent = useMemo(() => {
        return catalog && values[FieldName.parent]
            ? catalog.find((item) => item.id === values[FieldName.parent])
            : undefined
    }, [values[FieldName.parent]])

    const handlerChangeColor = (value: HsvaColor) => {
        change(FieldName.color, hsvaToHex(value).toUpperCase())
        setHsva({ ...hsva, ...value, a: hsva.a })
    }

    const handlerClickCategory = (value: ICatalogItem) => {
        change(FieldName.parent, value.id)
        setIsOpenModalCatalog(false)
    }

    return (
        <>
            <div className={style.header} style={{ backgroundColor: values[FieldName.color] }}>
                <div className={style.headerText} style={{ color: hsva.v > 50 ? '#000' : '#fff' }}>
                    {values[FieldName.name] || t('screen_pm_category_example_title')}
                </div>
            </div>
            <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
                <div className={cn(styleForm.row, styleForm.row_40)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_large)}>
                        {t('dialog_pm_add_category')}
                    </div>
                    <Field
                        name={FieldName.parent}
                        render={({ input, meta }) => (
                            <div className={styleForm.field}>
                                <Input
                                    {...input}
                                    styleType="dynamic"
                                    dynamicPlaceholder={t('dialog_pm_add_category')}
                                    value={parent?.name ?? ''}
                                    disabled={!parent}
                                    onFocus={(e) => {
                                        setIsOpenModalCatalog(true)
                                        e.currentTarget.blur()
                                    }}
                                    onChange={() => {}}
                                />
                                <SvgResource
                                    classes={style.fieldArrow}
                                    resourceKey="dropdpwn_daw_svg"
                                    isImgTag={false}
                                    width={18}
                                    height={11}
                                />
                            </div>
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_40)}>
                    <div className={cn(styleForm.group, styleForm.group_fullWidth)}>
                        <div className={cn(styleForm.groupItem, styleForm.groupItem_maxWidth, style.groupItem)}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_large)}>
                                {t('screen_pm_name_title')}
                            </div>
                            <Field
                                name={FieldName.name}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="dynamic"
                                            dynamicPlaceholder={t('screen_pm_name_title')}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                        <div className={cn(styleForm.groupItem, style.groupItem)}>
                            <div className={cn(styleForm.fieldHead, styleForm.fieldHead_large)}>
                                {t('pm_sorting_title')}
                            </div>
                            <Field
                                name={FieldName.order}
                                render={({ input, meta }) => (
                                    <div className={styleForm.field}>
                                        <Input
                                            {...input}
                                            classes={cn({ [styleForm.invalid]: meta.dirty && !!meta.error })}
                                            styleType="dynamic"
                                            inputMode="numeric"
                                            dynamicPlaceholder={t('pm_sorting_title')}
                                            pattern="[0-9]*"
                                            onChange={(e) => {
                                                if (e.currentTarget.validity.valid) {
                                                    input.onChange(e.currentTarget.value)
                                                }
                                            }}
                                        />
                                        <div className={styleForm.error}>
                                            {meta.dirty && meta.error}
                                        </div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_40)}>
                    <div className={cn(styleForm.fieldHead, styleForm.fieldHead_large)}>
                        {t('pm_color_title')}
                    </div>
                    <Field
                        name={FieldName.color}
                        render={({ input }) => (
                            <Saturation
                                style={{ width: '100%' }}
                                radius={8}
                                hsva={hsva}
                                pointer={({ top, left }) => (
                                    <ColorPointer
                                        classes={style.saturationPointer}
                                        top={top}
                                        left={left}
                                    />
                                )}
                                onChange={handlerChangeColor}
                            />
                        )}
                    />
                </div>
                <div className={cn(styleForm.row, styleForm.row_40)}>
                    <div className={style.fieldWrap}>
                        <Hue
                            radius={8}
                            height={26}
                            hue={hsva.h}
                            pointer={({ left }) => (
                                <ColorPointer
                                    classes={style.huePointer}
                                    left={left}
                                />
                            )}
                            onChange={(newHue) => {
                                setHsva({ ...hsva, ...newHue })
                            }}
                        />
                    </div>
                </div>
                <div className={cn(styleForm.row, styleForm.row_40, styleForm.row_vAlignCenter)}>
                    <div className={style.fieldWrap}>
                        <div className={cn(styleForm.group, styleForm.group_fullWidth, styleForm.group_alignCenter)}>
                            <Field
                                name={FieldName.color}
                                render={({ input }) => (
                                    <>
                                        <div
                                            className={cn(
                                                styleForm.groupItem,
                                                styleForm.groupItem_minWidth,
                                                style.groupItem,
                                                style.colorPreview,
                                            )}
                                            style={{ backgroundColor: input.value }}
                                        />
                                        <Input
                                            {...input}
                                            classes={cn(
                                                styleForm.groupItem,
                                                styleForm.groupItem_maxWidth,
                                                style.groupItem,
                                                style.colorInput,
                                            )}
                                            styleType="dynamic"
                                            pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
                                            onChange={(e) => {
                                                if (e.currentTarget.validity.valid) {
                                                    input.onChange(e.currentTarget.value)
                                                }
                                            }}
                                        />
                                    </>
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className={styleForm.controls_64}>
                    <Button
                        classes={style.action}
                        type="submit"
                        size="size46"
                        text={t('save')}
                        disabled={hasValidationErrors}
                    />
                </div>
            </form>

            <Modal
                isOpen={isOpenModalCatalog}
                size="smallCenter"
                onClose={() => setIsOpenModalCatalog(false)}
            >
                <Modal.Header
                    isCloseButton
                    classesTitle={style.modalCatalogTitle}
                    title={t('screen_pm_select_category')}
                />
                <Modal.Body classes={style.modalCatalogBody}>
                    {catalog && (
                        <CatalogTree
                            data={catalog}
                            onClick={handlerClickCategory}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ShopHeaderForm
