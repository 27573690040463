import React, { useContext } from 'react'
import cn from 'classnames'

import { Chevron } from 'components'
import { Context } from '../../Context'
import style from './SlideDownHeader.module.css'

export type SlideDownHeaderPropType = {
    classes?: string
    onClick?: () => void
}

const SlideDownHeader: React.FC<SlideDownHeaderPropType> = ({
    children,
    classes,
    onClick = () => {},
}) => {
    const { isOpen, setOpen } = useContext(Context)

    const handlerClick = () => {
        setOpen(!isOpen)
        onClick()
    }

    return (
        <div
            className={cn(style.header, classes)}
            role="button"
            aria-hidden="false"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={handlerClick}
        >
            <div className={style.title}>
                {children}
            </div>
            <div className={style.chevronWrap}>
                <Chevron
                    classes={style.chevron}
                    size={16}
                    type={isOpen ? 'up' : 'down'}
                />
            </div>
        </div>
    )
}

export default SlideDownHeader
