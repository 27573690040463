import { AxiosError } from 'axios'
import {
    UseQueryOptions,
    useQuery,
} from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TMarketServiceError,
    TOrderDeliveryProps,
    TOrderDeliveryResponse,
} from 'services/MarketService'
import { QueryCacheKeys } from 'enums'
import { MarketService } from 'services'
import { getRequestError } from 'utils/helpers'
import { useAppSelector } from 'store'

export type TFetchFnParams = TOrderDeliveryProps
export type TQueryFnOpts = UseQueryOptions<
    TOrderDeliveryResponse,
    [string, AxiosError<TMarketServiceError, TFetchFnParams>],
    TOrderDeliveryResponse,
    [string, TFetchFnParams]
>

const key = QueryCacheKeys.orderDeliveryList

/**
 * Hook API company order delivery track info and statuses list
 */
export default function useFetchOrderDeliveryList(params: TFetchFnParams, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()
    const user = useAppSelector((state) => state.user)

    return useQuery([`${key}-${user.id}`, params], () => {
        return MarketService.fetchOrderDeliveryList(params)
            .then(({ data }) => {
                return data
            })
            .catch((err) => {
                return Promise.reject([getRequestError(err) || t('update_error'), err])
            })
    }, opts)
}
