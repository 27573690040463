import React from 'react'
import cn from 'classnames'

import style from './TableListRow.module.css'

export type TableListRowPropType = {
    classes?: string
}

const TableListRow: React.FC<TableListRowPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.row, classes)}>
            {children}
        </div>
    )
}

export default TableListRow
