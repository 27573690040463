import React, { useMemo } from 'react'

import { CardV2, TextSkeleton, SvgResource } from 'components'
import { numberFormat } from 'utils/helpers'
import style from './ShopGoodsPreview.module.css'

type ShopItemPreviewPropType = {
    image?: string
    title?: string
    subtitle?: string
    price?: number
    currency?: string
}

const ShopGoodsPreview: React.FC<ShopItemPreviewPropType> = ({
    image,
    title,
    subtitle,
    price,
    currency,
}) => {
    const priceFormat = useMemo(() => {
        return price && !Number.isNaN(Number(price)) ? numberFormat(price) : ''
    }, [price])

    return (
        <div className={style.wrap}>
            <CardV2>
                <CardV2.Image
                    classes={style.image}
                    isPlaceholder={false}
                    src={image}
                />
                <CardV2.Body
                    classes={style.body}
                    classesTitle={style.title}
                    classesDesc={style.subtitle}
                    title={title}
                    desc={subtitle}
                >
                    {!title && (
                        <div className={style.textSkeletonWrap}>
                            <div className={style.row}>
                                <TextSkeleton classes={style.textSkeleton} />
                            </div>
                            <div className={style.row}>
                                <TextSkeleton classes={style.textSkeleton} />
                            </div>
                        </div>
                    )}
                    {!subtitle && (
                        <div className={style.textSkeletonWrap}>
                            <div className={style.row}>
                                <TextSkeleton classes={style.textSkeleton} />
                            </div>
                            <div className={style.row}>
                                <TextSkeleton classes={style.textSkeleton} />
                            </div>
                        </div>
                    )}
                    <div className={style.priceWrap}>
                        {priceFormat ? (
                            <div className={style.price}>
                                {`${priceFormat} ${currency}`}
                            </div>
                        ) : (
                            <TextSkeleton
                                width={50}
                                classes={style.textSkeleton}
                            />
                        )}
                    </div>
                    <div className={style.actionsWrap}>
                        {price ? (
                            <>
                                <SvgResource
                                    isImgTag={false}
                                    resourceKey="ic_shop_cart_svg"
                                    width={30}
                                    height={30}
                                />
                                <SvgResource
                                    isImgTag={false}
                                    resourceKey="ic_post_share2_andr_svg"
                                    width={30}
                                    height={30}
                                />
                            </>
                        ) : (
                            <>
                                <TextSkeleton
                                    width={35}
                                    height={27}
                                    classes={style.textSkeleton}
                                />
                                <TextSkeleton
                                    width={35}
                                    height={27}
                                    classes={style.textSkeleton}
                                />
                            </>
                        )}
                    </div>
                </CardV2.Body>
            </CardV2>
        </div>
    )
}

export default ShopGoodsPreview
