import React from 'react'
import { Collapse } from 'react-collapse'

export type BlockBodyCollapsePropType = {
    classes?: string
    classesContent?: string
    isOpen: boolean
}

const BlockBodyCollapse: React.FC<BlockBodyCollapsePropType> = ({
    children,
    classes,
    classesContent,
    isOpen,
}) => {
    return (
        <Collapse theme={{ collapse: classes, content: classesContent }} isOpened={isOpen}>
            {children}
        </Collapse>
    )
}

export default BlockBodyCollapse
