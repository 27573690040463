import { AxiosError } from 'axios'
import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import {
    TDirectoryServiceError,
    TCurrenciesProps,
    TCurrenciesResponse,
} from 'services/DirectoryService'
import { QueryCacheKeys } from 'enums'
import { DirectoryService } from 'services'

export type TFetchFnParams = TCurrenciesProps
export type TFetchFnError = AxiosError<TDirectoryServiceError, TFetchFnParams>
export type TError = [string, TFetchFnError]
export type TQueryKey = [string, TFetchFnParams]
export type TQueryFnOpts = UseQueryOptions<TCurrenciesResponse, TError, TCurrenciesResponse, TQueryKey>

const key = QueryCacheKeys.currencies

/**
 * Хук API получение списка валют - справочник
 */
export default function useFetchCurrencies(params: TFetchFnParams = {}, opts: TQueryFnOpts = {}) {
    const { t } = useTranslation()

    return useQuery([key, params], () => {
        return DirectoryService.fetchCurrencies()
            .then(({ data }) => {
                return data
            })
            .catch((err: TFetchFnError) => {
                return Promise.reject([t('update_error'), err])
            })
    }, opts)
}
