import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IBreadcrumb, IOrder } from 'interfaces'
import { APP_URL, BREAKPOINTS } from 'config/app'
import { ContentContainer, CardContainer, Block } from 'layout'
import {
    PageTitle,
    Breadcrumbs,
    Button,
    Loader,
    ErrorMsg,
    Modal,
} from 'components'
import { useFetchCompanyOrder, useQueryDataCompanyOrder } from 'containers/Orders/hooks'
import { OrderDetailsAction, OrderDeliveryDetailsAction, OrderCommentAction } from 'form-actions'
import { useWindowResize } from 'hooks'
import { dateTimeFormat, defaultDateFormat } from 'utils/helpers'
import {
    OrdersOrderOwner,
    OrdersOrderInfo,
    OrdersOrderDelivery,
    OrdersOrderContentsList,
    OrdersOrderHistoryList,
} from './components'
import style from './OrdersOrder.module.css'

/**
 * Company order details page
 */
const OrdersOrder: React.FC = () => {
    const { storeId, id } = useParams<{ storeId: string, id: string }>()
    const [windowWidth] = useWindowResize()
    const { t, i18n } = useTranslation()

    const [isEditComment, setIsEditComment] = useState(false)
    const [isOpenModalOrderDetails, setIsOpenModalOrderDetails] = useState(false)
    const [isOpenModalDeliveryDetails, setIsOpenModalDeliveryDetails] = useState(false)

    const {
        isInitialLoading: isLoading,
        data: dataCompanyOrder,
        error: errorCompanyOrder,
    } = useFetchCompanyOrder({
        storeId: Number(storeId),
        orderId: Number(id),
    })

    const { setQueryData: setQueryDataCompanyOrder } = useQueryDataCompanyOrder()

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        return [
            { id: 1, name: t('orders'), url: APP_URL.companyOrders },
            { id: 2, name: `${t('order')} ${id}` },
        ]
    }, [id])

    const orderDateTime = useMemo(() => {
        return dataCompanyOrder
            && `${defaultDateFormat(dataCompanyOrder.create_date)} ${dateTimeFormat(dataCompanyOrder.create_date, i18n.language, { timeStyle: 'medium' })}`
    }, [dataCompanyOrder])

    const handlerSuccessUpdatePost = (value: IOrder) => {
        setQueryDataCompanyOrder({
            storeId: Number(storeId),
            orderId: Number(id),
        }, (queryData) => {
            if (queryData) {
                return { ...queryData, ...value }
            }

            return undefined
        })
    }

    return (
        <>
            <ContentContainer classes={style.content} size="three-quarters">
                <PageTitle>
                    <Breadcrumbs items={breadcrumbs} />
                </PageTitle>

                {errorCompanyOrder && (
                    <ErrorMsg error={errorCompanyOrder[0]} />
                )}

                {isLoading && (
                    <Loader />
                )}

                {dataCompanyOrder && (
                    <>
                        <CardContainer classes={style.cardContainer} gap="15px">
                            <Block classes={style.blockCard}>
                                <Block.Header classes={style.blockCardHeader}>
                                    <div className={style.blockTitle}>
                                        {t('order_owner')}
                                    </div>
                                </Block.Header>
                                <OrdersOrderOwner
                                    classes={style.blockCardBody}
                                    data={dataCompanyOrder}
                                />
                            </Block>
                            <Block classes={style.blockCard}>
                                <Block.Header classes={style.blockCardHeader}>
                                    <div className={style.blockTitle}>
                                        {`${t('order')} ${t('from')}`}
                                    </div>
                                    <div className={style.blockHeaderInfo}>
                                        {orderDateTime}
                                    </div>
                                </Block.Header>
                                <OrdersOrderInfo
                                    classes={style.blockCardBody}
                                    data={dataCompanyOrder}
                                    onClickOrder={() => setIsOpenModalOrderDetails(true)}
                                />
                            </Block>
                            <Block classes={style.blockCard}>
                                <Block.Header classes={style.blockCardHeader}>
                                    <div className={style.blockTitle}>
                                        {t('delivery')}
                                    </div>
                                </Block.Header>
                                <OrdersOrderDelivery
                                    classes={style.blockCardBody}
                                    data={dataCompanyOrder}
                                    onClickDelivery={() => setIsOpenModalDeliveryDetails(true)}
                                />
                            </Block>
                        </CardContainer>
                        <Block classes={cn(style.block, style.block_row)}>
                            <Block.Header classes={style.blockHeader}>
                                <Block.HeaderInner classes={style.blockHeaderInner}>
                                    <div className={style.blockTitle}>
                                        {t('order_contents')}
                                    </div>
                                </Block.HeaderInner>
                            </Block.Header>
                            <div className={style.blockBody}>
                                <OrdersOrderContentsList
                                    data={dataCompanyOrder}
                                />
                            </div>
                        </Block>
                        <Block classes={cn(style.block, style.block_row, style.block_gray)}>
                            <Block.Header classes={style.blockHeader}>
                                <div className={style.blockTitle}>
                                    {t('order_comment')}
                                </div>
                                <div className={style.blockActions}>
                                    <Button
                                        textUpper={false}
                                        styleType="small"
                                        size="size30"
                                        text={isEditComment ? t('hide') : t('refresh')}
                                        onClick={() => setIsEditComment((prevState) => !prevState)}
                                    />
                                </div>
                            </Block.Header>
                            <div className={style.blockBody}>
                                {isEditComment ? (
                                    <OrderCommentAction
                                        orderProps={{
                                            storeId: Number(storeId),
                                            orderId: Number(id),
                                            comment: dataCompanyOrder.comment,
                                        }}
                                        onError={() => {}}
                                        onSuccess={handlerSuccessUpdatePost}
                                    />
                                ) : (
                                    <div className={style.comment}>
                                        {dataCompanyOrder.comment}
                                    </div>
                                )}
                            </div>
                        </Block>
                        {!!dataCompanyOrder.logs?.length && (
                            <Block classes={cn(style.block, style.block_row)}>
                                <Block.Header classes={style.blockHeader}>
                                    <Block.HeaderInner classes={cn(
                                        style.blockHeaderInner,
                                        style.blockHeaderInner_gray,
                                    )}
                                    >
                                        <div className={style.blockTitle}>
                                            {t('order_changes')}
                                        </div>
                                    </Block.HeaderInner>
                                </Block.Header>
                                <div className={style.blockBody}>
                                    <OrdersOrderHistoryList
                                        data={dataCompanyOrder}
                                    />
                                </div>
                            </Block>
                        )}
                    </>
                )}
            </ContentContainer>

            <Modal
                mobileFullScreen
                isOpen={isOpenModalOrderDetails}
                size="small2Center"
                onClose={() => setIsOpenModalOrderDetails(false)}
            >
                <Modal.Header
                    isBackButton={windowWidth < BREAKPOINTS.tabletLandscape}
                    isCloseButton={windowWidth >= BREAKPOINTS.tabletLandscape}
                    classes={style.modalHeader}
                    classesTitle={style.modalTitle}
                    title={`${t('order_details')} №${dataCompanyOrder?.id}`}
                    titlePos={windowWidth < BREAKPOINTS.tabletLandscape ? 'center' : 'left'}
                />
                <Modal.Body classes={style.modalBody}>
                    <OrderDetailsAction />
                </Modal.Body>
            </Modal>
            <Modal
                mobileFullScreen
                isOpen={isOpenModalDeliveryDetails}
                size="small2Center"
                onClose={() => setIsOpenModalDeliveryDetails(false)}
            >
                <Modal.Header
                    isBackButton={windowWidth < BREAKPOINTS.tabletLandscape}
                    isCloseButton={windowWidth >= BREAKPOINTS.tabletLandscape}
                    classes={style.modalHeader}
                    classesTitle={style.modalTitle}
                    title={t('order_delivery_details')}
                    titlePos={windowWidth < BREAKPOINTS.tabletLandscape ? 'center' : 'left'}
                />
                <Modal.Body classes={style.modalBody}>
                    <OrderDeliveryDetailsAction />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default OrdersOrder
