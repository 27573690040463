import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IDeliveryCost, IStoreCurrency } from '../../../../interfaces'
import { Radio } from '../../../../components'
import { plural, priceFormat } from '../../../../utils/helpers'
import style from './StoreOrderPickupListItem.module.css'

type StoreOrderPickupListItemPropType = {
    data: IDeliveryCost
    currency?: IStoreCurrency
    checked?: boolean
    onChange: (id: number) => void
}

const StoreOrderPickupListItem: React.FC<StoreOrderPickupListItemPropType> = ({
    data,
    currency,
    checked,
    onChange,
}) => {
    const { t } = useTranslation()

    const days = useMemo(() => {
        const { delivery_time_from, delivery_time_to } = data || {}
        let result = ''

        if (delivery_time_from || delivery_time_from >= 0) {
            result += delivery_time_from
        }
        if (delivery_time_to) {
            result += ` - ${delivery_time_to}`
        }

        return result
    }, [data])

    const dayPlural = useMemo(() => {
        const { delivery_time_from, delivery_time_to } = data || {}
        const forms = [t('days_1x'), t('days_2x'), t('days_5x')]

        if (delivery_time_to) {
            return plural(delivery_time_to, forms)
        }
        if (delivery_time_from || delivery_time_from === 0) {
            return plural(delivery_time_from, forms)
        }

        return ''
    }, [data])

    const cost = useMemo(() => priceFormat(data?.cost, currency), [data, currency])

    const handlerClick = () => {
        onChange(data.id)
    }

    return (
        // eslint-disable-next-line
        <div className={cn(style.item, checked ? style.item_active : '')} onClick={handlerClick}>
            <Radio
                classes={style.radio}
                name={String(data.id)}
                checked={!!checked}
            />
            <div className={style.itemBody}>
                <div>
                    {data.comment}
                </div>
                <div className={style.info}>
                    {days && (
                        <span className={cn(style.infoItem, style.time)}>
                            {days}
                            {' '}
                            {dayPlural}
                        </span>
                    )}
                    {cost && (
                        <span className={cn(style.infoItem, style.cost)}>
                            {cost}
                        </span>
                    )}
                </div>
            </div>
        </div>
    )
}

export default StoreOrderPickupListItem
