import React from 'react'
import cn from 'classnames'

import style from './TableListBody.module.css'

export type TableListBodyPropType = {
    classes?: string
}

const TableListBody: React.FC<TableListBodyPropType> = ({ children, classes }) => {
    return (
        <div className={cn(style.body, classes)}>
            {children}
        </div>
    )
}

export default TableListBody
