import React from 'react'
import { Field, useFormState } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { IStoreLoyalty } from 'interfaces'
import { Loader, Input, Button } from 'components'

import styleForm from 'styles/modules/form.module.css'
import style from './CompanyStoreSettingsForm.module.css'

export enum FieldName {
    values_for_store = 'values_for_store',
    cashback_value = 'cashback_value',
    kickback_value = 'kickback_value',
    max_level = 'max_level',
    need_save = 'need_save',
    has_advanced = 'has_advanced'
}

export type FormDataType = {
    [FieldName.values_for_store]: boolean
    [FieldName.cashback_value]: number
    [FieldName.kickback_value]: number
    [FieldName.max_level]: number
    [FieldName.need_save]: boolean
    [FieldName.has_advanced]: boolean
}

export type CompanyStoreSettingsPropType = {
    classes?: string
    isSubmitting: boolean
    storeLoyalty?: IStoreLoyalty
    onChange: (data: FormDataType) => void
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}

const MIN_VALUE = 0
const MAX_VALUE = 50

export const formDataInitial: FormDataType = {
    [FieldName.values_for_store]: false,
    [FieldName.cashback_value]: 0,
    [FieldName.kickback_value]: 0,
    [FieldName.max_level]: 1,
    [FieldName.need_save]: false,
    [FieldName.has_advanced]: false,
}

const CompanyStoreSettingsForm: React.FC<CompanyStoreSettingsPropType> = ({
    classes,
    isSubmitting,
    storeLoyalty,
    onChange,
    onSubmit,
}) => {
    const { t } = useTranslation()

    const { values } = useFormState<FormDataType>()

    return (
        <form className={cn(classes, { [styleForm.submitting]: isSubmitting })} onSubmit={onSubmit}>
            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.title}>
                    {t('reg_business_kickback_title')}
                </div>
                <div className={style.subTitle}>
                    {t('reg_business_kickback_subtitle')}
                </div>
                <Field
                    name={FieldName.kickback_value}
                    render={({ input }) => (
                        <div className={cn(styleForm.group, style.group)}>
                            <Input
                                {...input}
                                styleType="clear"
                                type="range"
                                inputMode="none"
                                min={MIN_VALUE}
                                max={MAX_VALUE}
                                onChange={({ currentTarget }) => {
                                    input.onChange(Number(currentTarget.value))
                                    onChange({ ...values, [FieldName.kickback_value]: Number(currentTarget.value) })
                                }}
                            />
                            <Input
                                {...input}
                                classes={style.input}
                                styleType="default"
                                type="number"
                                inputMode="numeric"
                                min={MIN_VALUE}
                                max={MAX_VALUE}
                                pattern="[1-9]+[0-9]*"
                                onChange={(e) => {
                                    if (e.currentTarget.validity.valid) {
                                        input.onChange(e.currentTarget.value)
                                    }
                                }}
                            />
                        </div>
                    )}
                />
                <div className={style.info}>
                    {!storeLoyalty ? (
                        <Loader />
                    ) : (
                        <>
                            <div
                                className={style.recommendedText}
                                style={{ color: storeLoyalty.recommended.kickback_color }}
                            >
                                {storeLoyalty.recommended.kickback_text}
                            </div>
                            <div className={style.recommendedPercent}>
                                {t('reg_business_store_spends_percent')}
                                {' '}
                                {storeLoyalty.kickback_store_spends_percent}
                                {' %'}
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className={cn(styleForm.row, styleForm.row_20)}>
                <div className={style.title}>
                    {t('reg_business_cashback_title')}
                </div>
                <div className={style.subTitle}>
                    {t('reg_business_cashback_subtitle')}
                </div>
                <Field
                    name={FieldName.cashback_value}
                    render={({ input }) => (
                        <div className={cn(styleForm.group, style.group)}>
                            <Input
                                {...input}
                                styleType="clear"
                                type="range"
                                inputMode="none"
                                min={MIN_VALUE}
                                max={MAX_VALUE}
                                onChange={({ currentTarget }) => {
                                    input.onChange(Number(currentTarget.value))
                                    onChange({ ...values, [FieldName.cashback_value]: Number(currentTarget.value) })
                                }}
                            />
                            <Input
                                {...input}
                                classes={style.input}
                                styleType="default"
                                type="number"
                                inputMode="numeric"
                                min={MIN_VALUE}
                                max={MAX_VALUE}
                                pattern="[1-9]+[0-9]*"
                                onChange={(e) => {
                                    if (e.currentTarget.validity.valid) {
                                        input.onChange(e.currentTarget.value)
                                    }
                                }}
                            />
                        </div>
                    )}
                />
                <div className={style.info}>
                    {!storeLoyalty ? (
                        <Loader />
                    ) : (
                        <>
                            <div
                                className={style.recommendedText}
                                style={{ color: storeLoyalty.recommended.cashback_color }}
                            >
                                {storeLoyalty.recommended.cashback_text}
                            </div>
                            <div className={style.recommendedPercent}>
                                {t('reg_business_store_spends_percent')}
                                {' '}
                                {storeLoyalty.cashback_store_spends_percent}
                                {' %'}
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={cn(styleForm.row, styleForm.row_20, style.controls)}>
                <Button
                    type="submit"
                    size="size44"
                    text={t('save')}
                />
            </div>
        </form>
    )
}

export default CompanyStoreSettingsForm
