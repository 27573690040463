import React, { useEffect } from 'react'
import {
    Switch,
    Route,
    useLocation,
} from 'react-router-dom'

import { APP_URL } from 'config/app'
import { scrollTop } from 'utils/helpers'
import ShopCatalog from './ShopCatalog/ShopCatalog'
import ShopFolder from './ShopFolder/ShopFolder'
import ShopHeader from './ShopHeader/ShopHeader'
import ShopGoods from './ShopGoods/ShopGoods'

/**
 * Shop - business store
 */
const Shop: React.FC = () => {
    const { pathname } = useLocation()

    useEffect(() => {
        const scrollTimerId = setTimeout(scrollTop)

        return () => {
            clearTimeout(scrollTimerId)
        }
    }, [pathname])

    return (
        <Switch>
            <Route exact path={APP_URL.shop} component={ShopCatalog} />
            <Route path={APP_URL.shopCatalog} component={ShopCatalog} />
            <Route exact path={APP_URL.shopFolder} component={ShopFolder} />
            <Route path={APP_URL.shopFolderEdit} component={ShopFolder} />
            <Route exact path={APP_URL.shopHeader} component={ShopHeader} />
            <Route path={APP_URL.shopHeaderEdit} component={ShopHeader} />
            <Route exact path={APP_URL.shopGoods} component={ShopGoods} />
            <Route path={APP_URL.shopGoodsEdit} component={ShopGoods} />
        </Switch>
    )
}

export default Shop
