import React, { CanvasHTMLAttributes, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ChartConfiguration, ChartDataset } from 'components/Chart/Chart'
import { Chart } from 'components'
import noDataText from 'components/Chart/plugins/noDataText'
import { dateTimeFormat } from 'utils/helpers'
import { vGray14 } from 'styles/modules/variables.module.css'

enum ChartItemField {
    nal,
    terminal,
    getaway,
    bank,
    cv,
    total,
}

type ReportsBarViewPropType = {
    data: any[] // FIXME on backend
    options?: CanvasHTMLAttributes<HTMLCanvasElement>
    isCumulative?: boolean
}

type TChartItemData = [
    nal: number,
    terminal: number,
    getaway: number,
    bank: number,
    cv: number,
    total: number,
]

const DATASET_PARAMS: Partial<ChartDataset> = {
    minBarLength: 1,
    maxBarThickness: 30,
    borderWidth: 0,
}

/**
 * Chart item values sorted by chart item field
 */
const getChartItemValues = (data: TChartItemData) => {
    return Object.entries(data).reduce<number[]>((acc, [key, value]) => {
        acc[ChartItemField[key]] = value
        return acc
    }, [])
}

const ReportsBarView: React.FC<ReportsBarViewPropType> = ({ data, options, isCumulative }) => {
    const { t, i18n } = useTranslation()

    const chartConfig: ChartConfiguration = useMemo(() => ({
        type: 'bar',
        data: {
            labels: data.map((item) => dateTimeFormat(item.date, i18n.language, { day: '2-digit', month: 'short' })),
            datasets: data.reduce((acc, values) => {
                const dataItem = getChartItemValues(values)

                if (!acc.length) {
                    const datasets = isCumulative ? getDatasetCumulative(dataItem) : getDatasetStacked(dataItem)
                    return initDatasets(datasets)
                }

                return isCumulative
                    // @ts-ignore FIXME type by backend
                    ? acc.map((item) => ({
                        ...item,
                        // @ts-ignore FIXME
                        data: [...item.data, item.data.reduce((a, v) => a + v, dataItem[ChartItemField.total])],
                    }))
                    // @ts-ignore FIXME
                    : acc.map((item, index) => ({ ...item, data: [...item.data, dataItem[index]] }))
            }, []),
        },
        options: {
            scales: {
                x: {
                    stacked: !isCumulative,
                    beginAtZero: true,
                },
                y: {
                    stacked: !isCumulative,
                },
            },
            plugins: {
                legend: {
                    display: !isCumulative,
                    position: 'bottom',
                    align: 'start',
                    // https://www.chartjs.org/docs/3.9.1/configuration/legend.html#legend-label-configuration
                },
                noDataText: {
                    enabled: !data.length,
                    font: '16px',
                    fontColor: vGray14,
                    text: t('no_data'),
                },
            },
        },
        plugins: [noDataText],
    }), [data])

    function initDatasets(values: ChartDataset[]) {
        return values.map((item) => ({
            ...DATASET_PARAMS,
            ...item,
        }))
    }

    function getDatasetStacked(values: number[]) {
        return [{
            label: t('reports_by_cash'),
            backgroundColor: '#FF0000',
            stack: 'Stack 0',
            data: [values[ChartItemField.nal]],
        }, {
            label: t('reports_by_terminal'),
            backgroundColor: '#0034FF',
            stack: 'Stack 0',
            data: [values[ChartItemField.terminal]],
        }, {
            label: t('reports_by_getaway'),
            backgroundColor: '#26B1FF',
            stack: 'Stack 0',
            data: [values[ChartItemField.getaway]],
        }, {
            label: t('reports_bank'),
            backgroundColor: '#DD9325',
            stack: 'Stack 0',
            data: [values[ChartItemField.bank]],
        }, {
            label: t('reports_by_bonus'),
            backgroundColor: '#9747FF',
            stack: 'Stack 0',
            data: [values[ChartItemField.cv]],
        }, {
            label: t('reports_just_money'),
            backgroundColor: '#58D60C',
            stack: 'Stack 1',
            data: [values[ChartItemField.total]],
        }]
    }

    function getDatasetCumulative(values: number[]) {
        return [{
            backgroundColor: '#000',
            data: [values[ChartItemField.total]],
        }]
    }

    return (
        <Chart
            config={chartConfig}
            options={options}
        />
    )
}

export default ReportsBarView
