import React, { useState } from 'react'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { IPersonalStore } from 'interfaces'
import { TAddPersonalStoreProps, TUpdatePersonalStoreProps } from 'services/MarketService'
import { FormDataType, FieldName, formDataInitial } from 'forms/StoreForm/StoreForm'
import { REGEXP_DATA_IMAGE_BASE64 } from 'config/app'
import { useFetchMarkerCategories, useMutationPersonalStore } from 'containers/Market/hooks'
import { useFetchCurrencies } from 'containers/App/hooks'
import { StoreForm } from 'forms'
import { getObjFlatValues, getRequestError } from 'utils/helpers'

type TCommonPersonalStoreProps = Pick<TAddPersonalStoreProps, 'companyId'> | TUpdatePersonalStoreProps

type StoreActionPropType = {
    storeProps: TCommonPersonalStoreProps
    onSuccess?: (data: IPersonalStore) => void
    onError?: (err?: string) => void
}

const StoreAction: React.FC<StoreActionPropType> = ({
    storeProps,
    onSuccess = () => {},
    onError = () => {},
}) => {
    const { t } = useTranslation()

    const [initialValues] = useState<FormDataType>({
        ...formDataInitial,
        ...getObjFlatValues<FormDataType>(storeProps),
    })

    const { data: dataCategories } = useFetchMarkerCategories()

    const { data: dataCurrencies } = useFetchCurrencies()

    const { add: addPersonalStore, update: updatePersonalStore } = useMutationPersonalStore()

    const handlerSubmit = ({
        id,
        companyId,
        icon,
        logo,
        notifyShopAdmins,
        notifyShopCashiers,
        notifySomebodyElse,
        ...params
    }: FormDataType) => {
        const storeParams = {
            icon: icon && REGEXP_DATA_IMAGE_BASE64.test(icon) ? icon.replace(REGEXP_DATA_IMAGE_BASE64, '') : icon,
            logo: logo && REGEXP_DATA_IMAGE_BASE64.test(logo) ? logo.replace(REGEXP_DATA_IMAGE_BASE64, '') : logo,
            emailAlertSettings: {
                notifyShopAdmins,
                notifyShopCashiers,
                notifySomebodyElse,
            },
            ...params,
        }

        if (id) {
            return updatePersonalStoresAction({ id, ...storeParams })
                .then(onSuccess)
                .catch(onError)
        }
        if (companyId) {
            return addPersonalStoresAction({ companyId, ...storeParams })
                .then(onSuccess)
                .catch(onError)
        }

        onError()
        return Promise.reject()
    }

    const handlerErrorFile = (err: any) => {
        return getRequestError(err)
    }

    function addPersonalStoresAction(params: TAddPersonalStoreProps) {
        return addPersonalStore.mutateAsync(params)
    }

    function updatePersonalStoresAction(params: TUpdatePersonalStoreProps) {
        return updatePersonalStore.mutateAsync(params)
    }

    return (
        <Form
            initialValues={initialValues}
            onSubmit={handlerSubmit}
            validate={(values) => {
                const errors = {}

                if (values[FieldName.name] === undefined || values[FieldName.name] === '') {
                    errors[FieldName.name] = t('error_field_is_empty')
                }
                if (!values[FieldName.category]) {
                    errors[FieldName.category] = t('error_field_is_empty')
                }
                if (!values[FieldName.currency]) {
                    errors[FieldName.currency] = t('error_field_is_empty')
                }

                return errors
            }}
            render={({ handleSubmit, submitting }) => (
                <StoreForm
                    isSubmitting={submitting}
                    categories={dataCategories}
                    currencies={dataCurrencies}
                    onErrorFile={handlerErrorFile}
                    onSubmit={handleSubmit}
                />
            )}
        />
    )
}

export default StoreAction
