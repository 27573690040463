import React, { useMemo, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { IBreadcrumb } from 'interfaces'
import { APP_URL } from 'config/app'
import { ContentContainer } from 'layout'
import { PageHeader, Breadcrumbs } from 'components'
import { DeliveryTypeAction } from 'form-actions'
import style from './DeliveryTypeAdd.module.css'

const DeliveryTypeAdd: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const history = useHistory()
    const { t } = useTranslation()

    // FIXME fetch data
    const [dataServices] = useState([
        {
            id: 1,
            name: 'Project V Европа',
            store: ['Vision - Europe (OLD) PROJECT', 'V - Первый заказ %'], // [1, 2],
            type: [{ id: 1, name: 'Самовывоз' }, { id: 2, name: 'Курьер' }],
            rate: [12, 12],
        },
        {
            id: 2,
            name: 'Почта России',
            store: ['NRK1987. для COFFEECELL sales'], // [3],
            type: [],
            rate: [12],
        },
    ])

    const deliveryService = useMemo(() => {
        return dataServices?.find((item) => item.id === Number(id))
    }, [dataServices])

    const breadcrumbs = useMemo<IBreadcrumb[]>(() => {
        const page = { id: 1, name: t('delivery_add_type') }
        return deliveryService ? [page, { id: 2, name: deliveryService.name }] : [page]
    }, [deliveryService])

    const handlerClickBack = () => {
        history.push(APP_URL.delivery)
    }

    return (
        <ContentContainer size="three-quarters">
            <PageHeader classesContent={style.headerContent} onClickBack={handlerClickBack}>
                <Breadcrumbs items={breadcrumbs} />
            </PageHeader>

            <DeliveryTypeAction
                classesBody={style.formBody}
                classesFooter={style.formFooter}
            />
        </ContentContainer>
    )
}

export default DeliveryTypeAdd
