import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import { Button, Chevron } from 'components'
import { Context } from '../Context'
import style from './ModalHeader.module.css'

export type ModalHeaderPropType = {
    classes?: string
    classesTitle?: string
    title?: string
    titlePos?: 'center' | 'left'
    isBackButton?: boolean
    isCloseButton?: boolean
}

const ModalHeader: React.FC<ModalHeaderPropType> = ({
    children,
    classes,
    classesTitle,
    title,
    titlePos = 'center',
    isBackButton,
    isCloseButton,
}) => {
    const { t } = useTranslation()

    const { toClose } = useContext(Context)

    return (
        <div
            className={cn(
                style.header,
                {
                    [style.header_pRight]: isCloseButton,
                },
                classes,
            )}
        >
            {isCloseButton && (
                <Button
                    classes={style.close}
                    styleType="text"
                    onClick={toClose}
                />
            )}
            {isBackButton && (
                <Button
                    classes={style.back}
                    styleType="transparent"
                    title={t('back')}
                    onClick={toClose}
                >
                    <Chevron size={15} type="left" />
                </Button>
            )}
            {title && (
                <div className={cn(style.title, style[`title_${titlePos}`], classesTitle)}>
                    {title}
                </div>
            )}

            {children}
        </div>
    )
}

export default ModalHeader
